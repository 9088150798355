import Reflux from "reflux";

import SFLeadPanelActions from "../actions/salesForce/sFLeadPanelActions";

const SFLeadPanelActionsStore = Reflux.createStore({
  listenables: SFLeadPanelActions,
  onRefreshLeads: function(){
    this.trigger();
  }
});

window.SFLeadPanelActionsStore = SFLeadPanelActionsStore;
export default SFLeadPanelActionsStore;
