const OrgEntity = (props) => {
  const publishableConceptRefs = [...props.org.publishable_concept_refs];
  publishableConceptRefs.forEach((e, i) => publishableConceptRefs[i]=e.substring(0,12));
  const industries = <b>({publishableConceptRefs.join(", ")})</b>
  const location = props.org.location ? " (" + props.org.location + ")" : null;
  return (
    <span><a href={props.org.edit_link} target="_blank">{props.org.name}</a>{location} {industries}; </span>
  );
};

export default OrgEntity;
