const RestoreEventButton = (props) => {
  const restoreEvent = (e) => {
    e.preventDefault();
    const url = "/commentary_article/" + props._id + "/awaiting_publication";
    $.post(url)
      .done(function (result) {
        $("#pm_" + props._id).fadeOut("fast");
      }.bind(this))
      .fail(function (xhr, ajaxOptions, thrownError) {
        if (xhr.status === 401) {
          alert("Un-authorized status received");
          $("#event_modal").modal("hide");
          window.location = "/admin_home";
        }
        else {
          // console.log(thrownError);
        }
      });
  }
  return (
    <a onClick={restoreEvent}  className="btn btn-warning " id="rspec_article_restore_btn">Restore</a>
  );
};

export default RestoreEventButton;
