const CancelColumnsButton = createReactClass({
  mixins: [Reflux.listenTo(SubmitFormStore,"disable_button")],
  getInitialState: function(){
    return {
      columns_chosen:this.props.columns_chosen,
      submit_form:this.props.columns_chosen
    }
  },
  disable_button: function(state) {
    this.setState({
      submit_form: true
    });
  },
  submit_changes: function (e){
    e.stopPropagation();
    ColumnControlsActions.toggleColumnControls('');
    ColumnsChosenActions.reset({...this.state.columns_chosen})
  },
  render: function () {
    const class_names = ["cancel_columns_button", "button-small"];
    if(this.state.submit_form === true){
      class_names.push("button-disable");
    }
    else {
      class_names.push("button-secondary");
    }
    return (
      <button type="reset" onClick={this.submit_changes} className={class_names.join(' ')}>Cancel</button>
    );
  }
});

export default CancelColumnsButton;
