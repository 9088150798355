import { clinicalStageLabels } from "../../../config/clinicalStages";

const EventProperties = (props) => {
  if(props.event.independent_properties && props.event.independent_properties.clin_stage) {
    const eventProperties = props.event.independent_properties.clin_stage.map(function(prop){
      return <span className="rspec_event_property">{clinicalStageLabels[prop]}; </span>;
    });
    return (
      <div className="rspec_event_properties_container">
        <p><b className='rspec_event_properties'>Properties:</b> <span>{eventProperties}</span></p>
      </div>
    );
  }
  else {
    return false;
  }
};

export default EventProperties;
