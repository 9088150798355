/** A schema to describe the time frame selected slice.
 * display is a set of options to display, a format for moment, and some language glue for the component
 * from is an object representing a from date from a date range, if chosen
 * selected is the current time option selected
 * to is an object representing a to date from a date range, if chosen
 * */
export const FacetSearchTimeFrameSlice = {
  // this really should be handled by our YAMLs for locale EN
  display: {
    between: "Between ",
    format: {
      moment: "MM-DD-YYYY",
      description: "MMM Do, YYYY"
    },
    in: "In ",
    options: {
      time_any: "anytime",
      time_last_24h: "the last 24 hours",
      time_last_3d: "the last 3 days",
      time_last_wk: "the last week",
      time_last_mon: "the last month",
      time_last_3m: "the last 3 months",
      time_last_6m: "the last 6 months",
      time_last_1y: "the last year",
      time_custom: "custom date range",
    },
  },
  from: null,
  selected: "time_any",
  to: null,
}
