import ArticleEventDetails from "./articleEventDetails";
import IncludedOrgList from "./includedOrgList";
import ArticleEventActionPanel from "./articleEventActionPanel";
import ExcludedOrgList from "./excludedOrgList";

const ArticleEventPanel = createReactClass({
  getInitialState: function () {
    if(this.props.article.status === "PUBLISHED"){
      if (this.props.article.event) {
        const eventEntities = _.map(this.props.article.event.entities, function(ent){return ent.name});
        const excludedOrgs = _.reject(this.props.article.orgs, function(org){ return _.contains(this, org.name)}.bind(eventEntities));
        const retainedOrgs = _.filter(this.props.article.orgs, function(org){ return _.contains(this, org.name)}.bind(eventEntities));
        const initState = _.extend({excludedOrgs:excludedOrgs}, this.props.article);
        initState.orgs = retainedOrgs;
        return initState;
      } else { // no event, but published
        const initState = _.extend( {excludedOrgs:[]}, this.props.article)
        return initState
      }
    }
    else if(this.props.article.status === "AWAITING_PUBLISHING" || this.props.article.status === "IGNORED"){
      const excludedOrgs = _.reject(this.props.article.orgs, function(org){ return _.contains(this, org._id)}.bind(this.props.article.predicted_org_ids));
      const retainedOrgs = _.filter(this.props.article.orgs, function(org){ return _.contains(this, org._id)}.bind(this.props.article.predicted_org_ids));
      const initState = _.extend({excludedOrgs:excludedOrgs}, this.props.article);
      initState.orgs = retainedOrgs;
      return initState;
    }
    else{
      return _.extend({excludedOrgs:[]}, this.props.article);
    }
  },
  toggleOrgOnList: function(orgId){
    if(this.props.article.status === "PUBLISHED"){
      return false;
    }
    let toggleOrg = _.find(this.state.orgs, function(org){return org._id  === this.id}, {id: orgId});
    if(toggleOrg){
      const updatedOrgs =  _.reject(this.state.orgs, function(org){return org._id === this.id}, {id: orgId});
      const updateExcludedOrgs = [...this.state.excludedOrgs];
      updateExcludedOrgs.push(toggleOrg);
      this.setState({orgs: updatedOrgs, excludedOrgs: updateExcludedOrgs});
    }
    else {
      toggleOrg = _.find(this.state.excludedOrgs, function(org){return org._id  === this.id}, {id: orgId});
      if(toggleOrg){
        const updatedOrgs =  _.reject(this.state.excludedOrgs, function(org){return org._id === this.id}, {id: orgId});
        this.state.orgs.push(toggleOrg);
        this.setState({excludedOrgs: updatedOrgs, orgs: this.state.orgs});
      }
    }
  },
  render: function () {
    let eventDetails;
    if(this.state.event){
      eventDetails = <ArticleEventDetails {...this.state.event} />;
    } else if (this.props.article.status === "PUBLISHED") {
      eventDetails = <div className="event_detail_panel">
        <p><i>No event associated with published article</i></p>
      </div>
    }
    return (
      <div style={{color:'black'}}>
        <IncludedOrgList {...this.state} article_id={this.props.article._id} toggleOrgHandler={this.toggleOrgOnList}/>
        <ArticleEventActionPanel {...this.state} />
        <ExcludedOrgList {...this.state} article_id={this.props.article._id} toggleOrgHandler={this.toggleOrgOnList}/>
        {eventDetails}
      </div>
    );
  }
});

window.ArticleEventPanel = ArticleEventPanel;
export default ArticleEventPanel;
