import ComponentFromHTML from "@javascript/reactComponents/shared/ComponentFromHTML";
import LegacyModalService from "@javascript/globals/LegacyModalService";
import { Modal } from "@javascript/reactComponents/lds/modal/Modal";

export default function LDSFlagEventModal({ legacyModalContent }) {
  return (
    <Modal open={true} onClose={LegacyModalService.closeModal}>
      <ComponentFromHTML html={legacyModalContent} />
    </Modal>
  );
}

window.LDSFlagEventModal = LDSFlagEventModal;
