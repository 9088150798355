import EditNonOrgEntity from "./editNonOrgEntity";

const EditEventNonOrgEntities = (props) => {
  const editNonOrgEntities = props.ents.map(function (entity) {
    return <EditNonOrgEntity key={entity.rec_id} ent={entity}/>;
  });
  return (
    <div>{editNonOrgEntities}</div>
  );
};

export default EditEventNonOrgEntities;
