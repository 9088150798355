import AddEntityLinks from "./addEntityLinks";
import { eventEditModalStyle } from "../../../config/administrateEvent";

const AddEntityLinksPanel = (props) => {
  return (
    <tr>
      <td style={eventEditModalStyle.cell}>
        <AddEntityLinks entities={props.orgs}/>
      </td>
      <td style={eventEditModalStyle.cell}>
        <AddEntityLinks entities={props.other_ents}/>
      </td>
    </tr>
  );
};

export default AddEntityLinksPanel;
