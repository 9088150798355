import React from "react";

import Chip from "../../lds/chips/Chip";

export default function EntityChip({ label, leadingIcon, size = "medium" }) {
  return (
    <Chip
      className="lds-chip-entity"
      label={label}
      leadingIcon={leadingIcon}
      size={size}
    />
  );
}

EntityChip.propTypes = {
  label: Chip.propTypes.label,
  size: Chip.propTypes.size,
  leadingIcon: Chip.propTypes.leadingIcon
}
