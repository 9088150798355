const ChooseSegmentContactButton = (props) => {
  let className = "button_unselected";
  if(props.choice === props.type){
    className = "button_selected";
  }
  return (
    <button className={"button-small button-secondary button_spaced_10_right " + className + " rspec_send_type_" + props.type} onClick={()=>props.updateChoice(props.type)}>{props.name}</button>
  );
};

export default ChooseSegmentContactButton;
