const SfIndexReturn = (props) => {
  const indexClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    SFPanelActions.jump("sf_index_table");
  };
  const leadsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    SFPanelActions.jump("sf_leads_div");
  };
  const leadsLink = props.lead_count > 0 ? <a onClick={leadsClick} className="fancy_link local_link sf_index_link">Leads card</a> : null;
  return (
    <div className={"sf_index_return " + props.style}>
      <a onClick={indexClick} className="fancy_link local_link sf_index_link">Accounts index</a><br/>
      {leadsLink}
    </div>
  );
};

export default SfIndexReturn;

