import MutedCompaniesHeader from "./mutedCompaniesHeader";
import MutedCompaniesSearch from "./mutedCompaniesSearch";
import MutedCompaniesSelectionPanels from "./mutedCompaniesSelectionPanels";

const MutedCompaniesSelector = () => {
  return (
    <div className="container w-1200" >
      <MutedCompaniesHeader />
      <MutedCompaniesSearch classNames={["autocomplete_muted_companies"]} placeHolder={"company name"}/>
      <MutedCompaniesSelectionPanels />
    </div>
  );
};

window.MutedCompaniesSelector = MutedCompaniesSelector;
export default MutedCompaniesSelector;
