import Input from "../lds/Input";

const FlaggingEventComment = (props) => {
  const change = function (value) {
    props.updateComment(value);
  }

  return (
    <div id="flag_event_comment" className="row-fluid">
      <div className="span12">
        <br />
        <Input
          multiLine
          size="large"
          id="flag_comment_text"
          className="fullstory_excluded"
          width="100%" height="100px"
          label="Add Comment (Optional)"
          value={props.comment}
          onValueChange={change}
        />
      </div>
    </div>
  );
}

export default FlaggingEventComment;
