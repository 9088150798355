import NO_LISTS_SVG from "../../../../images/lds/no-lists.svg";

/** Render a helpful message when no lists present */
export default function NoLists() {
  // Currently no tokens for a margin top of 124px
  const style = { marginTop: "124px" };
  const textStyle = { marginTop: "var(--space-1000)" };

  return (
    <div
      id="looe-no-lists-banner"
      className="flex-col align-center"
      style={style}
    >
      <img width="385" height="208" src={NO_LISTS_SVG} />

      <span className="type-body-large" style={textStyle}>
        You currently do not have any lists matching this list type
      </span>
    </div>
  );
}
