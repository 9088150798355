import PropTypes from "prop-types";

import Button from "@javascript/reactComponents/lds/Button";
import Heading from "@javascript/reactComponents/lds/typography/Heading";
import Icon from "@javascript/reactComponents/lds/Icon";
import LegacyModalService from "@javascript/globals/LegacyModalService";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@javascript/reactComponents/lds/modal/Modal";

const GenericErrorModal = ({
  distanceFromTopViewport= "75px",
  modalBody = "We're sorry, but something went wrong. Please reload the page.",
  modalHeader = "Something went wrong",
  onClose = LegacyModalService.closeModal,
  open= true,
  reloadPage = true,
}) => {

  let modalFooter = (
    <Button
      size="medium"
      variant="primary"
      onClick={onClose}
    >
      Close
    </Button>
  );

  const reloadThePage = () => {
    window.location.reload();
  };


  if(reloadPage) {
    modalFooter = (
      <Button
        onClick={reloadThePage}
        size="medium"
        variant="primary"
      >
        Reload Page
      </Button>
    );
  }

  return (
    <Modal
      distanceFromTopViewport={distanceFromTopViewport}
      onClose={onClose}
      open={open}
    >
      <ModalHeader>
        {modalHeader}
      </ModalHeader>
      <ModalBody
        className="lds-modal-body-height modal-success-content"
      >
        <Icon
          name="special-destruct"
        />
        <Heading
          containerClassName="generic-modal-header"
          size="h2"
        >
          We encountered an unexpected error.
        </Heading>
        <p>{modalBody}</p>
      </ModalBody>
      <ModalFooter align="flex-end">
        {modalFooter}
      </ModalFooter>
    </Modal>
  );
};

GenericErrorModal.propTypes = {
  distanceFromTopViewport: PropTypes.string,
  modalBody: PropTypes.string,
  modalHeader: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  reloadPage: PropTypes.bool,
};

export default GenericErrorModal;

window.GenericErrorModal = GenericErrorModal;
