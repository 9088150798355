import SfOpportunity from "./sfOpportunity";

const SfOpportunities = createReactClass({
  getInitialState: function(){
    return {display: 3};
  },
  show_more: function(){
    SFPanelActions.trackEvent("see_more_opportunities","User clicked to view more SF opportunities", {});
    this.setState({display: this.state.display + 10});
    return false;
  },
  render: function () {
    if(_.isUndefined(this.props.tab_content.data) || this.props.tab_content.data.length < 1) {
      return (
        <div className="sf_accounts_section">
          There are no opportunities listed in Salesforce for this account.
        </div>
      );
    }
    let moreButton;
    let displayOpportunities = Object.keys(this.props.tab_content.data)
    if(this.state.display < displayOpportunities.length){
      const remainder = displayOpportunities.length - this.state.display;
      const next = remainder > 10 ? "next 10" : 'last ' + remainder;
      moreButton = (
        <div onClick={this.show_more}>
          <h1>
            <a className="btn btn-primary">
              <span>Show the {next} of {displayOpportunities.length} total opportunities</span>
            </a>
          </h1>
        </div>
      )
    }
    displayOpportunities = this.props.tab_content.data.slice(0, this.state.display);
    const opportunities = displayOpportunities.map(function (opportunity) {
      return <SfOpportunity key={opportunity.sf_id}  {...opportunity} />
    }.bind(this));
    return (
      <div>
        {opportunities}
        {moreButton}
      </div>
    );
  }
});

export default SfOpportunities;
