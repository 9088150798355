import FacetSearchStore from "../../stores/facetSearchStore";
import FacetSearchCriteriaCategory from "./facetSearchCriteriaCategory";
import FacetSearchCriteriaQueryTerm from "./facetSearchCriteriaQueryTerm";
import FacetSearchCriteriaTimeFrame from "./facetSearchCriteriaTimeFrame";
import FacetSearchCriteriaEntities from "./facetSearchCriteriaEntities";
import FacetSearchCriteriaEntityValues from "./facetSearchCriteriaEntityValues";
import FacetSearchCriteriaAliasSearch from "./facetSearchCriteriaAliasSearch";
import FacetSearchCriteriaGeoCoordinates from "./facetSearchCriteriaGeoCoordinates";
import FacetSearchCriteriaClinicalTrialStages from "./facetSearchCriteriaClinicalTrialStages";
import FacetSearchCriteriaClinicalStatus from "./facetSearchCriteriaClinicalStatus";
import FacetSearchCriteriaLocations from "./facetSearchCriteriaLocations";
import FacetSearchCriteriaMutedCompany from "./facetSearchCriteriaMutedCompany";
import FacetSearchCriteriaRelationship from "./facetSearchCriteriaRelationship";
import FacetSearchCriteriaList from "./facetSearchCriteriaList";

/**
 * This is the container for all the criterias in the breadbox.
 */

const FacetSearchCriteriaContainer = () => {
  // this should be refactored as initial state when hooking this component up to FacetSearchStore
  const total_count_formatted = commifyNumber(FacetSearchStore.data.total_count);

  return (
    <div id='rspec_criteria_panel' className='criteria_panel'>
      <div className="criteria_body">
        <h3>Narrowing Events by:</h3>
        <p style={{marginBottom: '3px'}}>Your current search is narrowed to the <b className="rspec_search_total_count">{total_count_formatted}</b> events that involve <b>ALL</b> of the following:</p>
        <FacetSearchCriteriaCategory />
        <FacetSearchCriteriaQueryTerm />
        <FacetSearchCriteriaTimeFrame />
        <FacetSearchCriteriaEntities />
        <FacetSearchCriteriaEntityValues />
        <FacetSearchCriteriaGeoCoordinates />
        <FacetSearchCriteriaClinicalTrialStages />
        <FacetSearchCriteriaClinicalStatus />
        <FacetSearchCriteriaLocations />
        <FacetSearchCriteriaMutedCompany />
        <FacetSearchCriteriaRelationship />
        <FacetSearchCriteriaList />
        <FacetSearchCriteriaAliasSearch />
      </div>
      <br/>
      <h3>Narrow further</h3>
    </div>
  )
}

window.FacetSearchCriteriaContainer = FacetSearchCriteriaContainer;
export default FacetSearchCriteriaContainer;
