const AvailableLi = createReactClass({
  mixins: [Reflux.listenTo(DragStore,"update_dragged"), Reflux.listenTo(pivotListColumnSettingsStore, 'updateSettingsState')],
  getInitialState: function(){
    return {
      class_name:this.props.class_name,
      dragged:{},
      interactableColumnsCount: 0,
      fixedColumnsCount: 0
    }
  },
  update_dragged: function(dragged) {
    this.setState({
      dragged: dragged
    });
  },
  UNSAFE_componentWillMount: function(dragged) {
    if(pivotListColumnSettingsStore.state.interactableColumnsCount && pivotListColumnSettingsStore.state.interactableColumnsCount !== this.state.interactableColumnsCount) {
      this.setState({
        interactableColumnsCount: pivotListColumnSettingsStore.state.interactableColumnsCount,
        fixedColumnsCount: pivotListColumnSettingsStore.state.fixedColumnsCount
      });
    }
  },
  updateSettingsState(newState) {
    this.setState({
      interactableColumnsCount: newState.interactableColumnsCount,
      fixedColumnsCount: newState.fixedColumnsCount
    });
  },
  UNSAFE_componentWillReceiveProps: function(nextProps) {
    this.setState({class_name:nextProps.class_name});
  },
  componentDidMount: function() {
    this.available.addEventListener("dragstart", this.drag_start);
    this.available.addEventListener("dragend", this.drag_end);
  },
  componentWillUnmount: function() {
    this.available.removeEventListener("dragstart", this.drag_start);
    this.available.removeEventListener("dragend", this.drag_end);
  },
  drag_start: function(event){
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      DragActions.setDragged('available', false, this.props);
    }
  },
  drag_end: function(event){
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      if(this.state.dragged.over === 'choice'){
        this.add_choice();
      }
      DragActions.setDragged(null, null, {});
    }
  },
  add_choice: function(){
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      if(this.state.class_name != 'column_choice_disabled'){
        Zymewire.mixpanel_track("Lists: column choice", {choice: this.props.column_data.full_name});
        this.setState({
          class_name: 'column_choice_disabled'
        });
        setTimeout(function(){
          const interactableColumnsAvailable = this.state.interactableColumnsCount + this.state.fixedColumnsCount;
          ColumnsChosenActions.addToChosen(this.props.column_data, interactableColumnsAvailable);
        }.bind(this), 221)
      }
    }
  },
  render: function(){
    const class_names = ['column_overflow_protection','rspec_column_available','action_link','columns_chosen_link'];
    if(this.state.class_name === 'column_choice_disabled'){
      class_names.push('column_overflow_protection no_choice_link');
    }
    let full_name = this.props.column_data.full_name;
    if(this.props.search_string){
      const regex = new RegExp(this.props.search_string, 'gi');
      const new_name = full_name.replace( regex, "<span class='generic_search_string_highlight'>$&</span>");
      full_name = new_name;
    }
    return (
      <li key={this.props.index} ref={elem => this.available = elem} title={this.props.column_data.description} onClick={this.add_choice} className={"columns_choice " + this.state.class_name + this.props.over_class} id={this.props.column_data.column_name}>
        <a dangerouslySetInnerHTML={{__html : full_name}} key={this.props.column_data.column_name} className={class_names.join(' ')}/>
      </li>
    );
  }
});

export default AvailableLi;
