const SfContactEmail = (props) => {
  if(props.email){
    return <p className="sf_contact_details"><a href={"mailto:" + props.email}>{props.email}</a></p>;
  }
  else{
    return false;
  }
};

export default SfContactEmail;
