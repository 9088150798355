import UserColumn from "./userColumn";

// deprecatethis:
// the below components should be junked once we deprecate the can_use_flagged_event_reminder feature flag as they are only used with old flagging components
// UserListPage -> refactored into PageOfSelectableUsers for use across the application
// UserColumn -> refactored into ColumnOfSelectableUsers for use across the application
// FlagUserCheckbox -> refactored into SelectableUser for use across the application
const UserListPage = (props) => {
  let lists;

  if (typeof props.userList != 'undefined' && props.userList instanceof Array) {
    const five_users_at_a_time = chunkArray(props.userList, 5);
    lists = _.map(_.toArray(five_users_at_a_time), function (users, index) {
      return <UserColumn key={index} users={users} toggleUser={props.toggleUser} search_string={props.search_string} />;
    });
  }
  else {
    lists = 'No users found.';
  }
  return (
    <div className="row-fluid">
      {lists}
    </div>
  );
}

export default UserListPage;
