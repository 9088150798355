const RelatedTherapiesTable = (props) => {
  const maxNameLength = props.maxNameLength || 50;
  const errorCallback = props.errorCallback || console.error;           // Fallback to console logs if no callback provided
  const therapiesReported = props.therapiesReported || new Set();    // If no therapies given, assume none have been reported

  /**Given the name of a therapy, truncate it to maxNameLength - 3 characters,
   * followed by ellipses if the name length is greater than maxNameLength
   * @param name The name of the therapy
   * @returns A truncated copy of the name, if name is larger than maxNameLength
   * */
  const truncateTherapyName = (name) => {
    return name.length > maxNameLength ? name.slice(0, (maxNameLength - name.length ) - 3) + "..." : name;
  }

  const therapies = props.listOfTherapies.map(function(therapy){
    // If therapy name is > 50 characters, we need to truncate it
    let therapyName = truncateTherapyName(therapy.name);
    let isTherapyReported = therapiesReported.has(therapy.id);

    return(
      <tr id={therapy.id} key={therapy.id}>
        <td className="w-360">{therapyName}</td>
        <td className="w-260">{therapy.last_mentioned}</td>
        <td className="w-260">{therapy.in_human_clinical_trials}</td>
        <td className="text-center">
          {
            isTherapyReported ?
              <p style={{margin: 0}}>Reported!</p>
              :
              <a className="fancy_link local_link" onClick={() => errorCallback(therapy)}>Report Mismatch</a>
          }
        </td>
      </tr>
    );
  });
  return(
    <div className="row-fluid">
      <div className="span12">
        <table id="related_therapies_table" className="table" style={{marginBottom: "20px"}}>
          <thead>
          <tr>
            <td>
              <b>Therapy Name</b>
            </td>
            <td>
              <b>Last mentioned</b>
            </td>
            <td>
              <b>Reached human clinical trials?</b>
            </td>
            <td className="text-center">
              <b>Report Mismatch to {I18n.t("brand.name")} team</b>
            </td>
          </tr>
          </thead>
          <tbody>
          {therapies}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RelatedTherapiesTable;
