import Reflux from "reflux";
import FacetSearchStore from "../../stores/facetSearchStore";

/**
 * Component responsible for time frame selection.
 *
 * @param props {{ time_frame: string }}
 */
const FacetSearchCriteriaTimeFrame = createReactClass({
  mixins: [
    Reflux.connectFilter(FacetSearchStore, function (data) {
      return data.timeFrame;
    }),
  ],
  getInitialState: function () {
    return {
      ...(FacetSearchStore.data.timeFrame)
    };
  },
  shouldComponentUpdate: function (nextProps, nextState) {
    return true
  },
  resetTimeSelect: function(e) {
    e.stopPropagation();
    e.preventDefault();
    reset_time_select();
  },
  render: function(){
    // The URL is our source of truth here, since that is the active search
    const { selected, earliest, latest } = FacetSearchStore.getActiveTimeFrame();

    let timeFrameDescription = null;
    if (selected !== undefined && selected !== "time_any") {
      if (selected !== "time_custom") {
        timeFrameDescription = this.state.display.in + this.state.display.options[selected];
      }
      else if (earliest !== null) {
        const fromMoment = moment(earliest, this.state.display.format.moment);
        const toMoment = moment(latest, this.state.display.format.moment);
        timeFrameDescription = this.state.display.between + fromMoment.format(this.state.display.format.description) + " and " + toMoment.format(this.state.display.format.description);
      }
    }
    if (timeFrameDescription === null) return null;

    return (
      <a onClick={this.resetTimeSelect} className="action_link">
        <div
          className="filter_item_property"
          title="You are filtering your search by events within this time frame."
        >
          <div className="filter_item_close" style={{ display: "inline-block" }}>
            <i className="icon-remove  " />
          </div>
          <div style={{ display: "inline-block" }}>
            <p style={{ marginLeft: "-5px", fontSize: "13px" }}>{timeFrameDescription}</p>
          </div>
        </div>
      </a>
    );
  }
});

window.FacetSearchCriteriaTimeFrame = FacetSearchCriteriaTimeFrame;
export default FacetSearchCriteriaTimeFrame;
