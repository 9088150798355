import ArticleEventOrg from "./articleEventOrg";

const ExcludedOrgList =  (props) => {
  if(props.excludedOrgs.length < 1){
    return false;
  }
  const sortedOrgs = _.sortBy(props.excludedOrgs, function(org){return - org.mention_count});
  const excludedOrgs = sortedOrgs.map(function(org){
    return <ArticleEventOrg key={org._id} org={org} toggleHandler={props.toggleOrgHandler} excluded={true}/>;
  });
  return (<div><p>Organizations that are excluded</p><ul id={"excluded_orgs_" + props.article_id} className="event_org_list">{excludedOrgs}</ul></div>);
};

export default ExcludedOrgList;
