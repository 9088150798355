import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

function Button({
  id,
  className,
  type,
  selected,
  children,
  block,
  size,
  variant,
  width,
  maxWidth,
  icon,
  trailingIcon,
  onClick,
}) {
  maxWidth ||= "unset";
  width ||= "unset";
  className ||= "";

  return (
    <button
      id={id}
      type={type}
      className={`button-${size || "small"} button-${variant} ${
        selected ? "selected" : ""
      } ${className}`}
      onClick={onClick}
      style={{ flexGrow: block ? 1 : 0, maxWidth, width }}
    >
      {icon && <Icon name={icon} />}
      <span className="button-text">{children}</span>

      {trailingIcon && (
        <>
          <div className="flex-grow-1" />
          <Icon name={trailingIcon} />
        </>
      )}
    </button>
  );
}

export const ButtonPropTypes = {
  id: PropTypes.string,

  type: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "plain",
    "critical",
    "disable",
  ]).isRequired,

  width: PropTypes.string,

  maxWidth: PropTypes.string,

  /** If button should grow to occupy all available space */
  block: PropTypes.bool,

  icon: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

Button.propTypes = ButtonPropTypes
export default Button;
