import PhaseBar from "./phaseBar";

const ActiveStudiesCard = (props) => {
  const phaseBars = _.map(props.studies.active.phases, function(phase_data, index){
    return <PhaseBar url={props.url} key={index} phase_data={phase_data} org_name={props.comparable.org_name} max_phase={props.studies.active.max_phase}/>
  });
  let activeLink = props.studies.active.count
  if(props.studies.active.count > 0){
    const url = props.url + '/facet_search?query=registered&facets%5Borganizations%5D%5B%5D=' + encodeURIComponent(props.comparable.org_name).replace(/&/g, "%26") + '&category=all_event&time_select=time_any&clin_stage%5B%5D=clin_stage_1&clin_stage%5B%5D=clin_stage_2&clin_stage%5B%5D=clin_stage_3&clin_stage%5B%5D=clin_stage_4&clin_status%5B%5D=Recruiting&clin_status%5B%5D=Enrolling+by+invitation&clin_status%5B%5D=Active%2C+not+recruiting&scroll=0&feature_track=frominfographic'
    activeLink = <a className="fancy_link active_studies_link" title="Click to view the initial clinicaltrials.gov registration event of each study and to see more details of study design." href={url}>{props.studies.active.count}</a>
  }
  return (
    <div className="infographic_card_content" id="active_studies">
      <input type="checkbox" className="infographic_checkbox" id="more_info_active_studies" />
      <div className="card_top_section">
        <label className="infographic_more_info" htmlFor="more_info_active_studies">
          <div className="more_info_menu">
            <div className="more_info_menu_line more_info_menu_half more_info_menu_start"></div>
            <div className="more_info_menu_line"></div>
            <div className="more_info_menu_line more_info_menu_half more_info_menu_end"></div>
          </div>
        </label>
        <div className="cards_more_content m-l-3">
          <div className="cards_more_data">
            <div className="cards_more_top">
              <p className="infographic_panel_title">Active Studies</p>
              <p className="infographic_panel_subtitle">in total</p>
            </div>
            <div className="cards_more_graph">
              Total number of active studies (broken out by phase) as according to clinicaltrials.gov and updated {props.comparable.date}
            </div>
          </div>
          <p className="infographic_panel_title">Active Studies</p>
          <p className="infographic_panel_subtitle">by total and by phase</p>
          <div className="infographic_circle infographic_empty_circle infographic_active_circle">{activeLink}</div>
          <div className="barchart_phases">
            {phaseBars}
          </div>
          <div className="cards_more_float"></div>
        </div>
      </div>
      <div className="infographic_comparable_info">
        <p className="infographic_comparable_company"><span className="infographic_comparable_compared">* according to </span>clinicaltrials.gov</p>
        <p className="infographic_comparable_date">As of {props.comparable.date}</p>
      </div>
    </div>
  );
};

window.ActiveStudiesCard = ActiveStudiesCard;
export default ActiveStudiesCard;
