import AddEntityLinksPanel from "./addEntityLinksPanel";
import { eventEditModalStyle } from "../../../config/administrateEvent";

const AddEntitiesFromDoc = createReactClass({
  getInitialState: function(){
    return {showLinks: true};
  },
  toggleDisplay: function(e){
    e.preventDefault();
    this.setState({showLinks: !this.state.showLinks});
  },
  render: function () {
    let orgs = _.filter(this.props.entities, function (ent) {
      return ent.ent_type === "Organization";
    });
    orgs = _.sortBy(orgs, function(org){ return org.name.toLowerCase()});
    let otherEntities = _.filter(this.props.entities, function (ent) {
      return ent.ent_type !== "Organization";
    });
    otherEntities = _.sortBy(otherEntities, function(ent){ return ent.name.toLowerCase()});

    let toggleText = "..show";
    if(this.state.showLinks){
      toggleText = "hide";
    }
    else{
      orgs = [];
      otherEntities = [];
    }
    return (
      <table style={eventEditModalStyle.table}>
        <thead>
          <tr>
            <th style={eventEditModalStyle.header}>Orgs in source</th>
            <th style={eventEditModalStyle.header}>Other entities in source <span><a className="action_link right" onClick={this.toggleDisplay}>{toggleText}</a></span></th>
          </tr>
        </thead>
        <tbody>
          <AddEntityLinksPanel orgs={orgs} other_ents={otherEntities}/>
        </tbody>
      </table>
    );
  }
});

export default AddEntitiesFromDoc;
