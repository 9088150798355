import React from "react";
import Icon from "../../../lds/Icon";

const ListDeleteModalSuccessBody = () => {
  return (
    <div className="status">
      <div className="delete-checkmark-background">
        <Icon
          name="checkmark"
          style={{ height: 50, width: 50 }}
          className="icon-background"
        />
      </div>
      <h2>Lists deleted successfully!</h2>
    </div>
  );
};

export default ListDeleteModalSuccessBody;
