import { useState } from "react";
import { CHECKBOX_STATES } from "../selection_controls/CheckboxItem";
/**
 * A React hook to make working with checkboxes easier.
 * This hook exposes a value `selection` and callback `onClick`
 * that will correctly interop with `CheckboxItem` component.
 * @param {boolean} initialValue
 *
 * @example
 *    function MyCheckbox() {
 *       const checkbox = useCheckbox(false)
 *       return <CheckboxItem {...checkbox} />
 *    }
 */
export default function useCheckbox(initialValue) {
  const [selection, setSelection] = useState(
    initialValue ? CHECKBOX_STATES.selected : CHECKBOX_STATES.unselected
  );

  const onClick = () => {
    if (selection === CHECKBOX_STATES.selected) {
      setSelection(CHECKBOX_STATES.unselected);
    } else {
      setSelection(CHECKBOX_STATES.selected);
    }
  };

  return { selection, onClick };
}
