import { useClickAway, useLockBodyScroll } from "@uidotdev/usehooks";

import { createPortal } from "react-dom";
import { useContext } from "react";
import PropTypes from "prop-types";

import ModalContext from "@javascript/reactComponents/lds/modal/ModalContext";
import { useEffect } from "react";
// Import and re-export Modal components
export { ModalBody } from "@javascript/reactComponents/lds/modal/ModalBody";
export { ModalFooter } from "@javascript/reactComponents/lds/modal/ModalFooter";
export { ModalHeader } from "@javascript/reactComponents/lds/modal/ModalHeader";

function ModalBackdrop({ children }) {
  return (
    <div className="lds-modal-backdrop" role="backdrop">
      {children}
    </div>
  );
}

function ModalInner({
  id,
  children,
  persistent = true,
  distanceFromTopViewport = null,
  size = "medium",
  maxHeight = "80vh",
  width = null,
}) {
  const { onClose } = useContext(ModalContext);
  const closeFunction = persistent ? onClose : function () {};
  const modalContentRef = useClickAway(closeFunction);

  useEffect(() => {
    const originalOverflow = document.body.style.overflow
    document.body.style.overflow = "hidden"

    return () => {document.body.style.overflow = originalOverflow}
  }, [])

  const style = {
    width: width || `var(--modal-size-width-${size})`,
    maxHeight,
  };

  if (distanceFromTopViewport) {
    style.alignSelf = "start";
    style.marginTop = distanceFromTopViewport;
  }

  return (
    <ModalBackdrop>
      <div
        id={id}
        ref={modalContentRef}
        className="lds-modal-content"
        style={style}
        role="dialog"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </ModalBackdrop>
  );
}

export function Modal({
  onClose,
  open,
  // ModalInner.propTypes
  children,
  distanceFromTopViewport,
  id,
  maxHeight,
  persistent,
  size,
  verticallyCentered,
  width,
}) {
  onClose ||= function () {};

  if (!open) return null;

  return createPortal(
    <ModalContext.Provider value={{ open, onClose }}>
              <ModalInner
                children={children}
                distanceFromTopViewport={distanceFromTopViewport}
                id={id}
                maxHeight={maxHeight}
                persistent={persistent}
                size={size}
                verticallyCentered={verticallyCentered}
                width={width}
              />
            </ModalContext.Provider>,
    document.body
  );
}

Modal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  ...ModalInner.propTypes,
};

ModalInner.propTypes = {
  distanceFromTopViewport: PropTypes.any,
  id: PropTypes.string,
  maxHeight: PropTypes.any,
  persistent: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "large"]),
  verticallyCentered: PropTypes.bool,
  width: PropTypes.any,
};
