import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const TabContext = createContext({
  selectedTab: null,
  onTabSelected: () => {},
  updateIndicator: () => {},
});

export default function TabGroup({
  selectedTab,
  onTabSelected = () => {},
  children,
  animated = true,
}) {
  const [indicatorStyle, setIndicatorStyle] = useState({
    width: "0px",
    left: "0px",
  });

  const selectTab = (value, tabRef) => {
    onTabSelected(value);

    if (animated) {
      updateIndicator(tabRef);
    }
  };

  const updateIndicator = (tabRef) => {
    setIndicatorStyle({
      width: `${tabRef.current.offsetWidth}px`,
      left: `${tabRef.current.offsetLeft}px`,
    });
  };

  return (
    <TabContext.Provider
      value={{ selectedTab, onTabSelected: selectTab, updateIndicator }}
    >
      <div className="lds-tab-group" role="tablist">
        {children}
        <span
          className={`lds-tab-active-indicator ${!animated && "lds-tab-no-animation"}`}
          data-testid="lds-tab-indicator"
          style={indicatorStyle}
        />
      </div>
    </TabContext.Provider>
  );
}

TabGroup.propTypes = {
  selectedTab: PropTypes.string,
  onTabSelected: PropTypes.func,
};
