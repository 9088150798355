var ChosenLi = createReactClass({
  mixins: [Reflux.listenTo(DragStore,"update_dragged")],
  getInitialState: function(){
    return {
      class_name:this.props.column_data.column_name === 'empty' ? 'column_slot_open' : 'draggable',
      column_data:this.props.column_data,
      dragged:{}
    }
  },
  update_dragged: function(dragged) {
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      this.setState({
        dragged: dragged
      });
    }
  },
  UNSAFE_componentWillReceiveProps: function(nextProps) {
    this.setState({class_name:nextProps.column_data.column_name === 'empty' ? 'column_slot_open' : 'draggable',column_data:nextProps.column_data});
  },
  componentDidMount: function() {
    this.chosen.addEventListener("dragstart", this.drag_start);
    this.chosen.addEventListener("dragend", this.drag_end);
  },
  componentWillUnmount: function() {
    this.chosen.removeEventListener("dragstart", this.drag_start);
    this.chosen.removeEventListener("dragend", this.drag_end);
  },
  drag_start: function(event){
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      DragActions.setDragged('choice', false, this.props);
    }
  },
  drag_end: function(event){
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      if(this.state.dragged.over === 'available'){
        this.remove_choice();
      }
      DragActions.setDragged(null, null, {});
    }
  },
  remove_choice: function(){
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      this.setState({
        class_name: 'column_slot_open'
      });
      setTimeout(function(){
        ColumnsChosenActions.removeFromChosen(this.state.column_data)
      }.bind(this), 221);
    }
  },
  render: function(){
    const action_link = this.state.column_data.column_name === 'empty' ? '' : <a className="column_overflow_protection rspec_column_chosen action_link columns_chosen_link">{this.state.column_data.full_name}</a>;
    return (
      <li ref={elem => this.chosen = elem} title={this.state.column_data.description} onClick={this.remove_choice} className={"columns_choice " + this.state.class_name + this.props.over_class} data-chosen={this.state.column_data.column_name} id={"chosen_" + this.state.column_data.column_name}>{action_link}</li>
    );
  }
});

export default ChosenLi;
