/**
 * React component that displays a toggle (currently the out of place green + - toggle)
 *
 * @author
 * @namespace
 * @memberof
 *
 * @component
 * @example
 * return (
 *   <FacetSectionToggle />
 * )
 *
 * @param none
 *
 */
const FacetSectionToggle = () => {
  return (
    <span className="badge badge-success">
      <span className='toggle_icon icon_open'>
        <i className="icon-plus icon-white"></i>
      </span>
      <span className='toggle_icon icon_close' style={{display:'none'}}>
        <i className="icon-minus icon-white"></i>
      </span>
    </span>
  );
};

export default FacetSectionToggle;
