import BootstrapRow from "../generic/htmlElements/bootstrapRow";
import BootstrapSpan from "../generic/htmlElements/bootstrapSpan";
import TerritorySelectorHolder from "./territorySelectorHolder";
import ClearTerritoriesButton from "./clearTerritoriesButton";
import SelectedTerritories from "./selectedTerritories";
import SaveChangesButton from "./saveChangesButton";

const TerritorySelectorPanels = (props) => {
  return (
    <BootstrapRow>
      <BootstrapSpan wide="5">
        <TerritorySelectorHolder {...props} />
      </BootstrapSpan>
      <div className="clear_selections_button">
        <ClearTerritoriesButton {...props} />
      </div>
      <BootstrapSpan wide="5">
        <SelectedTerritories {...props}/>
        <SaveChangesButton {...props} />
      </BootstrapSpan>
    </BootstrapRow>
  );
};

export default TerritorySelectorPanels;
