import FlagUserCheckbox from "./flagUserCheckbox";

const UserColumn = (props) => {
  var users = props.users.map(function (user, index) {
    return <FlagUserCheckbox key={index} user={user} toggleUser={props.toggleUser} search_string={props.search_string} />;
  });

  return (
    <div className="span6 rspec_flag_event_users_list">
      {users}
    </div>
  );
}

export default UserColumn;