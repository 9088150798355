import { useState } from "react";
import PropTypes from "prop-types";

import Dropdown, { DropdownTrigger } from "@javascript/reactComponents/lds/dropdown/Dropdown";
import DropdownList from "@javascript/reactComponents/lds/dropdown/DropdownList";
import NavItem from "@javascript/reactComponents/lds/NavItem";

const SettingsDropdown = ({
  items,
  navTrigger,
}) => {

  const [useTrigger, setTrigger] = useState(navTrigger)

  const openDropdown = () => {
    const updatedTrigger = {
      ...useTrigger,
      className: navTrigger.selectedItem === "settings_nav_item" ? "" : "nav_dropdown_hover_override",
      trailingIcon: "chevron-up"
    };
    setTrigger(updatedTrigger);
  }

  const closeDropdown = () => {
    const updatedTrigger = {
      ...useTrigger,
      className: "",
      trailingIcon: "chevron-down"
    };
    setTrigger(updatedTrigger);
  }

  return(
    <Dropdown size="large" onDropdownOpen={openDropdown} onDropdownClose={closeDropdown}>
      <DropdownTrigger>
        <NavItem {...useTrigger} />
      </DropdownTrigger>
      <DropdownList items={items} />
    </Dropdown>
  );
};

SettingsDropdown.propTypes = {
  items: PropTypes.array.isRequired,
  navTrigger: PropTypes.object,
};

window.SettingsDropdown = SettingsDropdown;
export default SettingsDropdown;
