const EventActionForVisible = (props) => {
  const hideEvent = (e) => {
    e.preventDefault();
    EventTableAction.hideEvent(props.event);
  };
  return (
    <p><a className="action_link rspec_hide_event" onClick={hideEvent}>Hide</a></p>
  );
};

export default EventActionForVisible;
