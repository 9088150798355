import $ from "jquery";

export function markFlagEventButtonAsFlagged(selector) {
  const flagEventButton = $(selector);
  flagEventButton
    .removeClass("flag-event-button")
    .addClass("flagged-event-button");

  // Lets Backbone event_modal.jst.ejs template know that it's been flagged.
  // We should not be using data-* attributes in 2024 to pass around data...
  // strong refactor candidate for a store.
  flagEventButton.closest(".event_wrapper").data("flagged", true);

  flagEventButton.html(`<span class="icon-flag-filled"></span> Flagged Event`);
}
