const IgnoreEventButton = (props) => {
  const ignoreEvent = (e) => {
    e.preventDefault();
    const url = "/commentary_article/" + props._id + "/ignore";
    $.post(url)
      .done(function (result) {
        $("#pm_" + props._id).fadeOut("fast");
      })
      .fail(function (xhr, ajaxOptions, thrownError) {
        if (xhr.status === 401) {
          alert("Un-authorized status received");
          $("#event_modal").modal("hide");
          window.location = "/admin_home";
        }
        else {
          // console.log(thrownError)
        }
      });
  };
  return (<a onClick={ignoreEvent} className="btn btn-warning" id="rspec_article_ignore_btn">Ignore</a>);
};

export default IgnoreEventButton;
