import React, { useContext } from "react";
import PropTypes from "prop-types";
import DropdownList, { DropdownListContext } from "./DropdownList";
import { DropdownContext } from "./Dropdown";
import Icon from "../Icon";

/** A component to render a list of dropdown items, grouped by a heading. */
export default function DropdownItemGroup({ group, leadingIcon, showDivider = true, label, children }) {

  return (
    <div>
      <DropdownItemGroupHeading label={group?.label || label} leadingIcon={group?.leadingIcon || leadingIcon} />

      {children ?
        children :
        <DropdownList items={group.groupedItems}  />}

      {showDivider && <DropdownItemGroupDivider />}
    </div>
  );
}

export function DropdownItemGroupHeading({ label, leadingIcon }) {
  const { size } = useContext(DropdownContext);

  return (
    <div className={"lds-dropdown-heading-" + size}>
      { leadingIcon && <Icon name={leadingIcon} /> }
      {label}
    </div>
  );
}

export function DropdownItemGroupDivider() {
  const { size } = useContext(DropdownContext);

  return <div className={"lds-dropdown-item-group-divider-" + size} />;
}


DropdownItemGroup.propTypes = {
  group: PropTypes.any,
  showDivider: PropTypes.bool,

  label: PropTypes.string,
  leadingIcon: PropTypes.string
}

DropdownItemGroupHeading.propTypes = {
  label: PropTypes.string.isRequired,
  leadingIcon: PropTypes.string
}
