/**
 * WARNING: This component gets refs passed to it from
 * parent components. WHen refactoring to a function component,
 * this must be taken into account.
 * 
 * Consider using React.forwardRef().
 * 
 */
const PaginationBar = createReactClass({
    propTypes: {
      pageSize: PropTypes.number.isRequired,
      totalResults: PropTypes.number.isRequired,
      currentPage: PropTypes.number.isRequired,
      changePage: PropTypes.func.isRequired
    },
    render: function () {
        if (this.props.totalResults < this.props.pageSize) {
            return false;
        }
        var last_page = Math.ceil(this.props.totalResults / this.props.pageSize);
        var start_page = this.props.currentPage < 6 ? 1 : this.props.currentPage - 3
        var end_page = (last_page - this.props.currentPage) > 4 ? this.props.currentPage < 5 ? 9 : this.props.currentPage + 4 : last_page + 1;
        end_page = end_page > last_page ? last_page : end_page;
        var first_page;
        var start_section;
        if (start_page > 1) {
            first_page = <li key="1">
                <a href="#"
                   onClick={this.props.changePage.bind(null,
                       { page: 1, location_ref: this})}>
                    1
                </a>
            </li>;
            start_section = <li key="start_elipsis" className="disabled">
                ...
            </li>;
        }
        var main_section;
        var list = [];
        for (var i = start_page; i <= end_page; i++) {
            list.push(i);
        }
        main_section = list.map(function (i) {
            if (i == parseInt(this.props.currentPage)) {
                return (
                    <li key={i} className="active">
                        <span className="active">{i}</span>
                    </li>
                );
            }
            else {
                return (
                    <li key={i}>
                        <a href="#" id={"rspec_pagination_" + i}
                           onClick={this.props.changePage.bind(null,
                               { page: i, locationRef: this })}>
                            {i}
                        </a>
                    </li>
                );
            }

        }.bind(this));

        var end_section;
        var terminal_page;
        if (end_page < last_page) {
            end_section = <li key="end_elipsis" className="disabled">
                ...
            </li>;
            terminal_page = <li key={last_page} >
                <a href="#"
                   onClick={this.props.changePage.bind(null,
                       { page: last_page, locationRef: this })}>
                    {last_page}
                </a>
            </li>;
        }


        return (

            <div id={this.props.id} className="pagination" style={{margin: "4px"}}>
                <ul>
                    {first_page}
                    {start_section}
                    {main_section}
                    {end_section}
                    {terminal_page}
                </ul>
            </div>

        )
    }
});

export default PaginationBar;
