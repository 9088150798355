import EventActionForVisible from "./eventActionForVisible";
import EventActionForHiddenAndPublishable from "./eventActionForHiddenAndPublishable";

// this should be refactored... lots to grok
const EventActionCell = (props) => {
  if (!props.event.hidden) {
    return (
      <td className="rspec-event-action-cell"><EventActionForVisible {...props} /></td>
    );
  }
  if (props.event.industry_concept_refs.length === 0) {
    return <td className="rspec-event-action-cell"><p>Hidden event has no industry focus assigned</p></td>
  }

  if (props.event.industry_concept_refs.length > 1) {
    return <td className="rspec-event-action-cell"><p>Hidden event with multiple industry focus</p></td>
  }

  const eventIndustryConceptRef = props.event.industry_concept_refs[0];
  const isIndustryMatched = _.find(props.event.entities, function(ent){
    return ent.publishable_concept_refs.includes(eventIndustryConceptRef);
  });

  if (!isIndustryMatched){
    return (
      <td className="rspec-event-action-cell"><p>Hidden event with industry focus mismatch</p></td>
    );
  }

  const hasPublishableEntities = _.find(props.event.entities, function(ent){
    return ent.is_publishable;
  });

  if (!hasPublishableEntities) {
    return (
      <td className="rspec-event-action-cell"><p>Hidden event has no publishable orgs associated</p></td>
    );
  }

  return (
    <td className="rspec-event-action-cell"><EventActionForHiddenAndPublishable {...props} /></td>
  );
};

export default EventActionCell;
