import React, { useState, createContext } from "react";
import PropTypes from "prop-types";
import GenericModal from "../../generic/modals/genericModal";
import GenericModalHeader from "../../generic/modals/genericModalHeader";
import GenericModalBody from "../../generic/modals/genericModalBody";
import GenericModalActions from "../../generic/modals/genericModalActions";
import ButtonGroup from "../../lds/ButtonGroup";
import Button from "../../lds/Button";
import ListDeleteModalBody from "./listDeleteModal/ListDeleteModalBody";
import ListDeleteModalActionsBar from "./listDeleteModal/ListDeleteModalActionsBar";

const DeleteModalContext = createContext({
  onDeleteLists: (e) => {},
  cancelModal: (e) => {},
  resetCategory: (e) => {},
  selectedLists: new Map(),
  deleteState: "",
  setDeleteState: (e) => {},
  lists: [],
});

const ListDeleteModal = ({ onDeleteLists, cancelModal, resetCategory, selectedLists, lists }) => {

  const [deleteState, setDeleteState] = useState("preview");

  if (deleteState === "deleting" && selectedLists.size === 0) {
    setDeleteState("success");
  }

  return (
    <DeleteModalContext.Provider
      value={{
        onDeleteLists,
        cancelModal,
        resetCategory,
        selectedLists,
        deleteState,
        setDeleteState,
        lists,
      }}
    >
      <GenericModal open={true} style={{ width: "900px", maxHeight: "600px" }}>
        <GenericModalHeader style={{
          padding: "20px 40px",
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#1F2124",
          color: "#fff",
          borderRadius: '5px 5px 0 0'
        }}>
          <h2>You are about to delete your lists</h2>
          <Button size="medium" variant="plain" id="close-delete-modal" onClick={cancelModal} icon="cancel" ></Button>
        </GenericModalHeader>
        <GenericModalBody>
          <div className="container-fluid" id="modal_content_holder">
            <div className="delete-modal-body">
              <ListDeleteModalBody />
            </div>
          </div>
        </GenericModalBody>
        <GenericModalActions style={{justifyContent: "flex-end", alignItems: "center", backgroundColor: "#F1F2F4", height: "70px", paddingRight: "50px"}}>
          <ButtonGroup size="small">
            <ListDeleteModalActionsBar />
          </ButtonGroup>
        </GenericModalActions>
      </GenericModal>
    </DeleteModalContext.Provider>
  )
}

ListDeleteModal.propTypes = {
  onDeleteLists: PropTypes.func.isRequired,
  cancelModal: PropTypes.func.isRequired,
  resetCategory: PropTypes.func.isRequired,
  selectedLists: PropTypes.instanceOf(Map).isRequired,
}

export default ListDeleteModal;
export { DeleteModalContext };
