import NavItem from "../lds/NavItem";
import PropTypes from "prop-types";
import SettingsDropdown from "../settings/SettingsDropdown";

const NavBarItems = ({
 navBarItems,
 selectedItem,
 settingsItems,
 settingsTrigger
}) => {

  const navItems = navBarItems.map((item, index) => {
    return <NavItem key={index} {...item} selectedItem={selectedItem} />;
  });

  const navTrigger = {...settingsTrigger, selectedItem: selectedItem, trailingIcon: "chevron-down"}

  const items = settingsItems.map(setting => ({
    ...setting,
    selected: selectedItem === `${setting.leadingIcon}_nav_item`
  }));

   return(
    <div id="navigation-item-group" className="nav-inner-container">
      {navItems}
      <SettingsDropdown items={items} navTrigger={navTrigger} />
    </div>
  );
}

NavBarItems.propTypes = {
  navBarItems: PropTypes.array.isRequired,
  selectedItem: PropTypes.string,
  settingsItems: PropTypes.array.isRequired,
  settingsTrigger: PropTypes.object
}

window.NavBarItems = NavBarItems;
export default NavBarItems;
