import Reflux from "reflux";
import EventTableAction from "./actions/eventTableActions";

const EventTableStore = Reflux.createStore({
    eventData: {events: [], viz: 'awaiting', industry_type: '', query: '', repub_count: 0, created_by: ""},
    listenables: EventTableAction,
    onInitialLoad: function (event_list, viz, industry_type, query, repub_count, created_by) {
        this.eventData.events = event_list;
        this.eventData.viz = viz;
        this.eventData.industry_type = industry_type
        this.eventData.query = query;
        this.eventData.repub_count = repub_count;
        this.eventData.created_by = created_by;
        this.trigger(this.eventData);
    },
    doEventVizUpdate: function(url, event){
        $.getJSON(url, { event_id: event.id, query: this.eventData.query, viz: this.eventData.viz, industry_type: this.eventData.industry_type, created_by: this.eventData.created_by})
            .done(function (result) {
                // console.log(result);
                this.eventData.events = result.events;
                this.eventData.repub_count = result.repub_count;
                this.trigger(this.eventData);

            }.bind(this))
            .fail(function (xhr, ajaxOptions, thrownError) {
                if (xhr.status == 401) {
                    alert("Un-authorized status received")
                    $("#event_modal").modal('hide');
                    window.location = "/admin_home"
                }
                else {
                    // console.log(thrownError)
                }
            });

        this.eventData.events = _.map(this.eventData.events, function(potential_ev){
            if(potential_ev.id == this){
                potential_ev['loading'] = true;
                return _.clone(potential_ev);
            }
            else{
                return potential_ev;
            }
        }, event.id);
        this.trigger(this.eventData);
    },
    onHideEvent: function(event){
        this.doEventVizUpdate("/administrate_event/hide_event_on_list", event);
    },
    onShowEvent: function(event){
        this.doEventVizUpdate("/administrate_event/publish_event_on_list", event);
    },
    onPublishAsNow: function(event){
        this.doEventVizUpdate("/administrate_event/publish_event_on_list_as_now", event);
    },
    onDeleteEvent: function(event){
        this.doEventVizUpdate("/administrate_event/delete_event_on_list", event);
    },
    onInitiateRepublishing: function(){
        $.getJSON("/administrate_event/push_all_events_to_search").fail(function (xhr, ajaxOptions, thrownError) {
            if (xhr.status == 401) {
                alert("Un-authorized status received")
                $("#event_modal").modal('hide');
                window.location = "/admin_home"
            }
            else {
                // console.log(thrownError)
            }
        });
    },
    onUpdateEvent: function(event){
        this.eventData.events = _.map(this.eventData.events, function(potential_ev){
            if(potential_ev.id == this.id){
               return this;
            }
            else{
                return potential_ev;
            }
        }, event);
        this.trigger(this.eventData);
    }
});

window.EventTableStore = EventTableStore
export default EventTableStore
