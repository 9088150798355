import React, { useContext } from "react";
import { DeleteModalContext } from "../ListDeleteModal";
import ListDeleteModalPreviewBody from "./ListDeleteModalPreviewBody";
import ListDeleteModalDeletingBody from "./ListDeleteModalDeletingBody";
import ListDeleteModalSuccessBody from "./ListDeleteModalSuccessBody";

const ListDeleteModalBody = () => {

  const { deleteState } = useContext(DeleteModalContext);

  switch(deleteState) {
    case "preview":
      return (
        <ListDeleteModalPreviewBody />
      )
    case "deleting":
      return(
        <ListDeleteModalDeletingBody />
      )
    case "success":
      return (
        <ListDeleteModalSuccessBody />
      )
  }
}

export default ListDeleteModalBody;