import React, { useContext, useRef } from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import { isTextElementWrapped } from "../../utilities/DOMUtilities";
import Dropdown, { DropdownContext, DropdownTrigger } from "./Dropdown";
import DropdownList, { DropdownListContext } from "./DropdownList";

export default function DropdownItem({
   allowSelect = true,
   groupedItems = null,
   href = null,
   hrefOnClick = null, // this is legacy and to support both the href and onclick of user settings dropdown, circa 2015,
   id = "",
   label,
   leadingIcon = undefined,
   method = "",
   nestedItems = null,
   noneSelection = false,
   rel = "",
   selected = false,
   showDot = false,
   trailingIcon = undefined,
   value,
   variant = ""
}) {
  const hasChildren = nestedItems || groupedItems;
  const userCanSelectItem = !hasChildren && allowSelect;

  let component =
    <DropdownItemRow
      label={label}
      value={value}

      selected={selected}
      variant={variant}

      leadingIcon={leadingIcon}
      trailingIcon={trailingIcon}

      allowSelect={userCanSelectItem}
      noneSelection={noneSelection}
    />

  // this href logic started to creep in scope... a lot of it is to support the legacy logout dropdown item on the main nav
  // could be a candidate for refactor either into its own component or some other solution that fits.
  // -- GJ 2024.03.19

  if (href) {
    const attributes = { href };
    if (hrefOnClick) {
      attributes.onClick = hrefOnClick;
    }
    if (id) {
      attributes.id = id;
    }
    if(rel) {
      attributes.rel = rel;
    }
    if(method) {
      attributes["data-method"] = method;
    }
    component = <a {...attributes}> { component } </a>
  }

  // Nested dropdown
  if (hasChildren) {
    return (
      <Dropdown nested>
        <DropdownTrigger>
          { component }
        </DropdownTrigger>

        <DropdownList leadingIcon={leadingIcon} trailingIcon={trailingIcon} items={nestedItems || groupedItems} />
      </Dropdown>
    );
  }

  return component;
}

function DropdownItemRow({ label, value, leadingIcon, trailingIcon, allowSelect, selected, variant, noneSelection }) {
  const { size, onItemSelected } = useContext(DropdownContext);
  const listContext = useContext(DropdownListContext);

  const labelRef = useRef();

  // User can manually override icon per DropdownItem
  let finalLeadingIcon = leadingIcon === null ? null : (leadingIcon || listContext.leadingIcon);
  let finalTrailingIcon = trailingIcon === null ? null : (trailingIcon || listContext.trailingIcon);

  if (selected && finalLeadingIcon) finalLeadingIcon += "-filled";
  if (selected && finalTrailingIcon) finalTrailingIcon += "-filled";

  // Detect if label is wrapped, in which case the
  // leading icon should be top-aligned.
  const alignSelfStart = isTextElementWrapped(labelRef.current) ? "align-self-start" : "";

  const isSelected = selected ? "selected" : "";
  const hasVariant = variant ? `lds-dropdown-item-${variant}` : "";


  const rowClassNames = `lds-dropdown-item-${size} ${hasVariant} ${isSelected}`

  return (
    <div
      role="menuitem"
      className={rowClassNames}
      onClick={() => allowSelect && onItemSelected({ label, value, leadingIcon, trailingIcon, variant })}
    >
      {finalLeadingIcon && (
        <Icon
          name={finalLeadingIcon}
          className={alignSelfStart}
        />
      )}

      {noneSelection ? (
        <DropdownItemRowNoneSelection />
      ) : (
        <span ref={labelRef} className="dropdown-item-label" style={{overflowWrap: "break-word", width: "100%"}}>
          {label}
        </span>
      )}

      {finalTrailingIcon && (
        <>
          <div className="flex-grow-1" />
          <Icon name={finalTrailingIcon} className={alignSelfStart} />
        </>
      )}
    </div>
  );
}

function DropdownItemRowNoneSelection() {
  return (
    <span
      className="dropdown-item-label"
      style={{ color: "var(--dropdown-listItem-color-text-none)" }}
    >
      <i>None</i>
    </span>
  );
}

DropdownItem.propTypes = {
  allowSelect: PropTypes.bool,
  groupedItems: PropTypes.array,
  href: PropTypes.string,
  hrefOnclick: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  leadingIcon: PropTypes.string,
  method: PropTypes.string,
  nestedItems: PropTypes.array,
  rel: PropTypes.string,
  selected: PropTypes.bool,
  showDot: PropTypes.bool,
  trailingIcon: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.oneOf(["", "critical", "disabled"])
}
