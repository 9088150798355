export const fieldInError = (errors, fieldName) => {
  let isMissing = false;
  if(errors){
    const error = _.find(errors.error_fields, function(error){
      return error.field === fieldName;
    });
    if(error){
      isMissing = true;
    }
  }
  return isMissing;
};

export const commonFieldElements = (field, isMissing) => {
  const required = field.required ? "*" : "";

  const helperText = isMissing ? "This field is required" : "";
  const helperTextVariant = isMissing ? "critical" : "hidden";

  const placeholder = `${field.label}${required}`;

  return { helperText, helperTextVariant, placeholder };
};
