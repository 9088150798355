import Reflux from "reflux";

const RowRemovalActions = Reflux.createActions([
  "removeCompany",
  "restoreCompany",
  "refreshRemove",
  "refreshRestore",
]);

// We should import the actions in the files they're used in, thereby doing away with this window.hack
// But because the imports are used in files loaded by app/javascripts/applicaiton.js via //= require components
// the import statement required for this is sprocketed into a require statement: not supported by the browser
window.RowRemovalActions = RowRemovalActions;
export default RowRemovalActions;
