import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";

/** A component for when the trailing icon inside a Chip is clickable */
export default function ChipAction({
  className,
  dataTestId,
  icon,
  onClick,
  size = "medium",
  variant = "primary",
}) {
  const classNames = [
    "lds-chip-action-container-" + size,
    "lds-chip-action-container-" + variant,
    className || "",
  ];

  const disabled = variant === "disabled";

  const handleClick = (event) => {
    event.stopPropagation();
    onClick();
  };

  return (
    <div
      className={classNames.join(" ")}
      onClick={disabled ? undefined : handleClick}
      data-testid={dataTestId}
    >
      <Icon name={icon} />
    </div>
  );
}

ChipAction.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium"]),
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "primaryCritical",
    "secondaryCritical",
    "disabled",
  ]),
};
