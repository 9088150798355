import createReactClass from "create-react-class";
import FlagEventDatePicker from "@javascript/reactComponents/lds/datepicker/FlagEventDatePicker";
import moment from "moment";

const FlagEventReminder = createReactClass({
  getInitialState: function () {
    const reminderAlreadySet = !!this.props.flagged_event_reminder

    return {
      reminder: reminderAlreadySet,
      dateSelected: reminderAlreadySet ? new Date(this.props.flagged_event_reminder) : null,
    };
  },
  handleDateSelect: function(dateSelected) {
    this.setState({ dateSelected, reminder: true });
    this.props.updateDate(moment(dateSelected).toDate())
  },
  cancel_reminder: function () {
    this.setState({ reminder: false, dateSelected: null });
    this.props.updateDate([]);
  },
  render: function () {
    var class_name = '';
    if (this.state.reminder) {
      class_name = ' show_cancel_flagged_event_reminder';
    }

    let isInThePast = false;
    const originalChosenDate = moment(this.props.flagged_event_reminder)

    if (originalChosenDate.isValid()) {
      isInThePast = originalChosenDate.isBefore(moment(), "day")
    }

    return (
      <fieldset className="sideways_grey_form_section">
        <legend className="sideways_grey_form_legend">Reminder</legend>
        <div className="flagged_event_reminder">
          <span onClick={this.cancel_reminder} className={"cancel_flagged_event_reminder" + class_name}/>
          <FlagEventDatePicker
            className={"flag-event-date-picker-modal"}
            direction="top"
            flaggedDate={this.state.dateSelected}
            isInThePast={isInThePast}
            onDateSelect={this.handleDateSelect}
          />
        </div>

        {/* Lets the "Reminder" lagend be readable */}
        <div style={{ marginBottom: 56 }} />
      </fieldset>
    );
  }
});

export default FlagEventReminder;
