import Tab from "../lds/tabs/Tab";
import TabAnchor from "../lds/tabs/TabAnchor";
import TabGroup from "../lds/tabs/TabGroup";

export default function ViewFeedTabs({
  selectedTab,
  feed,
  hasNewcomers,
  showNewcomers,
  showEdit,
}) {

  const clickNewcomers = (event) => {
    event.preventDefault();
    trackNewcomersClick("View Newcomers", `/feeds/${feed.id}/new_companies`, feed.id);
  }

  return (
    <TabGroup selectedTab={selectedTab} animated={false}>

      <TabAnchor
        key="feed"
        href={feed.hasEntity ? "ent_feed" : `/feeds/${feed.id}`}
        label={feed.hasEntity ? `Activity involving ${feed.name}` : "Feed"}
        value="feed"
        leadingIcon="feeds"
      />

      { showNewcomers &&
        <TabAnchor
          key="new_companies"
          href={null}
          label={`Newcomers${ hasNewcomers ? " *" : ""}`}
          value="new_companies"
          leadingIcon="newcomer"
          onTabClicked={clickNewcomers}
        />
      }

      <TabAnchor
        key="flagged_events"
        href={feed.hasSavedFeed ? `/feeds/${feed.id}/flagged_events` : "flagged_events"}
        label={`Flagged Events${ feed.hasEntity ? ` with ${feed.name}` : ""}`}
        value="flagged_events"
        leadingIcon="flag"
      />

      { showEdit &&
        <TabAnchor
          key="edit_feed"
          href={`/feeds/${feed.id}/edit`}
          label="Edit Feed"
          value="edit"
          leadingIcon="edit"
        />
      }
    </TabGroup>
  );
}

window.ViewFeedTabs = ViewFeedTabs;
