const ZoominfoContactPurchasedDetails = function(props) {
  var revealed_date = moment(props.current_contact.created_at).format('MMM Do, YYYY');
  return (
    <td>
      Revealed by <span className="fullstory_excluded">{props.current_contact.purchased_by}</span> on {revealed_date}
    </td>
  );
}

export default ZoominfoContactPurchasedDetails;
