const AddIndustryLink = (props) => {
  const addIndustry = (e) => {
    e.preventDefault();
    EventModifyModalAction.addIndustry(props.industry);
  };
  const paragraphStyle = {marginBottom: "3px"};
  return (
    <p style={paragraphStyle}>
      <a className="action_link" id={"rspec_add_" + props.industry} onClick={addIndustry}>{props.industry}<i className="icon-plus" /></a>
    </p>
  );
};

export default AddIndustryLink;
