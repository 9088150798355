const SelectedTerritoryHolder = (props) => {
  const removeTerritory = (e) => {
    e.stopPropagation();
    TerritoriesAction.territoryRemoved(props);
  };
  let name = props.name;
  if(props.parent) {
    name = props.name + ", " + props.parent;
  }
  return (
    <div className="foldable">
      <div onClick={removeTerritory} className="foldable_indent_0 location_label location_hover">
        <a className="local_link location_link fancy_link">{name}</a>
      </div>
    </div>
  );
};

export default SelectedTerritoryHolder;
