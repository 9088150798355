const OrgListCountDescription = (props) => {
    const toggleOrgList = function(e){
        e.preventDefault();
        ListModalAction.switchDisplayOrgList();
    }

    var desc;
    var fixed_height = {height:'54px'};
    var display_org_list;
    if(props.displayOrgList){
        display_org_list = <a href='#' onClick={toggleOrgList} className="action_link">Hide preview</a>;
    }
    else {

        display_org_list = <a href='#' onClick={toggleOrgList} className="action_link" id="show_org_list_preview">Show preview</a>
    }

    if(props.justSponsor){
        if(props.sponsorList.length < props.total_sponsors){
            desc =<p style={fixed_height} >Your list will be generated from the { props.sponsorList.length} most active {props.total_sponsors} {I18n.t("terminology.target_organization_title")} companies in your search (ranked by event count). {display_org_list}</p>;
        }
        else{
            desc = <p>Your list will be generated from the { props.sponsorList.length} {I18n.t("terminology.target_organization_title")} companies in your search (ranked by event count). {display_org_list}</p>;
        }
    }
    else{
        if(props.allOrgList.length < props.all_org_count){
            desc = <p style={fixed_height}>Your list will be generated from the { props.allOrgList.length} most active {props.all_org_count} organizations in your search (ranked by event count). {display_org_list}</p>;
        }
        else{
            desc = <p>Your list will be generated from the { props.allOrgList.length} organizations in your search (ranked by event count). {display_org_list}</p>;
        }

    }

    return (
        <div className="row-fluid">
            <div className="span12">
            {desc}
            </div>
        </div>
      );
}

export default OrgListCountDescription;
