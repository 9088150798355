import React from "react";
import PropTypes from "prop-types";

export default function Icon({
  className = "",
  filled = false,
  name = "",
  onClick = () => {},
  role = "img",
  style = {},
}) {
  if (filled) name += "-filled";

  const classNames = [`icon-${name}`];
  if (className) {
    classNames.push(className);
  }

  return (
    <span
      className={classNames.join(" ")}
      onClick={onClick}
      style={style}
      role={role}
    ></span>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
  filled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  role: PropTypes.string,
  style: PropTypes.object,
}
