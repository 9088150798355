import Reflux from "reflux";
import EntSearchAction from "./actions/entSearchActions";

const EntSearchStore = Reflux.createStore({
    data: {search_string: '', results: []},
    listenables: EntSearchAction,
    onSearchFor: function(search_string){
        var srch = search_string.trim();
        this.data.search_string = search_string;
        if(srch.length > 2){
            // console.log("Doing search for " + srch);
            $.getJSON("/administrate_event/ent_autocomplete_search", {text: srch}, function(res){
                this.data = {results: res};
                this.trigger(this.data);
            }.bind(this));
        }
        else{
            this.data = {search_string: search_string , results: []}
        }
        this.trigger(this.data);
    },
    onChoseEntity: function(entity){
        EventModifyModalAction.addEntity(entity);
        this.data = {search_string: "", results: []};
        this.trigger(this.data);
    }
});

window.EntSearchStore = EntSearchStore;
export default EntSearchStore;
