const ZoominfoResultsText = function(props) {
  if (props.totalResults > 100) {
    return (<span>Retrieved the first 100 people of {props.totalResults} found</span>);
  }
  else if (props.totalResults > 1) {
    return (<span>{props.totalResults} people found</span>);
  }
  else if (props.totalResults > 0) {
    return (<span>1 person found</span>);
  }
  else {
    return (<span className="no_results">No Contact Information Found: Input a first and last name to check for validated email formulas</span>);
  }
}

export default ZoominfoResultsText;
