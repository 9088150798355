const ModifyListButton = createReactClass({
  mixins: [Reflux.listenTo(ColumnControlsStore,"change_control_state"), Reflux.listenTo(FilteringActionsStore,"change_filter_state")],
  getInitialState: function(){
    return {
      button_type: this.props.list_view === 'list' ? 'enabled' : 'disabled'
    }
  },
  change_control_state: function(controls_open) {
    this.setState({
      button_type: controls_open === 'checked' ? 'disabled' : 'enabled'
    });
  },
  change_filter_state: function(filter_state) {
    this.setState({
      button_type: filter_state.open || this.props.list_view !== 'list' ? 'disabled' : 'enabled'
    });
  },
  toggle_controls: function (e){
    e.stopPropagation();
    if(!this.props.is_editable) { return; }

    Zymewire.mixpanel_track("Lists: modify columns");
    if(this.state.button_type !== 'disabled'){
      ColumnControlsActions.toggleColumnControls('checked')
    }
  },
  render: function () {
    const class_names = ["button-small"];

    if(this.state.button_type !== 'enabled' || !this.props.is_editable){
      class_names.push("button-disable");
    }
    else{
      class_names.push("button-primary");
    }

    return (
      <div className="modify_list_row">
        <button id="modify_pivot_columns_button" onClick={this.toggle_controls} className={class_names.join(' ')}>Modify Columns</button>
      </div>
    );
  }
});

export default ModifyListButton;
