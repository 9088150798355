import Reflux from "reflux"

/**
 * Reflux action object
 *
 * @namespace FacetSearchCompanyRelationshipActions
 * @param [Array] of action names for use with FacetSearchCompanyRelationshipMutations
 */
const FacetSearchCompanyRelationshipActions = Reflux.createActions([
  "addCompanyRelationship",
  "companyRelationshipsSetup",
  "removeCompanyRelationship",
  "updateCompanyRelationships",
]);

/**
 * Reflux store object
 *
 * @namespace FacetSearchCompanyRelationshipMutations
 *
 * Store listens to FacetSearchCompanyRelationshipActions events and updates its data object.
 * Is used from outside the component to set up data in different points of the lifecycle.
 */
const FacetSearchCompanyRelationshipMutations = {
  onAddCompanyRelationship: function (relationship) {
    this.data.relationships.selected.push(relationship);
    doEventSearch();
    // this.trigger(this.data);
  },

  onCompanyRelationshipsSetup: function (condition, relationships) {
    this.data.relationships.salesforceCurrentlyProcessing = condition.salesforceCurrentlyProcessing;
    this.data.relationships.authorizedSalesForce = condition.authorizedSalesForce;
    this.data.relationships.selected = relationships;

    this.trigger(this.data);
  },

  onRemoveCompanyRelationship: function (relationship) {
    this.data.relationships.selected = _.reject(this.data.relationships.selected, function (relationshipSelected) {
      return relationshipSelected === relationship;
    });

    doEventSearch();
    // this.trigger(this.data);
  },

  onUpdateCompanyRelationships: function (relationships) {
    this.data.relationships.selected = relationships || [];
    this.trigger(this.data);
  },
};

window.FacetSearchCompanyRelationshipActions = FacetSearchCompanyRelationshipActions
export {
  FacetSearchCompanyRelationshipActions,
  FacetSearchCompanyRelationshipMutations
}