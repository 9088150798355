export const flattenLocationList = (locationList) => {
  let flattenedList = [];
  locationList.forEach(location =>{
    if(Array.isArray(location.children)) {
      flattenedList.push({code: location.code, name: location.name, parent: location.parent});
      flattenedList.push(...flattenLocationList(location.children));
    } else {
      flattenedList.push({code: location.code, name: location.name, parent: location.parent});
    }
  });
  return flattenedList;
};

export const constructSelectableLocationList = (fullLocationList, selectedLocations) => {
  // locationHelpers should be refactored to this file
  fullLocationList = _.reject(fullLocationList, function(child){
    return locationHelpers.findLocationInList(child.code, selectedLocations);
  });
  for(let i = 0; i < fullLocationList.length; i++){
    const location = fullLocationList[i];
    if(Array.isArray(location.children)){
      location.children = constructSelectableLocationList(location.children, selectedLocations);
    }
  }
  return fullLocationList;
};
