import React from "react";
import PropTypes from "prop-types";
import SelectionControl from "../selection_controls/SelectionControl";

const RADIO_STATES = Object.freeze({
  unselected: "unselected",
  selected: "selected",
});

const RADIO_ICONS = Object.freeze({
  unselected: "radio",
  selected: "radio-filled",
});

function RadioButtonItem({
  id,
  label,
  onClick,
  selection = RADIO_STATES.unselected,
  value,
  variant = "active", // TODO: replace
  styleOverride = {},
}) {
  const commonProps = { id, onClick, label, value, variant, styleOverride };

  return (
    <>
      <SelectionControl icon={RADIO_ICONS[selection]} {...commonProps} />
    </>
  );
}

export const RadioButtonItemPropTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  selection: PropTypes.oneOf(["selected", "unselected"]),
  value: PropTypes.string,
  variant: PropTypes.oneOf(["active", "disable"]),
  styleOverride: PropTypes.object,
};

RadioButtonItem.propTypes = RadioButtonItemPropTypes;
export default RadioButtonItem;
export { RADIO_STATES };
