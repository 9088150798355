import Reflux from "reflux"

/**
 * Reflux action object
 *
 * @namespace FacetSearchCompanyActivityActions
 * @param [Array] of action names for use with FacetSearchCompanyActivityMutations
 */
const FacetSearchCompanyActivityActions = Reflux.createActions({
  activitiesInitialState: { sync: true },
  activitySelected: { sync: true },
  activityRemoved: { sync: true }
});

/**
 * Reflux store object
 *
 * @namespace FacetSearchCompanyActivityMutations
 *
 * Store listens to FacetSearchCompanyActivityActions events and updates its data object.
 * Is used from outside the component to set up data in different points of the lifecycle.
 */
const FacetSearchCompanyActivityMutations = {
    onActivitiesInitialState: function(data){
      if (data === 'all_event') data = null

      this.data.activities.selected = data;

      this.trigger(this.data);
    },
    onActivitySelected: function(activity, opts = { doSearch: true }){
      if (activity === 'all_event') activity = null
      this.data.activities.selected = activity;
      

      if (opts.doSearch) {
        doEventSearch().then(() => this.trigger(this.data))
      } else {
        this.trigger(this.data);
      }
    },
    onActivityRemoved: function(){
        this.data.activities.selected = null;
        doEventSearch().then(() => {
          this.trigger(this.data);
        });        
    }
}

window.FacetSearchCompanyActivityActions = FacetSearchCompanyActivityActions
export {
  FacetSearchCompanyActivityActions,
  FacetSearchCompanyActivityMutations
}
