import Reflux from "reflux";
import FacetSearchStore from "../../stores/facetSearchStore";
import { FacetSearchGeoCoordinatesActions } from "../../stores/actions/facetSearch/geoCoordinatesActions";

/**
 * React component that displays a notice that mapping has been applied as a filter
 *
 * @param {Object} geoCoordinates a hash detailing map coordinates. { bottom_right_lat: number, bottom_right_lon: number, top_left_lat: number, top_left_lon: number, locked?: boolean }
 */

// this component, with a little work and standardization, could be made generic once the breadbox component PDLC is complete
//   -- GJ 2023.02.07
const FacetSearchCriteriaGeoCoordinates = createReactClass({
  mixins: [
    Reflux.connectFilter(FacetSearchStore, function (data) {
      return {
        geoCoordinates: data.geoCoordinates,
      };
    }),
  ],
  getInitialState() {
    return {
      geoCoordinates: FacetSearchStore.data.geoCoordinates,
    };
  },
  render() {

    let renderThis = false;
    if((this.state.geoCoordinates && _.keys(this.state.geoCoordinates).length > 0)) {
      // as per Gus suggestion: we only test the one coordinate because if one exists they all do
      const weHaveCoordinatesToRender = this.state.geoCoordinates.bottom_right_lat;
      renderThis = weHaveCoordinatesToRender !== null;
    }

    if(renderThis === false) {
      return null;
    }
    else {

      let locked = false;

      const originalFeedQuery = Zymewire.original_feed_query;
      if (originalFeedQuery && originalFeedQuery.geo_box) {
        locked = true;
      }
      let entry = <FacetSearchCriteriaGeoCoordinatesEntry locked={locked} />

      if(locked) {
        return entry
      }
      else {
        return <FacetSearchCriteriaGeoCoordinatesRemove>
          {entry}
        </FacetSearchCriteriaGeoCoordinatesRemove>
      }
    }
  }
});


/**
 * React component that actually displays the notice that mapping was used to filter a query. If the entry is a locked entry, as permitted on the feed view, then the entry's remove functions are disabled.
 *
 * @param {Boolean} locked is the filter removable or not
 */

// this component, with a little work and standardization, could be made generic once the breadbox component PDLC is complete
//   -- GJ 2023.02.07
const FacetSearchCriteriaGeoCoordinatesEntry = ({ locked }) => {

  let removeTitle = "Click to remove this filter.";
  let removeEntry = <GenericFilterItemRemove />;
  let removeStyle = {fontSize: "13px"};

  if(locked) {
    removeTitle = null;
    removeEntry = null;
    removeStyle.color = "#000000";
  }

  let locationName = "Companies in the mapped area";

  return (
    <div className="filter_item filter_item_entity" title={"You are filtering your search by a specific map geography. " + removeTitle}>
      {removeEntry}
      <div style={{display: "inline-block"}}>
        <p style={removeStyle}>
          <b>{locationName}</b>
        </p>
      </div>
    </div>
  )
};

/**
 * React component that acts as a wrapper for when the mapping filter's remove functions are enabled.
 *
 */

const FacetSearchCriteriaGeoCoordinatesRemove = ({ children }) => {

  let removeGeoCoordinates = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // remove_geo_box();
    //
    //
    // found 2 instances of remove_geo_box -- different outcomes, same method name -- the outcome depends on context:
    //
    // if on the mapping page
    //
    //     var cntr = new google.maps.LatLng(25.0, -30.0);
    //     window.myMap.setCenter(cntr);
    //     window.myMap.setZoom(2);
    //     Zymewire.geo_box = current_bounding_box();

    // otherwise:
    //
    //   Zymewire.geo_box = null;
    //   doEventSearch();
    //
    //
    // the curious thing is that the call to doEventSearch() only occurs if you're not on the mapping page -- timebox
    // prevented investigation as to why and what's happening

    let bounds = {...FacetSearchGeoCoordinatesSlice};
    let doSearch = true;

    if(window.myMap) {
      const mapCenter = new google.maps.LatLng(25.0, -30.0);
      window.myMap.setCenter(mapCenter);
      window.myMap.setZoom(2);
      bounds = bounds_to_geo_box(window.myMap.getBounds());
      doSearch = false;
    }
    // need a story to deprecate the Zymewire.geo_box part of the kitchen sink
    // any logic requiring "geo_box" should get it from the store as the single source of truth
    Zymewire.geo_box = bounds;
    FacetSearchGeoCoordinatesActions.geoCoordinatesUpdate(bounds, { doSearch: doSearch, triggerStateUpdate: false });
    return false;
  };

  return (
    <a onClick={removeGeoCoordinates} className="action_link">
      {children}
    </a>
  )
}

window.FacetSearchCriteriaGeoCoordinates = FacetSearchCriteriaGeoCoordinates;
export default FacetSearchCriteriaGeoCoordinates;
