// this component is near identical to AddEntityLink
// save for the action performed in addEntity
// should be candidate for bits and bobs refactor
// -- GJ 2023.10.09

const AutocompleteResult = (props) => {
  const addEntity = (e) => {
    e.preventDefault();
    EntSearchAction.choseEntity(props.entity);
  };
  const paragraphStyle = {marginBottom: "3px"};

  const publishableConceptRefs = props.entity.publishable_concept_refs ? [...props.entity.publishable_concept_refs] : [];
  publishableConceptRefs.forEach((e, i) => publishableConceptRefs[i]=e.substring(0,12));
  const industry = publishableConceptRefs.length > 0 ? <b>({publishableConceptRefs.join(", ")})</b> : null;

  return (
    <p style={paragraphStyle}>
      <a className="action_link" onClick={addEntity}>{props.entity.name} <i className="icon-plus" /></a> {industry}
    </p>
  );
};

export default AutocompleteResult;
