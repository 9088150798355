import { createRoot } from "react-dom/client";

export default class LegacyModalService {
  /** @type {import("react-dom/client").Root} */
  static reactRoot = null;

  static init() {
    LegacyModalService._provision();
  }

  static openModal(element, props) {
    LegacyModalService.reactRoot.render(React.createElement(element, props));
  }

  static closeModal() {
    if (LegacyModalService.reactRoot) {
      LegacyModalService.reactRoot.unmount();
      LegacyModalService._provision();
    }
  }

  static _provision() {
    LegacyModalService.reactRoot = createRoot(
      document.getElementById("LDS-LEGACY-MODAL-ROOT")
    );
  }
}

window.LegacyModalService = LegacyModalService;
