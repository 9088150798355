const ArticleEventOrg = (props) => {
  const clickHandler = (e) => {
    e.preventDefault();
    props.toggleHandler(props.org._id);
  };
  let spanStyle;
  let sponsor;
  let spanClassName;
  if(props.org.is_drug_sponsor){
    const publishableConceptRefs = [...props.org.publishable_concept_refs];
    publishableConceptRefs.forEach((e, i) => publishableConceptRefs[i]=e.substring(0,12));
    sponsor = " (" + publishableConceptRefs.join(", ") + ")";
    spanClassName = "action_link";
  }
  else{
    spanStyle = {color: "grey"};
  }
  const url = "/organizations/" + props.org._id + "/edit";
  const iconClass = props.excluded ? "icon-plus" : "icon-minus";

  return (
    <li><span onClick={clickHandler} className={spanClassName} style={spanStyle}><i className={iconClass}/> {props.org.name}{sponsor} ({props.org.mention_count}) </span> <a href={url} title="edit"><i className="icon-share-alt"/></a></li>
  );
};

export default ArticleEventOrg;
