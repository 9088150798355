import Input from "../lds/Input";

const SearchInput = createReactClass({
    timeout: 0,
    getInitialState: function () {
      return { value: "" };
    },
    doSearch: function(value){
      this.setState({value: value});

      if(this.timeout){
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.props.searchFunction(value)
      }, 300);
    },
    render: function(){
      let placeHolder = "Type a " + this.props.placeHolder + " to search",
        classes = (this.props.classNames || []).join(' ');

      return (
        <Input
          className={ classes }
          id={ this.props.id }
          leadingIcon="search"
          onValueChange={ this.doSearch }
          placeholder={ placeHolder}
          showClearIcon
          size={ this.props.size }
          value={ this.state.value }
          width={ this.props.width }
        />
      );
    }
  }
);

window.SearchInput = SearchInput;
export default SearchInput;
