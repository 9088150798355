import Reflux from "reflux";

const SalesforceListsActions = Reflux.createActions([
  "initialState",
  "loadMore",
  "searchName",
  "selectCategory",
  "clearSearch",
  "sort",
]);

export default SalesforceListsActions;
