const EventListTitle = (props) => {
  let eventType = "Awaiting Publishing";
  if(props.viz === "hidden"){
    eventType = "Hidden";
  }
  else if(props.viz === "visible"){
    eventType = "Visible/Published";
  }
  else if(props.viz === "queued"){
    eventType = "queued";
  }
  else if(props.viz === "all"){
    eventType = "all";
  }
  return (
    <div><br/><h2>Event list - showing {eventType} events</h2></div>
  );
};

export default EventListTitle;
