import Input from "../lds/Input";

const FlagEventComment = (props) => {
  const change = function (value) {
    props.updateComment(value);
  }

  var styl = { width: '100%' };
  return (
    <fieldset className="sideways_grey_form_section">
      <legend className="sideways_grey_form_legend">Comment</legend>

      <Input
        multiLine
        size="large"
        id="flag_comment_text"
        className="fullstory_excluded"

        width="100%" height="150px"

        label="Add Comment (Optional)"

        value={props.comment}
        onValueChange={change}
      />
    </fieldset>
  );
}

export default FlagEventComment;
