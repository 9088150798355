const QueryTimeRange = (props) => {
  if (props.search.time) {
    return (<p>Time Range: <b>{props.search.time}</b></p>);
  }
  else {
    return false;
  }
}

export default QueryTimeRange;
