import SfUserComment from "./sfUserComment";

const SfUserActivity = (props) => {
  const trackActivity = () => {
    SFPanelActions.trackEngagementEvent("View activity in SF", {});
  };
  const trackRelation = () => {
    SFPanelActions.trackEngagementEvent("View activity in SF", {reason: "relation"});
  };
  const keyDate = props.due_date ? props.due_date : props.sort_date;
  const actDate = moment(keyDate).add(12, "h");
  let relatedTo;
  if(props.related_to){
    const title = "See this " + props.related_to.type + " in Salesforce";
    relatedTo = <span> related to {props.related_to.type} <a href={props.related_to.url} onClick={trackRelation} target="blank" title={title}>{props.related_to.name}</a></span>;
  }
  return (
    <div className="sf_activity_div">
      <div className="sf_activity_headline">
					<span className="sf_activity_subject">
						<a href={props.url} onClick={trackActivity} target="blank" >{props.subject}</a>
					</span>
        <span className="sf_activity_relative_date">
						{actDate.fromNow()}
					</span>
      </div>
      {relatedTo}
      <SfUserComment comment={props.description} />
    </div>
  );
};

export default SfUserActivity;
