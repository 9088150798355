const QueryLocations = (props) => {
  if (props.search.locations) {
    var count = 0;
    var locations = props.search.locations.map(function (loc, index) {
      var loc_string = count > 0 ? "OR " + loc : loc;
      count = 1;
      return (<p key={index}><b>{loc_string}</b></p>);
    });
    return (<div><p>Sponsor companies based in:</p>{locations}</div>);
  }
  else {
    return false;
  }
}

export default QueryLocations;
