import { useState } from "react";
import PropTypes from "prop-types";

import Dropdown, { DropdownTrigger } from "@javascript/reactComponents/lds/dropdown/Dropdown";
import DropdownList from "@javascript/reactComponents/lds/dropdown/DropdownList";
import Input from "@javascript/reactComponents/lds/Input";

import { commonFieldElements, fieldInError } from "@javascript/helpers/crmUtilities";

const CRMDropdown = ({
  errors,
  field,
}) => {

  const [useValue, setValue] = useState(field.value ? field.value : "");
  const [open, setOpen] = useState(false);

  const selectItem = (value) => {
    setValue(value);
    setOpen(false);
  };

  let isMissing = fieldInError(errors, field.name);
  const { helperText, helperTextVariant, placeholder } = commonFieldElements(field, isMissing);

  const inputClassName = useValue ? "" : "dropdown-input-with-label-no-data";

  const items = field.format.map((field) => {
    if (typeof field === "object" && field !== null) {
      const label = field.label ? field.label : "--";
      const value = field.value ? field.value : "";
      return({label: label, value: value, selected: value === useValue});
    }
    else {
      const label = "None";
      const value = "";
      return({label: label, value: value, selected: value === useValue});
    }
  });

  return (
    <Dropdown
      id={`${field.label}_dropdown`}
      maxHeight="300px"
      onDropdownClose={() => setOpen(false)}
      onDropdownOpen={() => setOpen(true)}
      onItemSelected={(item) => {
        selectItem(item.value)
      }}
      popoverClassName={"crm-pulldown"}
      size="large"
    >
      <DropdownTrigger>
        <div onClick={() => setOpen(true)}>
          <Input
            className={inputClassName}
            id={field.name}
            helperText={helperText}
            helperTextVariant={helperTextVariant}
            name={field.name}
            onValueChange={() => {}}
            label={placeholder}
            readOnly
            size="large"
            trailingIcon={"chevron-" + (open ? "up" : "down")}
            value={useValue}
            width="100%"
          />
        </div>
      </DropdownTrigger>
      <DropdownList leadingIcon={"radio"} items={items} />
    </Dropdown>
  )
};

CRMDropdown.propTypes = {
  errors: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  field: PropTypes.object.isRequired,
};

export default CRMDropdown;
