import Reflux from "reflux";

const ColumnsChosenActions = Reflux.createActions([
  "initialState",
  "removeFromChosen",
  "addToChosen",
  "updateOrder",
  "clearAll",
  "reset",
]);

// We should import the actions in the files they're used in, thereby doing away with this window.hack
// But because the imports are used in files loaded by app/javascripts/applicaiton.js via //= require components
// the import statement required for this is sprocketed into a require statement: not supported by the browser
window.ColumnsChosenActions = ColumnsChosenActions;
export default ColumnsChosenActions;
