import Reflux from "reflux";
import FacetSearchStore from "../../stores/facetSearchStore";
import { FacetSearchCompanyActivityActions } from "../../stores/actions/facetSearch/activityActions";

/**
 * Component responsible for rendering the breadbox for when a category is selected.
 *
 * @param prop {{ category: object }}
 */
const FacetSearchCriteriaCategory = createReactClass({
  mixins: [
    Reflux.connectFilter(FacetSearchStore, function(data) { return data.activities })
  ],
  getInitialState() {
    return { ...(FacetSearchStore.data.activities) }
  },
  render() {
    let category

    const original = Zymewire.original_feed_query;
    const currentCat = this.state.selected

    // Display logic
    if (currentCat !== 'all_event') {
      category = Zymewire.category_hash[currentCat]
    }

    if (category === null || category === undefined) return null;

    // Should user be able to edit?
    if (
      original &&
      original.category &&
      original.category !== "all_event" &&
      original.category === currentCat
    ) {
      category["locked"] = true;
    }

    return (
      <div>
        {category.locked ? (
          <CategoryEntry category={category} />
        ) : (
          <CategoryRemove category={category} />
        )}
      </div>
    );
  }
})

const CategoryEntry = ({ category }) => {
  const classes = `selection_label event_sub_category ${category.category} false_fold cat_filter_rspec`;

  return <div className={classes}>{category.name}</div>;
};

const CategoryRemove = ({ category }) => {
  const classes = `selection_label event_sub_category ${category.category} false_fold cat_filter_rspec cat_filter_enabled`;

  const removeCategory = (e) => {
    e.preventDefault();
    e.stopPropagation();
    FacetSearchCompanyActivityActions.activityRemoved();
  };

  return (
    <div onClick={removeCategory} className={classes}>
      {category.name}
      <div className="remove_filter"></div>
    </div>
  );
};

window.FacetSearchCriteriaCategory = FacetSearchCriteriaCategory;
export default FacetSearchCriteriaCategory;
