import PropTypes from "prop-types";

import Button from "@javascript/reactComponents/lds/Button";
import ButtonGroup from "@javascript/reactComponents/lds/ButtonGroup";

import Heading from "@javascript/reactComponents/lds/typography/Heading";
import Icon from "@javascript/reactComponents/lds/Icon";

import {
  ModalBody,
  ModalFooter,
} from "@javascript/reactComponents/lds/modal/Modal";

import NavItem from "@javascript/reactComponents/lds/NavItem";

const ListModalSuccess = ({
  listId,
}) => {
  const goToList = () =>
    location.assign(`/lists/${listId}?list_view=first`);

  return (
    <>
      <ModalBody
        className="lds-modal-body-height modal-success-content"
      >
        <Icon
          name="special-success"
        />
        <Heading
          containerClassName="generic-modal-header"
          size="h2"
        >
          List generated successfully!
        </Heading>
        <p>
          You can also access all your lists on &nbsp;
          <NavItem
            className="lists_modal_nav_link hovered"
            href="/lists"
            icon="list"
            id="lists-modal-to-lists-page-link"
            variant="link"
          >
            Lists
          </NavItem>
          &nbsp; page.
        </p>
      </ModalBody>
      <ModalFooter align="flex-end">
        <ButtonGroup size="medium">
          <Button
            id="dismiss-flag-event-success-modal"
            onClick={LegacyModalService.closeModal}
            size="medium"
            variant="secondary"
          >
            Close
          </Button>
          <Button
            id="go-to-flagged-events-page"
            onClick={goToList}
            size="medium"
            variant="primary"
          >
            Go to List
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};

ListModalSuccess.propTypes = {
  listId: PropTypes.string,
};

export default ListModalSuccess;
