import { update } from 'lodash';
import Reflux from 'reflux';

/**
* Reflux action object
*
* @namespace FacetSearchAliasSearchActions
* @param [Array] of action names for use with FacetSearchStoreMutations
*/

const FacetSearchAliasSearchActions = Reflux.createActions({
  addAliasSearchRefs: { sync: true },
  removeAliasSearchRefs: { sync: true },
  setupAliasSearchRefs: { sync: true },
});

/**
 * Reflux store object
 *
 * @namespace FacetSearchAliasSearchMutations
 *
 * Store listens to FacetSearchAliasSearchMutations events and updates its data object.
 * Is used from outside the component to set up data in different points of the lifecycle.
 */

const FacetSearchAliasSearchMutations = {
  onAddAliasSearchRefs: function(aliasSearchData, ajaxSearch = true) {
    const updatedAliasSearchRefs = JSON.parse(JSON.stringify(this.data.aliasSearch.aliasSearchRefs));

    const existingRefs = updatedAliasSearchRefs.some(as => _.isEqual(as, aliasSearchData));

    if (!existingRefs) {
      updatedAliasSearchRefs.push(aliasSearchData);

      this.data.aliasSearch.aliasSearchRefs = updatedAliasSearchRefs;
    }

    if(ajaxSearch) {
      doEventSearch();
    }
  },

  onRemoveAliasSearchRefs: function(aliasSearchData) {
    let updatedAliasSearchRefs = JSON.parse(JSON.stringify(this.data.aliasSearch.aliasSearchRefs));

    const existingRefs = updatedAliasSearchRefs.some(as => _.isEqual(as, aliasSearchData));

    if (existingRefs) {
      updatedAliasSearchRefs = updatedAliasSearchRefs.filter(as => !_.isEqual(as, aliasSearchData));
    }

    this.data.aliasSearch.aliasSearchRefs = updatedAliasSearchRefs;

    doEventSearch();
  },

  onSetupAliasSearchRefs: function(aliasSearchRefs) {
    this.data.aliasSearch.aliasSearchRefs = aliasSearchRefs;
    this.trigger(this.data);
  },
}

window.FacetSearchAliasSearchActions = FacetSearchAliasSearchActions;

export {
  FacetSearchAliasSearchActions,
  FacetSearchAliasSearchMutations,
}