import PaginationHolder from "../generic/pagination/paginationHolder";
import SearchInput from "../generic/searchInput";
import PageOfSelectableUsers from "./pageOfSelectableUsers";

const PaginatedSelectableListOfUsers = createReactClass({
  getInitialState: function(){
    return {page: 0, userList:this.props.userList, searchString:''};
  },
  changePage: function(pageNumber){
    this.setState({page: pageNumber});
  },
  searchUsers: function(searchString){
    let usersAvailable = this.props.userList.slice();
    let filteredUsers = usersAvailable.filter(function (user) {
      return user.name.toLowerCase().indexOf(searchString.toLowerCase()) >= 0;
    });
    this.setState({userList:filteredUsers, searchString:searchString, page:0});
    for (const action of this.props.mixpanelActions) {
      Zymewire.mixpanel_track(action, {search_string:searchString})
    }
  },
  render: function(){
    let displaySize = this.props.columnsPerPage * this.props.usersPerColumn;
    let lists = [];
    let remainder = this.state.userList;
    while(remainder.length > 0){
      lists.push(_.first(remainder, displaySize));
      remainder = _.rest(remainder, displaySize);
    }
    let list = lists[this.state.page];
    let paginationClasses = ['pagination', 'standard_pagination_holder', 'search_enabled_user_pagination'];
    let fieldSetClasses = ['fullstory_excluded', 'sideways_grey_form_section'];
    if(lists.length > 1){
      fieldSetClasses.push('selectable_users_section')
    }
    else{
      fieldSetClasses.push('selectable_users_section_empty')
    }

    // Uniquely identify the generic search input
    let searchInputId = this.props.uniqueId + '_search_bar';

    // Always show search bar
    let topPagination = <SearchInput id={searchInputId} searchFunction={this.searchUsers} placeHolder={"user name"} width={this.props.width} size={this.props.size} />
    let bottomPagination  = null;

    // Only show pagination if more than one page
    if(lists.length > 1) {
      bottomPagination = <PaginationHolder pages={lists.length} displaySize={displaySize} changePage={this.changePage} paginationClasses={paginationClasses} currentPage={this.state.page}/>
    }

    return (
      <fieldset className={fieldSetClasses.join(' ')}>
        <legend className="sideways_grey_form_legend">{this.props.legend}</legend>
        <div className="selectable_users_top_navigation">
          {topPagination}
        </div>
        <PageOfSelectableUsers userList={list} toggleUser={this.props.toggleUser} searchString={this.state.searchString} usersPerColumn={this.props.usersPerColumn} />
        {bottomPagination}
      </fieldset>
    );
  }
});

window.PaginatedSelectableListOfUsers = PaginatedSelectableListOfUsers
export default PaginatedSelectableListOfUsers;
