import Input from "@javascript/reactComponents/lds/Input";
import Button from "@javascript/reactComponents/lds/Button";

import { useState } from "react";

const ApolloApiPanel = () => {
  const [apiKey, setApiKey] = useState("");
  const [saving, setSaving] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorLevel, setErrorLevel] = useState("");

  const handleValueChange = (value) => {
    setApiKey(value);
  };

  const fetchAPIKey = async () => {
    const url = "/settings/apollo_api_status";
    const response = await fetch(url, {
      method: "GET",
    });

    if (response.ok) {
      const data = await response.json();
      return data.configured;
    }

    return false;
  };

  const handleSave = async () => {
    setSaving(true);

    const apiSet = await fetchAPIKey();
    let confirmed = null;

    if (apiSet) {
      confirmed = confirm("API key already exists. Are you sure?");

      if (!confirmed) {
        setSaving(false);
        return;
      }
    }

    try {
      const url = "/settings/save_apollo_api_key";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify({ api_key: apiKey }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          setErrorMsg("API key saved successfully!");
          setErrorLevel("success");
          setApiKey("");
        } else {
          setErrorMsg("Invalid API key. Please check.");
          setErrorLevel("error");
        }
      } else {
        setErrorMsg("The API key could not be saved. Please try again later.");
        setErrorLevel("error");
      }
    } catch (error) {
    } finally {
      setSaving(false);
    }
  };

  const errorStyle = {
    color: errorLevel === "error" ? "red" : "green",
    fontWeight: "bold",
    paddingBottom: "10px",
  };

  const KEY_MAX_LENGTH = 22;
  const variant =
    saving || apiKey.length !== KEY_MAX_LENGTH ? "disable" : "primary";

  return (
    <div className="flex-col">
      {errorMsg && <h3 style={errorStyle}>{errorMsg}</h3>}
      <div className="input-group-horizontal">
        <div>
          <Input
            id="api-key"
            onSubmit={() => {}}
            onValueChange={handleValueChange}
            placeholder="Your Apollo API Key"
            disabled={saving}
            size="medium"
            showClearIcon={false}
            maxLength={KEY_MAX_LENGTH}
            value={apiKey}
            width={300}
          />
        </div>
        <div>
          <Button
            id="save-api-key"
            type="submit"
            size="medium"
            variant={variant}
            onClick={() => {
              handleSave(apiKey);
            }}
          >
            {saving ? "Saving..." : "Save"}
          </Button>
        </div>
      </div>
    </div>
  );
};

window.ApolloApiPanel = ApolloApiPanel;
export default ApolloApiPanel;
