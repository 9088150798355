const AffiliatedWithCard = (props) => {

  const toggleAffiliations = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const toggleAllCharacteristics = $("#toggle_all_characteristics");
    toggleAllCharacteristics.prop("checked", !toggleAllCharacteristics.prop("checked"));
    all_characteristics_opened();
  };

  return (
    <div id={"rspec_affiliations_" + props.id} className='infographic_card affiliated_with_card' onClick={toggleAffiliations}>
      {props.data.name}
    </div>
  );
};

export default AffiliatedWithCard;
