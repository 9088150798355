import Reflux from "reflux"

/**
 * Reflux action object
 *
 * @namespace FacetSearchTimeFrameActions
 * @param [Array] of action names for use with FacetSearchTimeFrameMutations
 */
const FacetSearchTimeFrameActions = Reflux.createActions({
  timeFrameUpdate: {sync: true},
  timeFrameReset: {sync: true},
  setSelectedTimeFrame: {sync: true},
  setDateRange: { sync: true },
});

/**
 * Reflux store object
 *
 * @namespace FacetSearchCriteriaTimeFrameMutations
 *
 * Store listens to FacetSearchTimeFrameActions events and updates its data object.
 */
const FacetSearchTimeFrameMutations = {
  onTimeFrameUpdate: function(options = {doSearch: false, timeSelectValue: ""} ){
    const { doSearch, timeSelectValue } = options;

    // REFACTOR:
    //   currently, this method is triggered when FacetSearchCriteriaTimeFrame's parent component is getting ready to mount
    //   in future, the FacetSearchCriteriaTimeFrame component should be refactored to listen to the FacetSearchStore store
    //   and the store should be initialized wherever we mount FacetSearchCriteriaTimeFrame's parent (FacetSearchCriteriaContainer)
    //   and the store' time frame state should be updated by the UI, not by polling the UI via search_data()

    const refactorMe = search_data();

    let dataToBeRefactored = {
      selected: timeSelectValue || refactorMe.time_select,
      from: refactorMe.earliest_date || this.data.timeFrame.from,
      to: refactorMe.latest_date || this.data.timeFrame.to,
    }

    this.data.timeFrame = { ...(this.data.timeFrame), ...dataToBeRefactored };

    this.trigger(this.data);

    if(doSearch) {
      doEventSearch();
    }
  },

  onSetSelectedTimeFrame: function(timeFrame) {
    const params = new URLSearchParams(location.search);
    const earliest = params.get("earliest_date");
    const latest = params.get("latest_date");

    if (!timeFrame) {
      // It's possible that user wants custom range
      if (earliest || latest) {
        this.data.timeFrame.selected = "time_custom";
        this.data.timeFrame.from = earliest;
        this.data.timeFrame.to = latest;

        // $("#selector_date_range").fadeOut("fast", function () {
        //   $("#custom_date_range").fadeIn("fast");
        // });
      } else {
        this.data.timeFrame.selected = "time_any";
      }
    } else {
      this.data.timeFrame.selected = timeFrame;

      if (timeFrame === "time_custom") {
        this.data.timeFrame.from = earliest;
        this.data.timeFrame.to = latest;
      }
    }

    this.trigger(this.data)
  },

  onTimeFrameReset: function(options = { doSearch: false}) {
    this.data.timeFrame = {
      ...(this.data.timeFrame),
      from: null,
      to: null,
      selected: "time_any",
    };

    this.trigger(this.data);

    if(options.doSearch) {
      doEventSearch();
    }
  },

  onSetDateRange(start, end) {
    this.data.timeFrame.from = start;
    this.data.timeFrame.to = end;

    this.trigger(this.data);
  },
  // Grab source of truth from the URL because it's almost
  // guaranteed to be the active one.
  getActiveTimeFrame() {
    const params = new URLSearchParams(location.search);

    const earliest = params.get("earliest_date");
    const latest = params.get("latest_date");
    let selected = params.get("time_select") || this.data.timeFrame.selected

    // Same as logic above, sometimes the URL doesn't
    // specify "time_select" while including earliest/latest
    // dates. If either is present, assume time_custom.
    if (!selected && (earliest || latest)) {
      selected = "time_custom";
    }

    return { selected, earliest, latest };
  },
}

window.FacetSearchTimeFrameActions = FacetSearchTimeFrameActions
export {
  FacetSearchTimeFrameActions,
  FacetSearchTimeFrameMutations
}
