const ZoominfoEmailReveal = function(props) {
  var email = "***@***";

  if (!props.showEmail) {
    email = "";
  }

  return (
    <td>
      {email}
    </td>
  );
}

export default ZoominfoEmailReveal;
