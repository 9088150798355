import React, { useContext } from "react";
import Toast from "../../../lds/notifications/Toast";
import Icon from "../../../lds/Icon";
import { DeleteModalContext } from "../ListDeleteModal";
import ToastBanner from "../ToastBanner";

const ListDeleteModalDeletingBody = () => {
  const { selectedLists } = useContext(DeleteModalContext);

  return (
    <>
      <div className="status">
        <div className="delete-sad-face-background">
          <Icon
            name="sad"
            style={{ height: 50, width: 50 }}
            className="icon-background"
          />
        </div>
        <h2>
          Deleting {selectedLists.size} list
          {selectedLists.size === 1 ? "" : "s"}...
        </h2>
      </div>
      <ToastBanner
        title="Taking too long?"
        variant="info"
        className="mock-banner-info"
      >
        You can close this window at any time and we will delete your lists in
        the background for you. Access all your generated lists by visiting{" "}
        <b>Lists</b>.
      </ToastBanner>
    </>
  );
};

export default ListDeleteModalDeletingBody;
