import { useContext, useState } from "react";
import Button from "../lds/Button";
import ButtonGroup from "../lds/ButtonGroup";
import Banner from "../lds/notifications/Banner";
import BannerBody from "../lds/notifications/BannerBody";
import BannerHeading from "../lds/notifications/BannerHeading";
import ModalContext from "../lds/modal/ModalContext";
import { ModalBody, ModalFooter, ModalHeader } from "../lds/modal/Modal";
import { FeedsIndexContext } from "@javascript/stores/FeedsIndex";
import Icon from "../lds/Icon";
import Heading from "../lds/typography/Heading";

function DeleteConfirmationModal({ children }) {
  return (
    <>
      <Banner variant="critical">
        <BannerHeading>This action is irreversible</BannerHeading>
        <BannerBody>
          All the selected feeds will be permanently removed and you won't be
          able to retrieve them, including the ones you've shared with your
          colleagues.
        </BannerBody>
      </Banner>

      <ul className="delete-feed-list flex-col">{children}</ul>
    </>
  );
}

function DeleteProgressModal({ feedsCount }) {
  return (
    <>
      <Icon name="special-destruct" />

      <Heading size="h2">
        Deleting {feedsCount} feed{feedsCount !== 1 ? "s" : ""}...
      </Heading>

      <Banner variant="info">
        <BannerHeading>Taking too long?</BannerHeading>
        <BannerBody>
          <p>
            You can close this window at any time and we will delete your feeds
            in the background for you. Access all your saved feeds by visiting{" "}
            <b>Feeds</b>.
          </p>
        </BannerBody>
      </Banner>
    </>
  );
}

function DeleteResultModal({ feedsCount }) {
  return (
    <>
      <Icon name="special-success" />

      <Heading size="h2">
        Feed{feedsCount !== 1 ? "s" : ""} deleted successfully!
      </Heading>
    </>
  );
}

const DeleteFeedsModal = function ({ group, feedsSelected }) {
  const { onClose } = useContext(ModalContext);
  const { getSelectedNames, removeFeedsFromView, toggleSelectAll } =
    useContext(FeedsIndexContext);

  // One of "confirmation", "deleting", "result"
  const [userFlowStep, setUserFlowStep] = useState("confirmation");
  const [feedsCount] = useState(feedsSelected.length);

  const deleteSelectedFeeds = () => {
    const data = { feeds_selected: feedsSelected };

    setUserFlowStep("deleting");

    removeFeedsFromView(feedsSelected);

    $.ajax({
      url: "/feeds/delete_feeds",
      type: "POST",
      data: data,
      success: function () {
        setTimeout(() => {
          setUserFlowStep("result");
          toggleSelectAll([], group, true);
        }, 3000);
      },
    });
  };

  const whichFeeds =
    feedsSelected.length > 1 ? `All ${feedsSelected.length} Feeds` : "Feed";

  const feedsList = getSelectedNames(feedsSelected).map((feedName) => {
    return <li key={feedName}>{feedName}</li>;
  });

  return (
    <>
      <ModalHeader title="You are about to delete your feeds" />

      <ModalBody
        className={`delete-feed-modal-body flex-col ${
          userFlowStep != "confirmation" && "align-center"
        }`}
      >
        {userFlowStep === "confirmation" && (
          <DeleteConfirmationModal>{feedsList}</DeleteConfirmationModal>
        )}

        {userFlowStep === "deleting" && (
          <DeleteProgressModal feedsCount={feedsCount} />
        )}

        {userFlowStep === "result" && (
          <DeleteResultModal feedsCount={feedsCount} />
        )}
      </ModalBody>

      <ModalFooter>
        <ButtonGroup size="medium" className="align-self-end">
          <Button
            size="medium"
            variant={userFlowStep === "confirmation" ? "secondary" : "primary"}
            onClick={onClose}
          >
            {userFlowStep === "confirmation" ? "Cancel" : "Close"}
          </Button>

          {userFlowStep === "confirmation" && (
            <Button
              id="confirm_saved_feed_delete_button"
              size="medium"
              variant="critical"
              onClick={deleteSelectedFeeds}
            >
              Delete {whichFeeds}
            </Button>
          )}
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};

window.DeleteFeedsModal = DeleteFeedsModal;
export default DeleteFeedsModal;
