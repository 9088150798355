import Reflux from "reflux";
import ListModalAction from "../actions/pivotLists/listModalActions";

const ListModalActionStore = Reflux.createStore({
  listModalData: {dataLoaded: false, justSponsor: false, displayPage: 0, displayOrgList: false, saving_list: false, succeeded: false, new_list_id: "", createFeed: false},
  listenables: ListModalAction,
  onInitialLoad: function(modalData){
    this.listModalData = _.extend({dataLoaded: false, justSponsor: false, displayPage: 0, displayOrgList: false, saving_list: false, succeeded: false, new_list_id: ""}, modalData);
    if(this.listModalData.map_display){
        this.listModalData.justSponsor = true;
    }
    this.trigger(this.listModalData);
  },
  onSwitchSponsorFlag: function(){
      // console.log("Changing in sponsor in store")
      this.listModalData.justSponsor = !this.listModalData.justSponsor;
      this.listModalData.displayPage = 0;
      this.trigger(this.listModalData);

  },
  onDisplayPage: function(page){
      this.listModalData.displayPage = page;
      this.trigger(this.listModalData);
  },
  onUpdateListName: function(name){
      this.listModalData.listName = name;
      this.trigger(this.listModalData);
  },
  onSwitchDisplayOrgList: function(){
    this.listModalData.displayOrgList = !this.listModalData.displayOrgList;
      this.trigger(this.listModalData);
  },
  onSwitchFeedCreation: function(){
      this.listModalData.createFeed = !this.listModalData.createFeed;
      this.trigger(this.listModalData);
  },
  onCreateList: function(){
      var list_names = this.listModalData.justSponsor ? _.map(this.listModalData.sponsorList, function(org){return org.name;}) :   _.map(this.listModalData.allOrgList, function(org){return org.name;});
      this.listModalData.saving_list = true;
      $.ajax({
          url: '/lists/create_new_list_from_query',
          type: 'POST',
          data: { title: this.listModalData.listName, query: this.listModalData.base_query, entity_names: list_names, create_feed: this.listModalData.createFeed },
          success: function (result) {
              // console.log(result);
              this.listModalData.saving_list = false;
              this.listModalData.succeeded = true;
              this.listModalData.new_list_id = result.list_id;
              $.event.trigger({type: 'list_successfully_created', message: "Successfully created the list", time: new Date()});
              this.trigger(this.listModalData);
          }.bind(this),
          error: function (xhr, ajaxOptions, thrownError) {
              if (xhr.status == 401) {
                  alert("Un-authorized status received")
                  $("#event_modal").modal('hide');
                  window.location = "/users/sign_in"
              }
              else {
                  // console.log(thrownError)
              }
          }
      });
      this.trigger(this.listModalData);
      return false;
  }
});

window.ListModalActionStore = ListModalActionStore;
export default ListModalActionStore;
