import Reflux from "reflux";
import FacetSearchStore from "../../stores/facetSearchStore";
import { FacetSearchLocationActions } from "../../stores/actions/facetSearch/locationActions";

/**
 * React component that displays a list of locations used to filter a query, if any.
 *
 * @author
 * @namespace
 * @memberof
 *
 * @component
 * @example
 *
 * return (
 *   <FacetSearchCriteriaLocation
 *         key={index}
 *         location={location}
 *         />
 * )
 *
 * @param {Array} locations an array of hashes detailing location filters. Each hash entry has a "name" entry and "value" entry, and possibly a "locked" and a "last" entry
 */
const FacetSearchCriteriaLocations = createReactClass({
  mixins: [
    Reflux.connectFilter(FacetSearchStore, function (data) {
      return {
        locations: data.location.selectedLocationCodes,
      };
    }),
  ],
  getInitialState() {
    return {
      locations: FacetSearchStore.data.location.selectedLocationCodes,
    };
  },
  render() {
    const { locations } = this.state;
    const locationsSelected = [];
    const lastIndex = locations.length - 1;

    for (let i = 0; i < locations.length; i++) {
      let isLast = lastIndex === i;
      if (locations[i] === "my_territory") {
        locationsSelected.push({
          name: "Your territory",
          value: locations[i],
          last: isLast,
        });
      } else {
        locationsSelected.push({
          name: Zymewire.loc_codes[locations[i]],
          value: locations[i],
          last: isLast,
        });
      }
    }

    // Lockery
    const original = Zymewire.original_feed_query;
    if (original && original.loc_codes && original.loc_codes.length > 0) {
      _.each(locationsSelected, function (locationSelected) {
        if (_.contains(original.loc_codes, locationSelected.value)) {
          locationSelected["locked"] = true;
        }
      });
      $("#loc_code_panel").hide();
    }

    if (locationsSelected.length > 0) {
      let locationsList = locationsSelected.map((location, index) => {
        return <FacetSearchCriteriaLocation key={index} location={location} />;
      });

      return (
        <div>
          <p style={{ marginBottom: "0px" }}>
            {I18n.t("terminology.company_based_in")}
          </p>
          {locationsList}
        </div>
      );
    } else {
      return null;
    }
  },
});

/**
 * React component that acts as a container for each location used to filter a query. If the entry is a locked entry, as permitted on the feed view, then the entry's remove functions are disabled.
 *
 * @author
 * @namespace
 * @memberof
 *
 * @component
 * @example
 *
 *
 * @param {hash} location a detailing of the location filter. Will have a "name" entry and "value" entry, and possibly a "locked" and a "last" entry
 */
const FacetSearchCriteriaLocation = ({ location }) => {

  let entry = <FacetSearchCriteriaLocationEntry locked={location.locked} name={location.name} last={location.last} />

  if(location.locked) {
    return entry
  }
  else {
    return <FacetSearchCriteriaLocationRemove value={location.value}>
      {entry}
    </FacetSearchCriteriaLocationRemove>
  }
};

/**
 * React component that displays a location used to filter a query. If the entry is a locked entry, as permitted on the feed view, then the entry's remove functions are disabled.
 *
 * @author
 * @namespace
 * @memberof
 *
 * @component
 * @example
 *
 *
 * @param {Boolean} locked the location name
 * @param {String} name the location name
 * @param {Boolean} last whether it's the last in the list... yeah.
 */
const FacetSearchCriteriaLocationEntry = ({ last, locked, name}) => {

  let removeTitle = "Click to remove this filter.";
  let removeEntry = <GenericFilterItemRemove />;
  let removeStyle = {fontSize: "13px"};

  if(locked) {
    removeTitle = null;
    removeEntry = null;
    removeStyle.color = "#000000";
  }

  let locationName = last ? name : name + " OR";

  return (
    <div className="filter_item_property" title={"You are filtering your search by events specifically involving a company based in '" + name + "'. " + removeTitle}>
      {removeEntry}
      <div style={{display: "inline-block"}}>
        <p style={removeStyle}>
          <b>{locationName}</b>
        </p>
      </div>
    </div>
  )
};

/**
 * React component that acts as a wrapper for when a location entry's remove functions are enabled.
 *
 * @author
 * @namespace
 * @memberof
 *
 * @component
 * @example
 *
 *
 * @param {String} value  the location value passed back to the API
 */
const FacetSearchCriteriaLocationRemove = ({ value, children }) => {
  let removeLocation = (e) => {
    e.preventDefault();
    e.stopPropagation();
    FacetSearchLocationActions.removeLocation(value);

    return false;
  };

  return (
    <div>
      <a onClick={removeLocation} className="action_link">
        {children}
      </a>
    </div>
  )
}

window.FacetSearchCriteriaLocations = FacetSearchCriteriaLocations;
export default FacetSearchCriteriaLocations;
