import React from "react";
import PropTypes from "prop-types";
import ChipAction from "./ChipAction";

export default function Chip({
  className,
  href,
  id,
  label,
  leadingIcon,
  onClick,
  size = "medium",
  style = {},
  selected = false,
  trailingIcon,
  trailingIconOnClick,
  trailingIconClassName,
  variant = "primary",
  width = "max-content",
}) {
  const classNames = ["lds-chip-" + size];
  const hasTrailingIcon = trailingIcon && trailingIconOnClick;

  if (!hasTrailingIcon) {
    if (trailingIcon) {
      console.warn(
        "[LDS WARNING - Chip] Trailing icon must have an action attached."
      );
    } else if (trailingIconOnClick) {
      console.warn(
        "[LDS WARNING - Chip] A trailing icon onClick was provided without a trailing icon."
      );
    }
  }

  if (variant !== "none") {
    classNames.push("lds-chip-" + variant);
  }

  // When both onClick are provided, we only want the trailing icon hover/active effects to change
  if ((onClick && !trailingIconOnClick) || href) {
    classNames.push("lds-chip-selectable");
  }

  if (leadingIcon) {
    classNames.push("lds-chip-has-leading-icon");
  }

  if (trailingIcon) {
    classNames.push("lds-chip-has-trailing-icon");
  }

  if (selected) {
    classNames.push("lds-chip-selected")
  }

  if (!label) {
    classNames.push("lds-chip-no-label")
  }

  if (href && onClick) {
    console.error(
      "[LDS ERROR - Chip] Both `href` and `onClick` were provided to Chip. Only one is allowed at a time."
    );
  }

  const disabled = variant === "disabled";
  classNames.push(className);

  const Component = (
    <div
      className={classNames.join(" ")}
      id={id}
      onClick={disabled ? undefined : onClick}
      style={{ ...style, width }}

      data-testid={!href && "lds-chip"}
    >
      {leadingIcon && (
        <ChipAction
          className="lds-chip-action-container-leading-icon"
          icon={leadingIcon}
          size={size}
          variant={variant}
          dataTestId="lds-chip-leadingIcon"
        />
      )}

      <span>{label}</span>

      {hasTrailingIcon && (
        <ChipAction
          className={trailingIconClassName}
          icon={trailingIcon}
          onClick={trailingIconOnClick}
          size={size}
          variant={variant}
          dataTestId="lds-chip-trailingIcon"
        />
      )}
    </div>
  );

  if (href) {
    return (
      <a
        className="lds-chip-anchor"
        href={href}
        data-testid="lds-chip"
      >
        {Component}
      </a>
    );
  } else {
    return Component
  }

}

Chip.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  leadingIcon: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium"]),
  style: PropTypes.object,
  trailingIcon: PropTypes.string,
  trailingIconOnClick: PropTypes.func,
  selected: PropTypes.bool,
  variant: PropTypes.oneOf([
    "disabled",
    "none",
    "primary",
    "primaryCritical",
    "secondary",
    "secondaryCritical",
  ]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
