import createReactClass from "create-react-class";
import UserPagination from "./userPagination";
import UserListPage from "./userListPage";

const FlaggingEventUserList = createReactClass({
  getInitialState: function () {
    return { page: 0 };
  },
  changePage: function (page_number) {
    this.setState({ page: page_number });
  },
  render: function () {
    var lists = [];
    var remainder = this.props.userList;
    while (remainder.length > 0) {
      lists.push(_.first(remainder, 10));
      remainder = _.rest(remainder, 10);
    }
    var list = lists[this.state.page];
    return (
      <div>
        <UserPagination pages={lists.length} changePage={this.changePage} current_page={this.state.page} />
        <UserListPage userList={list} toggleUser={this.props.toggleUser} />
      </div>
    );
  }
});

export default FlaggingEventUserList;
