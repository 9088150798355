import AutocompleteSuggestions from "./autocompleteSuggestions";
import SearchInput from "../searchInput";

const GenericAutocomplete = createReactClass({
  getInitialState: function(){
    return {
      userInput: '',
      // List we use to check what suggestions are available
      // (requires an array of strings)
      masterList: this.props.suggestions,
      filteredSuggestions: this.props.suggestions.slice()
    }
  },
  // In the case that new suggestions become available, rerender the suggestions
  // (Needed when the masterlist of selectable objects is modified outside of this component)
  componentDidUpdate(prevProps) {
    if (prevProps.suggestions.length !== this.props.suggestions.length) {
      let filteredSuggestions = this.getFilteredSuggestions(this.props.suggestions.slice(), this.state.userInput)
      this.setState({filteredSuggestions: filteredSuggestions, masterList: this.props.suggestions});
    }
  },
  searchFunction: function(searchString) {
    let filteredSuggestions = this.getFilteredSuggestions(this.props.suggestions.slice(), searchString)
    this.setState({userInput: searchString, filteredSuggestions: filteredSuggestions});
  },
  onClick: function(e) {
    this.props.onClick(e.currentTarget.innerText, e)
  },
  getFilteredSuggestions: function(suggestionsAvailable, searchString) {
    return suggestionsAvailable.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(searchString.toLowerCase()) > -1
    );
  },
  render: function(){
    let classNames = this.props.classNames;
    let placeHolder = this.props.placeHolder;
    let suggestions = this.state.userInput ? <AutocompleteSuggestions filteredSuggestions={this.state.filteredSuggestions} classNames={classNames} userInput={this.state.userInput} onClick={this.onClick} placeHolder={placeHolder} /> : null;
    return (
      <div>
        <div className={classNames.join(" ")}>
          <SearchInput id="autocomplete_search_bar" classNames={classNames} searchFunction={this.searchFunction} placeHolder={placeHolder} width={this.props.width} size={this.props.size} />
        </div>
        {suggestions}
      </div>
    );
  }
});

window.GenericAutocomplete = GenericAutocomplete;
export default GenericAutocomplete;
