import FacetListHeader from "./facetListHeader";

/**
 * Allows the user to filter events based on lists.
 * Will trigger a new facet search on change.
 *
 * @component
 *
 * */
const UserListSelector = createReactClass({
  mixins: [
    Reflux.connectFilter(FacetSearchStore, function (data) {
      let lists;

      if (Zymewire.user.feature_tags.includes("list_of_other_entities")) {
        lists = data.userLists;
      } else {
        lists = data.orgLists;
      }
      return lists;
    }),
  ],
  getInitialState() {
    // this.loadData();
    let data = {};

    if (Zymewire.user.feature_tags.includes("list_of_other_entities")) {
      data = FacetSearchUserListSlice;
    } else {
      data = FacetSearchCompanyListSlice;
    }

    return data;
  },
  // loadData() {
  UNSAFE_componentWillMount: function() {
    $.getJSON("/facet_search/user_company_list_details", function (data) {
      FacetSearchListActions.setListOfUsersLists(data);
    });
  },
  handleListClick: function (e, list) {
    e.preventDefault();
    e.stopPropagation();

    FacetSearchListActions.listSelected(list, { doSearch: true });
  },
  clearListSelection: function (e) {
    e.preventDefault();
    e.stopPropagation();

    FacetSearchListActions.unselectList({ doSearch: true });
  },
  quiet_react: function (){
    const quiet = 'react';
  },
  render: function () {
    const { lists, selected } = this.state;
    const selectedListId = selected && (selected.orgListId || selected.userListId)

    if (!Zymewire.user.feature_tags.includes("lists")) {
      return null;
    }

    return (
      <div id="list_panel">
        <div id="list_panel" className="facet_panel facet_panel_open">
          <FacetListHeader
            id="list_props"
            title="Filter events based on lists company is on"
            facetName={"Your Lists" + " "}
          />

          <div className="facet_body">
            <form id="list_form">
              <input
                type="hidden"
                name="authenticity_token"
                value={Zymewire.formAuthenticityToken}
              />
              <label>Listed on...</label>

              <div id="list_holder">
                {lists.map((list, index) => (
                  <label
                    key={list._id}
                    id={"list_holder_entry_" + index}
                    className="radio"
                    onClick={(event) => this.handleListClick(event, list)}
                  >
                    <input
                      type="radio"
                      checked={selectedListId === list._id || ""}
                      onChange={() => {
                        this.quiet_react()
                      }}
                      className="list_radios"
                      name="list_radios"
                      data-list-id={list._id}
                      data-list-name={list.name}
                      data-list-type={list.listType}
                      data-org-count={list.orgCount}
                      id={"list_radio_" + list._id}
                      value={list._id}
                    />
                    {list.name}
                  </label>
                ))}
              </div>
              {selectedListId && (
                <label>
                  <a href="#" onClick={this.clearListSelection}>
                    Clear list selection
                  </a>
                </label>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  },
});

window.UserListSelector = UserListSelector;
export default UserListSelector;
