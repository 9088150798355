import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { CalendarContext } from "./CalendarContext";
import { useDatePickerContext } from "@rehookify/datepicker";

import QuickActionMonthSelector from "./QuickActionMonthSelector";
import QuickActionYearSelector from "./QuickActionYearSelector";

export default function QuickActionContainer({
 className,
}) {
  const { activeOverlay } = useContext(CalendarContext);
  const {
    data: { calendars },
  } = useDatePickerContext();

  const currentYear = parseInt(calendars[0].year);

  const showMonthSelector = activeOverlay === "month";

  let OverlayComponent;

  if (activeOverlay) {
    OverlayComponent = showMonthSelector
      ? QuickActionMonthSelector
      : QuickActionYearSelector;
  } else {
    OverlayComponent = React.Fragment;
  }

  // Scroll into view selected year, if year selector is shown
  useEffect(() => {
    if (activeOverlay === "year") {
      // The amount to nudge the scroll body so user can
      // see chips above/below
      const offset = 10;

      const targetChip = document.getElementById(
        "lds-datepicker-quick-action-year-" + currentYear
      );

      // When user tries to open to an invalid year
      if (!targetChip) {
        return;
      }

      const scrollContainer = targetChip.closest(
        ".lds-datepicker-quick-action-container"
      );

      // Partially obscure some chips above, so user knows that they can scroll
      // On MacOS the scrollbar is hidden, so this UX cue is important for
      // for those users. Thanks Apple.
      let distanceToScroll = targetChip.offsetTop;
      distanceToScroll -= scrollContainer.clientHeight / 2;
      distanceToScroll -= targetChip.clientHeight / 2;
      distanceToScroll += offset;

      scrollContainer.scrollTop = distanceToScroll;
    }
  }, [activeOverlay]);

  if (!activeOverlay) {
    return null;
  }

  const classNames = ["lds-datepicker-quick-action-container"];

  if(className) {
    classNames.push(className);
  }

  return (
    <div
      data-testid={`lds-datepicker-quick-action-container${activeOverlay ? `-${activeOverlay}` : ''}`}
      className={classNames.join(" ")}
    >
      <OverlayComponent />
    </div>
  );
};

QuickActionContainer.propTypes = {
  className: PropTypes.string,
};
