import BootstrapFullWidthRow from "../../generic/htmlElements/bootstrapFullWidthRow";
import NoInfoNotice from "./noInfoNotice";
import ZoominfoPhoneAvailable from "./zoomInfoPhoneAvailable";
import ZoominfoEmailAvailable from "./zoominfoEmailAvailable";
import ZoominfoFurtherPersonDetails from "./zoominfoFurtherPersonDetails";
import ZoominfoLastReviewed from "./zoominfoLastReviewed";
import ZoominfoPersonCompany from "./zoominfoPersonCompany";
import ZoominfoPersonName from "./zoominfoPersonName";
import ZoominfoPersonRecordOpenCaret from "./zoominfoPersonRecordOpenCaret";

const ZoominfoPersonResult = createReactClass({
  getInitialState: function () {
    return ({open: false, current_contact: this.props.current_contact});
  },
  toggleOpenState: function (e) {
    if(!(_.contains(e.target.classList, 'dont_stop'))) {
      e.stopPropagation();
      e.preventDefault();
      var new_state = !this.state.open;
      this.setState({open: new_state});
    }
  },
  handleAddToSF: function(e){
    e.preventDefault();
    e.stopPropagation();
    ContactSearchAction.addContactToSalesforce(this.props.current_contact.entity_id, null);
  },

  updateCurrentContact: function(contact) {
    this.setState({current_contact: contact})
  },

  render: function () {
    var showEmail = ((this.props.company_domain && !this.props.hide_email) || this.props.email);
    var showPhone = this.props.phone.mobile;
    var has_details = showEmail || showPhone;

    var further_details;
    var zoominfo_contact_id = this.props.entity_id;

    if (this.state.open) {
      further_details = <ZoominfoFurtherPersonDetails  {...this.props} key={zoominfo_contact_id + '_details'} updateCurrentContact={this.updateCurrentContact} showEmail={showEmail} showPhone={showPhone} company_name={this.props.company_name} />
    }

    var current_employment = this.props.company;
    var handleAddToSF;
    var current_contact = this.props.current_contact;

    var contactInfo = [
      <ZoominfoEmailAvailable key={zoominfo_contact_id + '_email'} {...this.props} showEmail={showEmail}/>,
      <ZoominfoPhoneAvailable key={zoominfo_contact_id + '_phone'} {...this.props} showPhone={showPhone}/>,
      <ZoominfoLastReviewed key={zoominfo_contact_id + '_reviewed'} LastUpdatedDate={this.props.last_updated} />,
      <ZoominfoPersonRecordOpenCaret key={zoominfo_contact_id + '_caret'} open={this.state.open} handleClick={this.toggleOpenState}/>
    ];


    if (!has_details) {
      contactInfo = <NoInfoNotice key={zoominfo_contact_id + '_notice'}/>;
    }

    if(current_contact && this.props.can_use_salesforce){
      handleAddToSF = this.handleAddToSF;
    }

    return (
      <BootstrapFullWidthRow fluid>
        <div className="zi_person_card">
          <table className="zi_person_table" width="100%">
            <tbody>
            <tr className="topRow" onClick={this.toggleOpenState}>
              <ZoominfoPersonName
                key={zoominfo_contact_id + '_name'}
                zi_contact_id={ zoominfo_contact_id }
                org_id={ this.props.org_id }
                FirstName={this.props.first_name}
                LastName={this.props.last_name}
                JobTitle={this.props.designation}
                handleAddToSF={handleAddToSF}
                LocalAddress={this.props.address}
                localAddress={this.props.address}
                feature_flags={this.props.feature_flags}
                deliverability={current_contact ? current_contact.deliverability : null}
                sales_force_contacts={current_contact ? current_contact.sales_force_contacts : null}
                sales_force_leads={current_contact ? current_contact.sales_force_leads : null}
              />
              <ZoominfoPersonCompany CompanyName={current_employment.name} key={zoominfo_contact_id + '_company'} />
              {contactInfo}
            </tr>
            {further_details}
            </tbody>
          </table>
        </div>
      </BootstrapFullWidthRow>
    )
  }
});

export default ZoominfoPersonResult;
