import PropTypes from "prop-types";

import Button from "@javascript/reactComponents/lds/Button";
import ButtonGroup from "@javascript/reactComponents/lds/ButtonGroup";

import GenericErrorModal from "@javascript/reactComponents/generic/modals/GenericErrorModal";
import Heading from "@javascript/reactComponents/lds/typography/Heading";
import Icon from "@javascript/reactComponents/lds/Icon";

import LegacyModalService from "@javascript/globals/LegacyModalService";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@javascript/reactComponents/lds/modal/Modal";

const CopyToNewListSuccess = ({
  errorData = null,
  fromListName = "",
  listEntries = 0,
  toListId = "",
  toListName = "",
}) => {
  if(errorData) {
    return (
      <GenericErrorModal
        modalHeader={errorData.title}
        />
    );
  }
  else {
    const goToList = () => {
      location.assign(`/lists/${toListId}`);
    };
    const iconStyle = {
      alignSelf: "center",
    };
    return(
      <Modal
        distanceFromTopViewport="75px"
        onClose={LegacyModalService.closeModal}
        open={true}
      >
        <ModalHeader>
          Copy to a new list
        </ModalHeader>
        <ModalBody
          className="flex-col align-center justify-center"
        >
          <Icon
            name="special-success"
            style={iconStyle}
          />
          <Heading
            containerClassName="generic-modal-header"
            size="h2"
          >
            List generated successfully!
          </Heading>
          <p>Successfully copied the {listEntries} companies on the list "{fromListName}" to "{toListName}".</p>
        </ModalBody>
        <ModalFooter align="flex-end">
          <ButtonGroup size="medium">
            <Button
              size="medium"
              variant="secondary"
              onClick={LegacyModalService.closeModal}
            >
              Close
            </Button>
            <Button id="see_new_list" size="medium" variant="primary" onClick={goToList}>
              Go to List
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  }
}

CopyToNewListSuccess.propTypes = {
  errorData: PropTypes.oneOf([
    null,
    PropTypes.shape({
        title: PropTypes.string,
        message: PropTypes.string,
      }
    )
  ]),
  fromListName: PropTypes.string,
  listEntries: PropTypes.number,
  toListId: PropTypes.string,
  toListName: PropTypes.string,
};

window.CopyToNewListSuccess = CopyToNewListSuccess;
export default CopyToNewListSuccess;
