import PropTypes from "prop-types";

import ComponentFromHTML from "@javascript/reactComponents/shared/ComponentFromHTML";
import LegacyModalService from "@javascript/globals/LegacyModalService";

import {
  Modal,
  ModalHeader,
} from "@javascript/reactComponents/lds/modal/Modal";

export default function GenericLegacyModal({
  distanceFromTopViewport = null,
  legacyModalContent,
  size = "medium",
  title,
}) {

  return (
    <Modal
      distanceFromTopViewport={distanceFromTopViewport}
      onClose={LegacyModalService.closeModal}
      open={true}
      size={size}
    >
      {/*
        Title markup is somewhat more complex than body or footer
        so it's better if we don't have to write it every time
      */}
      {title && <ModalHeader title={title} />}

      <ComponentFromHTML html={legacyModalContent} />
    </Modal>
  );
}

GenericLegacyModal.propTypes = {
  distanceFromTopViewport: PropTypes.string,
  legacyModalContent: PropTypes.any,
  size: PropTypes.oneOf(["medium", "large"]),
  title: PropTypes.string,
};

window.GenericLegacyModal = GenericLegacyModal;
