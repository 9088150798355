const ZoominfoPhoneAvailable = function(props) {
  var phone = null;

  if (!props.current_contact && props.phone.mobile) {
    phone = "Phone"
  }

  if(props.current_contact && props.current_contact.phone.mobile){
    var ph_no = props.current_contact.phone.mobile;
    var tel_to = "tel:" + ph_no;
    phone = <a href={tel_to} target="_blank" className="dont_stop"><i className="fa fa-phone"/> Revealed</a>
  }

  return (
    <td className="action_link cs_phone_available" width="80px">
      {phone}
    </td>
  )
}

export default ZoominfoPhoneAvailable;
