import ModifyListButton from "./modifyListButton";
import PivotlistFilterControls from "./filterControls/pivotListFilterControls";
import ListTable from "./table/listTable";

const ListDisplay = createReactClass({
  getInitialState: function(){
    return {
      controls_open:this.props.list_view === 'first' ? 'checked' : ''
    }
  },
  restore_company: function(company_id) {
    RowRemovalActions.refreshRestore(company_id)
  },
  remove_company: function(company_id, list_entry) {
    RowRemovalActions.refreshRemove(company_id, list_entry)
  },
  processLastActivityDate: function(listEntries, lastActivityIndex) {
    let returnData = [];
    _.each(listEntries, function(entry){
      let dateValue = "---";
      if(entry[lastActivityIndex] !== null) {
        dateValue = "<a href='" + window.location.origin + "/facet_search?query=" + encodeURIComponent(entry[0].name) + "'>" + moment.parseZone(entry[lastActivityIndex]).format("MMM DD YYYY") + "</a>";
      }
      entry[lastActivityIndex] = dateValue;
      returnData.push(entry);
    }, this);
    return returnData;
  },
  UNSAFE_componentWillMount:function(){
    const columnsChosen = this.props.list_object.columns_chosen;
    const lastActivityIndex = columnsChosen.findIndex(entry => entry.column_name === "last_activity");
    if(lastActivityIndex !== -1) {
      this.props.list_object.columns_chosen[lastActivityIndex].data_type = "text";
      this.props.list_object.list_entries = this.processLastActivityDate(this.props.list_object.list_entries, lastActivityIndex);
    }

    const offset = 72; // $('.fixed_header_element').height() + $('.modify_list_header').height() + 40;
    let listTableContainerHeight = $(window).height();

    $("body").addClass("list_body_overflow_y_hidden");

    const getElementHeight = (domId) => {
      let domElement = document.querySelector(domId);
      let domStyle = window.getComputedStyle(domElement);
      let domMargin = parseFloat(domStyle['marginTop']) + parseFloat(domStyle['marginBottom']);
      return Math.ceil(domElement.offsetHeight + domMargin);
    };

    // temporary solution to the edge case of users with small screen and/or resolutions were unable to see the "clear all" and "done" buttons.
    // see bug description: https://www.pivotaltracker.com/story/show/179269095
    //
    // a more robust solution, one that takes into account more than 8 column choices, has been shelved for this simpler, less intensive approach.
    //
    // Further consideration will be needed to ensure that this story is fully delivered: https://www.pivotaltracker.com/story/show/177800985
    // specifically:
    //
    // "Care should be taken to ensure that the "clear all" and "done" buttons are always visible no matter the number of columns, nor the size of the user's viewport."
    //
    // for now, our solution is to enforce a body scroll when list container height is less than the minimum required to show 8 column choices.
    //
    // if a user's viewport defines a list container height smaller than 440px (the bare minimum to see the aforementioned buttons):
    //
    //  - we will add a CSS class to the body that forces a scrollbar on the y-axis of the body so that the user can see:
    //    - the "clear all" and "done" buttons for the modify list view
    //    - the pagination, if any, at the bottom of the page
    //  - we will enforce a minimum list container height so that the buttons are always in view
    //
    // -- GJ 2021.09.23

    const minListTableContainerHeight = 440;

    const miscBuffer = 50; // Accounting for any margins/padding
    const paginationHeight = this.props.list_length >= 30 ? 72 : 0; // QuerySelector was not able to select will_paginate even with id at the time of rendering
    const bodyPadding = 60; // Padding on the body

    const listTitleSectionHeight = getElementHeight("#list_title_and_bread_crumbs");
    const listCreationDetailsHeight = getElementHeight("#list_creation_details");
    const pivotListButtonsHeight = getElementHeight("#pivot_list_buttons");

    const reservedSpace = bodyPadding + listTitleSectionHeight + listCreationDetailsHeight + pivotListButtonsHeight + paginationHeight + miscBuffer;
    listTableContainerHeight = window.innerHeight - reservedSpace;

    if(listTableContainerHeight < minListTableContainerHeight) {
      $("body").addClass("list_body_overflow_y_scroll");
      listTableContainerHeight = minListTableContainerHeight;
    }

    const columnChoicesPerPage = parseInt((listTableContainerHeight-offset)/36)-3;

    const totalColumnChoicePages = Math.ceil(parseFloat(this.props.list_object.columns_available.length/columnChoicesPerPage))

    ColumnsChosenActions.initialState(this.props.list_object.columns_chosen)
    pivotListColumnSettingsActions.initialState(this.props.interactableColumnsCount, columnChoicesPerPage, totalColumnChoicePages, listTableContainerHeight)
  },
  render: function () {
    const class_names = ['list_container' , 'w-1200'];

    if(!this.props.is_editable){
      class_names.push('fullstory_excluded');
    }
    this.props.list_object.list_entries = _.extend(new Array(30).fill([]), this.props.list_object.list_entries);

    return (
      <div className={class_names.join(' ')}>
        <PivotlistFilterControls filter_pivot_lists={this.props.filter_pivot_lists} filter_columns={this.props.list_object.columns_chosen} list_id={this.props.list_object.list_id} filtered_length={this.props.list_object.count} list_length={this.props.list_length}/>
        <ModifyListButton {...this.props}/>
        <ListTable {...this.props} controls_open={this.state.controls_open}/>
      </div>
    );
  }
});

window.ListDisplay = ListDisplay;
export default ListDisplay;
