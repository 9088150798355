import React from "react";
import Chip from "../../lds/chips/Chip";

export default function NewEventChip({
  label,
  leadingIcon,
  size = "medium",
  href,
}) {
  return (
    <Chip
      className="lds-chip-new-event"
      label={label}
      leadingIcon={leadingIcon}
      size={size}
      href={href}
      variant="none"
    />
  );
}

NewEventChip.propTypes = {
  label: Chip.propTypes.label,
  size: Chip.propTypes.size,
  leadingIcon: Chip.propTypes.leadingIcon,
  href: Chip.propTypes.href,
};
