import ScrollableList from "../ScrollableList";
import ScrollableListItem from "../ScrollableListItem";

const SearchCriteriaPanel = (props) => {
  const styles = {
    display: "flex",
    flexDirection: "column",
    fontSize: "16px",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "16px",
  };
  const details = queryDetails(props);
  return (
    <ScrollableList style={styles}>
      {Object.keys(details).map((filterName) => {
        const value = details[filterName];
        return value.length > 0 ? (
          <ScrollableListItem
            key={filterName}
            filterName={filterName}
            filterValue={value}
          />
        ) : (
          false
        );
      })}
    </ScrollableList>
  );
};

export default SearchCriteriaPanel;

function queryDetails(props) {
  let allDetails = Object.assign(
    {},
    timeRange(props),
    searchWord(props),
    activityType(props),
    listedOn(props),
    orgListRefs(props),
    aliasSearchRefs(props),
    relationships(props),
    clinicalStatus(props),
    clinicalStage(props),
    geoCoords(props),
    locations(props)
  );
  const facetDetails = facets(props);
  if (facetDetails) {
    allDetails = Object.assign(allDetails, ...facetDetails);
  }

  return allDetails;
}

function timeRange(props) {
  const time_range = props.search.time;

  if (!time_range) return false;

  return { ["Time Range"]: [time_range] };
}

function searchWord(props) {
  const query = props.search.query;

  if (!query) return false;

  return { ["Search words"]: [query] };
}

function activityType(props) {
  const category = props.search.category;

  if (!category) return false;

  return { ["Activity Type"]: [category] };
}

function listedOn(props) {
  const list = props.search.list;

  if (!list) return false;

  return { ["Listed on"]: [list] };
}

function facets(props) {
  const facets = props.baseQuery.facets;

  if (!facets) return false;

  return Object.keys(facets).map((facet) => {
    const capitalized = facet.charAt(0).toUpperCase() + facet.slice(1);
    return { [capitalized]: facets[facet] };
  });
}

function orgListRefs(props) {
  const org_list_refs = props.search.org_list_refs;

  if (!org_list_refs) return false;

  return {
    ["Org List Refs"]: Array.isArray(org_list_refs)
      ? org_list_refs
      : [org_list_refs],
  };
}

function aliasSearchRefs(props) {
  const alias_search_hash = props.search.alias_search_hash;

  if (!alias_search_hash) return false;

  return { ["Alias Search"]: Object.values(alias_search_hash) };
}

function relationships(props) {
  const relationships = props.search.relationships;

  if (!relationships || relationships.length === 0) return false;

  let no_recent_activity = "";
  let rel = "";

  if (_.contains(relationships, "exclude_recent_sponsor")) {
    var and = relationships.length > 1 ? ", AND " : "";
    no_recent_activity = `Companies with no team activity in the last 6 months in Salesforce${and}`;
  }

  if (_.contains(relationships, "crm")) {
    rel = "Companies you have in your Salesforce";
  }

  if (_.contains(relationships, "exclude_muted_companies")) {
    rel = "Only your unmuted companies";
  }

  return { ["Relationships"]: [no_recent_activity + rel] };
}

function clinicalStatus(props) {
  const clin_status = props.search.clin_status;

  if (!clin_status) return false;

  return { ["Clinical Status"]: [clin_status.join(" OR ")] };
}

function clinicalStage(props) {
  const clin_stage = props.search.clin_stage;

  if (!clin_stage) return false;

  return { ["Clinical Stage"]: [clin_stage.join(" OR ")] };
}

function geoCoords(props) {
  const geo_box = props.search.geo_box;

  if (!geo_box) return false;

  return {
    ["Geographic Boundary"]: [
      `North boundary: ${geo_box.top_left_lat}`,
      `East boundary: ${geo_box.bottom_right_lon}`,
      `South boundary: ${geo_box.bottom_right_lat}`,
      `West boundary: ${geo_box.top_left_lon}`,
    ],
  };
}

function locations(props) {
  const locations = props.search.locations;

  if (!locations) return false;

  return { ["Sponsor companies based in"]: [locations.join(" OR ")] };
}
