const ColumnChoicePagination = function(props) {
  const get_a_range = (from, to, step = 1) => {
    let i = from;
    const range = [];
    while (i <= to) {
      range.push(i);
      i += step;
    }
    return range;
  }

  const pagination_array = () => {
    const totalNumbers = 5;
    const totalBlocks = 10;
    if (props.totalColumnChoicePages > totalBlocks) {
      let pages = [];
      const left_bound = props.current_page - 1;
      const right_bound = props.current_page + 1;
      const before_last = props.totalColumnChoicePages - 1;
      const start_page = left_bound > 2 ? left_bound : 2;
      const last_page = right_bound < before_last ? right_bound : before_last;
      pages = get_a_range(start_page, last_page);
      const pages_length = pages.length;
      const single_spill_offset = totalNumbers - pages_length - 1;
      const left_spill = start_page > 2;
      const right_spill = last_page < before_last;
      if (left_spill && !right_spill) {
        const extraPages = get_a_range(start_page - single_spill_offset, start_page - 1);
        pages = ['LEFT', ...extraPages, ...pages];
      } else if (!left_spill && right_spill) {
        const extraPages = get_a_range(last_page + 1, last_page + single_spill_offset);
        pages = [...pages, ...extraPages, 'RIGHT'];
      } else if (left_spill && right_spill) {
        pages = ['LEFT', ...pages, 'RIGHT'];
      }
      return [1, ...pages, props.totalColumnChoicePages];
    }
    return get_a_range(1, props.totalColumnChoicePages);
  }

  const pages = pagination_array();

  return (
    <div>
      <ul className="in_page_pagination">
        {pages.map((page, index) => {
          const current_page_class = (props.current_page === page) ? " page_active" : "";
          if (page === 'LEFT'){
            return (
              <li key={index} className="page_item">
                <a className="page_link" onClick={props.jump_left}>&laquo;</a>
              </li>
            );
          }
          if (page === 'RIGHT'){
            return (
              <li key={index} className="page_item">
                <a className="page_link" onClick={props.jump_right}>&raquo;</a>
              </li>
            );
          }
          return (
            <li key={index} className={"page_item" + current_page_class}>
              <a className="page_link" onClick={() => props.jump_to(page)}>{page}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default ColumnChoicePagination;
