import React from "react";

import SfActivities from "./sfActivities";
import SfOpportunities from "./sfOpportunities";
import SfContacts from "./sfContacts";

const SfTabContent = createReactClass({
  mixins: [Reflux.listenTo(SFTabActionsStore,"change_selected")],
  getInitialState: function(){
    return {
      tab_selected:this.props.tab_selected,
      height:0
    };
  },
  change_selected: function(account, tabSelected) {
    if(account === this.props.account_id){
      this.setState({
        tab_selected: tabSelected
      });
    }
  },
  render: function(){
    const div_classes = ["tabbed_content_div"];
    if(this.state.tab_selected === this.props.tab_content.type){
      div_classes.push('tabbed_current_content');
    }
    const components = {
      tasks:SfActivities,
      team:SfActivities,
      all:SfActivities,
      opportunities:SfOpportunities,
      contacts:SfContacts
    };
    const component = components[this.props.tab_content.type];
    const content = React.createElement(component, {account_id:this.props.account_id, type:this.props.type, tab_content:this.props.tab_content});
    return (
      <div className={div_classes.join(' ')}>
        {content}
      </div>
    )
  }
});

export default SfTabContent;
