import RemoveClinicalStage from "./removeClinicalStage";

const EditEventProperties = (props) => {
  if (props.event.independent_properties && props.event.independent_properties.clin_stage) {
    const editEventProperties = props.event.independent_properties.clin_stage.map(function (stage, index) {
      return <RemoveClinicalStage key={index} stage={stage} />;
    })
    return (
      <p>
        Event properties: <span>{editEventProperties}</span>
      </p>
    );
  }
  else {
    return false;
  }
};

export default EditEventProperties;
