import FacetListHeader from "./facetListHeader";
import GenericCheckboxList from "../generic/genericCheckboxList";

/**
 * React component that displays a list of facet search terms. Those terms are related to: "Clinical Trial Status", and "Clinical Trial Stages"
 *
 * @param {Array} checkboxList a list of checkboxes to display
 * @param {String} checkboxTitle the title to use for each checkbox, with %%TERM%% as a placeholder to be regex'd out with the checkbox label
 * @param {Array} checkedBoxes a list of checked checkboxes
 * @param {Array} facetClasses the CSS class(es) names specific to the section
 * @param {String} facetName the name of the section
 * @param {String} facetTitle the title of the section
 * @param {String} facetType the type of checkboxes (currently only trial_status and trial_stages)
 * @param {Method} onToggle the store update Method passed down from the parent component
 */
const FacetCheckboxContainer = ({
  checkboxList,
  checkboxTitle,
  checkedBoxes,
  facetClasses,
  facetName,
  facetTitle,
  facetType,
  onToggle
}) => {

  const classNames = ["facet_panel"].concat(facetClasses);

  // for when we consolidate all facet search checkboxes to use generic elements
  // const checkboxClasses = [facetType + "_checkbox", "facet_search_checkbox", "fancy_checkbox"];
  const checkboxClasses = [facetType + "_checkbox", "facet_search_checkbox"];
  const checkboxLabelClasses = ["facet_search_checkbox"];


  const openPanel = "facet_panel_open";

  const panelId = facetType + "_panel";
  const headerId = facetType + "_facets";
  const formId = facetType + "_form";
  const contentId = facetType + "_content";

  const checkedBoxesKeys = Object.keys(checkedBoxes);

  if(checkedBoxesKeys.length > 0 || classNames.indexOf(openPanel) > -1) {
    Zymewire.facet_expand_list.push(headerId);
  }
  return (
    <div id={panelId} className={classNames.join(' ')}>
      <FacetListHeader
        id={headerId} title={facetTitle}
        facetName={facetName + ' '}
        show={false}
      />
      <div className="facet_body clearfix" id={contentId}>
        <form id={formId}>
          <input type='hidden' name='authenticity_token' value={Zymewire.formAuthenticityToken}/>
          <GenericCheckboxList
            checkboxClasses={checkboxClasses}
            checkboxLabelClasses={checkboxLabelClasses}
            checkboxList={checkboxList}
            checkboxTitle={checkboxTitle}
            checkedBoxes={checkedBoxes}
            toggleCheckbox={onToggle}
          />
        </form>
      </div>
    </div>
  )
};

export default FacetCheckboxContainer;
