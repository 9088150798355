const FilterColumn = createReactClass({
  mixins: [Reflux.listenTo(FilteringActionsStore, 'change_state')],
  getInitialState(){
    return {column_selected: FilteringActionsStore.state.column_selected, column_filter: FilteringActionsStore.state.column_filters[this.props.index]};
  },
  select_column(e) {
    e.stopPropagation();
    FilteringActions.changeSelectedColumn(this.props.index);
  },
  change_state(new_state) {
    this.setState(state => (
      {...state, column_selected:new_state.column_selected}
    ));
  },
  render(){

    const class_names = ['column_overflow_protection'];
    const holder_class_names = ['filter_column_div'];
    if(this.state.column_selected === this.props.index){
      holder_class_names.push('filter_column_selected');
    }

    const column_class_names = ['filter_column_entry'];
    if(this.props.column_state.type === false){
      class_names.push('no_choice_link');
      column_class_names.push('filter_column_disabled');
    }
    else{
      class_names.push('action_link');
    }
    if(this.state.column_filter.filter.length > 0){
      column_class_names.push('filter_column_filtered');
    }

    return (
      <div onClick={this.select_column} className={holder_class_names.join(' ')}>
        <div className={column_class_names.join(' ')}>
          <a className={class_names.join(' ')}>{this.props.column_state.name}</a>
        </div>
      </div>
    );
  }
});

// TODO refactor this and FilterOptions into parent as there is no state/interaction at this container level
const FilterColumns = function(props) {
  const content = _.map(props.column_filter_options, function(column_state, index){
    if(index !== 0){  // skip filtering on companies for now
      return (
        <FilterColumn key={index} column_state={column_state} index={index} />
      );
    }
  });
  return (
    <div>
      {content}
    </div>
  );
}

export default FilterColumns;
