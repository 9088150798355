import { useContext } from "react";
import PropTypes from "prop-types";

import CheckboxItem, { CHECKBOX_STATES } from "../lds/selection_controls/CheckboxItem";
import { FeedsIndexContext } from "../../stores/FeedsIndex";

const FeedsIndexSelectAll = ({
 allIds,
 group,
}) => {
  const { getCheckboxArray, toggleSelectAll } = useContext(FeedsIndexContext);

  const groupCheckboxArray = getCheckboxArray(group);
  const selectAllChecked = groupCheckboxArray.length === allIds.length ? CHECKBOX_STATES.selected : CHECKBOX_STATES.unselected;

  const onClickCheckbox = () => {
    toggleSelectAll(allIds, group);
  };

  return (
    <CheckboxItem
      className={`delete_checkbox_for_${group}`}
      onClick={onClickCheckbox}
      selection={selectAllChecked}
      value={group}
    />
  );
};

FeedsIndexSelectAll.propTypes = {
  allIds: PropTypes.array.isRequired,
  group: PropTypes.string.isRequired,
};

export default FeedsIndexSelectAll;
