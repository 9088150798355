const EventRepublishButton = createReactClass({
  getInitialState: function() {
    return {count: this.props.count, dispatched: false};
  },
  UNSAFE_componentWillReceiveProps: function(nextProps) {
    if(this.state.count !== nextProps.count){
      this.setState({count: nextProps.count});
    }
  },
  updateCount: function(){
    $.getJSON("/administrate_event/event_awaiting_republishing_count").done(function(data){
      this.setState({count: data.count, dispatched: false});
    }.bind(this));
  },
  componentDidMount: function() {
    setInterval(this.updateCount, 30000);
  },
  initaiateRepublishing: function(e){
    e.preventDefault();
    this.setState({dispatched: true});
    EventTableAction.initiateRepublishing();
  },
  render: function(){
    let republishText = "Republish flagged events to search";
    if(this.state.dispatched){
      republishText = "Republish request sent";
    }
    return (
      <p><a id="rspec_repub_btn_id" className="btn btn-danger" onClick={this.initaiateRepublishing}>{republishText} ({this.state.count} awaiting republishing)</a></p>
    );
  }
});

export default EventRepublishButton;
