/**
 * A schema to describe the latitude and longitude aspects of a map filter applied to any given query.
 * the keys for this slice are snake case due to timebox for this refactor. Sorry.
 * */
const FacetSearchGeoCoordinatesSlice = {
  bottom_right_lat: null,
  bottom_right_lon: null,
  top_left_lat: null,
  top_left_lon: null,
}

window.FacetSearchGeoCoordinatesSlice = FacetSearchGeoCoordinatesSlice
export { FacetSearchGeoCoordinatesSlice }
