const MutedCompaniesHeader = () => {
  return (
    <div className="row">
      <div className="span5">
        <p>Look up sponsor companies you would like to exclude from searches and add them to Your Muted Companies list.</p>
      </div>
      <div className="span2">
        &nbsp;
      </div>
      <div className="span5">
        <h3 >Your Muted Companies</h3>
        <p>To exclude Your Muted Companies from a search, use the "Hide My Muted Companies" filter.</p>
      </div>
    </div>
  );
};

window.MutedCompaniesHeader = MutedCompaniesHeader;
export default MutedCompaniesHeader;
