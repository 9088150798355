import Reflux from "reflux";

import RowHoverActions from "../actions/pivotLists/rowHoverActions";

const RowHoverStore = Reflux.createStore({
  listenables: RowHoverActions,
  init: function() {
    this.state = ''
  },
  onUpdateHover: function(hover_id){
    this.state = hover_id;
    this.trigger(this.state);
  }
});

window.RowHoverStore = RowHoverStore;
export default RowHoverStore;
