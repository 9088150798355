import React, { useState, createContext } from "react";
import PropTypes from "prop-types";

import TabGroup from "./TabGroup";

export const TabbedViewContext = createContext({
  registerComponent: () => {}
})

export default function TabbedView({ initialTab, children }) {
  const [selectedTab, setSelectedTab] = useState(initialTab);
  const [componentRegistry, setRegistry] = useState({})

  const onTabSelected = (value) => {
    setSelectedTab(value);
  }

  const registerComponent = (key, component) => {
    setRegistry(currentRegistry => {
      const newRegistry = { ...currentRegistry }
      newRegistry[key] = component

      return newRegistry
    })
  }

  const ComponentToRender = componentRegistry[selectedTab]

  return (
    <TabbedViewContext.Provider value={{ registerComponent }}>
      <div className="lds-tabbed-view">
        <TabGroup selectedTab={ selectedTab } onTabSelected={ onTabSelected } >
          { children }
        </TabGroup>

        <div className="lds-tabbed-view-body" role="tabpanel">
          { ComponentToRender && <ComponentToRender />}
        </div>
      </div>
    </TabbedViewContext.Provider>
  )
}

TabbedView.propTypes = {
  initialTab: PropTypes.string,
}
