import FacetListHeader from "./facetListHeader";
import {constructSelectableLocationList, flattenLocationList} from "../../helpers/locations";


/**
 * The individual location row for Africa, Asia, Europe, etc...
 * Upon clicking, the provided callback is triggered
 *
 * @component
 * @param {{ id: string, name: string, callback: Function }} props
 *
 * */
const LocationSelectorEntry = ({ id, name, callback }) => {
  return (
    <div className="filter_item_property" style={{ paddingLeft: 0 }} title={'You can filter your search by countries in ' + name}>
      <a id={id} onClick={callback}>
        <div className="filter_item_close fancy_link local_link" style={{ display: 'inline-block' }}>
          open list...
        </div>
        <div style={{ display: 'inline-block' }}>
          <p style={{ fontSize: '13px', color: '#000000' }}>{name}</p>
        </div>
      </a>
    </div>
  );
}

/**
 * Allows user to select event location, or My Territory. Will trigger new facet search on change.
 *
 * @component
 *
 * */
var LocationSelectorSection = createReactClass({
  mixins: [Reflux.connectFilter(FacetSearchStore, function (data) { return {location: data.location, facets: data.facets} } )],
  getInitialState: function () {
    return {
      location: {
        ...FacetSearchLocationSlice
      },
      facets: {
        ...FacetSearchResultsSlice
      }
    }
  },
  handleEditTerritory: function() {
    locationRedirect('/territories/edit');
  },
  quiet_react: function (){
    // The whole “having to quiet react” doesn’t really sit well with me. Open to other ideas here.
    const quiet = 'react';
  },
  render: function () {

    const continentsWithLinkId = [];
    const sortedContinentList = Zymewire.region_list.fullLocationList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    _.each(sortedContinentList, function(continent){
      continentsWithLinkId.push({name: continent.name, id: continent.id});
    });
    const myTerritorySelected = _.includes(this.state.location.selectedLocationCodes, 'my_territory');

    return (
      <div ref="loc_code_panel" id="loc_code_panel" className="facet_panel facet_panel_open">
        <FacetListHeader
          id="loc_code_props"
          title={'Filter events based on where the ' + I18n.t('terminology.target_organization_title') + ' is located.'}
          facetName={ I18n.t("terminology.company_location") + ' ' }
        />

        <div className="facet_body">
          { continentsWithLinkId.map(code => <LocationSelectorEntry key={code.id} id={code.id} name={code.name} callback={() => FacetSearchLocationActions.openLocationSelectorModal(code.name)} />) }
          <form id="my_territory_prop">
            <label id="label_my_territory" className="checkbox country_checkbox">
              <input id="loc_my_territory" className="loc_checkbox" type="checkbox" checked={myTerritorySelected || ''} onClick={FacetSearchLocationActions.myTerritoryClick}
                     onChange={() => {
                       this.quiet_react()
                     }} />My Territory
              (<a id="edit_territory" className="override_page_link fancy_link" onClick={this.handleEditTerritory}>edit</a>)
            </label>
          </form>
        </div>
      </div>
    );
  }
})

const LocationSelectorPanel = createReactClass({
  mixins: [Reflux.connectFilter(FacetSearchStore, function (data) { return {location: data.location} } )],
  getInitialState: function () {
    return {
      location: {
        ...FacetSearchLocationSlice
      }
    }
  },
  UNSAFE_componentWillMount:function(){
    FacetSearchLocationActions.locationWillMount({
      searchLocations:this.props.searchLocations,
      selectedLocation:this.props.selectedLocation
    });
  },

  // function that allows autocomplete to select a location by name
  selectLocation: function (location,e){
    e.stopPropagation();
    var currentSelectableLocations = this.getCurrentSelectableLocations();
    for(var loc of currentSelectableLocations){
      // Hacky solution to fix duplicate name issue
      // We only have 2 occurrences of duplicate names (Georgia and Puerto Rico) in our selectable list as of June 2020
      // Better solution would be to give each location a unique name in fullLocationList
      if(loc.name === location || loc.code === "US_GA" && location === "Georgia, United States of America" || loc.code === "US_PR" && location === "Puerto Rico, United States of America"){
        FacetSearchLocationActions.selectLocation(loc);
        return
      }
    }
  },
  getCurrentSelectableLocations: function(){
    // We pass a deep copy of fullLocations, so that any modifications we make to our copy won't effect the original
    const copyFullLocation = JSON.parse(JSON.stringify(this.state.location.fullLocationList))
    return flattenLocationList(constructSelectableLocationList(copyFullLocation, this.state.location.searchLocations));
  },
  render: function () {
    var locationNames = this.getCurrentSelectableLocations();
    // Get only the names of locations from our array of tuples
    locationNames = locationNames.map(function(location){
      // Hacky solution to fix duplicate name issue
      // We only have 2 occurrences of duplicate names (Georgia and Puerto Rico) in our selectable list as of June 2020
      // Better solution would be to give each location a unique name in fullLocationList
      if(location.code === "US_GA") {
        return "Georgia, United States of America"
      } else if (location.code === "US_PR") {
        return "Puerto Rico, United States of America"
      } else {
        return location.name
      }
    })

    return (
      <div className="container locations_search">
        <LocationSelectHeader />
        <GenericAutocomplete onClick={this.selectLocation} suggestions={locationNames} classNames={["autocomplete_base", "autocomplete_location"]} placeHolder={"country or state"} width="308px" size="small" />
        <LocationSelectingPanels location={this.state.location} />
      </div>
    );
  }
});

const LocationSelectHeader = () => {
  return (
    <div className="row">
      <div className="span5 modal_location_selector">
        <h3>Available location filters</h3>
        <p>Look up countries or states then click to add them to your search</p>
      </div>
      <div className="span2 modal_location_clearer">
        &nbsp;
      </div>
      <div className="span5 modal_location_selector">
        <h3 >Location filters to search</h3>
        <p>Click to remove countries or states</p>
      </div>
    </div>
  );
};

const LocationSelectingPanels = createReactClass({
  render: function () {
    return (
      <div className="row">
        <div className="span5 modal_location_selector">
          <LocationSelector location={this.props.location} />
        </div>
        <div className="span2 modal_location_clearer">
          <ClearLocationsButton location={this.props.location} />
        </div>
        <div className="span5 modal_location_selector">
          <SelectedLocations location={this.props.location} />
          <ModifySearchButton location={this.props.location} />
        </div>
      </div>
    );
  }
});

const ClearLocationsButton = createReactClass({
  clearLocations: function(e){
    e.stopPropagation();
    FacetSearchLocationActions.clearLocations();
  },
  render: function(){
    if(this.props.location.searchLocations.length > 0){
      return <button type="submit" className="button-small button-secondary" onClick={this.clearLocations}>Clear</button>
    }
    else{
      return <button type="submit" className="button-small button-secondary button-disable">Clear</button>
    }
  }
});

const ModifySearchButton = createReactClass({
  saveChanges: function(e){
    e.stopPropagation();
    FacetSearchLocationActions.saveChanges();
  },
  cancelChanges: function(e){
    e.stopPropagation();
    close_modal_control()
    setTimeout(function() {
      ReactDOM.unmountComponentAtNode(document.getElementById('modal_content'));
    }, 400);
  },
  render: function(){
    const button = this.props.location.changed ? <button type="submit" className="button-small button-primary button_spaced_10_top" onClick={this.saveChanges}>Save Changes</button> : <button type="submit" className="button-small button-primary button-disable button_spaced_10_top">Save Changes</button>;
    return (
      <div className="right">
        {button}
        <a className="close_x"  onClick={this.cancelChanges}></a>
      </div>
    );
  }
});

const LocationSelector = createReactClass({
  render: function () {
    var indent = 0;
    var locations = _.reject(this.props.location.fullLocationList, function(location){return locationHelpers.findLocationInList(location.name, this.props.location.searchLocations) }.bind(this)).map(function(location, index){
      return <LocationHolder key={index} parentOpen='open' indent={indent} location={location} searchLocations={this.props.location.searchLocations} selectedLocation={this.props.location.selectedLocation} expandSubs={[]}/>
    }.bind(this));
    return (
      <div className="location_selector_box locations_available">
        {locations}
      </div>
    );
  }
});

const LocationHolder = createReactClass({
  getInitialState: function(){
    return {
      folded_unfolded:(this.props.selectedLocation === this.props.location.name) ? 'checked' : ''
    }
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.parentOpen === 'closed'){
      this.setState({
        folded_unfolded: ''
      });
    }
  },
  componentDidMount() {
    if(this.props.expandSubs.indexOf(this.props.location.code) !== -1 && this.props.location.children && this.props.parentOpen === 'open'){
      $(ReactDOM.findDOMNode(this).parentNode).addClass('foldable_content_' + this.props.location.code);
      this.setState({
        folded_unfolded: 'checked'
      });
    }
  },
  fold_unfold: function (e){
    e.stopPropagation();
    this.setState({folded_unfolded: (this.state.folded_unfolded === 'checked') ? '' : 'checked'})
    $(e.target.parentElement.parentElement).toggleClass('foldable_content_' + this.props.location.code)
    if(this.props.indent === 0){
      $(e.target.parentElement).find('.foldable_content').attr('class', 'foldable_content');
    }
  },
  select_location: function (location,e){
    e.stopPropagation();
    this.props.location.code === 'Oceania' ? this.fold_unfold(e) : FacetSearchLocationActions.selectLocation(location);
  },
  quiet_react: function (){
    // The whole “having to quiet react” doesn’t really sit well with me. Open to other ideas here.
    const quiet = 'react';
  },
  render: function () {
    let toggle_arrow = this.props.location.code + '_arrow'
    var toggle_id = this.props.location.code + '_toggle';
    var div_id = this.props.location.code + '_div';
    var children
    var indent = this.props.indent
    var label_classes = ['foldable_indent_' + this.props.indent, 'location_label']
    var toggle_class = 'foldable_toggle_div foldable_toggle_div_' + this.props.indent
    var open = (this.state.folded_unfolded) ? 'open' : 'closed';
    var expandSubs = [];
    if(this.props.location.expand_subs){
      expandSubs = this.props.location.expand_subs;
    }
    if(this.props.location.children){
      indent = this.props.indent + 1
      children = _.reject(this.props.location.children, function(child){return locationHelpers.findLocationInList(child.code, this.props.searchLocations) }.bind(this)).map(function(location, index){
        return (
          <div key={index} className="foldable_content">
            <LocationHolder parentOpen={open} indent={indent} location={location} searchLocations={this.props.searchLocations} selectedLocation={this.props.selectedLocation} expandSubs={expandSubs} />
          </div>
        );
      }.bind(this));
      label_classes.push('full_fold full_fold_location')
    }
    else {
      toggle_class = 'no_toggle_div'
    }
    var a_id= 'a_location_' + this.props.location.code
    var link = <a className="local_link location_link fancy_link" id={a_id}>{this.props.location.name}</a>
    // location_selector.js.jsx and territories.js.jsx are near identical and should be refactored into one
    var input_class = 'foldable_toggle standard_toggle'
    return (
      <div className="foldable">
        <input id={toggle_id} type="checkbox" checked={this.state.folded_unfolded} className={input_class} name={toggle_id} onChange={this.quiet_react} />
        <div id={toggle_arrow} onClick={this.fold_unfold} className={toggle_class}></div>
        <div id={div_id} onClick={this.select_location.bind(null, this.props.location)} className={label_classes.join(' ')}>{link}</div>
        {children}
      </div>
    )
  }
});

const SelectedLocations = createReactClass({
  render: function () {
    let locations = this.props.location.searchLocations.map(function(location, index){
      return <SelectedLocationHolder key={index} code={location.code} name={location.name} parent={location.parent} />
    });
    if(locations.length < 1){
      locations = <p className="selected_locations_default">Worldwide</p>
    }
    const random = Math.random();
    return (
      <div key={random} className="location_selector_box locations_selected">
        {locations}
      </div>
    );
  }
});

const SelectedLocationHolder = createReactClass({
  deselectLocation: function(e){
    e.stopPropagation();
    FacetSearchLocationActions.deselectLocation(this.props.code)
  },
  render: function(){
    let name = this.props.name
    if(this.props.parent) {
      name = this.props.name + ", " + this.props.parent;
    }
    return (
      <div className="foldable">
        <div onClick={this.deselectLocation} className="foldable_indent_0 location_label location_hover">
          <a className="local_link location_link fancy_link">{name}</a>
        </div>
      </div>
    );
  }
});

window.LocationSelectorPanel = LocationSelectorPanel;
window.LocationSelectorSection = LocationSelectorSection;
export default LocationSelectorSection;
