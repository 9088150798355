import LegacyModalService from "@javascript/globals/LegacyModalService";
import Button from "../../lds/Button";
import { ModalFooter } from "@javascript/reactComponents/lds/modal/Modal";
import ButtonGroup from "@javascript/reactComponents/lds/ButtonGroup";

const ListModalFooter = (props) => {
  const createNewList = function(e){
    e.preventDefault();

    if (props.listName.trim() === "") {
      return;
    }

    ListModalAction.createList();
    return;
  }

  let ctaClass = (props.listName.trim() === "" || props.total_sponsors <= 0) ? "button-disable" : ""

  if(props.saving_list){
    return (
      <ModalFooter>
        <Button size="medium" variant="disable" className="align-self-end">Please wait...</Button>
      </ModalFooter>
    );
  }
  else {
    return (
      <ModalFooter>
        <ButtonGroup size="medium" className="align-self-end">
          <Button size="medium"  type="reset" variant="secondary" onClick={LegacyModalService.closeModal}>Cancel</Button>
          <Button size="medium" type="submit" variant="primary" className={ctaClass} id="create_new_list_button" onClick={createNewList}>Generate List</Button>
        </ButtonGroup>
      </ModalFooter>
    );
  }
}

export default ListModalFooter;
