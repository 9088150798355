const AdminModal = createReactClass({
  componentDidMount: function(){
    let modal = ReactDOM.findDOMNode(this);
    $(modal).modal('show');
    $(modal).on('hidden.bs.modal', this.props.handleHideModal);
  },
  render: function(){
    return (
      // if removing this modal system, we should consider the maps page.  With the new, one modal for all approach, we've cured the problem of opening a modal from a modal (which erases the modal contents and replaces it).  We need a way of preserving a modal that opens a new modal (modal from modal).
      <div className="modal hide fade" >
        <div className="modal_wrapper">
          {this.props.children}
        </div>
      </div>
    )
  }
});

window.AdminModal = AdminModal;
export default AdminModal;
