import EntityListHolder from "./EntityListHolder";
import UserPagination from "../../flagged_events/userPagination";

const PagingOrgList = ({
  pageSize = 10,
  displayPage,
  displayOrgList,
  justSponsor,
  sponsorList,
  allOrgList
}) => {
    const changePage = (page) => ListModalAction.displayPage(page)
    if(displayOrgList == false){
        return false;
    }

    const currentEntityListsPage = justSponsor ? sponsorList : allOrgList;
    const pages = Math.ceil(currentEntityListsPage.length/pageSize)
    return (
        <div>
            <EntityListHolder sliceSize={pageSize} page={displayPage} entityList={currentEntityListsPage} />
            <UserPagination pages={pages} display_size={pageSize} changePage={changePage} current_page={displayPage} />
        </div>
    );
}

export default PagingOrgList;
