import React, { useContext } from 'react';

import EntityChip from "../../shared/chips/EntityChip";
import { TableContext } from './ListTable';

function ListRowChip({ label }) {
  const { showTag } = useContext(TableContext);

  if (!showTag) {
    return null;
  }

  return (
    <EntityChip label={label} />
  )
}

export default ListRowChip;
