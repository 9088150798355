const FlagThisEvent = (props) => 
  <fieldset className="sideways_grey_form_section flagged_event_section">
    <div className="event_title_panel">
      <div className={"event_wrapper " + props.category}>
        <div className="event_header">
          <div className={"event_sub_category " + props.category}>
            <div className="event_date">{props.event_date}</div>
          </div>
        </div>
        <div className={"event_content " + props.category}>
          <div className="event_hover">
            <div className="event_title">{props.title}</div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>

export default FlagThisEvent;
