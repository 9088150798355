import EditIndustriesFromDoc from "./editIndustriesFromDoc";
import { getAllPublishableConceptRefs } from "../../../helpers/administrateEvent";

const EditIndustryPanel = (props) => {
  let industries = props.event.industry_concept_refs;
  let possibleIndustries = getAllPublishableConceptRefs(props.event.entities);
  possibleIndustries=possibleIndustries.filter(ind => !industries.includes(ind));
  return <EditIndustriesFromDoc industries={industries} possibleIndustries={possibleIndustries}/>;
};

export default EditIndustryPanel;
