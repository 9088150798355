import Reflux from "reflux";
import MutedCompaniesActions from "./actions/mutedCompaniesActions";

const MutedCompaniesStore = Reflux.createStore({
  data: {},
  listenables: MutedCompaniesActions,
  getInitialState: function () {
    this.data = {
      suggestedCompanies: [],
      currentSuggested: [],
      mutedCompanies: [],
      currentMuted: [],
      settingsChanged: false,
      settingsSubmitted: false,
      referrer: '/settings'
    };
    return this.data;
  },
  onSetup: function(initialState){
    this.data = {
      suggestedCompanies: initialState.suggestedCompanies,
      currentSuggested: initialState.currentSuggested,
      mutedCompanies: initialState.mutedCompanies,
      currentMuted: JSON.parse(JSON.stringify(initialState.mutedCompanies)),
      settingsChanged: false,
      settingsSubmitted: false,
      referrer: initialState.referrer
    };
    this.trigger(this.data);
  },
  onClearSelections: function(){
    this.data.currentSuggested  = JSON.parse(JSON.stringify(this.data.suggestedCompanies));
    this.data.currentMuted  = [];
    this.data.settingsChanged = JSON.stringify(this.data.mutedCompanies) !== JSON.stringify(this.data.currentMuted);
    this.trigger(this.data);
  },
  onSaveChanges: function(){
    const currentMuted = this.data.currentMuted.map(company => company.id);
    $.ajax({
      method : "PATCH",
      url :  "/settings/muted_companies",
      dataType: "json",
      data : {org_ids: currentMuted}
    }).done(function() {
      this.data.settingsChanged = true;
      this.trigger(this.data);
      window.location.assign(this.data.referrer)
    }.bind(this));
    this.data.settingsSubmitted = true;
    this.trigger(this.data);
  },
  onSelectionAdded: function(company){
    const suggestedCopy = this.data.currentSuggested.filter(function( suggestedCompany ) {
      return suggestedCompany.id !== company.id;
    });
    this.data.currentSuggested = suggestedCopy;

    const alreadyMuted = this.data.currentMuted.findIndex(mutedCompany => mutedCompany.id === company.id);
    if(alreadyMuted > -1) {
      return;
    }

    this.data.currentMuted.push(company);
    const mutedCopy = JSON.parse(JSON.stringify(this.data.currentMuted));
    mutedCopy.sort(function(a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    })
    this.data.currentMuted = mutedCopy;
    this.data.settingsChanged = JSON.stringify(this.data.mutedCompanies) !== JSON.stringify(this.data.currentMuted);
    this.trigger(this.data);
  },
  onSelectionRemoved: function(company){
    const found = this.data.suggestedCompanies.some(suggested => suggested.id === company.id);
    const foundCurrent = this.data.currentSuggested.some(suggested => suggested.id === company.id);
    if(found && !foundCurrent) {
      this.data.currentSuggested.push(company);
      const suggestedCopy = JSON.parse(JSON.stringify(this.data.currentSuggested));
      suggestedCopy.sort(function(a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
      this.data.currentSuggested = suggestedCopy;
    }
    const mutedCopy = this.data.currentMuted.filter(function( mutedCompany ) {
      return mutedCompany.id !== company.id;
    });
    this.data.currentMuted = mutedCopy;
    this.data.settingsChanged = JSON.stringify(this.data.mutedCompanies) !== JSON.stringify(this.data.currentMuted);
    this.trigger(this.data);
  }
});

window.MutedCompaniesStore = MutedCompaniesStore;
export default MutedCompaniesStore;
