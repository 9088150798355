// future home of settings in react

var PremiumBaby = (props) => {
    const close_modal = function(){
        close_modal_control();
    }

    return (
        <div className="modal_wrapper">
            <div className="modal-header modal_header">
                <div className="close_x_white modal_close_offset" onClick={close_modal}></div>
                <h3 id="modal_flag_title">{props.title}</h3>
            </div>
            <div className="modal-body modal_body">
                <div className="container-fluid" id="modal_content_holder">
                    <div className="row-fluid">
                        <div className="span12">
                            <h3>This feature is associated with the {props.edition} of {I18n.t("brand.name")}.  To learn more, or to get a test run of this feature, <a href="/settings">view your settings.</a></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

window.PremiumBaby = PremiumBaby;
export default PremiumBaby;
