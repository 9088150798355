const EventDate = (props) => {
  const divStyle = {width: "100%", marginBottom: "5px", marginTop: "5px"};
  const dateString = moment(parseInt(props.event.dateMilli)).utcOffset("-0500").format("MMMM Do YYYY, h:mm:ss a %:z");
  return (
    <div style={divStyle} className="rspec-event-date">
      <b>
        <span className="right">{props.event.full_cat_name}</span>
        <span className="rspec-date-string">{dateString}</span>
      </b>
    </div>
  );
};

export default EventDate;
