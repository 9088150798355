import { useContext } from "react";

import Input from "../../../lds/Input";
import { DetailsSectionContext } from "./DetailsSection";

const ListName = () => {
  const { name, onListNameChange } = useContext(DetailsSectionContext);

  return (
    <div style={{marginTop: "32px"}}>
      <label style={{fontSize: "16px"}} htmlFor={"user_list_create_name"}>Provide a descriptive name for your list (required)</label>
      <Input
        id={"user_list_create_name"}
        onValueChange={onListNameChange}
        size="large"
        showLabel={false}
        helperTextVariant="hidden"
        value={name}
        width="100%"
      />
    </div>
  )
}

export default ListName;
