import Reflux from "reflux";
import FacetSearchStore from "../../stores/facetSearchStore";
import { FacetSearchAffiliatedWithActions } from "../../stores/actions/facetSearch/affiliatedWithActions";

/**
 * React component that displays a list of org lists
 *
 * @param {Object} orgListRefs a hash, each entry of which consists of a list ref as key pointing at a single value array - the sole member of the array is the display value associated with the list ref.
 */

// this component, with a little work and standardization, could be made generic once the breadbox component PDLC is complete
//   -- GJ 2023.02.07
const FacetSearchCriteriaEntityValues = createReactClass({
  mixins: [Reflux.connectFilter(FacetSearchStore, ({affiliation: orgListRefs}) => orgListRefs)],
  render(){
    const {orgListRefs} = this.state;

  if(orgListRefs) {
    const orgListRefsKeys = Object.keys(orgListRefs)
    const locked = ((Zymewire.original_feed_query || {}).entity_list_refs || []).length > 0
    let orgListContent = [];

      orgListRefsKeys.forEach((orgListRefsKey) => {
        const displayValues = orgListRefs[orgListRefsKey];

        displayValues.forEach((displayValue, displayIndex) => {

        let entry = <FacetSearchCriteriaOrgListsEntry key={orgListRefsKey + displayIndex + "entry"} displayValue={displayValue} locked={locked} />

        if(locked) {
          orgListContent.push(entry)
        }
        else {
          let unlockedContent = <FacetSearchCriteriaOrgListsRemove key={orgListRefsKey + displayIndex} displayValue={displayValue} orgListRefsKey={orgListRefsKey} >
            {entry}
          </FacetSearchCriteriaOrgListsRemove>;

            orgListContent.push(unlockedContent);
          }
        })
      })
      return <div>{orgListContent}</div>;
    }
    else{
      return null;
    }
  }
})

/**
 * React component that displays the org list ref used to filter a query. If the entry is a locked entry, as permitted on the feed view, then the entry's remove functions are disabled.
 *
 * @param {Boolean} locked is the filter removable or not
 */

// this component, with a little work and standardization, could be made generic once the breadbox component PDLC is complete
//   -- GJ 2023.02.07
const FacetSearchCriteriaOrgListsEntry = ({ displayValue, locked }) => {

  let removeTitle = "Click to remove this filter.";
  let removeEntry = <GenericFilterItemRemove />;
  let removeStyle = {fontSize: "13px"};

  if(locked) {
    removeTitle = null;
    removeEntry = null;
    removeStyle.color = "#000000";
  }

  return (
    <div className="filter_item filter_item_entity rspec_entity_value_result" title={"You are filtering your search by a specific entity range. " + removeTitle}>
      {removeEntry}
      <div style={{display: "inline-block"}}>
        <p style={removeStyle}>
          <b>{displayValue}</b>
        </p>
      </div>
    </div>
  )
};

/**
 * React component that acts as a wrapper for when the org list ref filter's remove functions are enabled.
 *
 */

const FacetSearchCriteriaOrgListsRemove = ({ children, displayValue, orgListRefsKey }) => {

  let removeOrgList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    FacetSearchAffiliatedWithActions.removeOrgListRef({ref: orgListRefsKey, display: displayValue});
    return false;
  };

  return (
    <div>
      <a onClick={removeOrgList} className="action_link">
        {children}
      </a>
    </div>
  )
}
window.FacetSearchCriteriaEntityValues = FacetSearchCriteriaEntityValues;
export default FacetSearchCriteriaEntityValues;
