import Reflux from "reflux";
import FacetSearchStore from "../../stores/facetSearchStore";
import { FacetSearchStoreActions } from "../../stores/actions/facetSearch/storeActions";

/**
 * This component handles rendering of the Clinical Stages filter criteria
 * within the Facet Search breadbox.
 */
const FacetSearchCriteriaClinicalTrialStages = createReactClass({
  mixins: [
    Reflux.connectFilter(FacetSearchStore, function (data) {
      return {
        display: data.clinicalTrialStages.display,
        selected: data.clinicalTrialStages.selected,
      };
    }),
  ],
  getInitialState() {
    return {
      display: FacetSearchStore.data.clinicalTrialStages.display,
      selected: FacetSearchStore.data.clinicalTrialStages.selected,
    };
  },
  shouldComponentUpdate: function (nextProps, nextState) {
    return this.state.selected.length !== nextState.selected.length;
  },
  render() {
    if(this.state.selected.length === 0) {
      return null;
    }
    else {
      // Zymewire.original_feed_query should probably be refactored to the FacetSearchStore under
      // searchTerms.originalQuery key
      // set on initial view, updated on any subsequent AJAX success
      const originalQuery = Zymewire.original_feed_query;

      let lastIndex = this.state.selected.length - 1

      let selectedList = this.state.selected.map((stage, index) => {

        let last = index === lastIndex;
        let name = this.state.display[stage];
        let locked = false;

        if (originalQuery) {
          if (originalQuery.clin_stage && originalQuery.clin_stage.length > 0) {
            if (_.contains(originalQuery.clin_stage, stage)) {
              locked = true;
              // this hide is ugly hacky and once we connect the clinical trial stages selector to the store in a future
              // refactor, we can safely junk this
              $('#trial_stages_panel').hide();
            }
          }
        }
        return <FacetSearchCriteriaClinicalTrialStage key={index} last={last} locked={locked} name={name} stage={stage} />
      });

      return(
        <div>
          <p style={{ marginBottom: "0px" }}>Specific mention of a</p>
          {selectedList}
        </div>
      )
    }
  }
});

/**
 * React component that acts as a container for each clinical trial stage used to filter a query. If the entry is a locked entry, as permitted on the feed view, then the entry's remove functions are disabled.
 *
 * @param {boolean} last is this the last entry in the list?
 * @param {boolean} locked is this filter locked and therefore not eligible to be removed?
 * @param {string} name the name of the stage
 * @param {string} stage the value of the stage
 *
 */

const FacetSearchCriteriaClinicalTrialStage = ({ last, locked, name, stage }) => {

  let entry = <FacetSearchCriteriaClinicalTrialStageEntry last={last} locked={locked} name={name} />

  if(locked) {
    return entry
  }
  else {
    return <FacetSearchCriteriaClinicalTrialStageRemove value={stage}>
      {entry}
    </FacetSearchCriteriaClinicalTrialStageRemove>
  }
};

/**
 * React component that displays a location used to filter a query. If the entry is a locked entry, as permitted on the feed view, then the entry's remove functions are disabled.
 *
 * @param {boolean} locked the location name
 * @param {string} name the location name
 * @param {boolean} last whether it's the last in the list... yeah.
 */

const FacetSearchCriteriaClinicalTrialStageEntry = ({ last, locked, name}) => {

  let removeTitle = "Click to remove this filter.";
  let removeEntry = <GenericFilterItemRemove />;
  let removeStyle = {fontSize: "13px"};

  if(locked) {
    removeTitle = null;
    removeEntry = null;
    removeStyle.color = "#000000";
  }

  let entryName = last ? name : name + " OR";

  return (
    <div className="filter_item_property" title={"You are filtering your search by events specifically mentioning a '" + name + "'. " + removeTitle}>
      {removeEntry}
      <div style={{display: "inline-block"}}>
        <p style={removeStyle}>
          <b>{entryName}</b>
        </p>
      </div>
    </div>
  )
};

/**
 * React component that acts as a wrapper for when a stage entry's remove functions are enabled.
 *
 * @param {string} value  the stage value passed back
 * @param {reactcomponent} children  the react component representing the stage
 */

const FacetSearchCriteriaClinicalTrialStageRemove = ({ value, children }) => {
  let removeStage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const clinicalTrialStagesSelected = [...FacetSearchStore.data.clinicalTrialStages.selected];
    const selectedIndex = clinicalTrialStagesSelected.indexOf(value);

    if (selectedIndex > -1) {
      // remove the clinical trial stage from the array... we no longer wish to represent it as checked
      clinicalTrialStagesSelected.splice(selectedIndex, 1);
    } else {
      // add the clinical trial stage to the array... it should be checked now
      clinicalTrialStagesSelected.push(value);
    }
    FacetSearchStoreActions.clinicalTrialStagesUpdate(
      { selected: clinicalTrialStagesSelected },
      { doSearch: true, triggerStateUpdate: false }
    );

    return false;
  };

  return (
    <div>
      <a onClick={removeStage} className="action_link">
        {children}
      </a>
    </div>
  )
};

window.FacetSearchCriteriaClinicalTrialStages = FacetSearchCriteriaClinicalTrialStages;
export default FacetSearchCriteriaClinicalTrialStages;
