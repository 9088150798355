import Reflux from "reflux"

/**
 * Reflux action object
 *
 * @namespace FacetSearchGeoCoordinatesActions
 * @param [Array] of action names for use with FacetSearchGeoCoordinatesMutations
 */
const FacetSearchGeoCoordinatesActions = Reflux.createActions({
  geoCoordinatesUpdate: {sync: true},
});

/**
 * Reflux store object
 *
 * @namespace FacetSearchGeoCoordinatesMutations
 *
 * Store listens to FacetSearchGeoCoordinatesActions events and updates its data object.
 * These methods are used from both inside and outside the relevant components to manage state.
 */

const FacetSearchGeoCoordinatesMutations = {
  onGeoCoordinatesUpdate: function(geoCoordinates, options = {doSearch: false, triggerStateUpdate: false} ) {

    this.data.geoCoordinates = geoCoordinates;

    const { doSearch, triggerStateUpdate } = options;

    if(doSearch) {
      doEventSearch();
    }
    if(triggerStateUpdate) {
      this.trigger(this.data);
    }
  }
};

window.FacetSearchGeoCoordinatesActions = FacetSearchGeoCoordinatesActions
export {
  FacetSearchGeoCoordinatesActions,
  FacetSearchGeoCoordinatesMutations
}
