const AdminModalBody = (props) => {
  const divStyle = props.style || { paddingTop: '20px', paddingBottom: '20px' };
  return (
    <div className="modal-body" style={divStyle}>
      {props.children}
    </div>
  )
};

window.AdminModalBody = AdminModalBody;
export default AdminModalBody;

