const TerritoryHolder = createReactClass({
  getInitialState: function(){
    return {
      folded_unfolded: ""
    };
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.parent_open === "closed"){
      this.setState({
        folded_unfolded: ""
      });
    }
  },
  fold_unfold: function (e){
    e.stopPropagation();
    this.setState({folded_unfolded: (this.state.folded_unfolded === "checked") ? "" : "checked"});
    $(e.target.parentElement.parentElement).toggleClass("foldable_content_" + this.props.location.code);
    if(this.props.indent === 0){
      $(e.target.parentElement).find(".foldable_content").attr("class", "foldable_content");
    }
  },
  select_location: function (location, e){
    e.stopPropagation();
    this.props.location.code === "Oceania" ? this.fold_unfold(e) : TerritoriesAction.territorySelected(location);
  },
  render: function () {
    let toggleArrow = this.props.location.code + "_arrow";
    const toggleId = this.props.location.code + "_toggle";
    const divId = this.props.location.code + "_div";
    let children;
    let indent = this.props.indent;
    const labelClasses = ["foldable_indent_" + this.props.indent, "location_label"];
    let toggleClass = "foldable_toggle_div foldable_toggle_div_" + this.props.indent;
    const open = (this.state.folded_unfolded) ? "open" : "closed";
    if(this.props.location.children){
      indent = this.props.indent + 1;
      children = _.reject(this.props.location.children, function(child){return this.props.territoryIsContainedInList(child.code, this.props.userTerritoryList) }.bind(this)).map(function(location, index){
        return (
          <div key={index} className="foldable_content">
            <TerritoryHolder parent_open={open} indent={indent} location={location} userTerritoryList={this.props.userTerritoryList} territoryIsContainedInList={this.props.territoryIsContainedInList}/>
          </div>
        );
      }.bind(this));
      labelClasses.push("full_fold full_fold_location");
    }
    else {
      toggleClass = "no_toggle_div";
    }
    const aId= "a_location_" + this.props.location.code;
    const link = <a className="local_link location_link fancy_link" id={aId}>{this.props.location.name}</a>;   
    const inputClass = "foldable_toggle standard_toggle";
    return (
      <div className="foldable">
        <input id={toggleId} type="checkbox" readOnly checked={this.state.folded_unfolded} className={inputClass} name={toggleId} />
        <div id={toggleArrow} onClick={this.fold_unfold} className={toggleClass} />
        <div id={divId} onClick={this.select_location.bind(null, this.props.location)} className={labelClasses.join(" ")}>{link}</div>
        {children}
      </div>
    )
  }
});

export default TerritoryHolder;
