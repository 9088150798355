const EditOrgEntity = (props) => {
  const removeOrg = (e) => {
    e.preventDefault();
    EventModifyModalAction.removeEntity(props.org.rec_id);
  };
  const publishableConceptRefs = props.org.publishable_concept_refs ? [...props.org.publishable_concept_refs] : [];
  publishableConceptRefs.forEach((e, i) => publishableConceptRefs[i]=e.substring(0,12));
  const industries = publishableConceptRefs.length > 0 ? <b>({publishableConceptRefs.join(", ")})</b> : null;
  const location = props.org.location ? " (" + props.org.location + ") " : null;
  return (
    <span>
        <a className="action_link org_remove_link" id={"rspec_remove_" + props.org.rec_id} onClick={removeOrg}>{props.org.name} <i className="icon-remove"/></a>{location}{industries};
      </span>
  );
};

export default EditOrgEntity;
