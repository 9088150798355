import { clinicalStageLabels } from "../../../config/clinicalStages";

const RemoveClinicalStage = (props) => {

  const removeStage = (e) => {
    e.preventDefault();
    EventModifyModalAction.removeClinStage(props.stage);
  };
  const stageName = clinicalStageLabels[props.stage];
  return (
    <a className="action_link clin_stage_remove_link" onClick={removeStage}><span className='rspec_remove_property'>{stageName}</span> <i
      className="icon-remove"/></a>
  );
};

export default RemoveClinicalStage;
