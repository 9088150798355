const SfLeadsNotice = createReactClass({
  jumpHere: function (e){
    e.stopPropagation();
    e.preventDefault();
    SFPanelActions.jump("sf_leads_div");
  },
  addLead: function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this.refs.new_sf_lead_alert).show();
    $(this.refs.link_to_create_sf_lead).hide();

    const mixpanelOptions = { action: "add to salesforce modal triggered", location: "from company page", type: "add lead manually" };
    triggerSFEngagementActionForClassicMode(mixpanelOptions);
    const addedFrom = "Added from " + I18n.t("brand.name") + " - to find other leads and to see the latest activity from " + this.props.company_name + " please visit " + I18n.t("default_host") + "/company/" + this.props.company_id;
    const url = "https://na88.salesforce.com/00Q/e?lea3=" + encodeURI(this.props.company_name) + "&lea17=" + encodeURI(addedFrom) + "."
    window.open(url, "_blank");
  },
  addLeadLightning:function(e){
    e.stopPropagation();
    e.preventDefault();
    const mixpanelOptions = { action: "add to salesforce modal triggered", location: "from company page", type: "add lead manually" };
    initiateAddToSalesforceModal("lead", this.props.company_id, null, mixpanelOptions);
  },
  render: function () {
    let leads = "is 1 lead"
    let addLeadLink, leadMessage;
    if(this.props.lead_count === 0){
      leads = "are no leads";
      if(this.props.use_lightning){
        addLeadLink = <a ref="link_to_create_sf_lead" className="lighting-sf-panel-lead-link" style={{cursor:"pointer"}} onClick={this.addLeadLightning}>Create a new lead in Salesforce.</a>;
      }
      else{
        addLeadLink = <a ref="link_to_create_sf_lead" className="lighting-sf-panel-lead-link" style={{cursor:"pointer"}} onClick={this.addLead}>Create a new lead in Salesforce.</a>;
      }
      leadMessage = (
        <div className="row" style={{paddingTop:"10px"}}>
          <div className="span12">
            <div ref="new_sf_lead_alert" className="alert alert-danger hide" style={{marginBottom:"0px"}}>
              <a className="close" data-dismiss="alert">×</a>
              <h4>After creating a new lead in Salesforce it may take up to a few minutes for {I18n.t("brand.name")} and Salesforce to synchronize. You will need to refresh this page to see the new lead.</h4>
            </div>
          </div>
        </div>
      )
    }
    else {
      if(this.props.lead_count > 1){
        leads = "are " + this.props.lead_count + " leads";
      }
      if(this.props.account_count > 0){
        leads = <a onClick={this.jumpHere} className="fancy_link local_link">{leads}</a>;
      }
    }
    const companyName = this.props.company_name.replace(/\.\s*$/, "");
    return (
      <div className="sf_paragraph">
        There {leads} related to {companyName}. {addLeadLink}{leadMessage}
      </div>
    );
  }
});

export default SfLeadsNotice;
