// needs to refactored heavily... my first React project circa 2016 lol
// and since we're upgrading it to do more, it now needs a store

/**
 * Renders event categories (e.g. Milestone activity, funding activity, etc.) and
 * also handles clicking a given category or entire section.
 *
 * @component
 * 
 * */
const FacetSections = createReactClass({
  mixins: [
      Reflux.connectFilter(FacetSearchStore, "store", function (data) {
        return data.activities
      }),
  ],
  getInitialState: function(){
    return {
      facet_filters: prep_facet_filters(),
    }
  },
  UNSAFE_componentWillMount:function(){
    const { facet_filters: { activities, affiliations } } = this.state

    FacetSearchCompanyActivityActions.activitiesInitialState(activities.selected);
    FacetSearchAffiliatedWithActions.affiliationsInitialState(affiliations);
  },
  render: function() {
    // Also controls which categories are highlighted,
    // so it needs to update on every render.
    const facet_sections = prep_facet_sections();

    return (
      <div>
        { _.map(facet_sections, (facet_data) => <FacetSection key={facet_data.id} facet_data={facet_data} />) }
      </div>
    );
  }
});

var FacetSection = createReactClass({
  getInitialState: function(){
    return {
      folded_unfolded:(this.props.facet_data.selected) ? 'checked' : ''
    }
  },
  quiet_react: function (){
    // The whole “having to quiet react” doesn’t really sit well with me. Open to other ideas here.
    const quiet = 'react';
  },
  fold_unfold: function (){
    this.setState({folded_unfolded: (this.state.folded_unfolded == 'checked') ? '' : 'checked'})
  },
  render: function(){
    var id = this.props.facet_data.id;
    var toggle_id = id + '_toggle';
    var div_id = id + '_div';
    let input_classes = ['foldable_toggle'];
    let div_classes = ['facet_section_label', 'foldable_label'];
    if(_.contains(Zymewire.user.feature_tags, "cmo_concepts_and_associations")) {
      div_classes.push('facet_section_label_CMO', 'foldable_label_CMO');
    }
    input_classes.push(this.props.facet_data.element_type);
    return (
      <div className="foldable facet_section">
        <input id={toggle_id} type="checkbox" checked={this.state.folded_unfolded} className={input_classes.join(' ')} name={toggle_id} onChange={this.quiet_react} />
        <div id={div_id} onClick={this.fold_unfold} className={div_classes.join(' ')}>{this.props.facet_data.name}</div>
        <FacetContent key={toggle_id} content={this.props.facet_data.content} />
      </div>
    );
  }
});

var FacetContent = createReactClass({
  render: function() {
    let indent = 0;
    let div_classes = ['foldable_content'];
    if(_.contains(Zymewire.user.feature_tags, "cmo_concepts_and_associations")) {
      div_classes.push('facet_section_foldable_content_CMO');
    }
    var facet_elements = _.map(this.props.content, function(element, index){
      // will want to refactor this: both ActivityEntry and AffiliatedEntry are super similar in their look and operation but consume/interact with the object differently
      if(element.type === 'activity'){
        return <ActivityEntry key={element.id} indent={indent} element_data={element} />
      }
      else if(element.type === 'affiliation'){
        return <AffiliatedEntry key={element.id} indent={indent} element_data={element} />
      }
      else {
        // houstonwehaveaquestionandthatquestionisWTF
        console.log('facet what?')
        return null
      }
    }.bind(this));
    return (
      <div className={div_classes.join(' ')}>
        {facet_elements}
      </div>
    );
  }
});

var ActivityEntry = createReactClass({
  mixins: [Reflux.listenTo(FacetSearchStore,"change_activity_state", function (data) { return data.activities })],
  getInitialState: function(){
    return {
      folded_unfolded:(this.props.element_data.selected) ? 'checked' : '',
      selected:false
    }
  },
  fold_unfold: function (){
    this.setState({folded_unfolded: (this.state.folded_unfolded == 'checked') ? '' : 'checked'})
  },
  change_activity_state: function (new_state){
    const selected = new_state.activities.selected === this.props.element_data.id;
    this.setState(state => (
      {...state, selected:selected}
    ));
  },
  remove_activity: function (e){
    e.stopPropagation();
    FacetSearchCompanyActivityActions.activityRemoved();
    return false;
  },
  select_activity: function (e){
    e.stopPropagation();
    this.setState({folded_unfolded: 'checked'});

    FacetSearchCompanyActivityActions.activitySelected(this.props.element_data.id);
    return false;
  },
  quiet_react: function (){
    // The whole “having to quiet react” doesn’t really sit well with me. Open to other ideas here.
    const quiet = 'react';
  },
  render: function () {
    var remove_filter = this.state.selected ? <span onClick={this.remove_activity} className="remove_filter selection_remove"></span> : null;
    var toggle_id = this.props.element_data.id + '_toggle';
    var div_id = this.props.element_data.id + '_div';
    var count_id = this.props.element_data.id + '_count';
    var children
    var indent = this.props.indent
    var label_classes = ['foldable_indent_' + this.props.indent, 'selection_label', 'event_sub_category']
    label_classes.push(this.props.element_data.id)
    if(this.props.element_data.value == "0"){
      label_classes.push('no_results')
    }
    if(this.props.element_data.content) {
      indent = this.props.indent + 1
      children = _.map(this.props.element_data.content, function (element, index) {
        return (
          <div key={element.id} className="foldable_content">
            <ActivityEntry indent={indent} element_data={element} />
          </div>
        );
      }.bind(this));
      label_classes.push('full_fold')
    }
    else{
      label_classes.push('false_fold')
    }
    var a_id= 'a_' + this.props.element_data.id
    var link = <a className="activity_link fancy_link" id={a_id} title={this.props.element_data.tooltip} onClick={this.select_activity}>{this.props.element_data.name}</a>
    if(Zymewire.original_feed_query && typeof Zymewire.original_feed_query.category != 'undefined'){
      // this logic will fail if we get grandchildren or deeper... will need a superset tag: Zymewire.original_feed_query.category != this.props.element_data..superset
      if(Zymewire.original_feed_query.category !== 'all_event' && Zymewire.original_feed_query.category !== this.props.element_data.parent && Zymewire.original_feed_query.category !== this.props.element_data.id){
        link = <i>{this.props.element_data.name}</i>;
        remove_filter = null
      }
      if(this.state.selected){
        if(Zymewire.original_feed_query.category === this.props.element_data.id){
          remove_filter = null
        }
        else{
          label_classes.push('selected_activity')
        }
      }
    }
    else if(this.state.selected){
      label_classes.push('selected_activity')
    }
    var input_class = 'foldable_toggle ' + this.props.element_data.size + '_toggle'
    return (
      <div key={div_id} className="foldable">
        <input id={toggle_id} type="checkbox" checked={this.state.folded_unfolded} className={input_class} name={toggle_id} onChange={this.quiet_react}/>
        <div id={div_id} onClick={this.fold_unfold} className={label_classes.join(' ')}>{link}{remove_filter}<span id={count_id} className="results_count">{this.props.element_data.value}</span></div>
        {children}
      </div>
    )
  }
});

const AffiliatedEntry = createReactClass({
  mixins: [Reflux.listenTo(FacetSearchStore,"change_affiliation_state", function (data) { return data.affiliation })],
  getInitialState: function(){
    return {
      folded_unfolded:(this.props.element_data.selected) ? 'checked' : '',
      chosen:null
    }
  },
  fold_unfold: function (){
    this.setState(state => (
      {...state, folded_unfolded: (this.state.folded_unfolded === 'checked') ? '' : 'checked'}
    ));
  },
  change_affiliation_state: function (new_state){
    this.setState(state => (
      { ...state, chosen: new_state.affiliation.selected }
    ));
  },
  remove_affiliation: function (e){
    e.stopPropagation();


    FacetSearchAffiliatedWithActions.affiliationRemoved({ doSearch: true });
    return false;
  },
  select_affiliation: function (e){
    e.stopPropagation();
    this.setState(state => (
      {...state, folded_unfolded: 'checked'}
    ));

    const selectedList = {
      orgListId: this.props.element_data.id,
      name: this.props.element_data.name,
      listType: 'public',
      orgCount: this.props.element_data.value
    }

    // TODO: Ideally, we move doEventSearch() into a store action, and
    //       adjust how we determine if an option is selected or not,
    //       in order to *exactly* match what is in production.
    FacetSearchListActions.listSelected(selectedList, { skipTrigger: true })
    FacetSearchAffiliatedWithActions.affiliationSelected(this.props.element_data.id, { skipTrigger: true });

    doEventSearch()
    return false;
  },
  quiet_react: function (){
    // The whole “having to quiet react” doesn’t really sit well with me. Open to other ideas here.
    const quiet = 'react';
  },
  render: function () {
    const { chosen } = this.state

    const this_selected = chosen === this.props.element_data.id;
    let remove_filter = null;
    const toggle_id = this.props.element_data.id + '_toggle';
    const div_id = this.props.element_data.id + '_div';
    let children = null;
    let indent = this.props.indent;
    let label_classes = ['foldable_indent_' + this.props.indent, 'selection_label', 'event_sub_category', 'affiliation_selection'];
    label_classes.push(this.props.element_data.id);
    if(this.props.element_data.value === "0"){
      label_classes.push('no_results')
    }
    if(this.state.chosen !== null && !this_selected){
      label_classes.push('no_results')
    }
    if(this.props.element_data.content) {
      indent = this.props.indent + 1;
      children = _.map(this.props.element_data.content, function (element, index) {
        return (
          <div key={element.id} className="foldable_content">
            <AffiliatedEntry indent={indent} element_data={element} />
          </div>
        );
      }.bind(this));
      label_classes.push('full_fold');
    }
    else{
      label_classes.push('false_fold');
    }
    const a_id= 'a_' + this.props.element_data.id;
    let link = <a className="activity_link fancy_link" id={a_id} onClick={this.select_affiliation}>{this.props.element_data.name}</a>;
    if(this_selected) {
      label_classes.push('selected_activity')
      remove_filter = <span onClick={this.remove_affiliation} className="remove_filter selection_remove"></span>
    }
    const input_class = 'foldable_toggle ' + this.props.element_data.size + '_toggle';
    return (
      <div key={div_id} className="foldable">
        <input id={toggle_id} type="checkbox" checked={this.state.folded_unfolded} className={input_class} name={toggle_id} onChange={this.quiet_react}/>
        <div id={div_id} onClick={this.fold_unfold} className={label_classes.join(' ')}>{link}{remove_filter}</div>
        {children}
      </div>
    )
  }
});

window.FacetSections = FacetSections;
export default FacetSections;
