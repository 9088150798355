import Input from "@javascript/reactComponents/lds/Input";
import { useEffect, useRef, useState } from "react";
import { formatDate, isKeyDownEventValidInput, parseDate } from "./timeSelectorHelpers";
import { datesEqual } from "@javascript/reactComponents/lds/datepicker/DatePicker";

export default function FacetSearchTimeSelectMultiInput({
  start,
  end,
  onUpdateStart,
  onUpdateEnd,
  focused,
  onTrailingActionClick,
  dropdownOpen,
  dropdownRef,
}) {
  // Update via ref value so that it doesn't cause the caret to jump
  const updateInputWithDate = (ref, date, force = false) => {
    if (ref.current) {
      // Prevent jitter, no need to update if they represent the same date
      const existingDate = parseDate(ref.current.value).toDate();

      if (datesEqual(existingDate, date) && !force) {
        return;
      }

      ref.current.value = formatDate(date);
    }
  };

  const handleInputBlur = (event, type) => {
    // When nothing given, revert to old value
    if (event.target.value.trim() === "") {
      type === "start" ? setStartText(formatDate(start)) : setEndText(formatDate(end))
      return
    }

    const date = parseDate(event.target.value).toDate();

    if (type === "start") {
      setStartText(event.target.value);
      updateInputWithDate(startRef, date, true);
    } else {
      setEndText(event.target.value);
      updateInputWithDate(endRef, date, true);
    }
  };

  // When user clicks the "Enter" key
  const handleKeyDown = (event, type) => {
    if (!isKeyDownEventValidInput(event)) {
      event.preventDefault();
      return
    }

    // No current way to stop the page from triggering a
    // search without some complicated logic. To account
    // for this, we update the store so that downstream
    // function such as search_data()
    // have access to the correct date range.
    FacetSearchTimeFrameActions.setDateRange(
      formatDate(start),
      formatDate(end)
    );

    if (event.key === "Enter") {
      handleInputBlur(event, type);
      dropdownRef.current.close();
    }
  };

  const isValidMoment = (momentDate) => {
    return momentDate.isValid();
  };

  const [startText, setStartText] = useState(formatDate(start));
  const [endText, setEndText] = useState(formatDate(end));

  const startRef = useRef();
  const endRef = useRef();

  const commonInputStyles = {
    flexGrow: 0,
    width: 77,
  };

  const inputValid = start <= end;

  // New data from parent
  useEffect(() => {
    updateInputWithDate(startRef, start);
  }, [start]);

  useEffect(() => {
    updateInputWithDate(endRef, end);
  }, [end]);

  // User manually edits From date
  useEffect(() => {
    let desiredStart = parseDate(startText);

    if (isValidMoment(desiredStart)) {
      onUpdateStart(desiredStart.toDate());
    }
  }, [startText]);

  // User manually edits To date
  useEffect(() => {
    let desiredEnd = parseDate(endText);

    if (isValidMoment(desiredEnd)) {
      onUpdateEnd(desiredEnd.toDate());
    }
  }, [endText]);

  useEffect(() => {
    if (!focused) {
      startRef.current.value = formatDate(start);
      endRef.current.value = formatDate(end);
    }
  }, [focused]);

  return (
    <Input
      size="small"
      trailingIcon={dropdownOpen ? "chevron-up" : "chevron-down"}
      trailingIconOnClick={onTrailingActionClick}
      focused={focused}
      variant={inputValid ? "normal" : "critical"}
      value={
        <div
          className="flex-row"
          style={{ alignItems: "baseline", width: "100%" }}
        >
          <input
            id="from_date"
            data-testid="facet-search-time-select-start-input"
            ref={startRef}
            className="lds-input-small"
            placeholder="MM-DD-YY"
            defaultValue={startText}
            onChange={(e) => setStartText(e.target.value)}
            onBlur={(e) => handleInputBlur(e, "start")}
            onKeyDown={(e) => handleKeyDown(e, "start")}
            style={commonInputStyles}
          />
          <div className="facet_search_date_range_picker_to_label">to</div>
          <input
            id="to_date"
            data-testid="facet-search-time-select-end-input"
            ref={endRef}
            className="lds-input-small"
            placeholder="MM-DD-YY"
            defaultValue={endText}
            onChange={(e) => setEndText(e.target.value)}
            onBlur={(e) => handleInputBlur(e, "end")}
            onKeyDown={(e) => handleKeyDown(e, "end")}
            style={commonInputStyles}
          />
        </div>
      }
      onValueChange={() => {}}
      showClearIcon={false}
      width="266px"
    />
  );
}
