import React, { useContext } from "react";
import Toast from "../../../lds/notifications/Toast";
import EntityChip from "../../../shared/chips/EntityChip";
import { DeleteModalContext } from "../ListDeleteModal";
import ScrollableList from "../ScrollableList";

import ToastBanner from "../ToastBanner";

const ListDeleteModalPreviewBody = () => {
  const { selectedLists } = useContext(DeleteModalContext);

  const listRows = [...selectedLists.values()].map((list) => {
    return (
      <div className="name-column" key={list.id} style={{ padding: "5px" }}>
        <EntityChip label={list.type_ref} size="small" />
        <p className="list-name">{list.name}</p>
      </div>
    );
  });

  return (
    <>
      <ToastBanner title="This action is irreversible" variant="warning" className="mock-banner-warning">
        All the selected lists will be permanently removed and you won't be
        able to retrieve them, including the ones you've shared with your
        colleagues.
      </ToastBanner>
      <ScrollableList>{listRows}</ScrollableList>
    </>
  );
};

export default ListDeleteModalPreviewBody;
