import Button from "../lds/Button";
import PropTypes from "prop-types";
import { FeedsIndexContext } from "../../stores/FeedsIndex";
import { Modal } from "../lds/modal/Modal";
import { useContext, useState } from "react";
import DeleteFeedsModal from "./DeleteFeedsModal";

const FeedsIndexDeleteButton = ({ group }) => {
  const { getCheckboxArray } = useContext(FeedsIndexContext);

  const groupCheckboxArray = getCheckboxArray(group);
  const buttonVariant = groupCheckboxArray.length > 0 ? "critical" : "disable";

  const [modalOpen, setModalOpen] = useState(false);
  const modalClose = () => setModalOpen(false);

  const onDeleteClick = () => {
    setModalOpen(groupCheckboxArray.length > 0);
  };

  return (
    <>
      <Button
        children="Delete"
        icon="delete"
        id={`delete_${group}`}
        onClick={onDeleteClick}
        size="small"
        variant={buttonVariant}
      />

      <Modal open={modalOpen} onClose={modalClose} size="medium">
        <DeleteFeedsModal
          group={group}
          feedsSelected={_.uniq(groupCheckboxArray)}
        />
      </Modal>
    </>
  );
};

FeedsIndexDeleteButton.propTypes = {
  group: PropTypes.string.isRequired,
};

export default FeedsIndexDeleteButton;
