import ZoominfoPersonResult from "./zoominfoPersonResult";

const ZoominfoDisplayResults = function(props) {
  if( props.search_results.max_results === 0){
    return false;
  }

  var start = (props.start_point - 1) * 20;
  var end = start + 20;
  var people;

  people = props.search_results.records.slice(start, end).map(function (person) {
    var pers_id = person.entity_id;
    return <ZoominfoPersonResult org_id={ props.org_id } feature_flags={props.feature_flags} credits_available={props.user_zinfo.remaining_for_month} key={pers_id} {...person} company_details={props.company_details} company_name={props.company_name} company_domain={props.company_domain} can_use_salesforce={props.can_use_salesforce} current_contact={_.find(props.current_contacts, function (contact) {
      return contact.entity_id == this
    }, pers_id)}/>
  }.bind(this));

  return (
    <div id="zi_search_results" className="">
      {people}
    </div>
  )
}

export default ZoominfoDisplayResults;
