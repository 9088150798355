import Reflux from "reflux";

const TerritoriesAction = Reflux.createActions([
  "initialState", 
  "territorySelected", 
  "territoryRemoved", 
  "clearTerritory", 
  "saveChanges" 
]);

window.TerritoriesAction = TerritoriesAction;
export default TerritoriesAction;
