import SelectableUser from "./selectableUser";

const ColumnOfSelectableUsers = function(props) {
  let users = props.users.map(function (user, index) {
    return <SelectableUser key={index} user={user} toggleUser={props.toggleUser} searchString={props.searchString} />;
  });

  return (
    <div className="span6">
      {users}
    </div>
  );
}

export default ColumnOfSelectableUsers