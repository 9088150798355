const EventTitle = (props) => {
  const backgroundColor = "#" + props.event.color;
  const divStyle = {
    width: "100%",
    backgroundColor: backgroundColor,
    marginLeft: "-8px",
    marginRight: "-8px",
    marginTop: "-8px",
    padding: "8px"
  };
  const h4Style = {color: "#fff"};
  if(props.event.category === "opinion"){
    divStyle.backgroundColor = "#fff";
    divStyle.border = "solid 1px #" + props.event.color;
    h4Style.color =  "#" + props.event.color;
  }
  return (
    <div className="rspec-event-title" style={divStyle}>
      <h4 style={h4Style}>{props.event.title}</h4>
    </div>
  );
};

export default EventTitle;
