const FacetSearchResultsSlice = {
  // Keys are kept here so at least we have some resemblance to a "schema"
  category_ref: undefined,
  clin_stage: undefined,
  clin_status: undefined,
  conditions: undefined,
  facet_expand_list: undefined,
  location_code: {
    buckets:[]
  },
  monthly_event_counts: null,
  organizations: undefined,
  therapies: undefined,
  trial: undefined
}

window.FacetSearchResultsSlice = FacetSearchResultsSlice
export { FacetSearchResultsSlice }