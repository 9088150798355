const SfTabNav = createReactClass({
  mixins: [Reflux.listenTo(SFTabActionsStore,"change_selected")],
  getInitialState: function(){
    return {
      tab_selected:this.props.tab_selected
    };
  },
  change_selected: function(account, tabSelected) {
    if(account == this.props.id){
      this.setState({
        tab_selected: tabSelected
      });
    }
  },
  switch_tab: function (ev){
    SFTabActions.selectedTab(this.props.id, this.props.tab_content.type);
  },
  render: function(){
    const num = this.props.tab_content.data.length < 100 ? this.props.tab_content.data.length : "99+";
    const liClasses = ["tabbed_nav_li"];
    if(this.state.tab_selected === this.props.tab_content.type){
      liClasses.push('tabbed_current_tab');
    }
    return (
      <li onClick={this.switch_tab} data-ripple="#FFF" className={liClasses.join(" ")}><a><span>{this.props.tab_content.name} ({num})</span></a></li>
    );
  }
});

export default SfTabNav;
