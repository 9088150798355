import React, { useContext } from "react";
import PropTypes from "prop-types";

import { TabContext } from "./TabGroup";
import Tab from "./Tab";

export default function TabAnchor({ href, ...props }) {
  const { selectedTab } = useContext(TabContext);

  const isSelected = props.selected || props.value === selectedTab;

  return (
    <>
      {isSelected ? (
        <Tab {...props} />
      ) : (
        <a
          href={href}
          className={`lds-tab-anchor`}
        >
          <Tab {...props} />
        </a>
      )}
    </>
  );
}

TabAnchor.propTypes = {
  href: PropTypes.string.isRequired,
};
