import SaveSettingsButton from "./saveSettingsButton";

const SaveMutedCompaniesButton = createReactClass({
  mixins: [Reflux.connect(MutedCompaniesStore, "store")],
  render: function() {
    let changing = this.state.store.settingsSubmitted;
    let changed = this.state.store.settingsChanged;
    return (
      <SaveSettingsButton id="save_muted_filter_button" changing={changing} changed={changed} onClick={this.props.onClick}/>
    );
  }
});

window.SaveMutedCompaniesButton = SaveMutedCompaniesButton;
export default SaveMutedCompaniesButton;
