import EventModifyModal from "../eventModifyModal";

const ModifyEvent = createReactClass({
  getInitialState: function(){
    return {
      view: {
        showModal: false
      },
      industry_type: ""
    };
  },
  handleHideModal: function(){
    this.setState({
      view: {
        showModal: false
      }
    });
  },
  handleShowModal: function(){
    this.setState({
      view: {
        showModal: true
      },
      industry_type: EventTableStore.eventData.industry_type
    });
  },
  render: function(){
    const event = _.clone(this.props.event);
    const adminIndustry = _.clone(this.state.industry_type);
    const eventModifyModal = this.state.view.showModal ? <EventModifyModal event={event} admin_industry={adminIndustry} handleHideModal={this.handleHideModal}/> : null;
    return(
      <div className="rspec-modify-event">
        <a className="action_link right" onClick={this.handleShowModal} data-event-id={this.props.event.id}>Modify event...</a>
        {eventModifyModal}
      </div>
    );
  }
});

export default ModifyEvent;
