import SalesforceContactLink from "../salesforceContactLink";

const ZoominfoPersonName = function(props) {
  var isDeliverable = !(props.deliverability === 'undeliverable' || props.deliverability === '');
  var canUseRolodex_v3 = Zymewire.user.feature_tags.includes('rolodex_v3');
  var enableSFAddButon = (canUseRolodex_v3 || isDeliverable);

  var addToSF;

  // In V3 we are allowing users to populate contacts even if they are undeliverable
  if (props.handleAddToSF && enableSFAddButon) {
    addToSF = <button type="submit" className="button-small button-primary rspec_add_contact_to_sf" onClick={props.handleAddToSF}>Add to Salesforce</button>;
  }


  var locAddress;
  if (props.localAddress) {

    var addressString = _.uniq(_.compact([props.localAddress.city, props.localAddress.state, props.localAddress.country]));
    locAddress = <span>{addressString.join(", ")}</span>;
  }

  var revealed_ago;
  if (props.revealed_date) {
    revealed_ago = " - revealed " + moment(props.revealed_date).fromNow();
  }

  var SFLink;
  if ((props.sales_force_contacts || props.sales_force_leads) && canUseRolodex_v3) {
    SFLink = <SalesforceContactLink sales_force_contacts={props.sales_force_contacts} sales_force_leads={props.sales_force_leads} />;
  }

  return (
    <td width="350px">
      <span className="action_link cs_results_name fullstory_excluded">{props.FirstName} {props.LastName} {revealed_ago}</span>
      <br />
      <span className="cs_job_title fullstory_excluded">{props.JobTitle}</span>
      <br />
      <span className="cs_location">{locAddress}</span>
      <div>
        {/*This is confusing af and needs to be better... maybe indicate in the button*/}
        {/*"Add to Salesforce" for contacts not in SF at all*/}
        {/*"Add again to Salesforce" for contacts already in SF*/}
        {/*GJ - 2020.10.27*/}
        {addToSF}
        {SFLink}
      </div>
    </td>
  );
}

export default ZoominfoPersonName;
