import SfTabNav from "./sfTabNav";
import SfTabContent from "./sfTabContent";

const SfLead = createReactClass({
  getInitialState: function(){
    const team = this.props.historical_activities.filter(function (activity) {
      return activity.owner?.zw_user == true;
    })
    return {
      folded_unfolded: 'checked',
      account_id:this.props.Id,
      sf_content: [
        {
          name: 'Open Tasks',
          data: this.props.open_activities,
          type:'tasks'
        },
        {
          name: I18n.t("brand.name")+' Team Interactions',
          data: team,
          type:'team'
        },
        {
          name: 'All Interactions',
          data: this.props.historical_activities,
          type:'all'
        }
      ]
    }
  },
  track_lead: function(){
    SFPanelActions.trackEvent('see_lead_in_salesforce','User clicked link to see a Lead in  SF', {});
    SFPanelActions.trackEngagementEvent('Go to Lead in Salesforce', {});
  },
  render: function(){
    const title = this.props.Title ? ', ' + this.props.Title : "";
    const email = this.props.Email ? <a href={"mailto:" + this.props.Email} target="_top">{this.props.Email}</a> : <a style={{fontWeight:'bold'}} href={this.props.sf_url} onClick={this.track_lead} target="blank">click to edit</a>;
    const description = this.props.Description ? <div className="sf_paragraph">Description: {this.props.Description}</div> : null;
    const createdDate = moment(this.props.CreatedDate).fromNow();
    const ownerIsZw = this.props.owner?.zw_user
      ? ""
      : "(Non " + I18n.t("brand.name") + " User)";
    const ownerName = this.props.owner
      ? this.props.owner.name + " " + ownerIsZw
      : "N/A";

    return (
      <div className="tabbed_content sf_lead_content">
        <div id={this.props.Id + "_lead"} className="sf_lead_row w-1120">
          <div className="sf_account_name sf_account_lead">
            <a
              className="sf_lead_name"
              href={this.props.sf_url}
              onClick={this.track_lead}
              target="blank"
            >
              {this.props.FirstName} {this.props.LastName}
            </a>
            {title} at {this.props.Company}
            <br />
            Email: {email}
          </div>
          <div className="sf_lead_right">
            <span className="sf_bold_label">Registered:</span> {createdDate}
            <br />
            <span className="sf_bold_label">Lead owner:</span>{" "}
            <span id="lead-owner-name">{ownerName}</span>
          </div>
          {description}
        </div>
        <div className="tabbed_nav_div">
          <ul className="sf_leads_nav">
            {this.state.sf_content.map(function (content) {
              return (
                <SfTabNav
                  key={content.type + "_" + this.props.Id}
                  id={this.props.Id}
                  tab_selected={this.props.tab_selected}
                  tab_content={content}
                />
              );
            }, this)}
          </ul>
        </div>
        <div
          id={"sf_panel_content_" + this.props.Id}
          className="tabbed_content_wrapper"
        >
          {this.state.sf_content.map(function (content) {
            // tab_calls++
            return (
              <SfTabContent
                key={content.type + "_" + this.props.Id}
                type={"lead"}
                account_id={this.props.Id}
                tab_selected={this.props.tab_selected}
                tab_content={content}
              />
            );
          }, this)}
        </div>
      </div>
    );
  }
});

export default SfLead;
