const BootstrapRow = (props) => {
  const className = props.fluid ? "row-fluid" : "row"

  return (
    <div className={className}>
      {props.children}
    </div>
  );
};

window.BootstrapRow = BootstrapRow;
export default BootstrapRow;
