import ArticleEventOrg from "./articleEventOrg";

const IncludedOrgList = (props) => {
  if(props.orgs.length < 1 && props.excludedOrgs.length < 1){
    return (
      <p>No organization matches were found</p>
    );
  }
  else if(props.orgs.length < 1 ){
    return (
      <p>No organization are included</p>
    );
  }
  else {
    const sortedOrgs = _.sortBy(props.orgs, function(org){return - org.mention_count});
    const includedOrgList = sortedOrgs.map(function(org){
      return (
        <ArticleEventOrg key={org._id} org={org} toggleHandler={props.toggleOrgHandler} />
      );
    });
    return (
      <ul id={"included_orgs_" + props.article_id} className="event_org_list">{includedOrgList}</ul>
    );
  }
};

export default IncludedOrgList;
