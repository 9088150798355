import React from "react";
import PropTypes from "prop-types";

import Toast from "@javascript/reactComponents/lds/notifications/Toast";

const TOAST_FLASH_TYPE_MAP = {
  alert: "warning",
  error: "critical",
  notice: "info",
  success: "success",
};

export default function FlashToast({ flashMessages = {} }) {
  const toastMessages = [];

  for (const messageType in flashMessages) {
    toastMessages.push(
      <Toast
        title={flashMessages[messageType]}
        key={messageType}
        variant={TOAST_FLASH_TYPE_MAP[messageType]}
      />
    );
  }

  return <div className="lds-toast-stack">{toastMessages}</div>;
}

FlashToast.propTypes = {
  flashMessages: PropTypes.shape({
    notice: PropTypes.string,
    alert: PropTypes.string,
    error: PropTypes.string,
    success: PropTypes.string,
  }),
};

window.FlashToast = FlashToast;

export { TOAST_FLASH_TYPE_MAP };
