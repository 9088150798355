import Reflux from 'reflux';
import ContactSearchAction from "../../stores/actions/contactSearch/contactSearchActions";
import ContactSearchStore from "../../stores/contactSearch/contactSearchStore";
import BootstrapFullWidthRow from "../generic/htmlElements/bootstrapFullWidthRow";
import DeliverabilityMessage from "./deliverabilityMessage";

const GenerateEmailBox = createReactClass({
  getInitialState: function () {
    return {
      is_button_clicked: false,
      hackySearchFlagNasty: this.props.hackySearchFlagNasty
    };
  },
  shouldComponentUpdate: function(nextProps) {
    if (nextProps.hackySearchFlagNasty !== this.state.hackySearchFlagNasty){
       this.setState({
         is_button_clicked: false,
       })
    }
    return nextProps.hackySearchFlagNasty !== this.state.hackySearchFlagNasty;
  },
  UNSAFE_componentWillUpdate(nextProps) {
    this.setState({
      hackySearchFlagNasty: nextProps.hackySearchFlagNasty
    })
  },
  generateEmail: function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.user_zinfo.remaining_for_month > 0) {
      this.setState({
        is_button_clicked: true
      })
      $("body").addClass("wait");
      let params = {
        first_name: this.props.first_name.trim(),
        last_name: this.props.last_name.trim(),
      };
        ContactSearchAction.generateEmail(params)
    }
  },
  render: function () {
    let basedOn = {firstName: this.props.first_name, lastName: this.props.last_name, company: this.props.company_name}
    return (
      <div style={{marginRight: '25px'}}>
        <div>
          <p>
            <b>{basedOn.firstName}</b> <b>{basedOn.lastName}</b> was not found. However, we are able to generate a "Best Guess" email address for this person. We will use what we know about the format of email addresses at <b>{basedOn.company}</b>, build an email address for <b>{basedOn.firstName}</b> <b>{basedOn.lastName}</b>, and check to see if it's valid. Be sure to include first and last name in your search for best results.
          </p>
          <p>
            Please be aware that if the email address is found to be deliverable, there will be a 1 credit deduction.
          </p>
        </div>
        <EmailFormatBestGuessBox checkEmailFormatResults={this.props.check_email_format_result} checkEmailFormat={this.generateEmail} creditsAvailable={this.props.credits_available} isButtonClicked={this.state.is_button_clicked} basedOn={basedOn}/>
      </div>
    )
  }
});

const EmailFormatBestGuessBox = createReactClass({
  mixins: [Reflux.listenTo(ContactSearchStore,"updateResults")],
  getInitialState: function(){
    return {
      checkEmailFormatResults: ContactSearchStore.data.check_email_format_result
    }
  },
  updateResults: function(results) {
    this.setState({
      checkEmailFormatResults: results.check_email_format_result
    });
  },
  render: function(){
    let best_guess;
    if (this.state.checkEmailFormatResults) {
      if(this.state.checkEmailFormatResults.email) {
        best_guess = <DeliverabilityMessage
                        email={this.state.checkEmailFormatResults.email}
                        deliverability_result={this.state.checkEmailFormatResults.deliverability}
                        score={this.state.checkEmailFormatResults.score}
                        generateEmailSection={true}
                        generatedEmail={true}
                      />
      }
      else {
        best_guess = <div><b>Could not find email</b></div>
      }
    }
    else {
      best_guess = <EmailFormatRevealGuessButton checkEmailFormatResults={this.state.checkEmailFormatResults} checkEmailFormat={this.props.checkEmailFormat} creditsAvailable={this.props.creditsAvailable} isButtonClicked={this.props.isButtonClicked} />;
    }
    return(
      <BootstrapFullWidthRow>
        <div className="zi_person_card">
          <table className="zi_person_table" width="100%">
            <tbody>
            <tr className="topRow">
              <td width="75">
                <div style={{marginLeft: '1%', marginTop: '.5%'}}><b>{this.props.basedOn.firstName}</b> <b>{this.props.basedOn.lastName}</b> at <b>{this.props.basedOn.company}</b></div>
              </td>
              <td width="25%">
                {best_guess}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </BootstrapFullWidthRow>
    )
  }
 });

const EmailFormatRevealGuessButton = props => {
  let guessButtonToolTip = 'Use a credit to see their email address';
  if (props.credits_available < 1) {
    guessButtonToolTip = "Sorry, you have no credits left for this month."
  }

  let guessButtonText = props.isButtonClicked ? (props.checkEmailFormatResults ? '' : 'Fetching Email') : 'Reveal Best Guess';
  return(
    <div style={{marginLeft: '40%'}}>
      <button className="button-small button-primary reveal_email_button" onClick={props.checkEmailFormat} title={guessButtonToolTip}>{guessButtonText}</button>
    </div>
  );
}

export default GenerateEmailBox;
