import { useRef, useState } from "react";
import Dropdown, { DropdownTrigger } from "../lds/dropdown/Dropdown";
import DropdownList from "../lds/dropdown/DropdownList";
import Input from "../lds/Input";

export default function CopyToExistingListDropdown({ lists }) {
  const [open, setOpen] = useState(false);

  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState();

  const triggerRef = useRef();

  const handleItemSelected = (item) => {
    $("#add_to_existing_list").removeClass("button-disable"); // necessary until we React+API the whole UX of this
    setSelected(item.label);
    setOpen(false);
  };

  const filteredItems = lists.filter((item) =>
    item.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );
  const hasItems = filteredItems.length > 0;

  const widthValue = triggerRef.current?.getBoundingClientRect().width
  const dropdownMaxWidth = widthValue ? widthValue + "px" : null;

  return (
    <Dropdown
      id="copy_to_existing_list_dropdown"
      size="small"
      ENABLE_LEGACY_DROPDOWN_SUPPORT
      LEGACY_DROPDOWN_ID="to_lists"
      maxWidth={dropdownMaxWidth} // Don't extend beyond parent modal
      maxHeight="300px"
      onItemSelected={handleItemSelected}
      onDropdownClose={() => setOpen(false)}
    >
      <DropdownTrigger>
        <div
          onClick={() => setOpen(true)}
          ref={triggerRef}
        >
          <Input
            helperTextVariant="hidden"
            id="copy_to_existing_list_dropdown_trigger"
            label="Select which existing custom list you wish to add the companies to:"
            labelPosition="top"
            onValueChange={() => {}}
            placeholder="Name of a list"
            readOnly
            showLabel={true}
            size="medium"
            trailingIcon={"chevron-" + (open ? "up" : "down")}
            value={selected}
            width="100%"
          />
        </div>
      </DropdownTrigger>

      <Input
        width="100%"
        size="medium"
        value={search}
        onValueChange={setSearch}
        leadingIcon="search"
        placeholder="Search"
        helperText={!hasItems ? "No search results" : ""}
        helperTextVariant={!hasItems ? "info" : "hidden"}
        helperTextStylesOverride={{
          marginBottom: hasItems ? "" : "var(--dropdown-list-space-paddingB)",
        }}
        forceShowHelperText
        showHelperTextIcon={false}
        showLabel={false}
      />
      <DropdownList items={filteredItems} />
    </Dropdown>
  );
}

window.CopyToExistingListDropdown = CopyToExistingListDropdown;
