import { createContext } from "react";

const TWENTY_YEARS_FROM_NOW = new Date().getFullYear() + 20;

export const DEFAULT_MIN_DATE = new Date(2012, 0, 1);

/** Ensure MAX_YEAR is always even */
export const DEFAULT_MAX_DATE =
  TWENTY_YEARS_FROM_NOW % 2 === 0
    ? new Date(TWENTY_YEARS_FROM_NOW, 0, 1)
    : new Date(TWENTY_YEARS_FROM_NOW + 1, 0, 1);

// TODO as part of CALENDARCLEANUP: rename this file, alpha the args
export const CalendarContext = createContext({
  activeOverlay: null,
  minDate: DEFAULT_MIN_DATE,
  maxDate: DEFAULT_MAX_DATE,
  dismissOverlay: () => {},
  setActiveOverlay: () => {},
  toggleMonthOverlay: () => {},
  toggleYearOverlay: () => {},
});

export const CalendarProvider = CalendarContext.Provider;
