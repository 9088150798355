import FacetSectionToggle from "./facetSectionToggle"

const FacetListHeader = createReactClass({

  handleToggleClick: function(event) {

    event.preventDefault();
    event.stopPropagation();

    let facetHeader = $(event.target).parents('.facet_header');
    let facetPanel = facetHeader.parent();
    let facetBody = facetPanel.find('.facet_body');
    let facetHeaderId = facetHeader.attr('id');
    let trackingEventText = 'closing_facet_entity_list';
    let trackingText = 'Closed facet list';

    if(facetBody.is(':visible')){
      $(facetBody).slideUp('fast');
      Zymewire.facet_expand_list = _.reject(Zymewire.facet_expand_list, function (facet_ref) {
        return facet_ref === facetHeaderId;
      });
    } else {
      Zymewire.facet_expand_list.push(facetHeaderId);
      $(facetBody).slideDown('fast');
      trackingEventText = 'opening_facet_entity_list';
      trackingText = 'Opened facet list';
    }

    track(trackingEventText, trackingText, {entity_type: facetHeaderId});
    facetHeader.find('.toggle_icon').toggle(0);

    this.props.onToggle && this.props.onToggle()
  },

  componentDidMount: function() {
    const facetHeader = $(this.refs.facetHeader)
    const facetPanel = facetHeader.parent('.facet_panel');
    const facetBody = facetPanel.find('.facet_body');
    const facetHeaderId = facetHeader.attr('id');
    const show = this.props.show
    if (!_.contains(Zymewire.facet_expand_list, facetHeaderId) || (show && !show)) {
      facetBody.hide();
    }
    else {
      facetHeader.find('.icon_close').show();
      facetHeader.find('.icon_open').hide();
    }
  },

  render: function() {
    return (
      <div id={this.props.id} className='facet_header' onClick={(event) => this.handleToggleClick(event)} ref='facetHeader'>
        <h4 className="hand_hover" title={this.props.title}>
          {this.props.facetName}
        <FacetSectionToggle />
        </h4>
      </div>
    );
  }
});

export default FacetListHeader;
