import Reflux from "reflux";

import DragActions from "../actions/pivotLists/dragActions";

const DragStore = Reflux.createStore({
  listenables: DragActions,
  init: function() {
    this.state = {type:null, over:false, data:{}}
  },
  onSetDragged: function(type, over, data){
    this.state = {type:type, over:over, data:data};
    this.trigger(this.state);
  },
  onSetDragOver: function(over){
    this.state.over = over;
    this.trigger(this.state);
  },
});

window.DragStore = DragStore;
export default DragStore;
