import Button from "@javascript/reactComponents/lds/Button";

import LegacyModalService from "@javascript/globals/LegacyModalService";

import {
  ModalBody,
  ModalFooter,
} from "@javascript/reactComponents/lds/modal/Modal";

import {
  SalesforceModalLoading,
} from "@javascript/reactComponents/salesforce/SalesforceModalMessages";
import SfContactButton from "@javascript/reactComponents/companyDashboard/salesforce/sfContactButton";

const SfFormSelector = createReactClass({
  getInitialState() {
    return {
      salesforce_accounts: null
    }
  },
  getSalesforceAccounts() {
    let url = "/company/" + this.props.org_id + "/retrieve_salesforce_accounts";
    let self = this;

    let loadingTimeout = setTimeout(() => {
      loadingTimeout = undefined;
    }, 1000);

    $.ajax({
      url: url,
      type: "POST",
      data: { org_id: this.props.org_id },
      headers: {
        // form_authenticity_token TODO: this is ignored, yet somehow the header is still set (deprecate this header and it still works)
        "X-CSRF-Token": "<%= form_authenticity_token.to_s %>"
      },
      success: function(results) {
        let loadingInterval = setInterval(() => {
          if (loadingTimeout === undefined) {

            if (results.response === false) {
              alert("There was an error generating the form");
            } else {
              self.setState({ salesforce_accounts: results });
            }

            clearInterval(loadingInterval);
          }
        }, 500);
      },
      error: function(e) {
        console.error(e);
        if(e.status === 401){
          location.reload();
        }
      }
    });
  },
  choseLead: function(e){
    e.preventDefault();
    this.props.parent_method("lead", null);
  },

  render() {
    const holderStyle = {
      margin: "20px"
    };
    const buttonPad = {
      marginBottom: "20px"
    };
    if (this.state.salesforce_accounts) {
      return (
        <>
          <ModalBody className="lds-modal-body-height salesforce-form-selector-buttons">
            {
              this.state.salesforce_accounts.sf_accounts.map(function(i, index){
                return (
                  <SfContactButton key={index} {...i} parent_method={this.props.parent_method} />
                )
              }.bind(this))
            }
            <Button
              onClick={this.choseLead}
              size="medium"
              variant="secondary"
            >
              Create New Lead
            </Button>
          </ModalBody>
          <ModalFooter align="flex-end">
            <Button
              onClick={LegacyModalService.closeModal}
              size="medium"
              variant="primary"
            >
              Cancel
            </Button>
          </ModalFooter>
        </>
      )
    }
    else {
      this.getSalesforceAccounts();
      return <SalesforceModalLoading message="Please wait while we load the options..." />
    }
  }
});

export default SfFormSelector;
