import PaginationLinks from "./paginationLinks";

const PaginationHolder = createReactClass({
  render: function(){
    if(this.props.pages < 2){
      return false;
    }
    const pages = [];
    let startPage = 0;
    const leftRange = 5;
    let endPage = this.props.pages;
    const numberOfPages = this.props.pages;
    const paginationClasses = this.props.paginationClasses;
    if(this.props.displaySize < numberOfPages){
      endPage = this.props.displaySize;
      if(startPage != this.props.currentPage){
        startPage = this.props.currentPage - leftRange;
        if(startPage < 0){
          startPage = 0;
        }
        endPage = startPage + this.props.displaySize;
        if(endPage > numberOfPages){
          endPage = numberOfPages;
        }
      }
    }
    for(let i = startPage; i < endPage; i++){
      const active = i == this.props.currentPage
      pages.push(<PaginationLinks key={i} changePage={this.props.changePage} active={active} page_number={i} />);
    }
    return (
      <div className={paginationClasses.join(" ")}>
        <ul>
          {pages}
        </ul>
      </div>
    )
  }
});

window.PaginationHolder = PaginationHolder;
export default PaginationHolder;
