import React, { useState } from "react";
import CheckboxItem, {
  CHECKBOX_STATES,
} from "../lds/selection_controls/CheckboxItem";
import NewEventChip from "../shared/chips/NewEventChip"

function HomeFeedTableRow({ feed, onSelected }) {
  const feedUrl = `/feeds/${feed.id}/display_saved_feed`;

  const goToFeed = () => {
    window.location = feedUrl;
  };

  return (
    <tr
      className={`home-feed-row ${feed.hidden ? "home-feed-row-hidden" : ""}`}
    >
      <td>
        <p>
          <a href={feedUrl}>{feed.name}</a>
        </p>
      </td>
      <td className="home-feed-row-chip" onClick={() => goToFeed(feed.id)}>
        <NewEventChip label={`${feed.new_event_count} events`} size="small" />
      </td>
      <td id={`feed-checkbox-${feed.id}`}>
        <CheckboxItem
          selection={
            feed.hidden ? CHECKBOX_STATES.unselected : CHECKBOX_STATES.selected
          }
          onClick={onSelected}
          value={feed.id}
        />
      </td>
    </tr>
  );
}

function HomeFeedTableBody({ initialFeeds = [] }) {
  const [feeds, setFeeds] = useState(initialFeeds);

  const onFeedCheckboxSelected = (value) => {
    change_home_page_setting(value);
    setFeeds(
      feeds.map((feed) => {
        return { ...feed, hidden: feed.id === value || feed.hidden }
      })
    );
  };

  return feeds.map((feed) => {
    return (
      <HomeFeedTableRow
        feed={feed}
        onSelected={onFeedCheckboxSelected}
        key={feed.id}
      />
    );
  });
}

export default HomeFeedTableBody;

window.HomeFeedTableBody = HomeFeedTableBody;
