import SfFormFields from "@javascript/reactComponents/companyDashboard/salesforce/sfFormFields";
import SfTwoColumnFormFields from "@javascript/reactComponents/companyDashboard/salesforce/SfTwoColumnFormFields";

const SfFormFieldsCore = ({
  errors,
  fields,
}) => {

  return (
    <SfTwoColumnFormFields
      className={"sf-form-fields-core"}
      Component={SfFormFields}
      errors={errors}
      fields={fields}
    />
  );
};

SfFormFieldsCore.propTypes = {
  errors: SfFormFields.propTypes.errors,
  fields: SfFormFields.propTypes.fields,
};

export default SfFormFieldsCore;
