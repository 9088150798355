import Reflux from "reflux";
import ContactSearchStore from "../../stores/contactSearch/contactSearchStore";
import ZoomInfoHeader from "./zoomInfo/zoomInfoHeader";
import CompanyDetailsPanel from "./companyDetailsPanel";
import ExistingContacts from "./existingContacts";
import ZoominfoSearch from "./zoomInfo/zoominfoSearch";

const ContactSearchPanel = createReactClass({
  mixins: [Reflux.connect(ContactSearchStore)],
  getInitialState: function () {
    return ContactSearchStore.data;
  },
  render: function () {
    if(this.state.user_zinfo) {
      return (
        <div>
          <ZoomInfoHeader {...this.state.user_zinfo} />
          <CompanyDetailsPanel company_name={this.state.company_name} {...this.state.company_details} />
          <ExistingContacts {...this.state} />
          <ZoominfoSearch   {...this.state}/>
        </div>
      );
    }
    else{
      return (<div><p>Loading</p></div>);
    }
  }
});

window.ContactSearchPanel = ContactSearchPanel;
export default ContactSearchPanel;
