import { useState } from "react";
import Dropdown, { DropdownTrigger } from "../lds/dropdown/Dropdown";
import DropdownList from "../lds/dropdown/DropdownList";
import Input from "../lds/Input";

const EventCheckEmailSettings = ({
  currentSetting,
  id
}) => {

  let settings = [
    {
      label: "OFF",
      leadingIcon: "off",
      selected: false,
      value: "Off",
    },
    {
      label: "ON: Daily",
      leadingIcon: "email",
      selected: false,
      value: "daily",
    },
    {
      label: "ON: Weekly",
      leadingIcon: "email",
      selected: false,
      value: "weekly",
    },
  ];

  const currentItems = settings.map((setting) => ({
    ...setting,
    selected: setting.value === currentSetting,
  }));

  const initialSetting = currentItems.find(
    (setting) => setting.value === currentSetting
  );

  const [selected, setSelected] = useState(
    initialSetting ? initialSetting : currentItems[0]
  );
  const [items, setItems] = useState(currentItems);
  const [open, setOpen] = useState(false);

  const setEventCheckEmailSetting = (item) => {
    setOpen(false)

    if (item.value !== selected.value) {
      const updatedSettings = items.map((setting) => ({
        ...setting,
        selected: setting.value === item.value,
      }));

      const matchingSetting = currentItems.find(
        (currentItem) => currentItem.value === item.value
      );

      setSelected(matchingSetting);
      setItems(updatedSettings);

      const url = "/feeds/" + id + "/change_email_notification";

      $.ajax({
        url: url,
        type: "POST",
        data: {
          notification_settings: item.value.toLowerCase(), // this is a tricky one -- this initial comes in as "Off" and needs to be sent back as "off" or it doesn't work.
        },
        error: function (xhr, ajaxOptions, thrownError) {
          if (xhr.status === 401) {
            alert("Un-authorized status received");
            window.location = "/users/sign_in";
          } else {
            console.log(thrownError);
          }
        },
      });
    }
  };

  const triggerStyles = {
    marginBottom: "10px",
    maxWidth: "170px",
  };

  const leadingIcon = selected.leadingIcon + "-filled";

  return (
    <Dropdown
      id={`"event_check_email_settings_dropdown_${id}"`}
      size="small"
      onItemSelected={(item) => setEventCheckEmailSetting(item)}
      onDropdownClose={() => setOpen(false)}
    >
      <DropdownTrigger>
        <div style={triggerStyles} onClick={() => setOpen(true)}>
          <Input
            id={`event_check_email_settings_input_${id}`}
            leadingIcon={leadingIcon}
            readOnly
            value={selected.label}
            onValueChange={() => {}}
            size="small"
            width="100%"
            trailingIcon={"chevron-" + (open ? "up" : "down")}
          />
        </div>
      </DropdownTrigger>
      <DropdownList items={items} />
    </Dropdown>
  );
};

export default EventCheckEmailSettings;
