import Reflux from "reflux";

import RelatedTherapiesActions from "./actions/relatedTherapiesActions"

const RelatedTherapiesStore = Reflux.createStore({
  listenables: RelatedTherapiesActions,
  init: function(props){
    this.data = {
      therapy_relationships: { },
      all_related_therapies: [],
      paginated_therapies: { },
      therapies_reported: new Set(),      // Keep track of all therapy IDs that have been reported as mismatch
      therapies_loaded: false,
      therapies_per_page: 10
    };
  },
  onInitialState: function(data){
    this.data  = data;
    this.trigger(this.data);
  },
  onGetTherapiesForOrg: function (org_id) {
    var url = `/company/${org_id}/get_related_therapies`;

    // If we already have therapy data, return it
    if(this.data.therapies_loaded) { return this.data.related_therapies; }

    // Else, request it and memoize
    $.get(url,
      function(res) { this._updateRelatedTherapies(res); }.bind(this)
    );
  },
  onReportMismatchTherapy: function(therapy) {
    var activity = 'report_therapy_mistmatch';
    var message = 'Incorrect therapy relationship';
    var data = {
      org_id: this.data.therapy_relationships.org_id,
      org_name: this.data.therapy_relationships.org_name,
      therapy_id: therapy.id,
      therapy_name: therapy.name
    };
    track(activity, message, data);

    // Acknowledge this therapy as being reported
    this.data.therapies_reported.add(therapy.id);
    this.trigger(this.data);
  },
  _updateRelatedTherapies: function(data) {
    this.data = {
      ...this.data,
      therapy_relationships: data,
      all_related_therapies: data.related_therapies,
      therapies_loaded: true,
      total_num_therapies: data.total_therapies
    }

    this._paginateRelatedTherapies();
    this.trigger(this.data);
  },
  _paginateRelatedTherapies: function() {
    var paginated_therapies = { };
    var per_page = this.data.therapies_per_page;
    var all_therapies = this.data.all_related_therapies;

    // Keep track of what page we're on
    var index = 1

    // Assign a therapy data to each page
    for(var i = 0; i < all_therapies.length; i+=per_page){
      paginated_therapies[index] = all_therapies.slice(i, i + per_page)
      index += 1;
    }
    this.data.paginated_therapies = paginated_therapies;
  }
});

window.RelatedTherapiesStore = RelatedTherapiesStore;
export default RelatedTherapiesStore;
