const EventCreatedByCell = (props) => {
  let text = "";
  if (props.event.created_by) {
    text = props.event.created_by;
  }
  return (
    <td className="rspec-event-created-by-cell">{text}</td>
  );
};

export default EventCreatedByCell;
