import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import DatePicker from "@javascript/reactComponents/lds/datepicker/DatePicker";
import Dropdown, {
  DropdownTrigger,
} from "@javascript/reactComponents/lds/dropdown/Dropdown";

const FlagEventDatePicker = ({
  className = "",
  id = "flag_event_reminder",
  direction,
  flaggedDate,
  isInThePast,
  onDateSelect = () => {},
}) => {
  const [date, setDate] = useState(flaggedDate);
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [pickerPlacement, setPickerPlacement] = useState("bottom");

  const componentRef = useRef(null);

  const formattedDate = moment(date).format("MMMM DD, YYYY");

  const getOptimalPlacement = () => {
    const { current } = componentRef;
    if (!current) return "bottom";

    const rect = current.getBoundingClientRect();
    const { innerWidth: viewportWidth, innerHeight: viewportHeight } = window;

    const DATEPICKER_WIDTH = 330;
    const DATEPICKER_HEIGHT = 430;

    const spaceBottom = viewportHeight - rect.bottom;
    const spaceLeft = rect.left;
    const spaceTop = rect.top;
    const spaceRight = viewportWidth - rect.right;

    // Check placement options in order of preference
    if (spaceBottom >= DATEPICKER_HEIGHT) return "bottom";
    if (spaceLeft >= DATEPICKER_WIDTH) return "left";
    if (spaceTop >= DATEPICKER_HEIGHT) return "top";
    if (spaceRight >= DATEPICKER_WIDTH) return "right";

    // If no space is available, default to bottom
    return "bottom";
  };

  const checkViewport = () => {
    let placement = getOptimalPlacement();

    // added for Storybook display
    if(direction) {
      placement = direction;
    }
    setPickerPlacement(placement);
  };

  useEffect(() => {
    checkViewport();
    window.addEventListener("resize", checkViewport);
    return () => {
      window.removeEventListener("resize", checkViewport);
    };
  }, [isDatePickerVisible]);

  useEffect(() => {
    setDate(flaggedDate);
  }, [flaggedDate]);

  const toggleDatePicker = () => {
    setIsDatePickerVisible(!isDatePickerVisible);
  };

  const handleDateSelect = ([selectedDate]) => {
    setDate(selectedDate);
    setIsDatePickerVisible(false);
    onDateSelect(selectedDate);
    dropdownRef.current.close();
  };

  const renderContent = () => {
    if (!date) {
      return <a id={id} className={"local_link fancy_link"} onClick={toggleDatePicker}>at a future date.</a>;
    }

    if (isInThePast) {
      return <a id={id} className={"local_link fancy_link"} onClick={toggleDatePicker}>Send another reminder.</a>;
    } else {
      return <a id={id} className={"local_link fancy_link"} onClick={toggleDatePicker}>{formattedDate}</a>;
    }
  };

  const prefixText = () => {
    if (!date) {
      return "Send yourself a reminder via email";
    }

    if (isInThePast) {
      return `A reminder was sent ${formattedDate}.`;
    } else {
      return "An email reminder will be sent";
    }
  };

  const tomorrow = useMemo(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);

  const dropdownRef = React.createRef();

  const classNames = ["flag-event-date-picker"];

  if (className) {
    classNames.push(className);
  }

  return (
    <div ref={componentRef} className={classNames.join(" ")}>
      <span>{prefixText()}</span>
      <Dropdown
        align="center"
        bodyStyles={{border: "none"}}
        gap="var(--datePicker-space-margin)"
        popoverPosition={pickerPlacement}
        ref={dropdownRef}
      >
        <DropdownTrigger>&nbsp;{renderContent()}</DropdownTrigger>
        <DatePicker
          dateStart={date}
          minDate={tomorrow}
          onDateSelect={handleDateSelect}
        />
      </Dropdown>
    </div>
  );
};

FlagEventDatePicker.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  direction: PropTypes.string,
  flaggedDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.oneOf([null, undefined])
  ]),
  isInThePast: PropTypes.bool,
  onDateSelect: PropTypes.func,
};

export default FlagEventDatePicker;
