import SfAccountForm from "./sfAccountForm";
import SfLeadForm from "./sfLeadForm";
import SfContactForm from "./sfContactForm";
import SfFormSelector from "./sfFormSelector";


/**
 *  SfFormModal
 *  -----------------------
 *  The parent component that establishes a modal which would contain
 *  the generated form. The body of the modal would contain either
 *  one of two child components depending on which this.props.form
 *  (if any) was selected.
 *
 *  Example
 *  -------
 *  if the form is 'account' then it would render the <Account/> form.
 *  Else if a form wasn't set, then it would render the
 *  <SfFormSelector/> component.
 *
 *  Currently we have three types of form for the Salesforce integration:
 *      - SfAccountForm
 *      - SfLeadForm
 *      - SfContactForm
 */

const SfFormModal = createReactClass({
  getInitialState(){
    return {
      form: this.props.form ? this.props.form : null,             // The type of form (account, lead, or contact)
      sf_account_id: null
    };
  },
  updateFormState(form, sfOrgId) {
    const mixpanelAction = "add to salesforce modal triggered";
    const mixpanelActionType = form === "lead" ? "add lead" : "add contact";
    const mixpanelActionLocation = "from contact search";
    track_engagement(mixpanelAction, { type: mixpanelActionType, location: mixpanelActionLocation });
    this.setState({ form: form, sf_org_id: sfOrgId });
  },
  render(){
    if(this.state.form){
      if (this.state.form === "account") return  <SfAccountForm org_id={ this.props.org_id }/>;
      if (this.state.form === "lead") return <SfLeadForm org_id={ this.props.org_id } zi_contact_id={ this.props.zi_contact_id }/>;
      if (this.state.form === "contact") return <SfContactForm org_id={ this.props.org_id } zi_contact_id={ this.props.zi_contact_id } sf_org_id={ this.state.sf_org_id }/>;
    }
    else{
      return <SfFormSelector org_id={ this.props.org_id } parent_method={ this.updateFormState }/>
    }
  }
});

window.SfFormModal = SfFormModal;
export default SfFormModal;
