import LegacyModalService from "@javascript/globals/LegacyModalService";
import {
  Modal,
  ModalHeader,
} from "@javascript/reactComponents/lds/modal/Modal";
import ListModalBody from "./listModalBody";
import ListModalFooter from "./listModalFooter";
import ListModalSuccess from "./listModalSuccess";

const ListModalPanel = createReactClass({
  mixins: [Reflux.connect(ListModalActionStore)],
  getInitialState: function () {
    return ListModalActionStore.listModalData;
  },
  componentDidMount: function () {
    ListModalAction.initialLoad({
      dataLoaded: true,
      sponsorList: this.props.sponsorList,
      allOrgList: this.props.allOrgList,
      total_sponsors: this.props.total_sponsors,
      all_org_count: this.props.all_org_count,
      query: this.props.query,
      listName: this.props.listName,
      base_query: this.props.base_query,
      map_display: this.props.map_display,
    });
  },
  render: function () {
    if (this.state.dataLoaded == false) {
      return false;
    }

    return (
      <Modal
        open={true}
        id="new_list_react_body"
        onClose={LegacyModalService.closeModal}
        distanceFromTopViewport="75px"
      >
        <ModalHeader>New list from current search</ModalHeader>

        {this.state.succeeded ? (
          <ListModalSuccess listId={this.state.new_list_id} />
        ) : (
          <>
            <ListModalBody {...this.state} />
            <ListModalFooter {...this.state} />
            <div className="tour-overlay"></div>
          </>
        )}
      </Modal>
    );
  },
});

window.ListModalPanel = ListModalPanel;
export default ListModalPanel;
