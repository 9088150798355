import createReactClass from "create-react-class";

import FlaggingEventModalBody from "@javascript/reactComponents/flagged_events/flaggingEventModalBody";
import FlaggingEventReminder from "@javascript/reactComponents/flagged_events/flaggingEventReminder";
import LegacyModalService from "@javascript/globals/LegacyModalService";

import { markFlagEventButtonAsFlagged } from "@javascript/reactComponents/utilities/flagEventUtilities";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@javascript/reactComponents/lds/modal/Modal";


const FlaggingEventPanel = createReactClass({
  getInitialState: function () {
    return { userList: this.props.users, comment: "" };
  },
  doSubmit: function (e) {
    e.stopPropagation();
    e.preventDefault();
    $("body").addClass("wait");
    var url = "/flagged_events/" + this.props.event.id + "/flag_for";
    if (this.props.flag_for === 'others') {
      url = "/flagged_events/" + this.props.flagged_event_id + "/flag_for_others";
    }
    var data = {
      comment: this.state.comment,
      flagged_users: _.map(_.filter(this.state.userList, function (user) { return user.checked; }), function (u) { return u.id; })
    };
    $.ajax({
      url: url,
      type: 'POST',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token
      },
      data: data,
      success: function (result) {
        if (this.props.flag_for === 'others') {
          if (result.success === true) {
            display_comment_panel(this.props.flagged_event_id, result.confirmation_display);
            update_status_panel(this.props.flagged_event_id, 'update');
            LegacyModalService.closeModal();
          }
          else {
            $("body").removeClass("wait");
            LegacyModalService.closeModal();
          }
        }
        else {
          $("body").removeClass("wait");
          if (result.confirmation_display === null) {
            LegacyModalService.closeModal();
          }
          else {
            LegacyModalService.openModal(LDSFlagEventModalSuccess, { notifiedUsers: result.notified_users || [] })
            if (result.flagged_for_user) {
              const selector = '#flagged_' + this.props.event.id;
              markFlagEventButtonAsFlagged(selector);

              if (typeof events !== 'undefined') {
                var event = _.find(events, function (event) {
                  return event.id == id;
                });
                event.flagged = true;
              }
            }
          }
        }
      }.bind(this)
    });
    return false;
  },
  toggleUser: function (user_id) {
    var user = _.find(this.state.userList, function (user) { return user.id == user_id; });
    if (user.currently_flagged === false) {
      user.checked = !user.checked;
      this.setState({ userList: this.state.userList });
    }
  },
  updateComment: function (comment) {
    this.setState({ comment: comment });
  },
  render: function () {
    return (
      <Modal
        id="flag_event_modal"
        open={true}
        onClose={LegacyModalService.closeModal}
        distanceFromTopViewport="75px"
      >
        <ModalHeader>Flag an event</ModalHeader>

        <ModalBody>
          <FlaggingEventModalBody
            {...this.state}
            {...this.props}
            toggleUser={this.toggleUser}
            updateComment={this.updateComment}
          />
        </ModalBody>

        <ModalFooter align="flex-end">
          <FlaggingEventReminder onSubmit={this.doSubmit} />
        </ModalFooter>
      </Modal>
    );
  }
});

window.FlaggingEventPanel = FlaggingEventPanel;
export default FlaggingEventPanel;
