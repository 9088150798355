const FlagContactButton = createReactClass({
  getInitialState: function () {
    return {
      flagging: false
    };
  },
  flagContact: function(e){
    e.preventDefault();
    e.stopPropagation();
    if(this.state.flagging === false){
      this.setState({
        flagging: true
      });
      FlagContactActions.flagContact({
          orgName: this.props.orgName,
          organizationId: this.props.organizationId,
          contactId: this.props.contactId,
          personId: this.props.personId,
          deliverability: this.props.deliverability
        }
      )
    }
  },
  render: function () {

    const flagButtonClasses = ['button-small', 'button-secondary', 'rspec_flag_contact'];
    let buttonText = 'Click for credit if your email bounced';

    if(this.state.flagging === true) {
      buttonText = 'flagging...';
      flagButtonClasses.push('button-disable');
    }

    let flagContact = <button className={flagButtonClasses.join(' ')} title={'Click to receive a credit for bad contacts.'} onClick={this.flagContact}>{buttonText}</button>

    if(this.props.flagged === true) {
      flagContact = "Credit request has been received and this contact is now under review."
    }

    const input_div_style = {position:'relative', float:'left', marginRight:'10px'};

    return (
      <span style={input_div_style}>
        {flagContact}
      </span>
    )

  }
});

export default FlagContactButton;
