import EditEventOrgEntities from "./editEventOrgEntities";
import EditEventNonOrgEntities from "./editEventNonOrgEntities";
import EditEventProperties from "./editEventProperties";
import AddClinicalTrialPropertyPanel from "./addClinicalTrialPropertyPanel";
import { eventEditModalStyle } from "../../../config/administrateEvent";

const EditEventEntities = (props) => {
  const orgs = _.filter(props.event.entities, function (entity) {
    return entity.ent_type === "Organization";
  });
  const otherEntities = _.filter(props.event.entities, function (entity) {
    return entity.ent_type !== "Organization";
  });
  return (
    <table style={eventEditModalStyle.table}>
      <thead>
        <tr>
          <th style={eventEditModalStyle.header}>Involving</th>
          <th style={eventEditModalStyle.header}>Entities and properties</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={eventEditModalStyle.cell}>
            <EditEventOrgEntities orgs={orgs}/>
          </td>
          <td style={eventEditModalStyle.cell}>
            <EditEventNonOrgEntities ents={otherEntities}/>
            <EditEventProperties {...props} />
            <AddClinicalTrialPropertyPanel {...props} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default EditEventEntities;
