const EditNonOrgEntity = (props) => {
  const removeEntity = (e) => {
    e.preventDefault();
    EventModifyModalAction.removeEntity(props.ent.rec_id);
  };
  const entityProperties = props.ent.prop_string ? " " + props.ent.prop_string : null;
  return (
    <span>
        <a onClick={removeEntity} className="action_link non_org_remove_link" id={"rspec_remove_" + props.ent.rec_id}>{props.ent.name} <i className="icon-remove"/></a>{entityProperties};
      </span>
  );
};

export default EditNonOrgEntity;
