const SaveSettingsButton = (props) => {
  let txt = props.changing ? "Saving Changes" : "Save Changes";
  let btn = props.changed ? <button id={props.id} type="submit" className="button-small button-primary button_spaced_10_top" onClick={props.onClick}>{txt}</button> : <button id={props.id} type="submit" className="button-small button-disable button_spaced_10_top">{txt}</button>;
  return (
    <div className="right">
      {btn}
    </div>
  );
};

window.SaveSettingsButton = SaveSettingsButton;
export default SaveSettingsButton;
