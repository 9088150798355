// locationSelector.jsx and territorySelector are near identical and should be refactored into one

import TerritorySelectorHeader from "./territorySelectorHeader";
import GenericAutocomplete from "../generic/autocomplete/genericAutocomplete";
import TerritorySelectorPanels from "./territorySelectorPanels";
import {constructSelectableLocationList, flattenLocationList} from "../../helpers/locations";

const TerritorySelector = createReactClass({
  mixins: [Reflux.connect(TerritoriesStore)],
  getInitialState: function () {
    return TerritoriesStore.data;
  },
  UNSAFE_componentWillMount:function(){
    const referrer = this.props.referrer ? this.props.referrer : "/settings";
    TerritoriesAction.initialState({fullLocationList:_.sortBy(Zymewire.region_list.fullLocationList, 'name'), userTerritoryList:this.props.userTerritoryList, changed: false, referrer: referrer});
  },
  select_location: function (location, e){
    e.stopPropagation();
    const currentSelectableLocations = this.getCurrentSelectableLocations();
    for(let loc of currentSelectableLocations){
      // Hacky solution to fix duplicate name issue
      // We only have 2 occurrences of duplicate names (Georgia and Puerto Rico) in our selectable list as of June 2020
      // Better solution would be to give each location a unique name in this.state.fullLocationList
      if(
        loc.name === location ||
        loc.code === "US_GA" && location === "Georgia, United States of America" ||
        loc.code === "US_PR" && location === "Puerto Rico, United States of America"
      ){
        TerritoriesAction.territorySelected(loc);
        return;
      }
    }
  },
  getCurrentSelectableLocations: function () {
    // We pass a deep copy of fullLocations, so that any modifications we make to our copy won't effect the original
    let copyFullLocation = JSON.parse(JSON.stringify(this.state.fullLocationList));
    return flattenLocationList(constructSelectableLocationList(copyFullLocation, this.state.userTerritoryList));
  },
  render: function () {
    let locationNames = this.getCurrentSelectableLocations();
    // Get only the names of locations from our array of tuples
    locationNames = locationNames.map(function(location){
      // Hacky solution to fix duplicate name issue
      // We only have 2 occurrences of duplicate names (Georgia and Puerto Rico) in our selectable list as of June 2020
      // Better solution would be to give each location a unique name in this.state.fullLocationList
      if(location.code === "US_GA") {
        return "Georgia, United States of America"
      } else if (location.code === "US_PR") {
        return "Puerto Rico, United States of America"
      } else {
        return location.name
      }
    });
    return (
      <div className="container w-1200">
        <TerritorySelectorHeader {...this.state} />
        <GenericAutocomplete onClick={this.select_location} suggestions={locationNames} classNames={["autocomplete_base", "autocomplete_territory"]} placeHolder={"country or state"}  width="375px" size="small" />
        <TerritorySelectorPanels {...this.state} />
      </div>
    );
  }
});

window.TerritorySelector = TerritorySelector;
export default TerritorySelector;
