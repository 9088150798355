import Reflux from "reflux";
import createReactClass from "create-react-class";
import SalesforceListsStore from "../../../stores/lists/SalesforceListsStore";
import SalesforceListsActions from "../../../stores/actions/lists/SalesforceListsActions";
import CategoryButtonGroup from "./CategoryButtonGroup";
import SearchFilters from "./SearchFilters";
import ListSearchDescription from "./ListSearchDescription"
import ListPageFooter from "./ListPageFooter";
import ListTable from "./ListTable";
import { DEFAULT_CATEGORY } from "../../../constants/lists/list_constants";
import NoLists from "./NoLists";

const SalesforceLists = createReactClass({
  mixins: [Reflux.connect(SalesforceListsStore)],
  getInitialState() {
    return SalesforceListsStore.data;
  },
  render() {
    const {
      lists,
      currentCount,
      selectedCategory,
      categories,
      sortOptions,
      selectedSortOption,
      totalCount,
      params: { name },
    } = this.state;

    if (!lists) return null;

    const blankSearch = name === "";
    const showTag = selectedCategory == DEFAULT_CATEGORY;

    let footerText = `Showing ${currentCount} of ${categories[selectedCategory]} lists `;
    if (!blankSearch) {
      footerText += "based on search";
    }

    const hasLists = categories[selectedCategory] > 0;
    const hasFilteredLists = lists.length > 0;

    const listSearchResults = <>
      <ListTable
        lists={lists}
        editable={false}
        showHeader
        showTag={showTag}
      />

      <ListPageFooter
        disableLoadMore={lists.length === totalCount}
        footerText={footerText}
        showSelectAll={false}
        currentCount={currentCount}
        onLoadMore={SalesforceListsActions.loadMore}
      />
    </>

    return (
      <>
        <CategoryButtonGroup
          categories={categories}
          selected={selectedCategory}
          onCategorySelected={SalesforceListsActions.selectCategory}
        />
        <div style={{ marginBottom: "var(--space-600)" }} />

        {hasLists ? (
          <div className="container w-1200">
            <SearchFilters
              searchValue={name}
              sortOptions={sortOptions}
              selectedSortOption={selectedSortOption}
              onSortOptionUpdate={SalesforceListsActions.sort}
              onSearchValueUpdate={SalesforceListsActions.searchName}
            />
            { !blankSearch && (
              <ListSearchDescription
                searchValue={name}
                selectedCategory={selectedCategory}
                onClearSearch={SalesforceListsActions.clearSearch}
              />
            )}
            { hasFilteredLists ? listSearchResults : <NoLists /> }
          </div>
        ) : (
          <NoLists />
        )}
      </>
    );
  },
});

export default SalesforceLists;
