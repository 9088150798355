import PropTypes from "prop-types";

import { useDatePickerContext } from "@rehookify/datepicker";

export default function CalendarDate({
  onDateSelect = () => {},
  rehookifyDateDTO,
}) {

  const {
    data: { calendars },
    propGetters: { addOffset, subtractOffset },
  } = useDatePickerContext();

  const firstDayOfCurrentMonth = calendars[0].days.find(day => day.inCurrentMonth).$date

  // interface between rehookifyDateDTO (which we do not control) and an
  // appropriately named set of variables we can use

  const dateObject = rehookifyDateDTO.$date;

  const inCurrentMonth = rehookifyDateDTO.inCurrentMonth;
  const isDisabled = rehookifyDateDTO.disabled;
  const isSelected = rehookifyDateDTO.selected;
  const isToday = rehookifyDateDTO.now;

  const thisDay = dateObject.getDate();
  const thisMonthIndex = dateObject.getMonth();


  const toggleDateSelection = () => {

    if (!inCurrentMonth) {
      if (dateObject < firstDayOfCurrentMonth) {
        subtractOffset({ months: 1 }).onClick();
      } else {
        addOffset({ months: 1 }).onClick();
      }
    }

    onDateSelect?.(rehookifyDateDTO);
  }

  const id = `calendarDate-${thisMonthIndex}-${thisDay}`;

  const classNames = [`lds-calendar-date${inCurrentMonth ? '-inside-month' : '-outside-month'}`];


  if (isToday) {
    classNames.push("today");
  }

  if (isDisabled) {
    classNames.push("disabled");
  }

  if (isSelected) {
    classNames.push("selected");
  }

  return (
    <div
      className={classNames.join(" ")}
      id={id}
      onClick={isDisabled ? undefined : toggleDateSelection}
    >
      <span>{thisDay}</span>
    </div>
  );
}

CalendarDate.propTypes = {
  onDateSelect: PropTypes.func,
  rehookifyDateDTO: PropTypes.shape({
    $date: PropTypes.instanceOf(Date),
    disabled: PropTypes.bool,
    inCurrentMonth: PropTypes.bool,
    now: PropTypes.bool,
    selected: PropTypes.bool,
  }).isRequired,
};
