const ClearMutedCompaniesButton = createReactClass({
  mixins: [Reflux.connect(MutedCompaniesStore, "store")],
  render: function(){

    const clearSelections = function(e){
      e.stopPropagation();
      MutedCompaniesActions.clearSelections();
    };

    if(this.state.store.currentMuted.length > 0){
      return <button type="submit" className="button-small button-secondary" onClick={(event) => clearSelections(event)} id={"clear_selections_button"}>Clear</button>
    }
    else{
      return <button type="submit" className="button-small button-disable" id={"clear_selections_button"}>Clear</button>;
    }
  }
});

window.ClearMutedCompaniesButton = ClearMutedCompaniesButton;
export default ClearMutedCompaniesButton;
