import BootstrapFullWidthRow from "../generic/htmlElements/bootstrapFullWidthRow";

const CompanyDetailsPanel = createReactClass({
  getInitialState: function () {
    return { desc_short: true };
  },
  toggleDescription: function () {
    var short = !this.state.desc_short;
    this.setState({ desc_short: short });
    track('zoominfo_click_company_description', 'Expand company description', { company_name: this.props.name, description_short: short });
  },
  trackClickOutToCompany: function () {
    track('zoominfo_click_to_company', 'Go to original company record', { company_name: this.props.name, zinfo_url: this.props.provider_url });
  },
  render: function () {
    if (this.props.name) {
      let description_text;
      if (this.props.description && this.props.description.length > 0) {
        if (this.state.desc_short && this.props.description.length > 400) {
          var maxLength = 400; // maximum number of characters to extract

          //trim the string to the maximum length
          var trimmedString = this.props.description.substr(0, maxLength);

          //re-trim if we are in the middle of a word
          trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
          description_text = <p onClick={this.toggleDescription}>{trimmedString}
            <span className="action_link">...more</span>
          </p>;
        }
        else {
          var less_link;
          if (this.props.description.length > 400) {
            less_link = <span className="action_link"> (less)</span>;
          }
          description_text = <div>
            <p onClick={this.toggleDescription}>{this.props.description} {less_link}</p>
            <p>
              <a href={this.props.provider_url} onClick={this.trackClickOutToCompany} target="_blank">See the '{this.props.name}' entry</a>
            </p>
          </div>;
        }
      }
      return (
        <BootstrapFullWidthRow>
          <div className="z_info_section">
            {description_text}
          </div>
        </BootstrapFullWidthRow>
      );
    }
    else {
      return null;
    }
  }
});

export default CompanyDetailsPanel;
