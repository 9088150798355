import React from "react";
import PropTypes from "prop-types";
import BannerContext from "./BannerContext";

const BANNER_VARIANTS = Object.freeze([
  "critical",
  "info",
  "success",
  "warning",
]);

const BANNER_ALERT_VARIANTS = Object.freeze(["critical", "warning"]);

export default function Banner({
  children,
  className,
  id,
  inverse = false,
  variant = "info",
  onDismiss,
}) {
  const isAlert = () => {
    return BANNER_ALERT_VARIANTS.includes(variant);
  };

  const commonProps = {
    id,
  };

  const classNames = [
    "lds-banner-container",
    `lds-banner-container-${variant}${inverse ? "-inverse" : ""}`,
  ];

  if (className) {
    classNames.push(className);
  }

  return (
    <div
      className={classNames.join(" ")}
      {...commonProps}
      role={isAlert() ? "alert" : "complementary"}
    >
      <BannerContext.Provider value={{ variant, inverse, onDismiss }}>
        {children}
      </BannerContext.Provider>
    </div>
  );
}

Banner.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  inverse: PropTypes.bool,
  variant: PropTypes.oneOf(BANNER_VARIANTS),
  onDismiss: PropTypes.func,
};

export { BANNER_VARIANTS, BANNER_ALERT_VARIANTS };
