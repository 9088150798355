const EventStatusCell = (props) => {
  let eventStatus = "Visible";
  if (props.event.hidden) {
    eventStatus = "Hidden";
  }
  if (props.event.awaiting_first_publication || props.event.republish_flag) {
    if(props.event.republish_flag) {
      eventStatus = eventStatus + "/Queued for republishing";
    }
    else{
      eventStatus = eventStatus + "/Awaiting publishing";
    }
  }
  if(props.event.loading){
    eventStatus = <div><img alt="loading..." src="/images/ajax-loader.gif"/></div>;
  }
  return (
    <td className="rspec-event-status-cell">{eventStatus}</td>
  );
};

export default EventStatusCell;
