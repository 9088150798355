import { useEffect } from "react"

const GenericModal = (props) => {
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    }
  }, [])

  const modalStyles = {
    position: 'fixed',
    top: 0, left: 0, bottom: 0, right: 0,
    zIndex: 10000,
    backgroundColor: 'rgba(0,0,0,0.75)',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const modalContentStyle = {
    borderRadius: '5px',
    backgroundColor: 'white',
    ...(props.style || {})
  }

  if (!props.open) {
    document.body.style.overflow = 'auto'
    return null
  }

  document.body.style.overflow = 'hidden'

  return (
    <div className="generic_modal" style={modalStyles}>
      <div style={modalContentStyle}>
        { props.children }
      </div>
    </div>
  )
}

window.GenericModal = GenericModal;
export default GenericModal;
