const QueryFacets = (props) => {
  if (props.search.facets) {
    var facets = props.search.facets.map(function (facet, index) {
      return (<p key={index}><b>{facet}</b></p>);
    });
    return (<div>{facets}</div>);
  }
  else {
    return false;
  }
}

export default QueryFacets;
