import Reflux from "reflux";
import UserListsActions from "../actions/lists/UserListsActions";
import { DEFAULT_CATEGORY } from "../../constants/lists/list_constants";
import ListsStoreClient from "./ListsStoreClient";

const UserListsStore = Reflux.createStore({
  listenables: [UserListsActions],
  init() {
    this.storeClient = new ListsStoreClient({ basePath: "user_lists" });
    this.data = {
      categories: {},
      originalCategories: {},
      selectedCategory: DEFAULT_CATEGORY,
      sortOptions: [],
      selectedSortOption: "",
      lists: [],
      selectedList: null,
      selectedLists: new Map(),
      currentCount: 0,
      totalCount: 0,
      params: {
        name: "",
        page: 1,
      },
    };
  },
  getInitialState() {
    this.onInitialState();
  },
  async onInitialState() {
    await this.storeClient.initialState(this.data);
    this.trigger(this.data);
  },
  async onLoadMore() {
    await this.storeClient.loadMore(this.data);
    this.trigger(this.data);
  },
  async onSort(item) {
    await this.storeClient.sort(this.data, item);
    this.trigger(this.data);
  },
  async onSearchName(name) {
    await this.storeClient.searchName(this.data, name);
    this.trigger(this.data);
  },
  async onSelectCategory(label) {
    await this.storeClient.selectCategory(this.data, label);
    this.trigger(this.data);
  },
  async onClearSearch() {
    await this.storeClient.clearSearch(this.data)
    this.trigger(this.data)
  },
  async onConfirmListsDeletion() {
    const params = new URLSearchParams({
      list_ids: [...this.data.selectedLists.keys()],
    });

    const url = new URL(
      `/user_lists/destroy_lists?${params}`,
      window.location.origin
    );

    const csrfToken = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    const response = await fetch(url, {
      method: "DELETE",
      headers: { "X-CSRF-Token": csrfToken },
    });

    if (response.ok) {
      const currentCategory = this.data.selectedCategory;
      await this.storeClient.fetchConfigs(this.data);
      this.data.lists = await this.storeClient.search(this.data);
      this.data.selectedCategory = currentCategory;
      this.data.selectedLists = new Map();
      this.trigger(this.data);
    } else {
      console.error(response);
    }
  },
  onSelectList(list) {
    const { selectedLists } = this.data;

    if (selectedLists.has(list.id)) {
      selectedLists.delete(list.id);
    } else {
      selectedLists.set(list.id, list);
    }

    this.trigger(this.data);
  },
  onSelectAllLists() {
    // If user has selected all lists previously
    if (this.data.selectedLists.size === this.data.lists.length) {
      this.data.selectedLists = new Map();
    } else {
      this.data.lists.forEach((list) => this.data.selectedLists.set(list.id, list));
    }

    this.trigger(this.data);
  },
});

window.UserListsStore = UserListsStore;
export default UserListsStore;
