const SelectableUser = function(props) {
  const changed = () => {
    props.toggleUser(props.user.id);
  }

  let fullName = props.user.name;

  if (props.search_string) {
    let regex = new RegExp(props.searchString, 'gi');
    fullName = fullName.replace(regex, "<span class='generic_search_string_highlight'>$&</span>");
  }

  let checkboxId = 'selectable_user_' + props.user.id;
  const labelClasses = ['fullstory_excluded', 'rspec_fullstory', 'checkbox'];

  if (props.user.currently_flagged) {
    labelClasses.push('checked');
  }

  return (
    <label className={labelClasses.join(' ')}>
      <input data-id={props.user.id} id={checkboxId} type="checkbox" onChange={changed} className="rspec_selectable_user_check fancy_checkbox" checked={props.user.checked} /> <span className="rspec_colleague_name" dangerouslySetInnerHTML={{ __html: fullName }} />
    </label>
  );
}

export default SelectableUser;
