import Reflux from "reflux"

const UserActions = Reflux.createActions({
  init: { sync: true },
  setSessionTTL: { },

  /**
   * Update how long to wait between each session check.
   * Will automatically restart checking. Extremely useful
   * in RSpec feature tests.
   * */
  setSessionCheckFrequency: {},
  startSessionCheck: { },
})

const UserStoreMutations = {
  onInit: function(data) {
    this.data = { ...(this.data), ...data }
  },
  onSetSessionTTL: function(ttl) {
    this.data.session.ttl = ttl

    if (ttl <= 0) {
      clearInterval(this.data.session.checkInterval)
    }

    this.trigger(this.data)
  },
  onStartSessionCheck: function() {
    if(this.data.onAdminSite) {
      console.warn('Running on Admin, skipping user session checks!')
      return
    }

    // Prevent more than one running!
    if (!this.data.session.checkInterval) {
      this.data.session.checkInterval = setInterval(this.checkUserSession, this.data.session.checkFrequency)
    }
  },
  onSetSessionCheckFrequency: function(newFrequency) {
    // Re-start the session check interval
    clearInterval(this.data.session.checkInterval)

    this.data.session.checkInterval = null
    this.data.session.checkFrequency = newFrequency
    this.onStartSessionCheck()
  },
  // Check user session.
  checkUserSession: function() {
    $.ajax({
      url: '/session_timeout',
      type: 'GET',
      success(result) {
        UserActions.setSessionTTL(result.ttl)
      },
      error(err) {
        // 401 indicates session expired, don't want
        // to capture errors such as 5xx which may
        // indicate server being down, user not having
        // internet, etc.
        if(err.status === 401) {
          UserActions.setSessionTTL(0)
        }
      }
    })
  }
}

window.UserActions = UserActions
export {
  UserActions,
  UserStoreMutations
}
