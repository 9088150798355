import Reflux from "reflux";

const ListModalAction = Reflux.createActions([
  "initialLoad",
  "switchSponsorFlag",
  "updateListName",
  "createList",
  "displayPage",
  "switchDisplayOrgList",
  "switchFeedCreation",
]);

window.ListModalAction = ListModalAction;
export default ListModalAction;
