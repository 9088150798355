import Reflux from "reflux";

const MutedCompaniesActions = Reflux.createActions([
  "setup",
  "selectionAdded",
  "selectionRemoved",
  "clearSelections",
  "saveChanges"
]);

window.MutedCompaniesActions = MutedCompaniesActions;
export default MutedCompaniesActions;
