import ComponentFromHTML from "@javascript/reactComponents/shared/ComponentFromHTML";
import LegacyModalService from "@javascript/globals/LegacyModalService";
import {
  Modal,
} from "@javascript/reactComponents/lds/modal/Modal";

const SalesforceModal = ({
  legacyModalContent,
}) => {

  return (
    <Modal
      open={true}
      onClose={LegacyModalService.closeModal}
      distanceFromTopViewport="75px"
    >
      <ComponentFromHTML html={legacyModalContent} />
    </Modal>
  );
};

export default SalesforceModal;
window.SalesforceModal = SalesforceModal;
