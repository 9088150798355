import RemoveIndustryLinks from "./removeIndustryLinks";
import AddIndustryLinks from "./addIndustryLinks";
import { eventEditModalStyle } from "../../../config/administrateEvent";

const EditIndustriesFromDoc = createReactClass({
  getInitialState: function(){
    return { showLinks: true,  eventData: EventModifyModalStore.eventData };
  },
  toggleDisplay: function(e){
    e.preventDefault();
    this.setState({showLinks: !this.state.showLinks, eventData: EventModifyModalStore.eventData});
  },
  checkForMismatch: function(industries){
    const adminIndustry = this.state.eventData.admin_industry;
    let tdStyle = eventEditModalStyle.industryMismatch;
    let tdClassname = "mismatched-industry-highlight";
    if (industries.includes(adminIndustry)) {
     tdStyle = eventEditModalStyle.cell;
     tdClassname = "";
    }
    return (
      <td style={tdStyle} className={tdClassname} id="rspec_event_industries">
        <RemoveIndustryLinks industries={industries}/>
      </td>
    );
  },
  render: function () {
    let industries = this.props.industries;
    let possibleIndustries = this.props.possibleIndustries;
    let toggleText = "..show";
    if(this.state.showLinks){
      toggleText = "hide";
    }
    else{
      industries = [];
      possibleIndustries = [];
    }
    return (
      <table style={eventEditModalStyle.table}>
        <thead>
          <tr>
            <th style={eventEditModalStyle.header}>Industries</th>
            <th style={eventEditModalStyle.header}>Possible Industries <span><a className="action_link right" onClick={this.toggleDisplay}>{toggleText}</a></span></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {this.checkForMismatch(industries)}
            <td style={eventEditModalStyle.cell} id="rspec_possible_industries">
              <AddIndustryLinks industries={possibleIndustries}/>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
});

export default EditIndustriesFromDoc;
