export const eventEditModalStyle = {
  table: {
    marginBottom: "-5px",
    width: "100%"
  },
  header: {
    borderStyle: "none",
    paddingBottom: "0px",
    width: "50%"
  },
  cell: {
    borderStyle: "none",
    width: "50%"
  },
  industryMismatch: {
    borderStyle: "none",
    width: "50%",
    backgroundColor: "yellow"
  },
};
