import NoRelatedTherapies from "./noRelatedTherapies";
import RelatedTherapiesFromList from "./relatedTherapiesFromList";

const RelatedTherapiesContainer = createReactClass({
  mixins: [Reflux.connect(RelatedTherapiesStore)],
  getInitialState: function() {
    RelatedTherapiesActions.getTherapiesForOrg(this.props.org_id);
    return {
      page: 1,
      paginated_therapies: {}
    }
  },
  handleTherapyMismatch: function(therapy) {
    RelatedTherapiesActions.reportMismatchTherapy(therapy);
  },
  changePage: function(newPage) {
    // Generic pagination indexes at zero
    newPage += 1;
    if(newPage === this.state.page) { return; }

    this.setState({ page: newPage })
  },
  render: function() {
    let relatedTherapies;
    const maxNameLength = 50;
    const numberOfPages = Math.ceil(this.state.total_num_therapies / this.state.therapies_per_page);
    const currentPage = this.state.page - 1;
    const listOfTherapies = this.state.paginated_therapies[this.state.page];
    const totalNumberOfTherapies = this.state.total_num_therapies;

    // If the therapies are still loading
    if (!this.state.therapies_loaded) {
      relatedTherapies = <img src="/images/ajax-loader.gif" style={{marginLeft: "5%"}} alt="Loading, please be patient"/>;
    }
    // If we have no related therapies whatsoever
    else if (this.state.total_num_therapies === 0) {
      relatedTherapies = <NoRelatedTherapies/>;
    }
    // There are related therapies!
    else {
      relatedTherapies =
        <RelatedTherapiesFromList listOfTherapies={listOfTherapies} totalNumberOfTherapies={totalNumberOfTherapies}
                                  numberOfPages={numberOfPages} maxNameLength={maxNameLength} therapiesReported={this.state.therapies_reported}
                                  changePage={this.changePage} currentPage={currentPage} errorCallback={this.handleTherapyMismatch} />
    }
    return(
      <div className="span12" id="related_therapies_container_loaded">
        <div>
          <h3 style={{marginBottom: "10px"}}>Pipeline and commercial drugs mentioned in the past 18 months</h3>
        </div>
        {relatedTherapies}
      </div>
    );
  }
});

window.RelatedTherapiesContainer = RelatedTherapiesContainer;
export default RelatedTherapiesContainer;
