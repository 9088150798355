const CompanySfIcon = function(props) {
  let sf_lit = 'unlit';
  let in_sf = ' NOT';
  if(props.company_data.sf === true){
    sf_lit = 'lit';
    in_sf = ''
  }
  return (
    <div className="rspec_salesforce_icon list_view_icon_div list_sf_icon" title={props.company_data.name + " is" + in_sf + " in your Salesforce."}>
      <div className="ep_icon_flipper">
        <div className={"ep_icon_front list_sf_" + sf_lit}></div>
        <div className="ep_icon_back ep_icon_close"></div>
      </div>
    </div>
  );
}

export default CompanySfIcon;
