const ClearTerritoriesButton = (props) => {
  const clearTerritory = (e) => {
    e.stopPropagation();
    TerritoriesAction.clearTerritory();
  };
  if(props.userTerritoryList.length > 0){
    return (
      <button type="submit" className="button-small button-secondary" onClick={clearTerritory}>Clear</button>
    );
  }
  else{
    return (
      <button type="submit" className="button-small button-disable">Clear</button>
    );
  }
};

export default ClearTerritoriesButton;
