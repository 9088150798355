const QueryOrgListRefs = (props) => {
  if (props.search.org_list_refs) {
    var org_list_refs = props.search.org_list_refs.map(function (org_list_ref, index) {
      return (<p className="rspec_org_list_description" key={index}><b>{org_list_ref}</b></p>);
    });
    return (<div>{org_list_refs}</div>);
  }
  return false;
}

export default QueryOrgListRefs;
