import Input from "../../lds/Input"

const ListName = createReactClass({
  getInitialState: function () {
      return {listName: this.props.listName};
  },
  UNSAFE_componentWillReceiveProps: function(new_props){
    if(this.state.listName != new_props.listName){
        this.setState({listName: new_props.listName});
    }
  },
  handleChange: function(value) {
      this.setState({listName: value})
      ListModalAction.updateListName(value);
  },
  switchFeedCreation: function(e){
    e.preventDefault();
      ListModalAction.switchFeedCreation();
  },
  createList:function(e){
      e.preventDefault();
      ListModalAction.createList();
      return;
  },
  render: function(){
      // console.log("Create feed is: " + this.props.createFeed);
      var topMargin = {marginTop:'10px'};
      var hide_submit = {display:'none'};
      return (
          <div className="row-fluid" style={topMargin}>
              <div className="span12">
                      <div id="title_input" className="control-group string required">
                          <label className="control-label" htmlFor="list_title"><b>Provide a descriptive name for your list
                              (required)</b></label>

                          <div className="controls">
                              <form onSubmit={this.createList}>
                                  <Input
                                    onValueChange={ this.handleChange }
                                    placeholder="Provide a descriptive title for your feed"
                                    showClearIcon
                                    size="small"
                                    value={this.state.listName}
                                    id="list_title"
                                    width="100%"
                                  />
                                  <input type="submit" style={hide_submit}/>
                              </form>
                              </div>
                          </div>
                  </div>
              </div>
      );

  }

})

export default ListName;