import MultiSelectCompanyHolder from "./multiSelectCompanyHolder";

const SuggestedMutedCompanies = createReactClass({
  mixins: [Reflux.connect(MutedCompaniesStore, "store")],
  render: function() {
    const selectCompany = function(company, e){
      e.stopPropagation();
      MutedCompaniesActions.selectionAdded(company)
    };
    const suggestedCompanies = this.state.store.currentSuggested ? this.state.store.currentSuggested : [];
    const availableCompanies = suggestedCompanies.map(function(company, index){
      return <MultiSelectCompanyHolder key={index} company={company} classNames={["mute_suggestions"]} labelClasses={["multirow_selection_available"]} onClick={selectCompany} />
    });
    return (
      <div className="multirow_selection_box multirow_selections_available">
        {availableCompanies}
      </div>
    );
  }
});

window.SuggestedMutedCompanies = SuggestedMutedCompanies;
export default SuggestedMutedCompanies;
