import React, { useContext } from 'react';
import { TableContext } from './ListTable';
import ListTableRow from './ListTableRow';

function ListTableBody() {
  const { lists } = useContext(TableContext);

  const listRows = lists.map((list) => {
    return <ListTableRow key={list.id} list={list}/>;
  });

  return (
    <tbody className='list-table-body'>
      {listRows}
    </tbody>
  )
}

export default ListTableBody;