import React from "react";

const ToastBanner = ({title, variant, className, children}) => {
  return(
    <div className={className}>
      <Toast title={title} variant={variant} />
      <div className="banner-text">
        {children}
      </div>
    </div>
  )
}

export default ToastBanner