const SaveChangesButton = (props) => {
  const saveChanges = (e) => {
    e.stopPropagation();
    TerritoriesAction.saveChanges();
  };
  const buttonText = props.changing ? "Saving Changes" : "Save Changes";
  let button = <button type="submit" className="button-small button-disable button_spaced_10_top">{buttonText}</button>;

  if(props.changed) {
    button = <button type="submit" className="button-small button-primary button_spaced_10_top" onClick={saveChanges}>{buttonText}</button>;
  }
  return (
    <div className="right">
      {button}
    </div>
  );
};

export default SaveChangesButton;
