const AdminModalHeader = createReactClass({
  render: function(){
    const titleStyle = { color:'#fff' };
    const divStyle = { backgroundColor: '#000' };
    return (
      <div className="modal-header" style={divStyle}>
        <h4 style={titleStyle}>
          {this.props.children}
        </h4>
      </div>
    )
  }
});

window.AdminModalHeader = AdminModalHeader;
export default AdminModalHeader;
