import ClearMutedCompaniesButton from "./clearMutedCompaniesButton";
import SuggestedMutedCompanies from "./suggestedMutedCompanies";
import SelectedMutedCompanies from "./selectedMutedCompanies";
import SaveMutedCompaniesButton from "./saveMutedCompaniesButton";

const MutedCompaniesSelectionPanels = () => {
  const saveMutedCompaniesSelection = function(e) {
    e.preventDefault();
    MutedCompaniesActions.saveChanges();
  };
  return (
    <div className="row">
      <div className="span5">
        <p>Suggested companies to mute</p>
        <SuggestedMutedCompanies />
      </div>
      <div className="clear_selections_button">
        <ClearMutedCompaniesButton />
      </div>
      <div className="span5">
        <p>Click to remove companies</p>
        <SelectedMutedCompanies />
        <SaveMutedCompaniesButton onClick={saveMutedCompaniesSelection} />
      </div>
    </div>
  );
};

window.MutedCompaniesSelectionPanels = MutedCompaniesSelectionPanels;
export default MutedCompaniesSelectionPanels
