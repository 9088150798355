import React, { useEffect } from 'react';
import PropTypes from "prop-types";

import _ from "lodash";

import { DatePickerProvider } from "@rehookify/datepicker";
import { useClickAway } from "@uidotdev/usehooks";

import { CalendarProvider, DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from "./CalendarContext";

import CalendarHeader from "./CalendarHeader";
import CalendarBody from "./CalendarBody";
import QuickActionContainer from "./QuickActionContainer";

const Calendar = ({
  initialDate,
  maxDate = DEFAULT_MAX_DATE,
  minDate = DEFAULT_MIN_DATE,
  onDateSelected = () => {},
  variant = "primary"
}) => {
  const [activeOverlay, setActiveOverlay] = React.useState(null);
  const [selectedDates, setSelectedDates] = React.useState([]);
  const [offsetDate, setOffsetDate] = React.useState(initialDate);

  const calendarRef = useClickAway(() => {
    if (activeOverlay) {
      setActiveOverlay(null);
    }
  });

  const toggleMonthOverlay = () => {
    setActiveOverlay(activeOverlay === "month" ? null : "month");
  };

  const toggleYearOverlay = () => {
    setActiveOverlay(activeOverlay === "year" ? null : "year");
  };

  const handleDateSelect = (dateDTO) => {
    const dateSelected = dateDTO.$date;
    if (!dateDTO || !dateSelected || !_.isDate(dateSelected)) {
      console.error("Invalid dateDTO:", dateDTO);
      return;
    }
    setSelectedDates([dateSelected]);
    onDateSelected(dateSelected);
  };

  const handleDatesChange = (dates) => {
    const validDates = dates.filter(_.isDate);
    setSelectedDates(validDates);
  };

  useEffect(() => {
    if (_.isDate(initialDate)) {
      setSelectedDates([initialDate]);
      setOffsetDate(initialDate);
    }
  }, [initialDate]);

  return (
    <DatePickerProvider
      config={{
        calendar: {
          mode: "static",
        },
        dates: {
          toggle: false,
        },
        offsetDate,
        onDatesChange: handleDatesChange,
        onOffsetChange: setOffsetDate,
        selectedDates,
      }}
    >
      <CalendarProvider
        value={{
          activeOverlay,
          dismissOverlay: () => setActiveOverlay(null),
          maxDate,
          minDate,
          setActiveOverlay,
          toggleMonthOverlay,
          toggleYearOverlay,
        }}
      >
        <div
          ref={calendarRef}
          role="calendar"
          className={`lds-calendar lds-calendar-${variant} ${activeOverlay ? "lds-calendar-highlight" : ""}`}>
          <div className="lds-calendar-header-container">
            <CalendarHeader />
          </div>
          <div className="lds-calendar-body-container">
            {activeOverlay && <QuickActionContainer />}
            <CalendarBody onDateSelect={handleDateSelect} />
          </div>
        </div>
      </CalendarProvider>
    </DatePickerProvider>
  );
};

Calendar.propTypes = {
  initialDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.oneOf([null, undefined])
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.oneOf([null, undefined])
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.oneOf([null, undefined])
  ]),
  onDateSelected: PropTypes.func,
  variant: PropTypes.oneOf(["primary", "critical"]),
};

export default Calendar;
