import React, { useContext } from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import { SelectionControlGroupContext } from "./SelectionControlGroup";

// TODO: read "onClick" from context if exists

function SelectionControl({
  className,
  id,
  icon,
  label,
  onClick,
  value,
  variant = "active", // TODO: change
  styleOverride = {},
  // TODO: size?
}) {
  const parentContext = useContext(SelectionControlGroupContext);

  onClick ||= parentContext.onSelection;

  const clicked = (value) => {
    if (variant !== "disable") {
      onClick(value);
    }
  };

  const classNames = ["lds-selection-control", `lds-selection-control-${variant}`];

  if(className) {
    classNames.push(className);
  }

  return (
    <div
      className={classNames.join(" ")}
      id={id}
      onClick={() => {
        clicked(value);
      }}
      style={styleOverride.container}
    >
      <span className="lds-selection-control-icon-container">
        <Icon name={icon} />
      </span>
      {label ? (
        <span className="lds-selection-control-label" style={styleOverride.label}>{label}</span>
      ) : (
        <></>
      )}
    </div>
  );
}

export const SelectionControlPropTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.oneOf(["active", "disable"]),
  styleOverride: PropTypes.exact({
    container: PropTypes.object,
    label: PropTypes.object,
  }),
};

SelectionControl.propTypes = SelectionControlPropTypes;
export default SelectionControl;
