const SfUserComment = createReactClass({
  getInitialState: function(){
    return {expanded: false};
  },
  toggle_expand: function(){
    this.setState({expanded: !this.state.expanded});
    return false;
  },
  render: function(){
    const commentDisplay = this.props.comment;
    if(commentDisplay && commentDisplay.length > 0){
      const longComment = commentDisplay.length > 200;
      if(longComment){
        if(this.state.expanded){
          return (<div className="sf_activity_desc" onClick={this.toggle_expand}>{commentDisplay} <span className="action_link"> ... less</span></div>);
        }
        else{
          return (<div className="sf_activity_desc" onClick={this.toggle_expand}>{commentDisplay.substring(0, 200)} <span className="action_link"> ... more</span></div>);
        }
      }
      else{
        return <div className="sf_activity_desc">{commentDisplay}</div>
      }
    }
    else{
      return false;
    }
  }
});

export default SfUserComment;
