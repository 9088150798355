import EntityListDisplay from "./EntityListDisplay";

const ListDisplayPanel = ({ entityList }) => {
  if (entityList.length > 5) {
    var leftList = entityList.slice(0, 5);
    var rightList = entityList.slice(5);
    return (
      <div className="row-fluid">
        <div className="span6">
          <EntityListDisplay key="list_1" entityList={leftList} />
        </div>
        <div className="span6">
          <EntityListDisplay key="list_2" entityList={rightList} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="row-fluid">
        <div className="span12">
          <EntityListDisplay key="list_1" entityList={entityList} />
        </div>
      </div>
    );
  }
};

export default ListDisplayPanel;
