const QueryGeoCoords = (props) => {
  if (props.search.geo_box) {
    return (
      <div>
        <p>Sponsor companies located within geographic boundary of:</p>
        <ul>
          <li>North boundary: {props.search.geo_box.top_left_lat}</li>
          <li>East boundary: {props.search.geo_box.bottom_right_lon}</li>
          <li>South boundary: {props.search.geo_box.bottom_right_lat}</li>
          <li>West boundary: {props.search.geo_box.top_left_lon}</li>
        </ul>
      </div>
    );
  }
  else {
    return false;
  }
}

export default QueryGeoCoords;
