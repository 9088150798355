import Button from "../../lds/Button";
import ButtonGroup from "../../lds/ButtonGroup";

export default function CategoryButtonGroup({
  categories,
  selected,
  onCategorySelected,
}) {
  const allCategories = Object.keys(categories).map((key) => ({
    label: key,
    count: categories[key],
  }));

  const parentStyles = {
    backgroundColor: "var(--color-gray-200)",
    paddingBottom: "var(--space-600)",
  };

  return (
    <div style={parentStyles}>
      <div className="container w-1200">
        <ButtonGroup id="categories_button_group" size="large">
          {allCategories.map(({ label, count }) => (
            <Button
              key={label}
              size="large"
              variant={selected === label ? "primary" : "secondary"}
              onClick={() => onCategorySelected(label)}
            >
              {label} ({count})
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </div>
  );
}
