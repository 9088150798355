import FacetListContainer from "./facetListContainer";

/**
 * React higher order pure component that returns a FacetLIstContainer component. Broken down this way (wrapped if you will) to support a future where we use a config file to render the search UI.
 *
 *
 * @author
 * @namespace
 * @memberof
 *
 * @component
 * @example
 * return (
 *   <ConditionSelector facet_list={facet_list} />
 * )
 *
 * @param {Object} facet_list a hash object of things related to the categories of facet list items, such as an array of individual items (detailing it's name/other characteristics) and other parameters related to the category of facet search terms and their display
 *
 */
const conditionsFacetKey = "conditions";
const ConditionSelector = createReactClass({
  mixins: [Reflux.connectFilter(FacetSearchStore, conditionsFacetKey, function(data) { return data.facets.conditions })],
  render() {
    if (!showSelector(this.state.conditions, conditionsFacetKey))
      return null;

    const facetList = decorateFacetList(this.state.conditions, conditionsFacetKey);

    return (
      <FacetListContainer facetList={facetList} />
    )
  }
});

window.ConditionSelector = ConditionSelector;
export default ConditionSelector;
