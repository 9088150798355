const SfAccountTeam = (props) => {
  if(props.account_team && props.account_team.length > 0) {
    var team_list = props.account_team.map(function (team_member) {
      if (team_member.zw_user) {
        return team_member.name;
      }
      else {
        return team_member.name + " (Non-"+I18n.t("brand.name")+" User)";
      }
    }).join(", ");
    return (
      <div className="sf_account_owner"><b>Account team:</b> {team_list}</div>
    );
  }
  else {
    return false;
  }
};

export default SfAccountTeam;
