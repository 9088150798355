import React from "react";
import PropTypes from "prop-types";

const Heading = React.forwardRef(({
  children,
  containerClassName,
  extraGapTop,
  id,
  size,
  trailingAction,
}, ref) => {
  const containerClassNames = ["lds-heading-container-" + size];
  const textClassNames = ["lds-heading-" + size];

  if (extraGapTop) {
    containerClassNames.push("extra-space-top");
  }

  if(containerClassName) {
    containerClassNames.push(containerClassName);
  }

  return (
    <div
      ref={ref}
      id={id}
      data-testid={id}
      className={containerClassNames.join(" ")}
    >
      <span className={textClassNames.join(" ")}>{children}</span>
      {trailingAction}
    </div>
  );
});

Heading.displayName = "Heading";

Heading.propTypes = {
  children: PropTypes.any,
  containerClassName: PropTypes.string,
  extraGapTop: PropTypes.bool,
  id: PropTypes.string,
  size: PropTypes.oneOf(["h1", "h2", "h3", "h4"]).isRequired,
  trailingAction: PropTypes.element,
};

export default Heading;
