import DeliverabilityMessage from "./deliverabilityMessage";

const ContactDeliverabilityChecker = createReactClass({
  componentDidMount: function () {
    let current_contact = this.props.current_contact;
    let email = this.props.email || current_contact.email;
    let deliverability = this.props.deliverability || current_contact.deliverability;

    if (!this.props.updateCurrentContact || !email || deliverability) {
      return;
    }

    ContactSearchAction.checkDeliverability(current_contact.provider_id);

  },
  render: function () {
    let current_contact = this.props.current_contact;
    let revealedSection = this.props.revealedSection;
    let generatedEmail = this.props.generated_email || this.props.current_contact.generated_email;

    let email = this.props.current_contact.email;
    let deliverability_message;

    let loading_text = '...Performing validation on email from third party source...';

    if (email) {
      deliverability_message = <DeliverabilityMessage generatedEmail={generatedEmail} revealedSection={revealedSection} {...this.props} loading_text={loading_text} email={this.props.current_contact.email} deliverability_result={current_contact.deliverability} score={this.props.current_contact.score} />;
    }

    return (
      <div key={current_contact.provider_id}>
        {deliverability_message}
      </div>
    );
  }
});

export default ContactDeliverabilityChecker;
