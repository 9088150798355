import SfIndexTable from "./sfIndexTable";
import SfAccountLoader from "./sfAccountLoader";
import SfLeadsNotice from "./sfLeadsNotice";
import SfLeadsPanel from "./sfLeadsPanel";
import ButtonGroup from "@javascript/reactComponents/lds/ButtonGroup";
import Button from "@javascript/reactComponents/lds/Button";

const PAGE_SIZE = 5;

const SfPanel = createReactClass({
  UNSAFE_componentWillMount: function(){
    SFPanelActions.initialState(this.props.company_id, this.props.company_name);
  },
  getInitialState: function() {
    return {
      initialDisplayedAccounts: 0,
      displayedAccounts: PAGE_SIZE,
    }
  },
  previousPage: function () {
    this.setState({
      initialDisplayedAccounts: this.state.initialDisplayedAccounts - PAGE_SIZE,
      displayedAccounts: this.state.displayedAccounts - PAGE_SIZE,
    });
  },
  nextPage: function () {
    this.setState({
      initialDisplayedAccounts: this.state.initialDisplayedAccounts + PAGE_SIZE,
      displayedAccounts: this.state.displayedAccounts + PAGE_SIZE,
    });
  },
  addAccount: function(e) {
    e.stopPropagation();
    e.preventDefault();
    const mixpanel_options = { action: "add to salesforce modal triggered", location: "from company page", type: "add account" };
    triggerSFEngagementActionForClassicMode(mixpanel_options);
    const url = "/company/" + this.props.company_id + "/add_company_as_new_account_in_sf";
    const window_opened = window.open(url, '_blank');
    if (window_opened) {
      window_opened.focus();
      $(this.refs.new_sf_alert_account).show();
      $(this.refs.link_to_create_sf_account).hide();
    }
    else {
      $.growl.error({message: "Please allow popup windows or visit your Salesforce account directly."});
    }
  },
  addAccountLightning:function(e){
    e.stopPropagation();
    e.preventDefault();
    const mixpanelOptions = { action: "add to salesforce modal triggered", location: "from company page", type: "add account" };
    initiateAddToSalesforceModal("account", this.props.company_id, null, mixpanelOptions);
  },
  render: function(){
    let multi, sfIndex, accountList;
    let accountsCount = 0;
    const companyName = this.props.company_name.replace(/\.\s*$/, "");
    let newAccount;
    if(this.props.use_lightning){
      newAccount = (<button type="submit" className="button-small button-primary" onClick={this.addAccountLightning} id="new_sf_account_btn">Create a new Account in Salesforce</button>);
    }
    else{
      newAccount = (<button type="submit" className="button-small button-primary" onClick={this.addAccount}>Create a new Account in Salesforce</button>);
    }
    if(!this.props.accounts || this.props.accounts.length === 0){
      multi = (
        <div className="row">
          <div className="span12">
            <div className="sf_paragraph">
              There were no accounts found in your Salesforce database for <b>{companyName}</b>.
            </div>
            <div ref="new_sf_alert_account" className="alert alert-danger hide" style={{marginTop:'10px', marginBottom:'0px'}}>
              <a className="close" data-dismiss="alert">×</a>
              <h4>After creating a new account in Salesforce it may take up to a few minutes for {I18n.t("brand.name")} and Salesforce to synchronize. You will need to refresh this page to see the new account.</h4>
            </div>
            <div ref="link_to_create_sf_account" className="sf_new_account_button">
              <h1>
                {newAccount}
              </h1>
            </div>
          </div>
        </div>
      )
    }
    else{
      let accounts = "account";
      accountsCount = this.props.accounts.length
      if(this.props.accounts.length > 1){
        accounts = 'accounts'
        sfIndex = (<SfIndexTable accounts={this.props.accounts} lead_count={this.props.leads.length} />)
      }
      multi = (
        <div id="sf_index_table" className="sf_paragraph">
          {I18n.t("brand.name")} matched { this.props.accounts.length } {accounts} for <b>{companyName}</b> in your Salesforce database.  All matches are shown below.
        </div>
      );
      accountList = this.props.accounts.slice(this.state.initialDisplayedAccounts, this.state.displayedAccounts).map(function(account_data){
        return <SfAccountLoader key={account_data.account_id} lead_count={this.props.leads.length} tab_selected={this.props.tab_selected} {...account_data} accounts={this.props.accounts} company_name={this.props.company_name} company_id={this.props.company_id} sf_user_id={this.props.sf_user_id} />
      }.bind(this));
    }
    const sfLeadsNotice = (<SfLeadsNotice lead_count={this.props.leads.length} use_lightning={this.props.use_lightning} account_count={accountsCount} company_name={this.props.company_name} company_id={this.props.company_id}/>)
    const sfLeads = (<SfLeadsPanel company_id={this.props.company_id} company_name={this.props.company_name} lead_count={this.props.leads.length} accounts_count={accountsCount}/>)

    const paginatorButtons = this.props.accounts &&
      <ButtonGroup size="small">
        <Button onClick={this.previousPage} size="small" variant={this.state.initialDisplayedAccounts > 0 && this.props.accounts.length ? "secondary" : "disable"}>
          Previous
        </Button>

        <Button onClick={this.nextPage} size="small" variant={this.state.displayedAccounts < this.props.accounts.length ? "secondary" : "disable"}>
          Next
        </Button>
      </ButtonGroup>

    return (<div>
      {multi}
      {sfLeadsNotice}
      {sfIndex}
      {paginatorButtons}
      {accountList}
      {paginatorButtons}
      {sfLeads}
    </div>);
  }
});

export default SfPanel;
