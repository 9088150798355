const NoMoreChoices = createReactClass({
  mixins: [Reflux.listenTo(pivotListColumnSettingsStore, 'updateSettingsState'), Reflux.listenTo(ColumnsChosenStore,"changeButtonState")],

  getInitialState: function(){
    return {
      columnsChosen:this.props.columns_chosen,
      fixedColumnsCount: pivotListColumnSettingsStore.state.fixedColumnsCount,
      interactableColumnsCount: pivotListColumnSettingsStore.state.interactableColumnsCount
    }
  },
  updateSettingsState: function(newState) {
    this.setState({
      fixedColumnsCount:newState.fixedColumnsCount,
      interactableColumnsCount:newState.interactableColumnsCount
    });
  },
  changeButtonState: function(columnsChosen) {
    this.setState({
      columnsChosen:columnsChosen
    });
  },
  render: function () {
    const interactableColumnsAvailable = this.state.interactableColumnsCount + this.state.fixedColumnsCount
    const className = (this.state.columnsChosen.length === (interactableColumnsAvailable)) ? 'no_more_choices no_more_shown' : 'no_more_choices no_more_hidden';
    return (
      <div className={className}>A limit of {this.state.interactableColumnsCount} columns has been reached.<br/>Remove a chosen column to replace it.</div>
    );
  }
});

export default NoMoreChoices;
