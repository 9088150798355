import Icon from "@javascript/reactComponents/lds/Icon";
import ToastBanner from "../ToastBanner";

const ListCreateInProgress = ({ listName }) => {
  return (
    <>
      <div className="status">
        <div className="create-happy-face-background">
          <Icon
            name="happy"
            style={{ height: 50, width: 50 }}
            className="icon-background"
          />
        </div>
        <h2 style={{ paddingTop: "16px" }}> Generating your list... </h2>
        <p
          style={{
            fontSize: "16px",
            paddingTop: "16px",
            paddingBottom: "16px",
          }}
        >
          {listName}
        </p>
      </div>
      <ToastBanner
        title="Taking too long?"
        variant="info"
        className="mock-banner-info"
      >
        You can close this window at any time and we will delete your lists in
        the background for you. Access all your generated lists by visiting{" "}
        <b>Lists</b>.
      </ToastBanner>
    </>
  );
};

export default ListCreateInProgress;
