import _ from "lodash";
import ColleaguesListsStore from "../../../../stores/lists/ColleagueListStore";
import ColleaguesListRow from "./ColleaguesListsRow";

export default function ColleaguesListsSection({ letter, bucket }) {
  // Group bucket by user
  const groupedByUserId = _.groupBy(bucket, (entry) => entry.user.id);

  return (
    <div className="colleagues-list-group">
      <h2>{letter}</h2>
      <hr />

      {Object.keys(groupedByUserId).map((userId, index) => (
        <ColleaguesListRow
          key={userId}
          evenChild={index % 2 === 0}
          lists={groupedByUserId[userId]}
        />
      ))}
    </div>
  );
}
