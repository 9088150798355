import { useLayoutEffect, useRef } from "react";

/**
 * DANGEROUS!!!!!!
 *
 * This is meant as a backwards-compatability tool
 * until we've re-written all legacy components as React.
 *
 * This component will render the given HTML and execute any script
 * tags that it finds as well. Use this CAREFULLY as it's basically
 * the equivalent of blindly eval'ing code.
 *
 * Taken with help from: https://macarthur.me/posts/script-tags-in-react/
 */

const ComponentFromHTML = ({ html }) => {
  const containerRef = useRef();

  useLayoutEffect(() => {
    if (containerRef.current) {
      const fragment = document.createRange().createContextualFragment(html);

      const scripts = fragment.querySelectorAll('script');
      scripts.forEach((script) => {
        const scriptEl = document.createElement('script');
        Array.from(script.attributes).forEach(attr => scriptEl.setAttribute(attr.name, attr.value));
        scriptEl.textContent = script.textContent;
        script.parentNode.replaceChild(scriptEl, script);
      });

      containerRef.current.innerHTML = '';
      containerRef.current.appendChild(fragment);
    }
  }, [html]);

  return <div ref={containerRef} />;
};

export default ComponentFromHTML;
