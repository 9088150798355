import { useState } from 'react'; import EntityListHolder from '../../listModalPanel/EntityListHolder';
import UserPagination from '../../../flagged_events/userPagination';

function PagedEntityListPreview({ entityList, pageSize = 10 }) {
  const [currentPage, setCurrentPage] = useState(0);
  
  const pageCount = Math.ceil(entityList.length/pageSize);
  return <>
      <div id="paged-entity-list-preview">
          <EntityListHolder sliceSize={pageSize} page={currentPage} entityList={entityList} />
          <UserPagination pages={pageCount} display_size={pageSize} changePage={setCurrentPage} current_page={currentPage} />
      </div>
  </>
}

export default PagedEntityListPreview;
