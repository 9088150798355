const BootstrapSpan = (props) => {
  const divClassname = `span${props.wide}`;
  return (
    <div className={divClassname}>
      {props.children}
    </div>
  );
};

window.BootstrapSpan = BootstrapSpan;
export default BootstrapSpan;
