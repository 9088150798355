import Reflux from "reflux";

import SFTabActions from "../actions/salesForce/sFTabActions";

const SFTabActionsStore = Reflux.createStore({
  listenables: SFTabActions,
  onSelectedTab: function(account, tab_selected){
    this.trigger(account, tab_selected);
  }
});

window.SFTabActionsStore = SFTabActionsStore;
export default SFTabActionsStore;
