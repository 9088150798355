import Button from "@javascript/reactComponents/lds/Button";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@javascript/reactComponents/lds/modal/Modal";

const SearchErrorModal = createReactClass({
  mixins: [
    Reflux.connectFilter(FacetSearchStore, 'message', function(data) { return data.searchTerm.queryErrorMessage })
  ],
  onCancel() {
    FacetSearchStoreActions.clearQueryError();
  },
  render() {
    let allowModal = !_.isEmpty(this.state.message);
    return (
      <Modal
        distanceFromTopViewport="75px"
        id="search_error_modal"
        onClose={this.onCancel}
        open={allowModal}
      >
        <ModalHeader>
          Search Error
        </ModalHeader>
        <ModalBody>
          { this.state.message + " "} See the <a className="underline_anchor" href={I18n.t("help.site")}>Help Page</a> for advanced search.
        </ModalBody>
        <ModalFooter align="flex-end">
          <Button
            id="dismiss_search_error"
            size="medium"
            variant="primary"
            onClick={this.onCancel}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
})

window.SearchErrorModal = SearchErrorModal;
export default SearchErrorModal;
