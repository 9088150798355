import Reflux from "reflux";

import FilteringActions from "../actions/pivotLists/filteringActions";

const FilteringActionsStore = Reflux.createStore({

  listenables: FilteringActions,
  init: function(){
    const orig_filters = [];
    const column_filters = [];
    this.state = {open:false, changed:false, column_selected:null, orig_filters:orig_filters, column_filters:column_filters};
  },
  onInitialState: function(column_filters, orig_filters){
    this.state.orig_filters  = orig_filters;
    this.state.column_filters  = column_filters;
    this.trigger(this.state);
  },
  onToggleFilteringOptions: function(toggle, column_selected){
    this.state.open = toggle;
    this.state.column_selected = column_selected;
    this.trigger(this.state);
  },
  onChangeSelectedColumn: function(column_selected){
    this.state.column_selected  = column_selected;
    this.trigger(this.state);
  },
  onEditFilter: function(index, value, state){
    const value_index = this.state.column_filters[index]['filter'].indexOf(value);
    if(state === true){
      if(value_index === -1){
        this.state.column_filters[index]['filter'].push(value);
      }
    }
    else{
      if (value_index > -1) {
        this.state.column_filters[index]['filter'].splice(value_index, 1);
      }
    }
    this.state.changed = JSON.stringify(this.state.orig_filters) !== JSON.stringify(this.state.column_filters);
    this.trigger(this.state);
  },
  onRevertState: function(){
    this.state = {open:false, changed:false, column_selected:null, orig_filters:this.state.orig_filters, column_filters:lodash.cloneDeep(this.state.orig_filters)};
    this.trigger(this.state);
  }
});

window.FilteringActionsStore = FilteringActionsStore;
export default FilteringActionsStore;
