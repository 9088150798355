import Icon from "@javascript/reactComponents/lds/Icon";
import ToastBanner from "../ToastBanner";

const ListCreateSetup = () => {
  return (
    <>
      <div className="status">
        <div className="create-happy-face-background">
          <Icon
            name="happy"
            style={{ height: 50, width: 50 }}
            className="icon-background"
          />
        </div>
        <h2 style={{ paddingTop: "16px" }}> Fetching data... </h2>
      </div>
      <ToastBanner
        title="Setting up data"
        variant="info"
        className="mock-banner-info"
      >
        Setting up data to create your list. This may take a few moments.
      </ToastBanner>
    </>
  );
};

export default ListCreateSetup;
