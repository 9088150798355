import Button from "@javascript/reactComponents/lds/Button";
import ButtonGroup from "@javascript/reactComponents/lds/ButtonGroup";
import DownloadPrimoCampaignButton from "@javascript/reactComponents/lists/downloadPrimoPlModal/downloadPrimoCampaignButton";

import GenericErrorModal from "@javascript/reactComponents/generic/modals/GenericErrorModal";
import Heading from "@javascript/reactComponents/lds/typography/Heading";

import Icon from "@javascript/reactComponents/lds/Icon";
import LegacyModalService from "@javascript/globals/LegacyModalService";

import {
  ModalBody,
  ModalFooter,
} from "@javascript/reactComponents/lds/modal/Modal";

import NameThisListCard from "@javascript/reactComponents/lists/nameThisListCard";

const DownloadPrimoPlChoice = ({
  choice,
  list,
  updateChoice,
}) => {

  /**
   * https://www.pivotaltracker.com/story/show/186622915
   * The keyword "ref" has a special meaning in React,
   * it should not be used.
   */
  const thisList = { ...list };
  thisList.list_ref = list.ref;
  thisList.ref = null;

  const campaign_types = {
    accounts: 'Accounts',
    contacts: 'Contacts Only',
    leads: 'Leads Only',
    contacts_and_leads: 'Contacts and Leads',
  };

  const campaign_buttons = Object.keys(campaign_types).map((entry, index) => (
    <DownloadPrimoCampaignButton
      key={index}
      choice={choice}
      name={campaign_types[entry]}
      type={entry}
      update_choice={updateChoice}
    />
  ));

  return (
    <ModalBody className="lds-modal-body-height flex-col">
      <NameThisListCard {...thisList} />
      <p>
        {I18n.t("brand.name")} can help you build faster and more targeted marketing campaigns
        by automating the retrieval of leads, contacts, or accounts from Salesforce and combining
        them with company data from your PivotLists.
      </p>
      <p>
        You can also use this feature to push PivotList information across Salesforce so your
        custom fields stay up to date. The campaign Excel file will display the
        {I18n.t("brand.name")} Organization ID and data columns from this PivotList alongside
        information and identifiers from Salesforce.
      </p>
      <p>Choose which Salesforce identifiers you wish to retrieve for this campaign:</p>
      <ul>
        <li>Selecting 'Accounts' will return matching SF Account IDs and the Account Name.</li>
        <li>
          Selecting 'Contacts' will return the first name, last name, title, email, and date of
          last activity for Contacts whose company matches a company on your PivotList.
        </li>
        <li>
          Selecting 'Leads' will return the first name, last name, title, email, and date of last
          activity for existing leads whose company matches a company on your PivotList.
        </li>
      </ul>
      <p>
        You will receive a link to a secure download when the file is ready. Note that assembling
        the file may take up to 1 hour depending on the length of your list.
      </p>
      <div className="centered_buttons buttonGroup-small">
        {campaign_buttons}
      </div>
    </ModalBody>
  );
};

const DownloadPrimoPlModal = createReactClass({
  getInitialState: function(){
    return this.initial_state();
  },
  initial_state: function() {
    return ({
      checked: false,
      choice: null,
      downloadStarted: false,
      downloadSuccess: null,
    });
  },
  update_choice: function(choice){
    this.setState({ choice, checked: true });
    return false;
  },
  onCancel: function(){
    this.setState(this.initial_state());
    return false;
  },
  submit_form: function(e){
    e.stopPropagation();
    e.preventDefault();
    $("body").addClass("wait");

    $.ajax({
      url: `/lists/${this.props.list._id}/email_user_list_download?list_type=${this.state.choice}`,
      type: 'GET',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token
      },
      success: function (result) {
        Zymewire.mixpanel_track('build campaign', {type: this.state.choice});
        $("body").removeClass("wait");
        this.setState({
          downloadStarted: true,
          downloadSuccess: result.success,
        });
      }.bind(this),
    });

    return false;
  },
  render: function(){

    if (this.state.downloadSuccess === false) {
      return (
        <GenericErrorModal
          onClose={this.onCancel}
          modalBody="We're sorry, but something went wrong. The issue has been logged."
          reloadPage={false}
        />
      );
    }

    const variant = this.state.checked ? "primary" : "disable";

    const modalBody = this.state.downloadStarted ? (
      <ModalBody className="flex-col align-center justify-center">
        <Icon
          name={"special-success"}
        />
        <Heading size="h2">Campaign Building Initiated</Heading>
        <p>
          The matching process has been initiated and your campaign file is being
          assembled.
        </p>
        <p>
          You will be emailed a link to a secure download when the process is complete.
          Note that assembling the file may take up to 1 hour depending on the length
          of your list.
        </p>
      </ModalBody>
    ) : (
      <DownloadPrimoPlChoice
        list={this.props.list}
        choice={this.state.choice}
        updateChoice={this.update_choice}
      />
    );

    const modalFooter = this.state.downloadStarted ? (
      <Button
        onClick={LegacyModalService.closeModal}
        size="medium"
        variant="primary"
      >
        Close
      </Button>
    ) : (
      <ButtonGroup size="medium">
        <Button
          onClick={LegacyModalService.closeModal}
          size="medium"
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          id="download_primo_pl_submit_button"
          onClick={this.submit_form}
          size="medium"
          variant={variant}
        >
          Send Link
        </Button>
      </ButtonGroup>
    );

    return (
      <>
        {modalBody}
        <ModalFooter align="flex-end">{modalFooter}</ModalFooter>
      </>
    );
  },
});

window.DownloadPrimoPlModal = DownloadPrimoPlModal;
export default DownloadPrimoPlModal;
