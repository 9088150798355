import FacetCheckboxContainer from "./facetCheckboxContainer";

/**
 * React component that displays a list of checkboxes for "Clinical Trial Stages"
 *
 * @params: none
 *
 */
const ClinicalTrialStagesSelector = createReactClass({
  mixins: [
    Reflux.connectFilter(
        FacetSearchStore,
        function(data) {
          return {
            clin_stage: data.facets.clin_stage,
            clinicalTrialStages: data.clinicalTrialStages
          }
        }
    )
  ],
  /** Important: this.state is an empty object on the first render. Once the store connects, it then gets populated.*/
  getInitialState() {
    return {}
  },
  render() {

    // what is clin_stage and can we discard it?
    // seems like it could be a part of locked logic and could be replaced.
    // -- GJ 2023.02.27

    const { clinicalTrialStages, clin_stage } = this.state;

    const show = clin_stage && clin_stage.buckets && clin_stage.buckets.length > 0;

    if (!show) {
      return null;
    }

    const updateClinicalTrialStagesSelected = (e, value) => {
      e.stopPropagation();

      const clinicalTrialStagesSelected = [...this.state.clinicalTrialStages.selected];
      const selectedIndex = clinicalTrialStagesSelected.indexOf(value);

      if (selectedIndex > -1) {
        // remove the clinical trial stage from the array... we no longer wish to represent it as checked
        clinicalTrialStagesSelected.splice(selectedIndex, 1);
      } else {
        // add the clinical trial stage to the array... it should be checked now
        clinicalTrialStagesSelected.push(value);
      }
      FacetSearchStoreActions.clinicalTrialStagesUpdate(
        {selected: clinicalTrialStagesSelected},
        {doSearch: true, triggerStateUpdate: true}
      );
    }

    const checkboxTitle = "Filter events by specific mention of a %%TERM%%";
    const facetTitle = "Filter events based on whether they contain an explicit mention of a stage of study.";
    const facetName = "Specific Reference to a";
    const facetType = "trial_stages";
    const facetClasses = ["facet_panel_open"];

    const {display, selected} = clinicalTrialStages;

    return (
        <FacetCheckboxContainer
          checkboxList={display}
          checkboxTitle={checkboxTitle}
          checkedBoxes={selected}
          facetClasses={facetClasses}
          facetName={facetName}
          facetTitle={facetTitle}
          facetType={facetType}
          onToggle={updateClinicalTrialStagesSelected}
        />
    )
  }
})

window.ClinicalTrialStagesSelector = ClinicalTrialStagesSelector;
export default ClinicalTrialStagesSelector;
