import SfUserActivity from "./sfUserActivity";

const SfUserToggle = createReactClass({
  getInitialState: function(){
    return {
      folded_unfolded:this.props.activities.owner?.zw_user ? "checked" : ""
    };
  },
  quiet_react: function (){
    // The whole “having to quiet react” doesn’t really sit well with me. Open to other ideas here.
    const quiet = "react";
  },
  fold_unfold: function (){
    this.setState({folded_unfolded: (this.state.folded_unfolded == "checked") ? "" : "checked"});
  },
  render: function () {
    const toggleId = this.props.count + "_" + this.props.account_id + "_" + this.props.type;
    const toggleDiv = toggleId + "_div";
    let activityDate = this.props.activities.due_date ? this.props.activities.due_date : this.props.activities.sort_date;
    activityDate = moment(activityDate).add(12, "h");
    activityDate = activityDate.format("ddd, MMMM Do, YYYY");
    let initials = "\u2022";
    let printName = this.props.activities.owner?.name + " (Non " + I18n.t("brand.name") + " User)";
    let hexColor = "#ccc !important";
    let className = "sf_non_zw_gravatar";
    if(this.props.activities.owner?.zw_user){
      className = "sf_zw_gravatar";
      initials = this.props.activities.owner.name.match(/\b\w/g) || [];
      initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
      printName = this.props.activities.owner.name;
      hexColor = "#" + convert_to_rgb(hash_this(this.props.activities.ownerId)) + " !important";
    }
    return(
      <div className="foldable sf_user_card">
        <input id={toggleId} type="checkbox" checked={this.state.folded_unfolded} className="foldable_toggle sf_user_panel" name={toggleId} onChange={this.quiet_react} />
        <div id={toggleDiv} onClick={this.fold_unfold} className="sf_user_row foldable_label sf_user_hover">
          <div className="sf_user_name">
            <div className={"sf_user_gravatar " + className} style={{backgroundColor: hexColor}}>{initials}</div>  {printName}
          </div>
          <div className="sf_activities_date">
            {activityDate}
          </div>
        </div>
        <div className="sf_user_body foldable_content">
          <SfUserActivity {...this.props.activities} />
        </div>
      </div>
    )
  }
});

export default SfUserToggle;
