const CopyToClipBoardButton = function(props) {
  const copyText = (event) => {
    event.stopPropagation();
    event.preventDefault();

    let text = props.text

    var text_el = document.getElementById(props.uid);

    if (window.navigator.clipboard) {
      window.navigator.clipboard.writeText(text)
    } else {
      copyTextLegacy();
    }

    text_el.style.display = 'block'

    setTimeout(function() { text_el.style.display = "none" }, 1000)
  }

  /** 
   * Very old browsers do not support `window.navigator.clipboard`.
   * This function may be retired in the future when we are confident
   * that all users have migrated to modern browsers.
   * 
   * - Bilal, Oct. 5 2023
   */
  const copyTextLegacy = () => {
    const el = document.createElement('textarea');

    el.style.position = 'fixed';
    el.style.top = 0;
    el.style.left = 0;
    el.style.border = 'none';
    el.style.outline = 'none';
    el.style.boxShadow = 'none';
    el.style.background = 'transparent';
    el.value = props.text;

    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  return (
    <i className='rspec_copy_to_clipboard_button' style={{marginLeft: '5px', position: 'relative'}} title='Copy to clipboard' onClick={copyText}>
      Copy
      <span className='success_copy' id={props.uid}>Copied</span>
    </i>
  );
}

export default CopyToClipBoardButton;
