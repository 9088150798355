import SfUserToggle from "./sfUserToggle";

const SfActivities = createReactClass({
  getInitialState: function(){
    return {display: 3}
  },
  show_more: function(){
    SFPanelActions.trackEvent('display_more_sf_activity', "Display more " + this.props.tab_content.type + " activity", {});
    this.setState({display: this.state.display + 10});
    return false;
  },
  render: function () {
    if (_.isUndefined(this.props.tab_content.data) || this.props.tab_content.data.length < 1) {
      let activity = this.props.tab_content.name.toLowerCase()
      if(this.props.tab_content.type == 'all') {
        activity = 'interactions at all (team or otherwise)'
      }
      activity = activity.replace(I18n.t("brand.name").toLowerCase(),I18n.t("brand.name"))
      return (
        <div className="sf_accounts_section">
          There are no {activity} for this {this.props.type}.
        </div>
      );
    }
    else {
      let moreButton;
      let displayActivities = Object.keys(this.props.tab_content.data)
      if(this.state.display < displayActivities.length){
        const remainder = displayActivities.length - this.state.display;
        const next = remainder > 10 ? "next 10" : 'last ' + remainder;
        moreButton = (
          <div onClick={this.show_more}>
            <h1>
              <a className="btn btn-primary">
                <span>Show the {next} of {displayActivities.length} total activities</span>
              </a>
            </h1>
          </div>
        )
      }
      displayActivities = displayActivities.slice(0, this.state.display);
      const activities = displayActivities.map(function (user_data, index) {
        return <SfUserToggle key={index} count={index} account_id={this.props.account_id} type={this.props.tab_content.type} activities={this.props.tab_content.data[user_data]}/>
      }.bind(this));
      return (
        <div>
          {activities}
          {moreButton}
        </div>
      );
    }
  }
});

export default SfActivities;
