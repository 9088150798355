const QueryActivityCategory = (props) => {
  if (props.search.category) {
    return (<p>Activity Type:  <b>{props.search.category}</b></p>);
  }
  else {
    return false;
  }
}

export default QueryActivityCategory;
