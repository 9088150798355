import PropTypes from "prop-types";

import CRMDropdown from "@javascript/reactComponents/companyDashboard/CRMDropdown";
import CRMInput from "@javascript/reactComponents/companyDashboard/CRMInput";

const SfFormFields = ({
  errors,
  fields,
}) => {

  const booleanFormat = [
    {
      "active": true,
      "defaultValue": true,
      "label": "false",
      "validFor": null,
      "value": "false"
    },
    {
      "active": true,
      "defaultValue": false,
      "label": "true",
      "validFor": null,
      "value": "true"
    }
  ];

  const sfFields = fields.map(function(f,i) {
    {
      switch(f.type) {
        case "picklist":
          return <CRMDropdown field={f} key={i} errors={errors}/>
        case "boolean":
          f.value = f.value || "false";
          f.format = booleanFormat;
          return <CRMDropdown field={f} key={i} errors={errors}/>
        default:
          return <CRMInput field={f} key={i} errors={errors}/>
      }
    }
  });
  return <div>{sfFields}</div>
};

SfFormFields.propTypes = {
  errors: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      opt_custom: PropTypes.bool,  // This is optional
      required: PropTypes.bool,
      type: PropTypes.string,
    })
  ).isRequired,
};

export default SfFormFields;
