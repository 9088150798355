import SfPanel from "./sfPanel";

const SfPanelWrapper = createReactClass({
  mixins: [Reflux.listenTo(SFPanelWrapperActionsStore,"updatePanel")],
  getInitialState: function(){
    return {...this.props};
  },
  updatePanel: function(objectType, newSet) {
    switch(objectType) {
      case "accounts":
        this.setState({
          accounts: newSet
        });
        break;
      case "leads":
        this.setState({
          leads: newSet
        });
        SFLeadPanelActions.refreshLeads();
        break;
    }

  },
  render: function(){
    return <SfPanel {...this.state} />
  }
});

window.SfPanelWrapper = SfPanelWrapper;
export default SfPanelWrapper;
