import PivotlistSortIcon from "./pivotListSortIcon";

const PivotlistFilterIcon = createReactClass({
  mixins: [Reflux.listenTo(FilteringActionsStore,"change_toggle_state")],
  getInitialState: function(){
    return {
      open:false,
      column_selected:false,
      column_filter: FilteringActionsStore.state.column_filters[this.props.filter_index]
    }
  },
  change_toggle_state: function(new_state) {
    this.setState(state => (
      {...state, open:new_state.open, column_selected:new_state.column_selected, column_filter:new_state.column_filters[this.props.filter_index]}
    ));
  },
  toggle_controls: function (e){
    e.stopPropagation();
    Zymewire.mixpanel_track('pivotlist filter intent', {type:this.props.filter_options.type, column:this.props.column_name})
    FilteringActions.toggleFilteringOptions(!this.state.open, this.props.filter_index)
   },
  render: function () {
    const class_names = ['pivotlist_filter_icon'];
    if(this.state.column_filter && this.state.column_filter.filter.length > 0){
      class_names.push('show_filter_icon')
    }
    if(this.state.column_selected === this.props.filter_index){
      class_names.push('selected_filter_icon')
    }
    return <span className={class_names.join(' ')} onClick={this.toggle_controls} title={this.props.filter_options.description}>&nbsp;</span>;
  }
});

var HeaderCell = createReactClass({
  getInitialState: function(){
    return {
      current_sort:this.props.element.current_sort,
      default_sort:this.props.element.default_sort
    }
  },
  re_sort: function(){
    if(this.state.default_sort !== 'null'){
      $("body").addClass("wait");
      var current_sort = this.state.current_sort === 'false' ? (this.state.default_sort === 'false' ? 'asc' : this.state.default_sort) : (this.state.current_sort === 'desc' ? 'asc' : 'desc');
      Zymewire.mixpanel_track("Lists: column sorted", {choice: this.props.element.full_name});
      window.location.href = '/lists/' + this.props.list_id + '?sort_field=' + this.props.element.column_name + '&sort_by=' + current_sort;
    }
  },
  render: function () {
    const classNames = ['list_header', 'list_column_calc', 'sorted_by_' + this.props.element.current_sort, 'default_sorted_by_' + this.props.element.default_sort, 'list_column_' + this.props.column_index];

    let filter_icon = null;
    let sort_icon = (this.props.element.current_sort === 'null' || this.props.column_index === 0) ? null : <PivotlistSortIcon current_sort={this.props.element.current_sort} default_sort={this.props.element.default_sort} />;
    if(this.props.filter_pivot_lists === true){
      classNames.push('rspec_filter_pivot_lists');
      if(this.props.element.filter_options.type !== false){
        filter_icon = <PivotlistFilterIcon filter_index={this.props.filter_index} filtered={this.props.element.current_filter} filter_options={this.props.element.filter_options} column_name={this.props.element.full_name}/>;
      }
    }
    const header_name = this.props.element.full_name.split(': ');
    let link = header_name[0];
    if(this.state.default_sort !== 'null') {
      link = <a className="action_link" title="Sort based on ascending or descending values.">{header_name[0]}</a>
    }
    let sub_header = <div className="list_sub_header">&nbsp;<br/>&nbsp;</div>;
    let widthStyle = {};

    if(this.props.element.column_name !== 'companies' && header_name[1] !== 'Name'){
      sub_header = (
        <div className="rspec_sub_header list_sub_header">{header_name[1]}</div>
      )
      const widthAdjustment = this.props.columnWidths[this.props.column_index] + 'px';
      widthStyle = {width: widthAdjustment, maxWidth: widthAdjustment, minWidth: widthAdjustment};
    }
    return (
      <div style={widthStyle} id={'header_for_' + this.props.element.column_name} title={this.props.element.description} onClick={this.re_sort} className={classNames.join(' ')} data-placement="bottom">{link}{sub_header}{sort_icon}{filter_icon}</div>
    );
  }
});

const HeaderRow = (props) => {
  var column_index = props.list_object.columns_chosen.length-1;
  var headerClasses = ['list_column_row list_header_row ', props.header_type];
  var content, columns;
  var column_settings = [];
  let companies_index_offset = 0;
  if(props.header_type === 'list_entity_header'){
    columns = props.list_object.columns_chosen.slice(0,1);
  }
  else{
    companies_index_offset = 1;
    columns = props.list_object.columns_chosen.slice(1);
  }
  if(columns.length === 0){
    var no_columns = {column_name:"no_columns", css_classes: null, current_filter:[], current_sort:"false", data_type:"text",default_sort:"null",description:"To add columns, click the 'Modify Columns' button", filter_options:{type:false, options:[], description:'Sorry.'}, full_name:"(no columns chosen)", name:	"(no columns chosen)"};
    content = <HeaderCell key='0' columnWidths={props.columnWidths} filter_pivot_lists={props.filter_pivot_lists} list_id={props.list_object.list_id} element={no_columns} column_index={column_index} filter_index='0'/>
  }
  else{
    content = _.map(columns, function(element, index){
      column_settings.push({data_type:element.data_type, current_sort:element.current_sort, default_sort:element.default_sort, column_index:column_index, column_name:element.column_name, css_classes:element.css_classes});
      return (
        <HeaderCell key={index} columnWidths={props.columnWidths} filter_pivot_lists={props.filter_pivot_lists} list_id={props.list_object.list_id} element={element} column_index={column_index} filter_index={index+companies_index_offset} />
      );
    });
  }
  let scrollId = 'list_entries_scroll_header';
  if(props.header_type === 'list_column_header'){
    ColumnSettingsActions.adjustColumnSettings(column_settings)
  }
  else{
    scrollId = 'list_companies_scroll_header';
    CompanySettingsActions.adjustCompanySettings(column_settings)
  }
  return (
    <div id={scrollId} className={headerClasses.join(' ')}>
      {content}
    </div>
  );
}

export default HeaderRow;
