import Reflux from "reflux"

/**
 * Reflux action object
 *
 * @namespace FacetSearchListActions
 * @param [Array] of action names for use with FacetSearchListMutations
 */
const FacetSearchListActions = Reflux.createActions({
  listSelected: { sync: true },
  unselectList: { sync: true },
  addList: { sync: true },
  setListOfUsersLists: { sync: true }
});

/**
 * Reflux store object
 *
 * @namespace FacetSearchLocationMutations
 *
 * Store listens to FacetSearchListActions events and updates its data object.
 * These methods are used from both inside and outside the relevant components to manage state.
 */
const FacetSearchListMutations = {
  onListSelected: function (list, opts = { doSearch: false, skipTrigger: false }) {
    if (!list) return

    const listId = list._id || list.org_list_id || list.orgListId || list.userListId || list.user_list_id;
    const listType = list.listType || list.list_type;
    const entity_type = list.entity_type || "Entities";

    const trigger = () => {
      if (!opts.skipTrigger) {
        this.trigger(this.data)
      }
    }

    if (this._publicListAssigned() && listType !== 'public') {
      this.data.affiliation.selected = null;
    }

    if(Zymewire.user.feature_tags.includes("list_of_other_entities")) {
      this.data.userLists.selected = {
        userListId: listId,
        name: list.name,
        listType: listType,
        orgCount: list.org_count,
        entity_type: entity_type,
      }
    } else {
      this.data.orgLists.selected = {
        orgListId: listId,
        name: list.name,
        listType: listType,
        orgCount: list.org_count,
        entity_type: entity_type,
      }
    }

    if (opts.doSearch) {
      doEventSearch().then(trigger)
    } else {
      trigger()
    }
  },
  onUnselectList: function (opts = {}) {
    const trigger = () => {
      if (!opts.skipTrigger) {
        this.trigger(this.data)
      }
    }

    if(Zymewire.user.feature_tags.includes("list_of_other_entities")) {
      this.data.userLists.selected = null;
    } else {
      this.data.orgLists.selected = null;
    }

    if (opts.doSearch)
      doEventSearch().then(trigger)
    else
      trigger()
  },
  onAddList: function (list) {
    if(Zymewire.user.feature_tags.includes("list_of_other_entities")) {
      this.data.userLists.lists.push(list);
    } else {
      this.data.orgLists.lists.push(list);
    }

    this.trigger(this.data);
  },
  onSetListOfUsersLists: function (data) {
    let lists;

    if (Zymewire.user.feature_tags.includes("list_of_other_entities")) {
      lists = this.data.userLists;
    } else {
      lists = this.data.orgLists;
    }

    lists.lists = data.lists;

    this.trigger(this.data);
  },
  _publicListAssigned() {
    const { affiliation, orgLists, userLists } = this.data;

    return !!(
      affiliation.selected ||
      (
        orgLists &&
        orgLists.selected &&
        orgLists.selected.listType === "public"
      ) ||
      (
        userLists &&
        userLists.selected &&
        userLists.selected.listType === "public"
      )
    )
  }
};

window.FacetSearchListActions = FacetSearchListActions
export {
  FacetSearchListActions,
  FacetSearchListMutations
}
