import IgnoreEventButton from "./ignoreEventButton";
import RestoreEventButton from "./restoreEventButton";
import PublishEventButton from "./publishEventButton";

const ArticleEventActionPanel = (props) => {
  if(props.status === "NOT_PROCESSED" || props.status === "PUBLISHED"){
    return false;
  }
  let eventButton = <IgnoreEventButton {...props}/>;
  if(props.status === "IGNORED"){
    eventButton = <RestoreEventButton {...props}/>;
  }
  const paragraphStyle = {width: "100%"};
  return (
    <p style={paragraphStyle}>
      <PublishEventButton {...props}/> <span className="right">{eventButton}</span>
    </p>
  );
};

export default ArticleEventActionPanel;
