import SelectedTerritoryHolder from "./selectedTerritoriesHolder";

const SelectedTerritories = (props) => {
  let locations = props.userTerritoryList.map(function(loc, index){
    return <SelectedTerritoryHolder key={index} {...loc} />
  });
  if(locations.length < 1){
    locations = <p className="selected_locations_default">Worldwide</p>;
  }
  return (
    <div className="location_selector_box territory_edit locations_selected">
      {locations}
    </div>
  );
};

export default SelectedTerritories;
