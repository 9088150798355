import AddEntitiesFromDoc from "./addEntitiesFromDoc";

const AddEntityPanel = createReactClass({
  getInitialState: function () {
    return {sourceEntities: []};
  },
  componentDidMount: function () {
    const url = "/administrate_event/" + this.props.event.id + "/initial_source_entities";
    $.get(url, function (result) {
      this.setState({sourceEntities: result});
    }.bind(this));
  },
  render: function () {
    const currentEntityIds = _.map(this.props.event.entities, function (ent) {
      return ent.rec_id
    });
    const unusedEntityIds = _.reject(this.state.sourceEntities, function (ent) {
      return _.contains(currentEntityIds, ent.rec_id);
    }, currentEntityIds);

    return <AddEntitiesFromDoc entities={unusedEntityIds} />;
  }
});

export default AddEntityPanel;
