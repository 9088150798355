import AdminModalFooter from "../../generic/modals/adminModalFooter";
import { getAllPublishableConceptRefs } from "../../../helpers/administrateEvent";

const EditEventFooter = (props) => {
  const saveEvent = (e) => {
    e.preventDefault();
    EventModifyModalAction.saveEventChanges(false);
  };
  const saveEventVisible = (e) => {
    e.preventDefault();
    EventModifyModalAction.saveEventChanges(true);
  };
  const saveEventNow = (e) => {
    e.preventDefault();
    const now = moment();
    EventModifyModalAction.updateDate(now);
    EventModifyModalAction.saveEventChanges(true);
  };
  let saveButton;
  let cancelButton;
  let makeVisible;
  let publishAsNow;
  if (props.saving) {
    saveButton = <a href="#" className="btn btn-primary">Saving</a>;
    cancelButton = <img height="28px" src="/images/ajax-loader.gif"/>;
  }
  else {
    if(props.event.hidden){
      if(props.event.awaiting_first_publication){
        makeVisible =  <a className="btn btn-primary rspec_make_visible" onClick={saveEventVisible}>Make Visible</a>;
        publishAsNow = <a className="btn btn-primary rspec_publish_as_now" onClick={saveEventNow}>Publish as Now</a>;
      }
      else{
        makeVisible =  <a className="btn btn-primary rspec_save_visible" onClick={saveEventVisible}>Save and make visible</a>;
      }
    }
    saveButton = <a className="btn btn-primary" onClick={saveEvent}>Save</a>;
    cancelButton = <a data-dismiss="modal" className="btn">Cancel</a>;
  }
  const hasPublishableEntities = _.find(props.event.entities, function(entity){
    return entity.is_publishable;
  });

  const allPublishableConceptRefs = getAllPublishableConceptRefs(props.event.entities);
  const hasPublishableIndustries = (props.event.industry_concept_refs.every(ind => allPublishableConceptRefs.includes(ind)) && props.event.industry_concept_refs.length > 0);

  if(!hasPublishableEntities || !hasPublishableIndustries){
    makeVisible = null;
    publishAsNow = null;
    saveButton = null;
  }
  return (
    <AdminModalFooter>
      {publishAsNow}
      {makeVisible}
      {saveButton}
      {cancelButton}
    </AdminModalFooter>
  );
};

export default EditEventFooter;
