const AddEntityLink = (props) => {
  const addEntity = (e) => {
    e.preventDefault();
    EventModifyModalAction.addEntity(props.entity);
  };
  const paragraphStyle = {marginBottom: "3px"};

  const publishableConceptRefs = props.entity.publishable_concept_refs ? [...props.entity.publishable_concept_refs] : [];
  publishableConceptRefs.forEach((e, i) => publishableConceptRefs[i]=e.substring(0,12));
  const industry = publishableConceptRefs.length > 0 ? <b>({publishableConceptRefs.join(", ")})</b> : null;

  return (
    <p style={paragraphStyle}>
      <a className="action_link" id={"rspec_add_" + props.entity.rec_id} onClick={addEntity}>{props.entity.name} <i className="icon-plus" /></a> {industry}
    </p>
  );
};

export default AddEntityLink;
