import Reflux from "reflux";

import ColumnControlsActions from "../actions/pivotLists/columnControlsActions";

const ColumnControlsStore = Reflux.createStore({
  listenables: ColumnControlsActions,
  init: function() {
    this.state = ''
  },
  onToggleColumnControls: function(controls_state){
    this.state = controls_state;
    this.trigger(this.state);
  },
  onScrollWheelControls: function(delta, shiftKey, scrollSection) {
    // this shouldn't be here -- but I'm loathe to:
    //
    //   a. create loose functions all over the place
    //   b. impose a namespace/regime for just this function without a formal architecture discussion around the proper composition of our JS codebase
    //
    // I played with import export for a bit but feel we should have a proper discussion on how to structure our code (namespacing, logical organization, component break up, stores etc.) before embarking on any path

    const listEntriesScrollData = document.getElementById("list_entries_scroll_data");
    const listEntriesScrollHeader = document.getElementById("list_entries_scroll_header");

    const listCompaniesScrollData = document.getElementById("list_companies_scroll_data");
    const listCompaniesScrollHeader = document.getElementById("list_companies_scroll_header");

    if(shiftKey === true) {

      scrollSection.scrollLeft += ( delta < 0 ? 1 : -1 ) * 50;

      const pivotlistHorizontalScroll = document.getElementById("pivotlist_horizontal_scroll");
      const headerForCompanies = document.getElementById("header_for_companies")

      listEntriesScrollData.scrollLeft = pivotlistHorizontalScroll.scrollLeft = listEntriesScrollHeader.scrollLeft = scrollSection.scrollLeft;

      if(scrollSection.scrollLeft > 0) {
        listCompaniesScrollData.classList.add('pivotlist_horizontal_scroll_shadow');
        headerForCompanies.classList.add('pivotlist_horizontal_scroll_shadow');
        listCompaniesScrollHeader.classList.add('pivotlist_horizontal_scroll_shadow');
      }
      else {
        listCompaniesScrollData.classList.remove('pivotlist_horizontal_scroll_shadow');
        headerForCompanies.classList.remove('pivotlist_horizontal_scroll_shadow');
        listCompaniesScrollHeader.classList.remove('pivotlist_horizontal_scroll_shadow');
      }

    }
    else {
      scrollSection.scrollTop += ( delta < 0 ? 1 : -1 ) * 50;

      const pivotlistVerticalScroll = document.getElementById("pivotlist_vertical_scroll");

      listCompaniesScrollData.scrollTop = listEntriesScrollData.scrollTop = pivotlistVerticalScroll.scrollTop = scrollSection.scrollTop;

      if(scrollSection.scrollTop > 0) {
        listEntriesScrollHeader.classList.add('pivotlist_vertical_scroll_shadow');
        listCompaniesScrollHeader.classList.add('pivotlist_vertical_scroll_shadow');
      }
      else {
        listEntriesScrollHeader.classList.remove('pivotlist_vertical_scroll_shadow');
        listCompaniesScrollHeader.classList.remove('pivotlist_vertical_scroll_shadow');
      }
    }
  }
});

window.ColumnControlsStore = ColumnControlsStore;
export default ColumnControlsStore;
