const ChooseSegmentContactReportHeader = () => {
  const closeModal = () => {
    close_modal_control();
  };
  return (
    <div id="choose_pdf_report_modal_header" className="modal_header black_modal_header">
      <div className="close_x_white modal_close_offset" onClick={closeModal} />
      <h3>Send PDF report to:</h3>
    </div>
  );
};

export default ChooseSegmentContactReportHeader;
