import TabAnchor from "../lds/tabs/TabAnchor";
import TabGroup from "../lds/tabs/TabGroup";

const DashboardNavTabs = ({
                       items,
                       selectedItem
                     }) => {

  const tabAnchors = items.map((item, index) => {
    return <TabAnchor key={index} {...item} />;
  });

  return(
    <TabGroup
      animated={false}
      onTabSelected={() => {}}
      selectedTab={selectedItem}
    >
      {tabAnchors}
    </TabGroup>
  );
}

window.DashboardNavTabs = DashboardNavTabs;
export default DashboardNavTabs;
