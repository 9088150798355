import EditOrgEntity from "./editOrgEntity";

const EditEventOrgEntities = (props) => {
  const editOrgEntities = props.orgs.map(function (org) {
    return <EditOrgEntity key={org.rec_id} org={org}/>;
  });
  return (
    <div>{editOrgEntities}</div>
  );
};

export default EditEventOrgEntities;
