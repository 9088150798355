import PaginationPage from "./paginationPage";

// deprecatethis:
// the above components should be junked once we deprecate the can_use_flagged_event_reminder feature flag as they are only used with old flagging components
// UserListPage -> refactored into PageOfSelectableUsers for use across the application
// UserColumn -> refactored into ColumnOfSelectableUsers for use across the application
// FlagUserCheckbox -> refactored into SelectableUser for use across the application
// this should be junked and all instances of it replaced with PaginationHolder
const UserPagination = (props) => {
  if (props.pages < 2) {
    return false;
  }

  var pages = [];
  var start_page = 0;
  var end_page = props.pages;

  if (props.display_size < props.pages) {
    end_page = props.display_size;
    if (start_page != props.current_page) {
      start_page = props.current_page - 5;
      if (start_page < 0) {
        start_page = 0;
      }
      end_page = start_page + props.display_size;
      if (end_page > props.pages) {
        end_page = props.pages;
      }
    }
  }

  for (var i = start_page; i < end_page; i++) {
    var active = i == props.current_page;
    pages.push(<PaginationPage key={i} changePage={props.changePage} active={active} page_number={i} />);
  }

  var pagination_classes = ['pagination', 'flag_event_pagination'];

  return (
    <div className={pagination_classes.join(' ')}>
      <ul>
        {pages}
      </ul>
    </div>
  );
}

export default UserPagination;
