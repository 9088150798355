import React from "react";

export const WEEK_DAY_LABELS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

export default function CalendarWeekDayLabels({}) {
  return (
    <div className="lds-calendar-week-day-labels">
      {WEEK_DAY_LABELS.map((day_label, index) => (
        <span key={index}>{day_label}</span>
      ))}
    </div>
  );
}
