import AddEntityLink from "./addEntityLink";
import AddIndustryLink from "./addIndustryLink";

const AddIndustryLinks = (props) => {
  const addIndustryLinks = props.industries.map(function(ind){
    return <AddIndustryLink industry={ind} />;
  });
  return (
    <div>{addIndustryLinks}</div>
  );
};

export default AddIndustryLinks;
