import AddEntityLink from "./addEntityLink";

const AddEntityLinks = (props) => {
   const addEntityLinks = props.entities.map(function(entity, index){
     return <AddEntityLink key={index} entity={entity} />;
   });
   return (
     <div>
       {addEntityLinks}
     </div>
   );
};

export default AddEntityLinks;
