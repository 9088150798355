import AddClinicalStagePropertyPicker from "./addClinicalStagePropertyPicker";

const AddClinicalTrialPropertyPanel = createReactClass({
  getInitialState: function () {
    return {closed: true};
  },
  toggleClose: function (e) {
    e.preventDefault();
    this.setState({closed: !this.state.closed});
  },
  render: function () {
    if (this.state.closed) {
      return (
        <p>
          <a className="action_link" onClick={this.toggleClose}>Add property</a>
        </p>
      );
    }
    else {
      return <AddClinicalStagePropertyPicker closeHandler={this.toggleClose} {...this.props} />;
    }
  }
});

export default AddClinicalTrialPropertyPanel;
