const GenericModalHeader = ({style, children}) => {

  const styles = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '18px',
    fontSize: '14pt',
    ...style
  }

  return (
    <div style={styles}>
      { children }
    </div>
  )
}

window.GenericModalHeader = GenericModalHeader;
export default GenericModalHeader;
