import Reflux from "reflux"
import { FacetSearchCompanyActivityActions, FacetSearchCompanyActivityMutations } from "./actions/facetSearch/activityActions"
import { FacetSearchAliasSearchActions, FacetSearchAliasSearchMutations } from "./actions/facetSearch/aliasSearchActions";
import { FacetSearchAffiliatedWithActions, FacetSearchAffiliatedWithMutations } from "./actions/facetSearch/affiliatedWithActions";
import { FacetSearchListActions, FacetSearchListMutations } from "./actions/facetSearch/listActions"
import { FacetSearchCompanyRelationshipActions, FacetSearchCompanyRelationshipMutations } from "./actions/facetSearch/companyRelationshipActions";
import { FacetSearchGeoCoordinatesActions, FacetSearchGeoCoordinatesMutations } from "./actions/facetSearch/geoCoordinatesActions";
import { FacetSearchLocationActions, FacetSearchLocationMutations } from "./actions/facetSearch/locationActions";
import { FacetSearchStoreActions, FacetSearchStoreMutations } from "./actions/facetSearch/storeActions";
import { FacetSearchTimeFrameActions, FacetSearchTimeFrameMutations } from "./actions/facetSearch/timeFrameActions";

import { FacetSearchCompanyActivitySlice } from "./slices/facetSearch/activitySlice";
import { FacetSearchAffiliatedWithSlice } from "./slices/facetSearch/affiliatedWithSlice";
import { FacetSearchAliasSearchSlice } from "./slices/facetSearch/aliasSearchSlice";
import { FacetSearchClinicalTrialStagesSlice } from "./slices/facetSearch/clinicalTrialStagesSlice";
import { FacetSearchClinicalTrialStatusSlice } from "./slices/facetSearch/clinicalTrialStatusSlice";
import { FacetSearchCompanyListSlice } from "./slices/facetSearch/companyListSlice";
import { FacetSearchCompanyRelationshipSlice } from "./slices/facetSearch/companyRelationshipSlice";
import { FacetSearchGeoCoordinatesSlice } from "./slices/facetSearch/geoCoordinatesSlice";
import { FacetSearchLocationSlice } from "./slices/facetSearch/locationSlice";
import { FacetSearchResultsSlice } from "./slices/facetSearch/resultsSlice";
import { FacetSearchTimeFrameSlice } from "./slices/facetSearch/timeFrameSlice";
import { FacetSearchUserListSlice } from "./slices/facetSearch/userListSlice"

const FacetSearchStore = Reflux.createStore({
  listenables: [
    FacetSearchAffiliatedWithActions,
    FacetSearchAliasSearchActions,
    FacetSearchCompanyActivityActions,
    FacetSearchListActions,
    FacetSearchCompanyRelationshipActions,
    FacetSearchGeoCoordinatesActions,
    FacetSearchLocationActions,
    FacetSearchStoreActions,
    FacetSearchTimeFrameActions,
  ],
  init: function () {
    this.data = {
      activities: {
        display: null,
        selected: null
      },
      affiliation: {
        ...FacetSearchAffiliatedWithSlice,
      },
      aliasSearch: {
        ...FacetSearchAliasSearchSlice,
      },
      clinicalTrialStages: {
        ...FacetSearchClinicalTrialStagesSlice,
      },
      clinicalTrialStatuses: {
        ...FacetSearchClinicalTrialStatusSlice,
      },
      events: [],
      facets: {
        ...FacetSearchResultsSlice
      },
      // Which filters are already selected for each type of entity, e.g. "Pfizer" already selected in org dropdown
      filterCriteria: {
        conditions: [],
        organizations: [],
        therapies: [],
      },
      geoCoordinates: {
        ...FacetSearchGeoCoordinatesSlice,
      },
      location: {
        ...FacetSearchLocationSlice
      },
      orgLists: {
        ...FacetSearchCompanyListSlice
      },
      userLists: {
        ...FacetSearchUserListSlice
      },
      relationships: {
        ...FacetSearchCompanyRelationshipSlice
      },
      searchTerm: {
        feedPageQueryTerm: "",
        queryTerm: "",
      },
      timeFrame: {
        ...FacetSearchTimeFrameSlice
      },
      total_count: 0,
    }
  },

  /* Include any mutation functions here. Note that each mutation function MUST have a unique name! */

  ...FacetSearchAffiliatedWithMutations,
  ...FacetSearchAliasSearchMutations,
  ...FacetSearchCompanyActivityMutations,
  ...FacetSearchListMutations,
  ...FacetSearchCompanyRelationshipMutations,
  ...FacetSearchGeoCoordinatesMutations,
  ...FacetSearchLocationMutations,
  ...FacetSearchStoreMutations,
  ...FacetSearchTimeFrameMutations,
});

window.FacetSearchStore = FacetSearchStore;
export default FacetSearchStore;
