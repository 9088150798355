/**
* The stores imported/defined here are made globally available.
**/
import Reflux from "reflux";

import "./facetSearchStore";
import "./userStore";

import "./eventModifyModalStore";
import "./eventTableStore";
import "./entSearchStore";

import "./salesForce/sFPanelActionsStore";
import "./salesForce/sFAccountActionsStore";
import "./salesForce/sFTabActionsStore";
import "./salesForce/sFPanelWrapperActionsStore";
import "./salesForce/sFLeadPanelActionsStore";
import "./salesForce/sFAccountLoaderActionsStore";

import "./relatedTherapiesStore";
import "./pivotLists/columnSettingsStore";
import "./pivotLists/columnControlsStore";
import "./pivotLists/columnsChosenStore";
import "./pivotLists/pivotlistColumnSettingsStore";

import "./contactSearch/flagContactStore";
import "./pivotLists/filteringActionsStore";
import "./pivotLists/companySettingsStore";
import "./pivotLists/submitFormStore";

import "./pivotLists/dragStore";
import "./pivotLists/rowHoverStore";
import "./pivotLists/rowRemovalStore";

import "./pivotLists/listFeedActionStore"
import "./pivotLists/listModalActionStore"

import "./mutedCompaniesStore";
import "./territoriesStore"
import "./contactSearch/contactSearchStore"


// Lists v2
import "./listPageStore";

window.Reflux = Reflux;
