const EventActionForHiddenAndPublishable = (props) => {
  const makeVisible = (e) => {
    e.preventDefault();
    EventTableAction.showEvent(props.event);
  };
  const publishAsNow = (e) => {
    e.preventDefault();
    EventTableAction.publishAsNow(props.event);
  };
  return (
    <div>
      <p>
        <a className="action_link rspec_make_visible" onClick={makeVisible}>Make visible</a>
      </p>
      <p>
        <a className="action_link rspec_publish_as_now" onClick={publishAsNow}>Publish as NOW!</a>
      </p>
    </div>
  );
};

export default EventActionForHiddenAndPublishable;
