import FilterColumns from "./filterColumns";
import FilterControlButton from "./filterControlButton";
import FilterOptions from "./filterOptions";


const PivotlistFilterControls = createReactClass({
  mixins: [Reflux.listenTo(FilteringActionsStore, "change_toggle_state")],
  getInitialState: function () {
    return {open: FilteringActionsStore.state.open};
  },
  change_toggle_state: function(new_state) {
    // if open, we need to desat companies and companies header: see desat_list_companies_container in list_display.jsx
    this.setState(state => (
      {...state, open:new_state.open}
    ));
  },
  clear_all_filters: function(e, id) {
    e.preventDefault();
    e.stopPropagation();
    $("body").addClass("wait");
    $("html, body").animate({ scrollTop: 0 }, 'fast');
    NProgress.configure({parent:'.filter_control_buttons', showSpinner:false});
    NProgress.start();
    const sort_field = getParameterByName('sort_field');
    const sort_by = getParameterByName('sort_by');
    const params = [];
    if(sort_field && sort_by){
      params.push('sort_field=' + sort_field);
      params.push('sort_by=' + sort_by)
    }
    window.location.href = '/lists/' + this.props.list_id + (params.length > 0 ) ? '?' + params.join('&') : '';
  },
  cancel_filters: function(e, id) {
    e.preventDefault();
    e.stopPropagation();
    FilteringActions.revertState();
  },
  apply_filters: function(e, id) {
    e.preventDefault();
    e.stopPropagation();
    const sort_field = getParameterByName('sort_field');
    const sort_by = getParameterByName('sort_by');
    const params = [];
    if(sort_field && sort_by){
      params.push('sort_field=' + sort_field);
      params.push('sort_by=' + sort_by);
    }
    if(JSON.stringify(FilteringActionsStore.state.orig_filters) !== JSON.stringify(FilteringActionsStore.state.column_filters)){
      params.push('filters=' + encodeURIComponent(JSON.stringify(FilteringActionsStore.state.column_filters)));
      FilteringActionsStore.state.column_filters.forEach( function( column ) {
        Zymewire.mixpanel_track('pivotlist filter applied', {type:column.type, column:column.full_name, filter:column.filter});
      } );
    }
    NProgress.configure({parent:'.filter_control_buttons', showSpinner:false});
    NProgress.start();
    window.location.href = '/lists/' + this.props.list_id + (params.length > 0 ) ? '?' + params.join('&') : '';
  },
  UNSAFE_componentWillMount:function(){
    const column_filters = this.props.filter_columns.map(function(column) {
      return {column:column.column_name, full_name: column.full_name, type: column.filter_options.type, filter:lodash.cloneDeep(column.current_filter)};
    });
    const orig_filters = this.props.filter_columns.map(function(column) {
      return {column:column.column_name, filter:column.current_filter};
    });
    FilteringActions.initialState(column_filters, orig_filters);
  },
  render: function () {
    const class_names = ['pivotlist_filter_controls_card'];

    if(this.state.open){
      class_names.push('swing_in_filter_controls');
    }

    const column_filter_options = this.props.filter_columns.map(function(column) {
      return {name: column.full_name, description: column.filter_options.description, type: column.filter_options.type, options:column.filter_options.options, current_filter:column.current_filter}
    });

    const filter_columns = <FilterColumns column_filter_options={column_filter_options}/>;
    const filter_options = <FilterOptions column_filter_options={column_filter_options}/>;
    const filter_buttons = [{'class_names':['button-small', 'button_green', 'filter_control_button_clear'], 'id':'clear_all_filters', 'label':'Clear all filters', 'action':this.clear_all_filters}, {'class_names':['button-small', 'button-primary', 'filter_control_button_apply'], 'id':'apply_all_filters', 'label':'Apply filters', 'action':this.apply_filters}, {'class_names':['button-small', 'button_light_grey', 'button-secondary', 'filter_control_button-secondary'], 'id':'cancel_filters', 'label':'Cancel', 'action':this.cancel_filters}];

    const filter_buttons_content = filter_buttons.map(function(button_data, index){
      return (
        <FilterControlButton key={index} button_data={button_data} click_control_button={this.click_control_button} />
      );
    }.bind(this));

    if(this.props.list_length !== this.props.filtered_length){
      const pivotlist_count = document.getElementById('pivotlist_count');
      pivotlist_count.innerHTML = 'filtered to <b>' + this.props.filtered_length + ' companies</b>';
      pivotlist_count.classList.add('pivotlist_filtered');
    }
    return (
      <div className="pivotlist_filter_controls">
        {this.state.open &&
        <div className={class_names.join(' ')}>
          <div className="pivotlist_filter_control_holder">
            <div className="pivotlist_filter_control_columns">
              {filter_columns}
            </div>
            {filter_options}
          </div>
          <div className="filter_control_buttons">
            {filter_buttons_content}
          </div>
        </div>
        }
      </div>
    );
  }
});

export default PivotlistFilterControls;
