import Reflux from "reflux";
import EventModifyModalAction from "./actions/eventModifyModalActions";
import { getAllPublishableConceptRefs } from "../helpers/administrateEvent";

const EventModifyModalStore = Reflux.createStore({
  eventData: {event: {}, admin_industry: "", saving: false},
  listenables: EventModifyModalAction,
  onInitialLoad: function (event, hide_callback, admin_industry) {
      this.eventData.event = event;
      this.hide_callback = hide_callback;
      this.eventData.admin_industry = admin_industry;
      this.trigger(this.eventData);
  },
  onSaveEventChanges: function (make_vizible) {
      var url = "/administrate_event/" + this.eventData.event.id + "/update_event_from_list";
      if(make_vizible) {
          $.post(url, {event: this.eventData.event})
              .done(function (result) {
                  // console.log(result);
                  this.eventData.event = result;
                  this.eventData.saving = false;
                  this.trigger(this.eventData);
                  EventTableAction.showEvent(this.eventData.event);
                  this.hide_callback();

              }.bind(this))
              .fail(function (xhr, ajaxOptions, thrownError) {
                  if (xhr.status == 401) {
                      alert("Un-authorized status received")
                      $("#event_modal").modal('hide');
                      window.location = "/admin_home"
                  } else if (xhr.status == 504) {
                      alert("It seems like the server is a bit too busy. Please try again later!")
                      $("#event_modal").modal('hide');
                      window.location = "/administrate_event/events"
                  }
                  else {
                      // console.log(thrownError)
                  }
              });
      }
      else{
          $.post(url, {event: this.eventData.event})
              .done(function (result) {
                  // console.log(result);
                  this.eventData.event = result;
                  this.eventData.saving = false;
                  this.trigger(this.eventData);
                  EventTableAction.updateEvent(result);
                  this.hide_callback();

              }.bind(this))
              .fail(function (xhr, ajaxOptions, thrownError) {
                  if (xhr.status == 401) {
                      alert("Un-authorized status received")
                      $("#event_modal").modal('hide');
                      window.location = "/admin_home"
                  } else if (xhr.status == 504) {
                      alert("It seems like the server is a bit too busy. Please try again later!")
                      $("#event_modal").modal('hide');
                      window.location = "/administrate_event/events"
                  }
                  else {
                      // console.log(thrownError)
                  }
              });

      }
      this.eventData.saving = true;
      this.trigger(this.eventData);
  },
  onUpdateDate: function (date) {
      this.eventData.event.dateMilli = date.valueOf();
      this.trigger(this.eventData);
  },
  onRemoveEntity: function (rec_id) {
      this.eventData.event.entities = _.reject(this.eventData.event.entities, function (ent) {
          return ent.rec_id == this;
      }, rec_id);

      const possibleIndustries = getAllPublishableConceptRefs(this.eventData.event.entities);
      let industries = this.eventData.event.industry_concept_refs;
      if (possibleIndustries.length === 1){
        this.eventData.event.industry_concept_refs = possibleIndustries;
      }
      else{
        this.eventData.event.industry_concept_refs.forEach(function (ind){
          if (!possibleIndustries.includes(ind) || possibleIndustries.length < 1)
            industries = industries.filter(e => e != ind);
          });
        this.eventData.event.industry_concept_refs = industries;
      }
      this.trigger(this.eventData);
  },
  onRemoveClinStage: function (stage) {
      this.eventData.event.independent_properties.clin_stage = _.reject(this.eventData.event.independent_properties.clin_stage, function (stage) {
          return stage == this;
      }, stage);
      this.trigger(this.eventData);
  },
  onAddClinStage: function (stage) {
      if (this.eventData.event.independent_properties) {
          if (this.eventData.event.independent_properties.clin_stage) {
              this.eventData.event.independent_properties.clin_stage.push(stage)
          }
          else {
              this.eventData.event.independent_properties["clin_stage"] = [stage]
          }
      }
      else {
          this.eventData.event["independent_properties"] = {clin_stage: [stage]}
      }
      this.trigger(this.eventData);
  },
  onAddEntity: function(entity){
      this.eventData.event.entities.push(entity);
      const allIndustries = getAllPublishableConceptRefs(this.eventData.event.entities);
      if (allIndustries.length === 1){
        this.eventData.event.industry_concept_refs = allIndustries;
      }
      this.trigger(this.eventData);
  },
  onAddIndustry: function(industry){
    this.eventData.event.industry_concept_refs.push(industry);
    this.trigger(this.eventData);
  },
  onRemoveIndustry: function(industry){
    this.eventData.event.industry_concept_refs = this.eventData.event.industry_concept_refs.filter(e => e != industry);
    this.trigger(this.eventData);
  }
});

window.EventModifyModalStore = EventModifyModalStore
export default EventModifyModalStore;
