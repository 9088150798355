import * as PropTypes from "prop-types";
import FundingQuarter from "./fundingQuarter";

/**
 * React component that displays an organization's funding events for the past 8 quarters and cumulative funding amounts for the past 3 years
 *
 * @author GJ <gordon@zymewire.com>
 * @namespace FundingEventsCard
 *
 * @component
 * @example
 * return (
 *   <FundingEventsCard {...props} />
 * )
 *
 * @param {object} props Component props
 * @param {string} props.url organization's url
 * @param {string} props.orgName organization's name
 * @param {object} props.cumulativeFunding object of cumulative funding info for 3 years
 * @param {object} props.fundingEvents object of 8 quarters of funding events
 *
 */
const FundingEventsCard = (props) => {

  const quarters = _.map(props.fundingEvents, function(fundingData, index){
    return <FundingQuarter url={props.url} key={index} quarter={index} fundingData={fundingData} orgName={props.orgName}/>
  }.bind(this));

  let cumulativeCalendarYears = null;
  let cumulativeDescription = null;
  let fundingEventsBlurb = 'over the last 8 quarters'

  if(props.cumulativeFunding.yearsAvailable) {
    fundingEventsBlurb = 'Events found over the last 8 quarters'
    cumulativeDescription = <p>calendar year funding ($USD): amount {props.orgName} has raised over the last 2 calendar years.</p>
    cumulativeCalendarYears = <CumulativeFundingCalendarYears yearsAvailable={props.cumulativeFunding.yearsAvailable} />
  }

  return (
    <div className="infographic_card_content" id="funding_events_card">
      <input type="checkbox" className="infographic_checkbox" id="more_info_funding_events_card" />
      <div className="card_top_section">
        <label className="infographic_more_info" htmlFor="more_info_funding_events_card">
          <div className="more_info_menu">
            <div className="more_info_menu_line more_info_menu_half more_info_menu_start"></div>
            <div className="more_info_menu_line"></div>
            <div className="more_info_menu_line more_info_menu_half more_info_menu_end"></div>
          </div>
        </label>
        <div className="cards_more_content">
          <div className="cards_more_data">
            <div className="cards_more_top">
              <p className="infographic_panel_title">Funding Events Found by {props.brandName}</p>
              <p className="infographic_panel_subtitle">for {props.orgName}</p>
            </div>
            <div className="cards_more_graph">
              <div className="cards_more_info">
                {cumulativeDescription}
                <p>over the last 8 quarters: number of funding events {props.orgName} has completed in the past 8 quarters.  Click the links to see those funding events.</p>
              </div>
            </div>
          </div>
          <p className="infographic_panel_title">Funding Events Found by {props.brandName}</p>
          {cumulativeCalendarYears}
          <p className="infographic_panel_subtitle">{fundingEventsBlurb}</p>
          <div className="funding_by_quarters">
            {quarters}
          </div>
          <div className="cards_more_float"></div>
        </div>
      </div>
    </div>
  );
};

FundingEventsCard.propTypes = {
  url: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  cumulativeFunding: PropTypes.shape({
    yearsAvailable: PropTypes.array
  }).isRequired,
  fundingEvents: PropTypes.array.isRequired
};

window.FundingEventsCard = FundingEventsCard;
export default FundingEventsCard;
