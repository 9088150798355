import Reflux from "reflux";

import SFAccountLoaderActions from "../actions/salesForce/sFAccountLoaderActions";

const SFAccountLoaderActionsStore = Reflux.createStore({
  listenables: SFAccountLoaderActions,
  onRefreshAccount: function(account_id){
    this.trigger(account_id);
  }
});

window.SFAccountLoaderActionsStore = SFAccountLoaderActionsStore;
export default SFAccountLoaderActionsStore;
