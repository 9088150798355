const QueryClinStatus = (props) => {
  if (props.search.clin_status) {
    var count = 0;
    var facets = props.search.clin_status.map(function (status, index) {
      var stat_string = count > 0 ? "OR " + status : status;
      count = 1;
      return (<p key={index}><b>{stat_string}</b></p>);
    });
    return (<div>{facets}</div>);
  }
  else {
    return false;
  }
}

export default QueryClinStatus;
