import Reflux from "reflux";
import ListFeedAction from "../actions/pivotLists/listFeedActions";

const ListFeedActionStore = Reflux.createStore({
  data: {},
  listenables: ListFeedAction,
  onInitialLoad: function (initial_data) {
    this.data = _.extend({
      creating_feed: false
    }, initial_data);
    this.trigger(this.data);
  },
  onCreateFeed: function(){
    this.data.creating_feed = true;
    var target_url = "/feeds/" + this.data.list_id + "/create_feed_for_list";
    $.post(target_url, function (result) {
      LegacyModalService.openModal(LDSFeedSaveModal, {
        legacyModalContent: result,
      });
    }.bind(this));
  }
});

window.ListFeedActionStore = ListFeedActionStore;
export default ListFeedActionStore;
