import Reflux from "reflux";

import CompanySettingsActions from "../actions/pivotLists/companySettingsActions";

const CompanySettingsStore = Reflux.createStore({
  listenables: CompanySettingsActions,
  init: function() {
    this.state = []
  },
  onAdjustCompanySettings: function(company_settings){
    this.state = company_settings;
    this.trigger(this.state);
  }
});

window.CompanySettingsStore = CompanySettingsStore;
export default CompanySettingsStore;

