import React from "react";
import PropTypes from "prop-types";

function ButtonGroup({ id, size, children, className }) {
  return (
    <div
      id={id}
      className={`buttonGroup-${size || "large"} ${className}`}
      role="group"
    >
      {children}
    </div>
  );
}

export const ButtonGroupPropTypes = {
  size: PropTypes.oneOf(["small", "medium", "large", "segmented"]).isRequired,
  children: PropTypes.node.isRequired,
};

ButtonGroup.propTypes = ButtonGroupPropTypes;
export default ButtonGroup;
