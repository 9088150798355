import React from "react";
import PropTypes from "prop-types";
import Banner from "@javascript/reactComponents/lds/notifications/Banner";
import { useContext, useRef } from "react";
import BannerContext from "./BannerContext";
import Icon from "../Icon";
import { isTextElementWrapped } from "@javascript/reactComponents/utilities/DOMUtilities";

export default function BannerHeading({
  children,
  className,
  leadingIcon = null,
  trailingAction = null,
  variant,
}) {
  const headingRef = useRef()
  const context = useContext(BannerContext);
  const { inverse, onDismiss } = context;

  variant ||= context.variant;
  leadingIcon ||= (
    <Icon filled={inverse} className={"icon-leading"} name={variant} />
  );

  const classNames = [
    `lds-banner-heading`,
    `lds-banner-heading-${variant}${inverse ? "-inverse" : ""}`,
  ];

  if (className) {
    classNames.push(className);
  }

  const alignSelfStart = isTextElementWrapped(headingRef.current) ? "align-self-start" : "center";

  return (
    <div className={classNames.join(" ")}>
      <div className={"lds-banner-heading-leading-content"} ref={headingRef} style={{ alignSelf: alignSelfStart }}>
        {leadingIcon}
        {children}
      </div>

      <div className={"lds-banner-heading-trailing-content"}>
        {trailingAction && (
          <div className="lds-banner-heading-trailing-action">
            {trailingAction}
          </div>
        )}

        {onDismiss && <CloseIcon />}
      </div>
    </div>
  );
}

function CloseIcon() {
  const { onDismiss } = useContext(BannerContext);

  return (
    <div className="actionIcon-trailing-container">
      <Icon role="dismiss" className="icon-trailing" name="cancel" onClick={onDismiss} />
    </div>
  );
}

BannerHeading.propTypes = {
  className: PropTypes.string,
  leadingIcon: PropTypes.element,
  trailingAction: PropTypes.element,
  variant: Banner.propTypes.variant,
};
