import React from "react";
import PropTypes from "prop-types";

const FeedsIndexTableHeader = ({
  className = "",
  field,
  label,
  onSortClick,
  sortData,
}) => {

  const classNames = [
    "tablesorter-header",
    sortData.field === field ? `tablesorter-header${sortData.asc ? 'Asc' : 'Desc'}` : "tablesorter-headerUnsorted",
  ];

  if(className) {
    classNames.push(className);
  }

  return (
    <>
      <th onClick={() => onSortClick(field)} className={classNames.join(" ")}>{label}</th>
    </>
  );
};

FeedsIndexTableHeader.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onSortClick: PropTypes.func.isRequired,
  sortData: PropTypes.object.isRequired,
};

export default FeedsIndexTableHeader;
