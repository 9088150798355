import Reflux from "reflux";
import FacetSearchStore from "../../stores/facetSearchStore";

const FacetSearchCriteriaQueryTerm = createReactClass({
  mixins: [
    Reflux.connectFilter(
      FacetSearchStore,
      ({searchTerm: {queryTerm, feedPageQueryTerm}}) =>
      ({queryTerm, feedPageQueryTerm})
    )
  ],

  getInitialState() {
    return {
      queryTerm: FacetSearchStore.data.searchTerm.queryTerm,
      feedPageQueryTerm: FacetSearchStore.data.searchTerm.feedPageQueryTerm,
    };
  },

  render(){
    let queryTerm = this.state.queryTerm;
    let feedPageQueryTerm;
    const originalFeedQuery = Zymewire.original_feed_query;

    if(originalFeedQuery){
      if (originalFeedQuery.query && $.trim(originalFeedQuery.query).length > 0){
        queryTerm = _.unescape(queryTerm);
        feedPageQueryTerm = decodeHTMLContent(_.unescape(originalFeedQuery.query));
      }
    }

    if (!queryTerm && !feedPageQueryTerm){
      return (null);
    }

    let editable = true;

    if (queryTerm && feedPageQueryTerm){
      editable = queryTerm.toLowerCase() !== feedPageQueryTerm.toLowerCase();
    }

    const details = {
      queryTerm: queryTerm,
      feedPageQueryTerm: feedPageQueryTerm,
      editable: editable
    }

    let info = <QueryTermInfo {...details} />;

    if (editable){
      info = (
        <a className="action_link" onClick={ () => {clear_text_query();}}>
          <QueryTermInfo {...details} />
        </a>
      )
    }

    return (
      <div>
        {info}
      </div>
    )
  }
})

const QueryTermInfo = ({ queryTerm, feedPageQueryTerm, editable }) => {
  let startingIndex = decodeURI(queryTerm).toLowerCase().indexOf(decodeURI(feedPageQueryTerm).toLowerCase())
  let original_overlap = feedPageQueryTerm ? startingIndex > -1 : false;
  let matching_term;

  if (queryTerm && feedPageQueryTerm && !original_overlap){
    matching_term = <b>{queryTerm} with {feedPageQueryTerm}</b>
  }
  else if (queryTerm){
    matching_term = <b>{queryTerm}</b>
  }
  else{
    matching_term = <b>{feedPageQueryTerm}</b>
  }

  return (
    <div className="filter_item_property" title="You are filtering your search by this query.">
      {editable &&
        <div className="filter_item_close" style={{display:"inline-block"}}>
          <i className="icon-remove"></i>
        </div>
      }
      <div style={{display:"inline-block"}}>
        <p style={{marginLeft:"-5px",fontSize:"13px",width:"235px",overflow:"hidden",textOverflow:"ellipsis"}}>
          Matching {matching_term}
        </p>
      </div>
    </div>
  )
}

window.FacetSearchCriteriaQueryTerm = FacetSearchCriteriaQueryTerm;
export default FacetSearchCriteriaQueryTerm;
