import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { FeedsIndexContext } from "../../stores/FeedsIndex";

import CheckboxItem, { CHECKBOX_STATES } from "../lds/selection_controls/CheckboxItem";
import Icon from "../lds/Icon";

import { updateUserHomeSettings } from "../../helpers/feedManagement";

import EventCheckEmailSettings from "../events/EventCheckEmailSettings";
import NewEventChip from "../shared/chips/NewEventChip";

const FeedsIndexTableRow = ({
  feed,
  group,
}) => {

  const [data, setData] = useState(feed);
  const { getCheckboxArray, toggleCheckbox } = useContext(FeedsIndexContext);

  const groupCheckboxArray = getCheckboxArray(group);
  const deleteChecked = _.include(groupCheckboxArray, feed.ids.savedFeed) ? CHECKBOX_STATES.selected : CHECKBOX_STATES.unselected;

  const onShowOnUserHomeSelected = (value) => {
    updateUserHomeSettings(value);
    setData({ ...data, displayOnUserHome: !data.displayOnUserHome });
  };

  const updateDeleteFeedCheckbox = (value) => {
    toggleCheckbox(group, value);
  };

  const classNames = `rspec-feeds-index-${group}-row ${
    deleteChecked === CHECKBOX_STATES.selected ? "delete_this_entry" : ""
  }`;

  const canUseNewCompaniesOnFeed = _.contains(Zymewire.user.feature_tags, "new_companies_on_feed")

  const newCompaniesOnFeed = (canUseNewCompaniesOnFeed && feed.newCompanies) ? "*" : "";

  const newEvents = feed.newEventCount > 0 ? <NewEventChip label={ feed.newEventCount } size="small" href={`/feeds/${feed.ids.savedFeed}/display_saved_feed`}/> : "-";

  const lastChecked = moment.parseZone(feed.lastChecked).format('MMM DD YYYY');
  // const lastChecked = moment(feed.lastChecked).format("MMM D YYYY");

  return (
    <tr className={classNames} id={`feed_${feed.ids.userFeedMonitor}`}>
      <td className="feed_name"><a href={`/feeds/${feed.ids.savedFeed}/display_saved_feed`}>{feed.name} {newCompaniesOnFeed}</a>
      </td>
      <td className="feed_new_events">{newEvents}</td>
      <td>{feed.eventsThisMonth}</td>
      <td className="feed_last_checked">{lastChecked}</td>
      <td id={`home_display_${feed.ids.savedFeed}`}>
        <div className="user_home_checkbox">
          <Icon name={"home-filled"} />
          <CheckboxItem
            className="rspec_display_feed_checkbox"
            selection={
              data.displayOnUserHome ? CHECKBOX_STATES.selected : CHECKBOX_STATES.unselected
            }
            onClick={onShowOnUserHomeSelected}
            value={feed.ids.savedFeed}
          />
        </div>
      </td>
      <td className="feed_email_display">
        <div id={`event_check_email_settings_${feed.ids.savedFeed}`} title={`Get ${I18n.t("brand.name")} to check this feed automatically and email you any new activity`}>
          <EventCheckEmailSettings id={feed.ids.savedFeed} currentSetting={feed.eventCheckEmailSetting} />
        </div>
      </td>
      <td className="feed_delete_cell">
          <CheckboxItem
            className="delete_checkbox_for_feeds"
            id={`rspec_${feed.ids.savedFeed}`}
            onClick={updateDeleteFeedCheckbox}
            selection={deleteChecked}
            value={feed.ids.savedFeed}
          />
      </td>
    </tr>
  );
};

FeedsIndexTableRow.propTypes = {
  feed: PropTypes.object.isRequired,
  group: PropTypes.string.isRequired,
};

export default FeedsIndexTableRow;
