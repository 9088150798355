import UnorderedList from "./unorderedList";

const TwoColumnList = (props) => {
  const columnLength = 5
  const start = props.page * props.slice_size;
  const end = start + props.slice_size;
  const items = props.list.slice(start, end);
  let returnDisplay;

  if (items.length > columnLength) {
    const leftList = items.slice(0, columnLength);
    const rightList = items.slice(columnLength);
    returnDisplay =
      <div className="row-fluid">
        <div className="span6">
          <UnorderedList key="list_1" items_list={leftList}/>
        </div>
        <div className="span6">
          <UnorderedList key="list_2" items_list={rightList}/>
        </div>
      </div>;
  }
  else {
    returnDisplay =
      <div className="row-fluid">
        <div className="span6">
          <UnorderedList key="list_1" items_list={items}/>
        </div>
      </div>;
  }
  return returnDisplay;
};

window.TwoColumnList = TwoColumnList;
export default TwoColumnList;
