import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

function NavItem({
  children,
  className = "",
  href = "",
  hrefOnClick,
  icon,
  id,
  title,
  variant,
  selectedItem,
  trailingIcon,
}) {
  let attributes;
  if (href) {
    attributes = { href };
  }
  if (hrefOnClick) {
    attributes.onClick = hrefOnClick;
  }
  // Storybook blows up with this, browser does not...
  // so title is explicitly set in the anchor
  // if (title) {
  //   attributes.title = title;
  // }
  const filled = id === selectedItem ? true : false;
  const selected = id === selectedItem ? "selected" : "";
  return (
    <a
      id={id}
      title={title}
      className={`nav-${variant} ${selected} ${className}`}
      {...attributes}
    >
      {icon && <Icon filled={filled} name={icon} />}
      {children}

      {trailingIcon && <Icon name={trailingIcon} />}
    </a>
  );
}

export const NavItemPropTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  hrefOnclick: PropTypes.func,
  icon: PropTypes.string,
  trailingIcon: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.oneOf(["button", "link", "logo", "trigger"]).isRequired,
  selectedItem: PropTypes.string,
};

NavItem.propTypes = NavItemPropTypes;
export default NavItem;
