import Reflux from "reflux";

import pivotListColumnSettingsActions from "../actions/pivotLists/pivotlistColumnSettingsActions";

const pivotListColumnSettingsStore = Reflux.createStore({
  listenables: pivotListColumnSettingsActions,
  init: function () {
    this.state = {
      fixedColumnsCount: 1,
      interactableColumnsCount: 4,
      columnChoicesPerPage: 0,
      totalColumnChoicePages: 0,
      listTableContainerHeight: 0
    };
  },
  onInitialState: function(interactableColumnsCount, columnChoicesPerPage, totalColumnChoicePages, listTableContainerHeight){
    this.state.interactableColumnsCount = interactableColumnsCount;
    this.state.columnChoicesPerPage = columnChoicesPerPage;
    this.state.totalColumnChoicePages = totalColumnChoicePages;
    this.state.listTableContainerHeight = listTableContainerHeight;
    this.trigger(this.state);
  }
});

window.pivotListColumnSettingsStore = pivotListColumnSettingsStore;
export default pivotListColumnSettingsStore;
