import CategoryButtonGroup from "./CategoryButtonGroup";
import SearchFilters from "./SearchFilters";
import ListPageFooter from "./ListPageFooter";
import ListTable from "./ListTable";
import createReactClass from "create-react-class";
import UserListsStore from "../../../stores/lists/UserListsStore";
import UserListsActions from "../../../stores/actions/lists/UserListsActions";
import { DEFAULT_CATEGORY } from "../../../constants/lists/list_constants";
import ListDeleteModal from "./ListDeleteModal";
import NoLists from "./NoLists";
import ListSearchDescription from "./ListSearchDescription"

const UserListsComponent = createReactClass({
  mixins: [Reflux.connect(UserListsStore)],
  getInitialState() {
    return {
      ...UserListsStore.data,
      isModalOpen: false,
    };
  },
  render() {
    const {
      categories,
      selectedCategory,
      sortOptions,
      selectedSortOption,
      lists,
      selectedLists,
      currentCount,
      totalCount,
      isModalOpen,
      params: { name },
    } = this.state;

    if (!lists) return null;

    const blankSearch = name === "";
    const enableDeleteBtn = selectedLists.size > 0;
    const showTag = selectedCategory === DEFAULT_CATEGORY;

    let footerText = `Showing ${currentCount} of ${categories[selectedCategory]} lists `;
    if (!blankSearch) {
      footerText += "based on search";
    }

    const hasLists = categories[selectedCategory] > 0
    const hasFilteredLists = lists.length > 0;

    const listSearchResults = <>
      <ListTable
        lists={lists}
        editable={true}
        showHeader
        showTag={showTag}
        enableDeleteBtn={enableDeleteBtn}
        selectedRows={selectedLists}
        onListSelected={(list) => UserListsActions.selectList(list)}
        onDeleteButtonClick={() => {
          this.setState({ isModalOpen: true });
        }}
      />

      <ListPageFooter
        showSelectAll
        disableLoadMore={lists.length === totalCount}
        footerText={footerText}
        currentCount={currentCount}
        onLoadMore={UserListsActions.loadMore}
        isAllSelected={lists.length === selectedLists.size}
        onSelectAllLists={UserListsActions.selectAllLists}
      />
    </>

    return (
      <>
        <CategoryButtonGroup
          categories={categories}
          selected={selectedCategory}
          onCategorySelected={UserListsActions.selectCategory}
        />

        <div style={{ marginBottom: "var(--space-600)" }} />

        { hasLists || isModalOpen ?
          <div className="container w-1200">
            <SearchFilters
              searchValue={name}
              sortOptions={sortOptions}
              selectedSortOption={selectedSortOption}
              onSortOptionUpdate={UserListsActions.sort}
              onSearchValueUpdate={UserListsActions.searchName}
            />

            { !blankSearch && (
              <ListSearchDescription
                searchValue={name}
                selectedCategory={selectedCategory}
                onClearSearch={UserListsActions.clearSearch}
              />
            )}

            { hasFilteredLists ? listSearchResults : <NoLists /> }

            { isModalOpen && (
              <ListDeleteModal
                onDeleteLists={() => {
                  UserListsActions.confirmListsDeletion();
                }}
                cancelModal={() => {
                  this.setState({ isModalOpen: false });
                }}
                resetCategory={() => {
                  UserListsActions.selectCategory(DEFAULT_CATEGORY);
                }}
                selectedLists={selectedLists}
                lists={lists}
              />
            )}
          </div> : <NoLists />
        }
      </>
    );
  },
});

export default UserListsComponent;
