import EventDisplayCell from "./eventDisplayCell";
import EventStatusCell from "./eventStatusCell";
import EventActionCell from "./eventActionCell";
import EventCreatedByCell from "./eventCreatedByCell";
import EventEditCell from "./eventEditCell";

const EventRow = (props) => {
  const eventRowId = "event_row_" + props.event.id;
  return (
    <tr id={eventRowId} className="rspec-event-row">
      <EventDisplayCell {...props} />
      <EventStatusCell {...props} />
      <EventActionCell {...props} />
      <EventCreatedByCell {...props} />
      <EventEditCell {...props} />
    </tr>
  );
};

export default EventRow;
