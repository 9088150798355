import Reflux from "reflux"
import { UserActions, UserStoreMutations } from "./actions/userActions"

window.UserStore = Reflux.createStore({
  listenables: [
    UserActions
  ],
  init() {
    this.data = {
      session: {
        ttl: 1800,  // Number of seconds before session expires
        checkFrequency: 30000,
        checkInterval: null,
      },
      onAdminSite: false
    }
  },

  /* Include any mutation functions here. Note that each mutation function MUST have a unique name! */
  ...UserStoreMutations
})
