const StudiesCard = (props) => {
  return (
    <div className="infographic_card_content" id={props.card_id}>
      <input type="checkbox" className="infographic_checkbox" id={props.checkbox_id}/>
      <div className="card_top_section">
        <label className="infographic_more_info" htmlFor={props.checkbox_id}>
          <div className="more_info_menu">
            <div className="more_info_menu_line more_info_menu_half more_info_menu_start"></div>
            <div className="more_info_menu_line"></div>
            <div className="more_info_menu_line more_info_menu_half more_info_menu_end"></div>
          </div>
        </label>
        <div className="cards_more_content">
          <div className="cards_more_data">
            <div className="cards_more_top">
              <p className="infographic_panel_title">{props.title}</p>
              <p className="infographic_panel_subtitle">{props.subtitle}</p>
            </div>
            <div className="cards_more_graph">
              <div className="cards_more_info">
                {props.org_name} has {props.about} {props.count} studies {props.subtitle}.  This is as according to clinicaltrials.gov and updated {props.comparable_date}
              </div>
            </div>
          </div>
          <p className="infographic_panel_title">{props.title}</p>
          <p className="infographic_panel_subtitle">{props.subtitle}</p>
          <div className="infographic_circle infographic_empty_circle" id={props.card_id + "_count"}>{props.count}</div>
          <div className="cards_more_float"></div>
        </div>
      </div>
      <div className="infographic_comparable_info">
        <p className="infographic_comparable_company"><span className="infographic_comparable_compared">* according to </span>clinicaltrials.gov</p>
      </div>
    </div>
  );
};

window.StudiesCard = StudiesCard;
export default StudiesCard;
