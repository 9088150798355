import Button from "@javascript/reactComponents/lds/Button";

const SfContactButton = (props) => {
  const handleClick = (e) => {
    e.preventDefault();
    props.parent_method("contact", props.sf_org_id)
  };
  return (
    <Button
      onClick={handleClick}
      size="medium"
      variant="secondary"
    >
      Create New Contact at { props.sf_org_name }
    </Button>
  );
};

export default SfContactButton;
