import Tab from "../lds/tabs/Tab";
import TabAnchor from "../lds/tabs/TabAnchor";
import TabGroup from "../lds/tabs/TabGroup";

export default function FlaggedEventsTabs({ myFlaggedEvents }) {
  const selectedTab = myFlaggedEvents ? "personal" : "colleague";

  return (
    <TabGroup selectedTab={selectedTab} animated={false}>
      <TabAnchor
        id="my_flagged_events_tab"
        href="/flagged_events"
        label="My Flagged Events"
        value="personal"
        leadingIcon="flag"
      />
      <TabAnchor
        id="colleague_flagged_events_tab"
        href="/flagged_events?company_wide=true"
        label="Colleagues’ Flagged Events"
        value="colleague"
        leadingIcon="team"
      />
    </TabGroup>
  );
}

window.FlaggedEventsTabs = FlaggedEventsTabs;
