import BootstrapRow from "../generic/htmlElements/bootstrapRow";
import BootstrapSpan from "../generic/htmlElements/bootstrapSpan";
import BootstrapFullWidthRow from "../generic/htmlElements/bootstrapFullWidthRow";
import ZoominfoResultsText from "./zoomInfo/zoominfoResultsText";
import ZoominfoDisplayResults from "./zoomInfo/zoominfoDisplayResults";
import PaginationBar from "../generic/pagination/paginationBar";

const ContactSearchResults = createReactClass({
  getInitialState: function () {
    return {
      page: this.props.page,
      paginationTop: 0,
      locationRef: null
    };
  },
  UNSAFE_componentWillReceiveProps: function(nextProps) {
    if(this.state.page != nextProps.page){
      this.setState({page: nextProps.page});
    }
  },
  componentDidUpdate: function() {
    if(this.state.locationRef === this.refs.bottomPaginationRef) {
      const paginationTop = this.getBottomPaginationTop();
      if(paginationTop !== this.state.paginationTop) {
        const diff = paginationTop - this.state.paginationTop;
        window.scrollBy(0, diff);
      }
    }
  },
  changePage: function (options, e) {
    e.preventDefault();
    e.stopPropagation();
    const paginationTop = this.getBottomPaginationTop();
    this.setState({locationRef: options.locationRef, paginationTop: paginationTop});
      ContactSearchAction.changePage(options.page);
  },
  getBottomPaginationTop: function() {
    const paginationRef = ReactDOM.findDOMNode(this.refs.bottomPaginationRef);
    return paginationRef.getBoundingClientRect().top;
  },

  render: function () {

    let currentPage = this.state.page;
    let changePage = this.changePage;
    let start_point = this.state.page;

    let displayLength = this.props.display_length;
    let hasResults = this.props.has_results;

    let secondResults = hasResults ? <ZoominfoResultsText totalResults={this.props.search_results.max_results} /> : null;

    return (
      <div>
        <BootstrapRow>
          <BootstrapSpan wide="3">
            <PaginationBar id="top_pagination_contact_search" currentPage={currentPage} totalResults={displayLength} changePage={changePage} pageSize={20} />
          </BootstrapSpan>
          <BootstrapSpan wide="9">
            <div style={{paddingTop:"14px"}}>
              <ZoominfoResultsText totalResults={this.props.search_results.max_results} />
            </div>
          </BootstrapSpan>
        </BootstrapRow>
        <BootstrapFullWidthRow fluid>
          <ZoominfoDisplayResults {...this.props} start_point={start_point} />
        </BootstrapFullWidthRow>
        <BootstrapRow>
          <BootstrapSpan wide="3">
            <PaginationBar ref="bottomPaginationRef" id="bottom_pagination_contact_search" currentPage={currentPage} totalResults={displayLength} changePage={changePage} pageSize={20} />
          </BootstrapSpan>
          <BootstrapSpan wide="9">
            <div style={{paddingTop:"14px"}}>
              {secondResults}
            </div>
          </BootstrapSpan>
        </BootstrapRow>
      </div>
    )
  }
})

export default ContactSearchResults;
