import Reflux from "reflux"

/**
 * Reflux action object
 *
 * @namespace FacetSearchLocationActions
 * @param [Array] of action names for use with FacetSearchLocationMutations
 */
const FacetSearchLocationActions = Reflux.createActions([
  "clearLocations",
  "deselectLocation",
  "locationWillMount",
  "myTerritoryClick",
  "openLocationSelectorModal",
  "removeLocation",
  "saveChanges",
  "selectLocation",
  "updateLocationState",
]);

/**
 * Reflux store object
 *
 * @namespace FacetSearchLocationMutations
 *
 * Store listens to FacetSearchLocationActions events and updates its data object.
 * These methods are used from both inside and outside the relevant components to manage state.
 */

const FacetSearchLocationMutations = {
  cleanSelectedLocations: function(newLocation, existingLocations){
    if(newLocation.code.length === 2){
      return _.reject(existingLocations, function(location){return location.code.startsWith(newLocation.code)});
    }
    else if(newLocation.code.charAt(2) !== '_'){
      //assume it must be a continent
      const continent = _.find(this.data.location.fullLocationList, function(location){return location.code === newLocation.code});
      if(continent){
        _.each(continent.children, function(country){existingLocations = this.cleanSelectedLocations(country, existingLocations)}, this);
      }
      return existingLocations;
    }
    else{
      return existingLocations;
    }
  },
  onClearLocations: function(){
    this.data.location.searchLocations = new Array();
    this.data.location.changed = true;
    this.trigger(this.data);
  },
  onDeselectLocation: function(locationCode){
    this.data.location.searchLocations = _.reject(this.data.location.searchLocations, function(searchLocation){ return searchLocation.code === locationCode});
    this.data.location.changed = true;
    this.trigger(this.data);
  },
  onLocationWillMount: function(seedData) {
    const sortedLocations = _.sortBy(Zymewire.region_list.fullLocationList, 'name');
    this.data.location.fullLocationList = sortedLocations;
    this.data.location.searchLocations = seedData.searchLocations;
    this.data.location.selectedLocation = seedData.selectedLocation;
    this.trigger(this.data);
  },
  onMyTerritoryClick: function(){
    if(_.contains(this.data.location.selectedLocationCodes, "my_territory")){
      this.data.location.selectedLocationCodes = _.reject(this.data.location.selectedLocationCodes, function (ref) {
        return ref === "my_territory";
      });
    }
    else{
      this.data.location.selectedLocationCodes.push('my_territory');
    }
    doEventSearch();
  },
  onOpenLocationSelectorModal: function (selectedLocation) {
    const locationsConverted = find_the_needle(Zymewire.region_list, 'code', this.data.location.selectedLocationCodes);
    ReactDOM.render(React.createElement(LocationSelectorPanel, {
      searchLocations: locationsConverted,
      selectedLocation: selectedLocation
    }), document.getElementById("modal_content"));

    open_modal_control();
  },
  onRemoveLocation: function(locationCode){
    this.data.location.selectedLocationCodes = _.reject(this.data.location.selectedLocationCodes, function (selectedLocationCode) {
      return selectedLocationCode === locationCode
    });
    if(locationCode === "my_territory"){
      $("#loc_my_territory").prop('checked', false);
    }
    doEventSearch();
  },
  onSaveChanges: function(){
    // is this going to cause re-renders galore?
    this.data.location.selectedLocationCodes = _.map(this.data.location.searchLocations, function(searchLocation){ return searchLocation.code; })
    if ($("#loc_my_territory").is(':checked')) {
      this.data.location.selectedLocationCodes.push('my_territory');
    }
    this.data.location.selectedLocationCodes = _.uniq(this.data.location.selectedLocationCodes);
    doEventSearch()
    close_modal_control()
    setTimeout(function() {
      ReactDOM.unmountComponentAtNode(document.getElementById('modal_content'));
    }, 400);
  },
  onSelectLocation: function(location){
    this.data.location.searchLocations = this.cleanSelectedLocations(location, this.data.location.searchLocations);
    this.data.location.searchLocations.push(location);
    this.data.location.changed = true;
    this.data.location.searchLocations = _.sortBy(this.data.location.searchLocations, function(searchLocation){ return searchLocation.parent + "" + searchLocation.name;})
    this.trigger(this.data);
  },
  onUpdateLocationState: function(locationCodes) {
    this.data.location.selectedLocationCodes = _.uniq(locationCodes);
    this.trigger(this.data);
  }
};

window.FacetSearchLocationActions = FacetSearchLocationActions
export {
  FacetSearchLocationActions,
  FacetSearchLocationMutations
}
