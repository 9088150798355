import React, { useContext } from "react";
import { DeleteModalContext } from "../ListDeleteModal";
import DeleteListButtons from "../DeleteListButtons";
import CloseButton from "../CloseButton";

const ListDeleteModalActionsBar = () => {
  const { deleteState } = useContext(DeleteModalContext);

  return (
    deleteState === "preview" ? <DeleteListButtons /> : <CloseButton />
  )
}

export default ListDeleteModalActionsBar;