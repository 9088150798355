import React, { useContext } from 'react';
import Button from '../../lds/Button';
import { TableContext } from './ListTable';

function ListTableHeader() {
  const { showHeader, editable, enableDeleteBtn, onDeleteButtonClick } = useContext(TableContext);

  let deleteButton = null;

  if (editable) {
    const variant = enableDeleteBtn ? "critical" : "disable";

    deleteButton = (
      <th className={["list-table-column", "text-center"].join(" ")}>
        <Button
          size="small"
          variant={variant}
          id="delete_lists"
          icon="delete"
          onClick={onDeleteButtonClick}
        >
          Delete
        </Button>
      </th>
    );
  }

  const listNameStyles = {
    width: "70%",
  }

  return (
    <thead className="list-table-header">
      {
        showHeader &&
        <tr>
          <th className="list-table-column type-body-bold-large" style={listNameStyles}>List Name</th>
          <th className="list-table-column type-body-bold-large text-center">Number of Entries</th>
          {deleteButton}
        </tr>
      }

    </thead>
  )
}

export default ListTableHeader;
