import Reflux from "reflux";

import FlagContactActions from "../actions/contactSearch/flagContactActions";

const FlagContactStore = Reflux.createStore({
  listenables: FlagContactActions,
  init: function(){
    this.data = {
      contactDetails: {
        orgName: '',
        organizationId: '',
        contactId: '',
        personId: '',
        deliverability: ''
      }
    };
  },
  onFlagContact: function(contactDetails){

    $("body").addClass("wait");

    this.data.contactDetails.orgName = contactDetails.orgName;
    this.data.contactDetails.organizationId = contactDetails.organizationId;
    this.data.contactDetails.contactId = contactDetails.contactId;

    this.data.contactDetails.personId = contactDetails.personId;
    this.data.contactDetails.deliverability = contactDetails.deliverability;
    this.trigger(this.data);

    const url =  [location.protocol, '//', location.host, location.pathname].join('') + "/rolodexes/flag";

    const flagParams = {org_name: contactDetails.orgName, organization_id: contactDetails.organizationId, contact_id: contactDetails.contactId, person_id: contactDetails.personId, deliverability: contactDetails.deliverability}
    $.get(url, flagParams, function(response){
      $("body").removeClass("wait");
      ContactSearchAction.updateRevealedContacts(response.current_contacts);
    }.bind(this));

  }
});

window.FlagContactStore = FlagContactStore;
export default FlagContactStore;
