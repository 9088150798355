import TerritoryHolder from "./territoryHolder";

const TerritorySelectorHolder = (props) => {
  const territoryIsContainedInList = (loc_code, territoryList) => {
    const res = _.findWhere(territoryList, {code:loc_code});
    return typeof res !== "undefined";
  };
  const indent = 0;
  const parentIsOpen = "open";
  const locations = _.reject(props.fullLocationList, function(child){return territoryIsContainedInList(child.name, props.userTerritoryList) }).map(function(location, index){
    return <TerritoryHolder key={index} parent_open={parentIsOpen} indent={indent} location={location} userTerritoryList={props.userTerritoryList} territoryIsContainedInList={territoryIsContainedInList} />
  });
  return (
    <div className="location_selector_box territory_edit locations_available">
      {locations}
    </div>
  );
  
};

export default TerritorySelectorHolder;
