import EventOrgEntities from "./eventOrgEntities";
import EventNonOrgEntities from "./eventNonOrgEntities";
import EventProperties from "./eventProperties";

const EventEntities = (props) => {
  const orgs = _.filter(props.event.entities, function (ent) {
    return ent.ent_type === "Organization";
  });
  const otherEntities = _.filter(props.event.entities, function (ent) {
    return ent.ent_type !== "Organization";
  });
  const tableStyle = {marginBottom: "-5px", width: "100%"};
  const headerStyle = {borderStyle: "none", paddingBottom: "0px", width: "50%"};
  const cellStyle = {borderStyle: "none", width: "50%"};
  return (
    <table style={tableStyle} className="rspec-event-entities">
      <thead>
        <tr>
          <th style={headerStyle} className="rspec-event-org-entities-header">Involving</th>
          <th style={headerStyle} className="rspec-event-non-org-entities-header">Entities and properties</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={cellStyle}>
            <EventOrgEntities orgs={orgs}/>
          </td>
          <td style={cellStyle}>
            <EventNonOrgEntities ents={otherEntities}/>
            <EventProperties {...props} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default EventEntities;
