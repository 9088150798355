const SfContactLastActivity = (props) => {
  let last_act;
  let temp_date;
  if(props.last_activity){
    temp_date = moment(props.last_activity).add(12, 'h');
    last_act = temp_date.format("MMMM Do, YYYY");
  }
  else{
    temp_date = moment(props.created_date).add(12, 'h');
    last_act = "None listed (record added " + temp_date.format("MMMM Do, YYYY") + ") ";
  }
  return (<p className="sf_contact_details">Last activity: {last_act}</p>);
};

export default SfContactLastActivity;
