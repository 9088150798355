import React, { useState } from "react";
import PropTypes from "prop-types";

import { FeedsIndexProvider } from "../../stores/FeedsIndex";

import Icon from "../lds/Icon";

import FeedsIndexDeleteButton from "./FeedsIndexDeleteButton";
import FeedsIndexSelectAll from "./FeedsIndexSelectAll";
import FeedsIndexTableHeader from "./FeedsIndexTableHeader";
import FeedsIndexTableRow from "./FeedsIndexTableRow";

const FeedsIndexTable = ({
  feeds,
  group,
  tableData,
}) => {

  const [initialFeeds, setInitialFeeds] = useState([...feeds]);

  const [sortData, setSortData] = useState({ field: "name", asc: true });

  const changeSort = (field) => {
    let ascending = true;
    if (sortData.field === field && sortData.asc === true) {
      ascending = false;
    }
    setSortData({ field: field, asc: ascending });
  }

  const normalizeValue = (value) => {
    if (typeof value === "number") {
      return value;
    }
    if (typeof value === "string") {
      const date = new Date(value);
      if (!isNaN(date.getTime())) {
        return date;
      }
      return value.toLowerCase();
    }
    if (value instanceof Date) {
      return value;
    }
    return value;
  };

  const sortedFeeds = [...initialFeeds].sort((a, b) => {
    const valueA = normalizeValue(a[sortData.field]);
    const valueB = normalizeValue(b[sortData.field]);

    if (valueA < valueB) {
      return sortData.asc === true ? -1 : 1;
    }
    if (valueA > valueB) {
      return sortData.asc === true ? 1 : -1;
    }
    return 0;
  });

  const allIds = [];

  const feedsIndexTableBody = sortedFeeds.map((feed) => {
    allIds.push(feed.ids.savedFeed);
    return (
        <FeedsIndexTableRow key={feed.ids.savedFeed} feed={feed} group={group} />
    );
  });

  const tableHeaderData = [
    { className: "", field: "name", label: tableData["label"] },
    { className: "w-80", field: "newEventCount", label: "New Events" },
    { className: "w-80", field: "eventsThisMonth", label: "Last month" },
    { className: "w-100", field: "lastChecked", label: "Last Checked" },
  ];

  const tableHeaders = tableHeaderData.map(({ className, field, label }, index) => {
    return (
      <FeedsIndexTableHeader
        className={className}
        field={field}
        key={index}
        label={label}
        onSortClick={changeSort}
        sortData={sortData}
      />
    );
  });

  const deleteButton = <FeedsIndexDeleteButton group={group} />;
  const selectAllCheckbox = <FeedsIndexSelectAll allIds={allIds} group={group} />;
  const tableId = `feeds-index-${group}-table`;

  return (
    <FeedsIndexProvider feeds={initialFeeds} setFeeds={setInitialFeeds}>
      <div className="lds-container span12 w-1200">
        <h3>{tableData["header"]}</h3>
        <br/>
        <table id={tableId} className="table table-bordered feed_index_table">
          <thead>
          <tr>
            {tableHeaders}
             <th className="w-80 color-icon">
              Show on <Icon name={"home-filled"} />
            </th>
            <th className="w-140 color-icon">
              Check on my behalf <Icon name={"email-filled"} />
            </th>
            <th id="feeds-delete-button" className="w-132">
              {deleteButton}
            </th>
          </tr>
          </thead>
          <tbody id="custom-feeds-table-body">
          {feedsIndexTableBody}
          </tbody>
        </table>
        <div className="select_all_feeds">
          select all
          {selectAllCheckbox}
        </div>
      </div>
    </FeedsIndexProvider>
  );
}

FeedsIndexTable.propTypes = {
  feeds: PropTypes.array.isRequired,
  group: PropTypes.string.isRequired,
  tableData: PropTypes.object.isRequired,
};

export default FeedsIndexTable;
window.FeedsIndexTable = FeedsIndexTable;
