import React from 'react';
import { createContext } from 'react';
import ListTableHeader from './ListTableHeader';
import ListTableBody from './ListTableBody';

const TableContext = createContext({
  onListSelected: (e) => {},
  lists: [],
  editable: false,
  showHeader: false,
  showTag: false,
  categories: [],
  enableDeleteBtn: false,
  selectedRows: new Map(),
});

function ListTable({
  lists,
  editable,
  showHeader,
  showTag,
  onListSelected,
  enableDeleteBtn,
  selectedRows,
  onDeleteButtonClick
}) {

  const styles = {
    // Prevent weird top border when header isn't shown
    borderCollapse: showHeader ? "separate" : "collapse"
  }

  return (
    <TableContext.Provider
      value={{
        lists,
        editable,
        showHeader,
        showTag,
        onListSelected,
        enableDeleteBtn,
        selectedRows,
        onDeleteButtonClick,
      }}
    >
      <table id="list_table" className="list-table table table-bordered" style={styles}>
        <ListTableHeader />
        <ListTableBody />
      </table>
    </TableContext.Provider>
  );
}

export default ListTable;
export { TableContext };
