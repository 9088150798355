import RadioButtonItem, {
  RADIO_STATES,
} from "../../lds/selection_controls/RadioButtonItem";

const SponsorOnlyListSwitch = (props) => {
  const switchSponsorList = function (event) {
    ListModalAction.switchSponsorFlag();
  };

  if (props.map_display) {
    return false;
  }

  var all_orgs = !props.justSponsor;

  const selectAll = () => !all_orgs && switchSponsorList();
  const selectSponsors = () => all_orgs && switchSponsorList();

  return (
    <div>
      <p>List is to include:</p>
      <div className="row-fluid">
        <div className="span6">
          <RadioButtonItem
            id="radio-all-orgs"
            label="All types of organizations"
            onClick={selectAll}
            selection={
              all_orgs ? RADIO_STATES.selected : RADIO_STATES.unselected
            }
            styleOverride={{
              label: {
                fontSize: "unset",
              },
            }}
          />
        </div>
        <div className="span6">
          <RadioButtonItem
            id="radio-sponsor-orgs"
            label={`Just ${I18n.t(
              "terminology.target_organization_title"
            )} companies`}
            selection={
              props.justSponsor
                ? RADIO_STATES.selected
                : RADIO_STATES.unselected
            }
            onClick={selectSponsors}
            styleOverride={{
              label: {
                fontSize: "unset",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

window.SponsorOnlyListSwitch = SponsorOnlyListSwitch;
export default SponsorOnlyListSwitch;
