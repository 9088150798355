import QueryActivityCategory from "./queryActivityCategory";
import QueryAliasSearchRefs from "./queryAliasSearchRefs";
import QueryClinStage from "./queryClinStage";
import QueryClinStatus from "./queryClinStatus";
import QueryFacets from "./queryFacets";
import QueryGeoCoords from "./queryGeoCoords";
import QueryList from "./queryList";
import QueryLocations from "./queryLocations";
import QueryOrgListRefs from "./queryOrgListRefs";
import QueryRelationships from "./queryRelationships";
import QuerySearchTerm from "./querySearchTerm";
import QueryTimeRange from "./queryTimeRange";

const SearchDescriptionPanel = (props) => {
  return (
    <div className="row-fluid">
      <div className="span12">
        <QueryTimeRange {...props} />
        <QuerySearchTerm {...props} />
        <QueryActivityCategory {...props} />
        <QueryList {...props} />
        <QueryFacets {...props} />
        <QueryOrgListRefs {...props} />
        <QueryAliasSearchRefs {...props} />
        <QueryRelationships {...props} />
        <QueryClinStatus {...props} />
        <QueryClinStage {...props} />
        <QueryGeoCoords {...props} />
        <QueryLocations {...props} />
      </div>
    </div>
  );
}

window.SearchDescriptionPanel = SearchDescriptionPanel;
export default SearchDescriptionPanel;
