import { DEFAULT_CATEGORY } from "../../constants/lists/list_constants";

class ListsStoreClient {
  constructor({ basePath }) {
    this.basePath = basePath;
  }

  async search(data) {
    const params = new URLSearchParams({
      name: data.params.name,
      page: data.params.page,
      category: data.selectedCategory,
      sort: data.selectedSortOption.value,
    });
    const url = new URL(
      `/${this.basePath}/search?${params}`,
      window.location.origin
    );

    try {
      const response = await fetch(url);
      if (response.ok) {
        const res = await response.json();
        data.totalCount = res.total_count;
        data.currentCount = res.lists.length;
        return res.lists;
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async initialState(data) {
    this._resetAll(data);
    await this.fetchConfigs(data);
    const lists = await this.search(data);
    data.lists = lists;
  }

  async fetchConfigs(data) {
    const response = await fetch(`/${this.basePath}/configs`);
    const res = await response.json();

    data.sortOptions = res.sorting;
    data.selectedSortOption = data.sortOptions[0];
    const sum = Object.values(res.categories_count).reduce(
      (acc, curr) => acc + curr,
      0
    );
    data.categories = { All: sum, ...res.categories_count };
    data.originalCategories = { ...data.categories };
  }

  async loadMore(data) {
    data.params.page++;
    const lists = await this.search(data);
    data.lists.push(...lists);
    data.currentCount = data.lists.length;
  }

  async sort(data, option) {
    data.selectedSortOption = option;
    data.params.page = 1;
    const lists = await this.search(data);
    data.lists = lists;
  }

  async searchName(data, name) {
    data.params.page = 1;
    data.params.name = name.trim();
    const lists = await this.search(data);
    data.lists = lists;
  }

  async selectCategory(data, category) {
    data.selectedCategory = category;
    data.selectedSortOption = data.sortOptions[0];
    data.params = {
      page: 1,
      name: "",
    };

    const lists = await this.search(data);
    data.lists = lists;
  }

  async clearSearch(data) {
    await this.searchName(data, "")
  }

  _resetAll(data) {
    data.params = {
      page: 1,
      name: "",
    };

    data.lists = [];
    data.selectedLists = new Map();
    data.selectedSortOption = data.sortOptions[0];
    data.selectedCategory = DEFAULT_CATEGORY;

    data.currentCount = 0;
    data.totalCount = 0;

    data.categories = { ...data.originalCategories };
  }
}

export default ListsStoreClient;
