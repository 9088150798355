import GenericCheckboxItem from "./genericCheckboxItem";

/**
 * React component that displays a list of checkboxes.
 *
 * @author
 * @namespace
 * @memberof
 *
 * @component
 * @example
 * return (
 *   <GenericCheckboxList
 *             toggleCheckbox={this.toggleCheckbox}
 *             checkboxList={props.checkboxList}
 *             checkedBoxes={props.checkedBoxes}
 *             checkboxTitle={props.checkboxTitle}
 *             checkboxClasses={checkboxClasses}
 *             checkboxLabelClasses={checkboxLabelClasses}
 *         />
 *
 * @param {Method} toggleCheckbox toggles the state of each individual checkbox, updating the state of that list (checked or not), as well as updating Zymewire.kitchen_sink and other assorted callbacks.
 * @param {Hash} checkboxList a hash of checkbox labels and values
 * @param {Array} checkedBoxes an array of initially checked boxes
 * @param {String} checkboxTitle the title to use for each checkbox, with %%TERM%% as a placeholder to be regex'd out with the checkbox label
 * @param {Array} checkboxClasses array of CSS classes to use with each generic checkbox
 * @param {Array} checkboxLabelClasses array of CSS classes to use with each generic checkbox's label
 */


const GenericCheckboxList = (props) => {
  const checkboxList = props.checkboxList;
  const checkedBoxes = props.checkedBoxes;
  const toggleCheckbox = props.toggleCheckbox;
  const checkboxTitle = props.checkboxTitle;
  const checkboxClasses = props.checkboxClasses;
  const checkboxLabelClasses = props.checkboxLabelClasses;

  let checkboxItems = Object.keys(checkboxList).map(function (key, index) {

    const checkboxValue = key;
    const checkboxLabel = checkboxList[key];

    const checked = checkedBoxes.indexOf(checkboxValue) > -1 ? 'checked' : '';
    const checkboxTitleTranslated = checkboxTitle.replace(/%%TERM%%/g, checkboxLabel);

    return <GenericCheckboxItem
      key={index}
      checkboxValue={checkboxValue}
      checkboxLabel={checkboxLabel}
      checked={checked}
      toggleCheckbox={toggleCheckbox}
      checkboxTitle={checkboxTitleTranslated}
      checkboxClasses={checkboxClasses}
      checkboxLabelClasses={checkboxLabelClasses}
    />
  });

  return (
    <div>
      {checkboxItems}
    </div>
  )
};

export default GenericCheckboxList;
