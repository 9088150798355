import React from "react";

const ScrollableListItem = ({ filterName, filterValue }) => {
  return (
    <div className="scrollable-list-item">
      <p className="filter-name">{filterName}:</p>
      <div className="filter-value">
        {filterValue.map((value) => {
          return (
            <p key={value}>
              {value}
              <br />
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default ScrollableListItem;
