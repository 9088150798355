import ZoominfoEmailReveal from "./zoominfoEmailReveal";
import ZoominfoPhoneReveal from "./zoominfoPhoneReveal";
import ZoominfoPersonLinks from "./zoominfoPersonLinks";
import ZoominfoCompanyAddress from "./zoominfoCompanyAddress";
import ZoominfoBuyContact from "./zoominfoBuyContact";
import ZoomInfoRevealed from "./zoomInfoRevealed";

const ZoominfoFurtherPersonDetails = function(props) {
  var has_details = props.showPhone || props.showEmail;

  var buyButton = <ZoominfoBuyContact
    key={props.entity_id +'_buycontact'}
    showPhone={props.showPhone}
    showEmail={props.showEmail}
    credits_available={props.credits_available}
    feature_flags={props.feature_flags}
    EmailAddress={props.email}
    DirectPhone={props.phone.mobile}
    PersonId={props.entity_id}
    LocalAddress={props.address}
    provider={props.provider}
  />;

  var last_section = [
    <ZoominfoEmailReveal key={props.entity_id +'_emailreveal'} EmailAddress={props.email} showEmail={props.showEmail} />,
    <ZoominfoPhoneReveal key={props.entity_id +'_phonereveal'} DirectPhone={props.phone.mobile} showPhone={props.showPhone}/>
  ];

  if(has_details){
    last_section.push(buyButton);
  }

  if(props.current_contact){
    if (props.current_contact.email || props.current_contact.phone.mobile) {
      last_section = <ZoomInfoRevealed {...props} key={props.entity_id +'_revealed'} revealedSection={false} />
    } else {
      last_section = null;
    }
  }

  return (
    <tr className="bottomRow">
      <ZoominfoPersonLinks {...props} company_name={props.company_name} key={props.entity_id +'_personlinks'} />
      <ZoominfoCompanyAddress {...props} key={props.entity_id +'_companyaddress'}/>
      {last_section}
    </tr>
  );
}

export default ZoominfoFurtherPersonDetails;

