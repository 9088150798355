/**
 * React component that displays a simple X that removes a query criteria from the breadbox
 *
 * @author
 * @namespace
 * @memberof
 *
 * @component
 * @example
 *
 */
const GenericFilterItemRemove = () => {
  return(
    <div className="filter_item_remove">
      <i className="icon-remove"></i>
    </div>
  )
};

window.GenericFilterItemRemove = GenericFilterItemRemove;
export default GenericFilterItemRemove;
