// this should be junked and all instances of it replaced with PaginationLinks see above PaginationHolder
const PaginationPage = (props) => {
  const change = function (e) {
    e.preventDefault();
    e.stopPropagation();
    props.changePage(props.page_number);
  }

  var display_no = props.page_number + 1;
  var classname = props.active ? "active" : "";
  return (
    <li className={classname}>
      <a href="#" onClick={change}>{display_no}</a>
    </li>
  )
}

export default PaginationPage;