import Reflux from 'reflux';
import FacetSearchStore from '../../stores/facetSearchStore';
import { FacetSearchAliasSearchActions } from '../../stores/actions/facetSearch/aliasSearchActions';

/**
 * React component that displays a list of Alias Searches
 * @param {Object} aliasSearchRefs a hash, each entry of
 * which consists of a Alias Search ref as key pointing at a single
 * - display value associated with the Alias Search ref.
 */

const FacetSearchCriteriaAliasSearch = createReactClass({
  mixins: [Reflux.connectFilter(FacetSearchStore, ({ aliasSearch: aliasSearchRefs }) => aliasSearchRefs)],
  render() {
    const { aliasSearchRefs } = this.state;

    if (aliasSearchRefs) {
      const locked = ((Zymewire.original_feed_query || {}).alias_search_refs || []).length > 0
      let aliasSearchContent = [];
      aliasSearchRefs.forEach((aliasSearchRef) => {
        const displayValue = aliasSearchRef.display;
        const ref = aliasSearchRef.ref;
        let entry = <FacetSearchCriteriaAliasSearchEntry key={ref + 'entry'} displayValue={displayValue} locked={locked} />;

        if (locked) {
          aliasSearchContent.push(entry);
        } else {
          let unlockedContent = <FacetSearchCriteriaAliasSearchRemove key={ref + 'entry'} displayValue={displayValue} aliasSearchRefsKey={ref}>
              {entry}
            </FacetSearchCriteriaAliasSearchRemove>;

          aliasSearchContent.push(unlockedContent);
        }
      });
      return <div>{aliasSearchContent}</div>;
    } else {
      return null;
    }
  }
});

/**
 * React component that displays the alias search ref used to filter a query. If the entry is a locked entry, as permitted on the feed view, then the entry's remove functions are disabled.
 *
 * @param {Boolean} locked is the filter removable or not
 */

const FacetSearchCriteriaAliasSearchEntry = ({ displayValue, locked }) => {
  const removeTitle = locked ? null : 'Click to remove this filter.';
  const removeEntry = locked ? null : <GenericFilterItemRemove />;
  const removeStyle = { fontSize: '13px', color: locked ? "#000000" : undefined };

  return (
    <div className="filter_item filter_item_entity rspec_alias_search_result" title={"You are filtering your search by a specific alias search. " + removeTitle}>
      {removeEntry}
      <div style={{display: "inline-block"}}>
        <p style={removeStyle}>
          <b>{displayValue}</b>
        </p>
      </div>
    </div>
  )
};

/**
 * React component that acts as a wrapper for when the alias search ref filter's remove functions are enabled.
*/

const FacetSearchCriteriaAliasSearchRemove = ({ children, displayValue, aliasSearchRefsKey }) => {

  let removeAliasSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();

    FacetSearchAliasSearchActions.removeAliasSearchRefs({ ref: aliasSearchRefsKey, display: displayValue });

    return false;
  }

  return (
    <div>
      <a onClick={removeAliasSearch} className="action_link">
        {children}
      </a>
    </div>
  )
};

window.FacetSearchCriteriaAliasSearch = FacetSearchCriteriaAliasSearch;
export default FacetSearchCriteriaAliasSearch;

