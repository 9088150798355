import moment from "moment";

export function formatDate(date) {
  return moment(date).format("MM-DD-YYYY");
}

export function parseDate(dateStr) {
  return moment(dateStr, "MM-DD-YYYY");
}

/**
 * True iff the keyDown event is valid for
 * manual entry into the input field.
 *
 * Only allows numbers and hyphens through.
 */
export function isKeyDownEventValidInput(event) {
  const allowedKeys = [
    "Enter",
    "Backspace",
    "ModeChange",
    "ArrowLeft",
    "ArrowRight",
    "Tab",
  ];

  if (
    allowedKeys.includes(event.key) ||
    event.ctrlKey ||
    event.altKey ||
    event.metaKey
  )
    return true;

  const isNumber = isFinite(event.key);
  const isHyphen = event.key === "-";

  if (isNumber || isHyphen) return true;
  return false;
}

/**
 * Why is this a function? We need to make sure that
 * this is always the latest copy of "right now",
 * as other components may use `new Date()`. Keeping
 * it static risks this date no longer being the current
 * "now", even if by a few seconds, which is enough
 * to cause boolean "<", ">=", etc. checks to fail.
 *
 * @returns {Date} Current date
 */
export const DATEPICKER_MAX_DATE = () => new Date();
