import Reflux from "reflux";

const UserListsActions = Reflux.createActions([
  "initialState",
  "loadMore",
  "sort",
  "searchName",
  "selectCategory",
  "selectList",
  "selectAllLists",
  "clearSearch",
  "confirmListsDeletion",
]);

export default UserListsActions;
