import Reflux from "reflux"

/**
 * Reflux action object
 *
 * @namespace FacetSearchAffiliatedWithActions
 * @param [Array] of action names for use with FacetSearchStoreMutations
 */
const FacetSearchAffiliatedWithActions = Reflux.createActions({
  affiliationsInitialState: { sync: true },
  affiliationRemoved: { sync: true },
  affiliationSelected: { sync: true },
  addOrgListRef: { sync: true },
  removeOrgListRef: { sync: true },
  setupOrgListRefs: { sync: true },
});

/**
 * Reflux store object
 *
 * @namespace FacetSearchAffiliatedWithMutations
 *
 * Store listens to FacetSearchAffiliatedWithActions events and updates its data object.
 * Is used from outside the component to set up data in different points of the lifecycle.
 */
const FacetSearchAffiliatedWithMutations = {
  onAffiliationsInitialState: function(data){
    this.data.affiliation = { ...(this.data.affiliation), ...data };

    this.trigger(this.data);
  },
  onAffiliationRemoved: function(opts = {}){
    this.data.affiliation.selected = null;
    FacetSearchListActions.unselectList({ skipTrigger: true })

    if (opts.doSearch) {
      doEventSearch().then(() => this.trigger(this.data))

    } else {
      this.trigger(this.data)
    }
  },
  onAffiliationSelected: function(affiliation, opts = {}){
    this.data.affiliation.selected = affiliation;

    if (!opts.skipTrigger) this.trigger(this.data);
  },
  onAddOrgListRef: function(orgListData, ajaxSearch = true) {
    // let's get a working copy
    const updatedOrgListRefs = JSON.parse(JSON.stringify(this.data.affiliation.orgListRefs));
    // does the org list ref exist as a key?
    if (this.data.affiliation.orgListRefs[orgListData.ref]) {
      // if so, let's add the display value to that ref's array
      const updatedDisplayValues = updatedOrgListRefs[orgListData.ref].concat(orgListData.display);
      // unless it already exists
      const filteredDisplayValues = updatedDisplayValues.filter((value, i, values) => values.indexOf(value) === i);
      updatedOrgListRefs[orgListData.ref] = filteredDisplayValues;
    }
    // otherwise, let's add the key and populate it with an array of one: the display value
    // this code is remnant from when we displayed synonyms and needed to keep track of all synonyms displayed
    // now we simply store the ref list description so this could be refactored to a string versus an array
    else {
      updatedOrgListRefs[orgListData.ref] = [orgListData.display];
    }

    this.data.affiliation.orgListRefs = updatedOrgListRefs;
    if(ajaxSearch) {
      doEventSearch();
    }
  },
  onRemoveOrgListRef: function(orgListData) {
    // let's get a working copy
    const updatedOrgListRefs = JSON.parse(JSON.stringify(this.data.affiliation.orgListRefs));

    // does the org list ref exist as a key?
    if (this.data.affiliation.orgListRefs[orgListData.ref]) {
      // then let's remove the display value from that ref's array, if it exists
      // this code is remnant from when we displayed synonyms and needed to keep track of all synonyms displayed
      // now we simply store the ref list description so this could be refactored to a string versus an array
      const displayValues = updatedOrgListRefs[orgListData.ref];
      const filteredDisplayValues = displayValues.filter((display) => display !== orgListData.display);
      // then, if there are no more display values for that ref list, remove the entry
      if (filteredDisplayValues.length === 0) {
        delete updatedOrgListRefs[orgListData.ref];
      }
      // otherwise, update the ref entry with the filtered list of display values
      else {
        updatedOrgListRefs[orgListData.ref] = filteredDisplayValues;
      }
    }
    this.data.affiliation.orgListRefs = updatedOrgListRefs;
    doEventSearch();
  },
  onSetupOrgListRefs:  function(orgListRefs){
    this.data.affiliation.orgListRefs = orgListRefs;
    this.trigger(this.data);
  }
}

window.FacetSearchAffiliatedWithActions = FacetSearchAffiliatedWithActions
export {
  FacetSearchAffiliatedWithActions,
  FacetSearchAffiliatedWithMutations
}
