const ClearColumnsButton = createReactClass({
  mixins: [Reflux.listenTo(ColumnsChosenStore,"change_button_state"),Reflux.listenTo(SubmitFormStore,"disable_button")],
  getInitialState: function(){
    return {
      button_type:this.props.columns_chosen.length > 1 ? 'enabled' : 'disabled',
      submit_form:false
    }
  },
  change_button_state: function(columns_chosen) {
    this.setState({
      button_type: columns_chosen.length > 1 ? 'enabled' : 'disabled'
    });
  },
  disable_button: function(state) {
    this.setState({
      submit_form: true
    });
  },
  submit_changes: function (e){
    e.stopPropagation();
    if(this.state.button_type !== 'disabled'){
      ColumnsChosenActions.clearAll()
    }
  },
  render: function () {

    let classNames = ["clear_columns_button", "button-small", "button-secondary"];
    if(this.state.button_type !== 'enabled' || this.state.submit_form === true){
      classNames.push("button-disable");
    }
    return (
      <button type="reset" onClick={this.submit_changes} className={classNames.join(" ")}>Clear All</button>
    );
  }
});

export default ClearColumnsButton;
