/**
 * React component that displays an organization's cumulative funding for one of 3 years: current, prior, or two years prior
 *
 * @author GJ <gordon@zymewire.com>
 * @namespace CumulativeFundingByYear
 * @memberof window unfortunately
 *
 * @component
 * @example
 * return (
 *   <CumulativeFundingByYear {...props} />
 * )
 *
 * @param {object} props Component props
 * @param {object} props.year number denoting the ordinal year, index 0
 * @param {object} props.yearData object denoting of that year's funding info
 *
 */
const CumulativeFundingByYear = (props) => {
  let amount = props.yearData.amount;
  let formattedAmount = makeMillions(amount);

  return (
    <div id={"rspec_cumulative_by_year_label_" + props.yearData.label} className={"cumulative_by_year_cell cumulative_by_year_" + props.year}>
      <span className="cumulative_by_year_label">{props.yearData.label}</span><span className={"cumulative_by_year_formatted_amount cumulative_by_year_" + props.year}>{formattedAmount}</span>
    </div>
  );
};

CumulativeFundingByYear.propTypes = {
  yearData: PropTypes.shape({
    label: PropTypes.string,
    amount: PropTypes.number
  }).isRequired
};

export default CumulativeFundingByYear;
