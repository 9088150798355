import SfIndexRow from "./sfIndexRow";

const SfIndexTable = createReactClass({
  mixins: [Reflux.listenTo(SFAccountActionsStore,"update_accounts")],
  getInitialState: function(){
    return {
      accounts: this.props.accounts
    }
  },
  update_accounts: function(accounts) {
    this.setState({
      accounts: accounts
    });
  },
  render: function () {
    const sf_account_list = this.state.accounts.map(function(account_data, index){
      return <SfIndexRow key={index} {...account_data} />
    });
    return (
      <div className="div_table sf_index_table">
        <div className="div_table_header">
          <div className="div_table_head sf_accounts_head sf_accounts_column">Name</div>
          <div className="div_table_head sf_accounts_head sf_accounts_column">Account Owner</div>
          <div className="div_table_head sf_accounts_head sf_accounts_column">Opportunities</div>
          <div className="div_table_head sf_accounts_head sf_accounts_column">Last Activity</div>
        </div>
        <div className="div_table_body sf_accounts_index_body">
          {sf_account_list}
        </div>
      </div>
    );
  }
});

export default SfIndexTable;
