const DownloadPrimoCampaignButton = function (props) {
  let className = 'button-secondary';
  if (props.choice === props.type) {
    className = 'button-primary selected';
  }
  const classNames = [className, "primo_list_type_" + props.type, "button-small"];

  return (
    <button className={classNames.join(' ')} onClick={() => props.update_choice(props.type)}>{props.name}</button>
  );
};

export default DownloadPrimoCampaignButton
