export function openTop(element, trigger, { align, gap }) {
  const styles = element.style;
  const rect = element.getBoundingClientRect();
  const triggerRect = trigger.getBoundingClientRect();

  styles.top = triggerRect.top - rect.height + "px";
  styles.paddingBottom = gap;

  alignHorizontally(element, trigger, align)
}

export function openBottom(element, trigger, { align, gap }) {
  const { height, top } = trigger.getBoundingClientRect();
  const styles = element.style;

  styles.top = `calc(${height}px + ${gap} + ${top}px)`; //top + "px";
  // styles.paddingTop = `calc(${height}px + ${gap})`;

  alignHorizontally(element, trigger, align);
}

export function openLeft(element, trigger, { align, gap }) {
  const { top, left } = trigger.getBoundingClientRect();

  const styles = element.style;
  const rect = element.getBoundingClientRect();
  styles.left = left - rect.width + "px";

  styles.paddingRight = gap;

  alignVertically(element, trigger, align)
}

export function openRight(element, trigger, { align, gap }) {
  const { width, top, left } = trigger.getBoundingClientRect();
  const styles = element.style;

  styles.left = left + width + "px";
  styles.paddingLeft = gap

  alignVertically(element, trigger, align)
}

function alignVertically(element, trigger, align) {
  const { top, height } = trigger.getBoundingClientRect();
  const elementHeight = element.getBoundingClientRect().height;

  const styles = element.style;

  let topValue;

  switch (align) {
    case "start":
      topValue = top;
      break;
    case "center":
      topValue = top - (elementHeight - height)/2;
      break;
  
    default:
      break;
  }

  styles.top = topValue + "px";
}

function alignHorizontally(element, trigger, align) {
  const {left, width} = trigger.getBoundingClientRect();
  const elementWidth = element.getBoundingClientRect().width;

  const styles = element.style;

  let leftValue;

  switch (align) {
    case "start":
      leftValue = left
      break;
    case "center":
      leftValue = left + (width - elementWidth)/2
      break;
  
    default:
      break;
  }

  styles.left = leftValue + "px";
}

/** Create intersection observer for given root, target and callback. */
export function createIntersectionObserverFor(root, target, callback) {
  const options = {
    root,
    threshold: 0,
  };

  const observer = new IntersectionObserver(callback, options);
  observer.observe(target);
}

export function listenToScrollOnElement(element, callback) {
  element.addEventListener("scroll", callback);

  return () => {
    element.removeEventListener("scroll", callback);
  };
};
