import EditEventDateForm from "./editEventDateForm";

const EventDateHandler = createReactClass({
  getInitialState: function () {
    return {showInputDateField: false, loading: false};
  },
  showDateInput: function (e) {
    e.preventDefault();
    this.setState({showInputDateField: true});
  },
  showDate: function (e) {
    e.preventDefault();
    this.setState({showInputDateField: false});
  },
  render: function () {
    const divStyle = {width: '100%', marginBottom: '5px', marginTop: '5px'};
    const dateString = moment(parseInt(this.props.event.dateMilli)).format("MMMM Do YYYY, h:mm:ss a");
    if (this.state.showInputDateField) {
      return (
        <div style={divStyle}>
          <EditEventDateForm {...this.props} closeForm={this.showDate}/>
        </div>
      );
    }
    else {
      return (
        <div style={divStyle}>
          <b>
            <a className="edit-event-date-link action_link" onClick={this.showDateInput}>{dateString}</a>
          </b>
        </div>
      );
    }
  }
});

export default EventDateHandler;
