import PropTypes from "prop-types";

export function ModalFooter({ align = "start", children }) {
  return (
    <div className="lds-modal-footer" style={{ alignItems: align }}>
      {children}
    </div>
  );
}

ModalFooter.propTypes = {
  align: PropTypes.string,
};
