const assembleSuggestionsEntries = (props) => {
  return props.filteredSuggestions.map((suggestion, index) => {
    let cleaned_suggestion = suggestion.replace(/[^a-zA-Z ]/g, "");
    cleaned_suggestion = cleaned_suggestion.replace(/ /g, "_");
    return (
      <li
        id={"suggestion_" + index + "_" + cleaned_suggestion}
        className={"autocomplete_suggestion-active"}
        key={index}
        onClick={props.onClick}
      >
        <a className="fancy_link local_link location_link">{suggestion}</a>
      </li>
    );
  });
};

const SuggestionsHolder = (props) => {
  let classNames = ["autocomplete_suggestions"].concat(props.classNames);
  return (
    <div>
      <ul id="autocomplete_suggestions" className={classNames.join(' ')}>
        {props.suggestionsEntries}
      </ul>
    </div>
  )
};

const AutocompleteSuggestions = (props) => {
  if (props.filteredSuggestions.length) {
    let suggestionsEntries = assembleSuggestionsEntries({filteredSuggestions:props.filteredSuggestions, onClick:props.onClick});
    return <SuggestionsHolder suggestionsEntries={suggestionsEntries} classNames={props.classNames} />
  }
  else {
    let suggestionsEntries = <li className="autocomplete_suggestion-inactive">No {props.placeHolder} matching '{props.userInput}'</li>
    return <SuggestionsHolder suggestionsEntries={suggestionsEntries} classNames={props.classNames} />
  }
};

window.AutocompleteSuggestions = AutocompleteSuggestions;
export default AutocompleteSuggestions;
