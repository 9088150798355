import Reflux from "reflux";
import FacetSearchStore from "../../stores/facetSearchStore";
import { FacetSearchStoreActions } from "../../stores/actions/facetSearch/storeActions";

/**
 * This component handles rendering of the Clinical Status filter criteria
 * within the Facet Search breadbox. It is ble to render both locked/unlocked
 * params, such that when it is viewed in a feed, the locked params are not
 * able to be removed.
 */
const FacetSearchCriteriaClinicalStatus = createReactClass({
  mixins: [
    Reflux.connectFilter(FacetSearchStore, function(data) { return data.clinicalTrialStatuses })
  ],

  getInitialState() {
    return { ...(FacetSearchStore.data.clinicalTrialStatuses) }
  },

  render() {
    let { selected } = this.state;

    if (selected == null || selected.length === 0) return null

    let originalClinStatus = (Zymewire.original_feed_query || {}).clin_status || []

    if (originalClinStatus.length > 0) {
      $('#trial_status_panel').hide();
    }

    let selectedForRender = _.unique(selected).map((status, index) => {
      return {
        value: status,
        locked: _.contains(originalClinStatus, status),
        last: (index == selected.length - 1),
      }
    })

    return(
      <div>
        <p style={{ marginBottom: 0 }}>References a trial with status</p>
        { selectedForRender.map(prop => <CriteriaClinStatusEntry key={prop.value} prop={prop} />) }
      </div>
    )
  }
})

/**
 * @param props {{ value: string, locked?: boolean, last?: boolean }}
*/
const CriteriaClinStatusEntry = ({ prop }) => {
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (readOnly) return;

    const clinicalTrialStatusesSelected = [...FacetSearchStore.data.clinicalTrialStatuses.selected];
    const selectedIndex = clinicalTrialStatusesSelected.indexOf(prop.value);

    if (selectedIndex > -1) {
      clinicalTrialStatusesSelected.splice(selectedIndex, 1);

      FacetSearchStoreActions.clinicalTrialStatusesUpdate(
        {selected: clinicalTrialStatusesSelected},
        {doSearch: true, triggerStateUpdate: false}
      );
    }

    return false
  }

  const readOnly = prop.locked

  let title = `You are filtering your search by events with trials with a status of '${prop.value}'.`
  const textStyle = {
    marginLeft: "-5px",
    fontSize: "13px",
  }

  if (readOnly) {
    textStyle.color = "#000000"
  } else {
    title += " Click to remove this filter."
  }

  return (
    <a onClick={handleClick} className="action_link">
      <div className="filter_item_property" title={title}>
        <div className="filter_item_close" style={{ display: "inline-block" }}>
          { !readOnly && <i className="icon-remove"></i> }
        </div>

        <div style={{ display: "inline-block" }}>
          <p style={textStyle}>
            <b>{ prop.value }</b>
            { !prop.last && <b> OR</b> }
          </p>
        </div>
      </div>
    </a>
  );
}

window.FacetSearchCriteriaClinicalStatus = FacetSearchCriteriaClinicalStatus;
export default FacetSearchCriteriaClinicalStatus;
