import { useContext } from "react";
import PropTypes from "prop-types";
import { chunk } from "lodash";

import { useDatePickerContext } from "@rehookify/datepicker";
import { CalendarContext } from "./CalendarContext";

import CalendarDate from "@javascript/reactComponents/lds/datepicker/CalendarDate";
import CalendarWeekDayLabels from "@javascript/reactComponents/lds/datepicker/CalendarWeekDayLabels";

export default function CalendarBody({
  onDateSelect = () => {},
 }) {

  const rehookifyContext = useDatePickerContext();

  const {
    minDate,
    maxDate,
  } = useContext(CalendarContext);

  const rehookifyCalendars = rehookifyContext.data.calendars;

  const currentMonth = rehookifyCalendars[0];

  const isCurrentDateInRange = (currentDate) => {
    const currentTime = currentDate.getTime();
    const minTime = minDate ? minDate.getTime() : -Infinity;
    const maxTime = maxDate ? maxDate.getTime() : Infinity;

    return currentTime >= minTime && currentTime <= maxTime;
  };

// Generating calendar dates with conditional rendering based on date range
  const calendarDates = chunk(
    currentMonth.days.map((date, index) => {
      const isInRange = isCurrentDateInRange(date.$date);
      const dateProps = isInRange ? date : { ...date, disabled: true };

      return (
        <CalendarDate
          key={`calendar-date-${currentMonth.month}-${index}`}
          onDateSelect={onDateSelect}
          rehookifyDateDTO={dateProps}
        />
      );
    }),
    7
  ).map((week, index) => (
    <div
      className="lds-calendar-body-date-row"
      data-testid="lds-calendar-body-date-row"
      key={`lds-calendar-body-date-row-${index}`}
    >
      {week}
    </div>
  ));

  const classNames = ["lds-calendar-body"];

  return (
    <div
      className={classNames.join(" ")}
    >
      <CalendarWeekDayLabels />
      {calendarDates}
    </div>
  );
}

CalendarBody.propTypes = {
  onDateSelect: PropTypes.func,
};
