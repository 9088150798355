const QueryRelationships = (props) => {
  if (props.search.relationships) {
    var no_recent_activity;
    var rel;
    var style = { paddingLeft: '10px' };

    if (_.contains(props.search.relationships, "exclude_recent_sponsor")) {
      var and = props.search.relationships.length > 1 ? ", AND" : "";
      no_recent_activity = <p><b>Companies with no team activity in the last 6 months in Salesforce{and}</b></p>;
    }

    if (_.contains(props.search.relationships, "crm")) {
      rel = <p><b>Companies you have in your Salesforce</b></p>;
    }
    // this needs to be reworked
    if (_.contains(props.search.relationships, "exclude_muted_companies")) {
      rel = <p><b>Only your unmuted companies</b></p>;
    }
    return <div style={style}>{no_recent_activity}{rel}</div>;
  }
  else {
    return false;
  }
}

export default QueryRelationships;
