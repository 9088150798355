const SignInLink = (props) => {
  const clickHandler = () => {
    if(props.id != props.current){
      window.location.href = props.url;
    }
  };
  const linkId = props.id + "_link";
  const aId = props.id + "_a";
  const img = "/images/" + props.id + "_icon.png";
  let link, linkDivClassname;
  if(props.id === props.current){
    link = props.title;
    linkDivClassname = "disabled_link_div";
  }
  else{
    link = <a id={aId} className="fancy_link"  href={props.url}>{props.title}</a>;
    linkDivClassname = "link_div";
  }
  return (
    <div id={linkId} className={linkDivClassname} onClick={clickHandler}>
      <div className="link_icon_cell">
        <img className="link_icon" alt={props.title} src={img} />
      </div>
      <div className="link_details_div">
        {link}<br/>
        <span className="link_subtitle">{props.sub_title}<br/>{props.desc}</span>
      </div>
    </div>
  );
};

export default SignInLink;
