import LegacyModalService from "@javascript/globals/LegacyModalService";

const FlaggingEventReminder = ({ onSubmit }) => {
  return (
    <div className="buttonGroup-medium">
      <button id="cancel_flag_event_button" className="button-medium button-secondary" onClick={LegacyModalService.closeModal}>Cancel</button>
      <button id="flag_event_button" onClick={onSubmit} className="button-medium button-primary">Flag Event</button>
    </div>
  );
}

export default FlaggingEventReminder;
