import ListDisplayPanel from "./listDisplayPanel";

const EntityListHolder = function ({ sliceSize = 10, page, entityList }) {
  const start = page * sliceSize;
  const end = start + sliceSize;
  const entityListPage = entityList.slice(start, end);
  return <ListDisplayPanel entityList={entityListPage} />;
};

export default EntityListHolder;
