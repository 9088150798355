function DevTools() {
  const setBrand = (brand) => {
    $.post({
      url: "/dev_tools/set_brand",
      data: { brand },
      success: () => location.reload(),
      error: (error) => {
        console.error("Failed to set brand:", error)
      },
    })
  }

  const { dev_tools: { available_brands, git_branch_truncated } } = window

  return (
    <div className="development-toolbar dropdown">
      <div className="dev-toolbar-item dropdown-toggle" data-toggle="dropdown">
        <svg fill="#000000" width="14px" height="14px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <path d="M416,160a64,64,0,1,0-96.27,55.24c-2.29,29.08-20.08,37-75,48.42-17.76,3.68-35.93,7.45-52.71,13.93V151.39a64,64,0,1,0-64,0V360.61a64,64,0,1,0,64.42.24c2.39-18,16-24.33,65.26-34.52,27.43-5.67,55.78-11.54,79.78-26.95,29-18.58,44.53-46.78,46.36-83.89A64,64,0,0,0,416,160ZM160,64a32,32,0,1,1-32,32A32,32,0,0,1,160,64Zm0,384a32,32,0,1,1,32-32A32,32,0,0,1,160,448ZM352,192a32,32,0,1,1,32-32A32,32,0,0,1,352,192Z"/>
        </svg>
        
        <span style={{ marginLeft: "3px" }} title={git_branch_truncated}>
          { git_branch_truncated }
        </span>
      </div>

      <ul className="dropdown-menu">
        <li className="dropdown-header">Set Brand</li>
        <li><a href="#" onClick={() => setBrand(null)}>Reset</a></li>

        {
          available_brands.map((brand) =>
            <li key={brand}>
              <a className="dev-tools-brand-item" href="#" onClick={() => setBrand(brand)}>
                <img className="dev-tools-brand-logo" src={require(`../../../images/brand_specific/${brand}/favicon.png`)} />
                {brand}
              </a>
            </li>
          )
        }
      </ul>
    </div>
  )
}

window.DevTools = DevTools
export default DevTools;
