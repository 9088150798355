const AutocompleteSearchPanel = (props) => {
  const handleChange = (event) => {
    EntSearchAction.searchFor(event.target.value);
  };
  return (
    <input type="text" id="rspec_autocomplete_textbox" value={props.search_string} onChange={handleChange} placeholder="Type to search for other entities" />
  );
};

export default AutocompleteSearchPanel;
