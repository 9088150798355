import React from "react";
import PropTypes from "prop-types";

import SfFormFields from "@javascript/reactComponents/companyDashboard/salesforce/sfFormFields";

const SfTwoColumnFormFields = ({
  className,
  Component,
  errors,
  fields,
}) => {

  const oddFieldsLength = fields.length % 2 !== 0;

  const renderField = (field, index) => {
    const isLastOdd = oddFieldsLength && index === fields.length - 1;
    const isLeftColumn = index % 2 === 0;

    const classNames = ["input-group-vertical", "sf-input-group"];

    let divStyle = {
      width: isLastOdd ? "100%" : "50%",
      boxSizing: "border-box",
    };

    if (!isLastOdd) {
      if (isLeftColumn) {
        classNames.push("sf-input-left-column");
      } else {
        classNames.push("sf-input-right-column");
      }
    }

    if (className) {
      classNames.push(className);
    }

    return (
      <div
        key={field.name}
        style={divStyle}
        className={classNames.join(" ")}
      >
        <Component fields={[field]} errors={errors} />
      </div>
    );
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {fields.map(renderField)}
    </div>
  );
};

SfTwoColumnFormFields.propTypes = {
  className: PropTypes.string,
  Component: PropTypes.elementType.isRequired,
  errors: SfFormFields.propTypes.errors,
  fields: SfFormFields.propTypes.fields,
};

export default SfTwoColumnFormFields;
