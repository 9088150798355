const TherapeuticArea = (props) => {
  const hrClasses = ["therapeutic_area_holder"];
  let pixels = 10;
  if(props.therapeutic_data.count == "0"){
    hrClasses.push("therapeutic_area_holder_zero");
  }
  else{
    pixels = (props.therapeutic_data.count/props.max_therapies*90) + 10;
  }
  const hrStyle = {
    width: pixels + "%"
  }
  return (
    <div className={hrClasses.join(" ")}>
      <span className="therapeutic_area_span">{props.therapeutic_data.name}</span>
      <hr className="therapeutic_area_hr" style={hrStyle}/>
    </div>
  );
};

export default TherapeuticArea;


