import DatePicker, {
  datesEqual,
} from "@javascript/reactComponents/lds/datepicker/DatePicker";
import Dropdown, {
  DropdownTrigger,
} from "@javascript/reactComponents/lds/dropdown/Dropdown";

import { useEffect, useRef, useState } from "react";
import { FacetSearchTimeFrameActions } from "@javascript/stores/actions/facetSearch/timeFrameActions";
import {
  DATEPICKER_MAX_DATE,
  formatDate,
} from "@javascript/reactComponents/facetSearch/timeSelector/timeSelectorHelpers";
import FacetSearchTimeSelectMultiInput from "@javascript/reactComponents/facetSearch/timeSelector/FacetSearchTimeSelectMultiInput";
import { useIsFirstRender } from "@uidotdev/usehooks";

export default function FacetSearchDateRangePicker({
  onDismiss,
  fromDate,
  toDate,
  dropdownRef,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [start, setStart] = useState(fromDate);
  const [end, setEnd] = useState(toDate);
  const isFirstRender = useIsFirstRender();

  const handleDateClick = (dates) => {
    if (!datesEqual(start, dates[0])) {
      setStart(dates[0]);
    }

    if (!datesEqual(end, dates[1])) {
      setEnd(dates[1]);
    }

    FacetSearchTimeFrameActions.setDateRange(
      formatDate(dates[0]),
      formatDate(dates[1])
    );
  };

  // When user clicks the Confirm button
  const handleDateSelect = () => {
    FacetSearchTimeFrameActions.setDateRange(
      formatDate(start),
      formatDate(end)
    );

    dropdownRef.current.close();

    // Taken from: app/views/shared/_search_bar.html.erb line 18
    save_searchbar_query();
    doEventSearch();
  };

  const handleChevronClick = (event) => {
    dropdownRef.current.close();
    onDismiss();
  };

  // FacetSearchStore has new data
  useEffect(() => {
    if (!datesEqual(fromDate, start)) {
      setStart(fromDate);
    }
  }, [fromDate]);

  useEffect(() => {
    if (!datesEqual(toDate, end)) {
      setEnd(toDate);
    }
  }, [toDate]);

  // When datepicker is dismissed, ensure FacetSearchStore has latest date range
  useEffect(() => {
    if (!dropdownOpen && !isFirstRender) {
      FacetSearchTimeFrameActions.setDateRange(
        formatDate(start),
        formatDate(end)
      );
    }
  }, [dropdownOpen]);

  return (
    <Dropdown
      id="facet_search_date_range_dropdown"
      ref={dropdownRef}
      popoverPosition="bottom"
      align="center"
      bodyStyles={{ border: "none" }}
      onDropdownOpen={() => setDropdownOpen(true)}
      onDropdownClose={() => setDropdownOpen(false)}
      closeOnTriggerClick={false}
    >
      <DropdownTrigger>
        <FacetSearchTimeSelectMultiInput
          start={start}
          end={end}
          focused={dropdownOpen}
          onTrailingActionClick={handleChevronClick}
          onUpdateStart={setStart}
          onUpdateEnd={setEnd}
          dropdownOpen={dropdownOpen}
          dropdownRef={dropdownRef}
        />
      </DropdownTrigger>

      <DatePicker
        range
        dateStart={start}
        dateEnd={end}
        onDateSelect={handleDateSelect}
        onDateClick={handleDateClick}
        maxDate={DATEPICKER_MAX_DATE()}
      />
    </Dropdown>
  );
}
