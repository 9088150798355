import { clone } from "lodash";

import Button from "@javascript/reactComponents/lds/Button";
import ButtonGroup from "../lds/ButtonGroup";
import Icon from "../lds/Icon";
import Heading from "@javascript/reactComponents/lds/typography/Heading";
import LegacyModalService from "@javascript/globals/LegacyModalService";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@javascript/reactComponents/lds/modal/Modal";

function NotifiedUsers({ listOfNames }) {
  listOfNames = clone(listOfNames);

  if (listOfNames.length === 1) {
    return (
      <span>
        Notification sent to <b>{listOfNames[0]}.</b>
      </span>
    );
  }

  const lastName = listOfNames.pop();

  // When more than two users are flagged, a comma before the word "and" should be present.
  const preceedingComma = listOfNames.length > 1 ? "," : ""

  return (
    <span>
      Notifications sent to <b>{listOfNames.join(", ")}{preceedingComma}</b> and{" "}
      <b>{lastName}.</b>
    </span>
  );
}

export function LDSFlagEventModalSuccess({ notifiedUsers }) {
  return (
    <Modal open={true} onClose={LegacyModalService.closeModal} distanceFromTopViewport="75px">
      <ModalHeader>Flag an event</ModalHeader>
      <ModalBody className="flex-col align-center justify-center flag_event_modal_confirmation fullstory_excluded">
        <Icon
          name="special-success"
        />

        <Heading size="h2">Event flagged successfully!</Heading>

        {notifiedUsers.length > 0 && (
          <NotifiedUsers listOfNames={notifiedUsers} />
        )}
      </ModalBody>
      <ModalFooter align="flex-end">
        <ButtonGroup size="medium">
          <Button
            id="dismiss-flag-event-success-modal"
            size="medium"
            variant="secondary"
            onClick={LegacyModalService.closeModal}
          >
            Close
          </Button>

          <Button
            id="go-to-flagged-events-page"
            size="medium"
            variant="primary"
            onClick={() => location.assign("/flagged_events")}
          >
            Go to Event
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
}

window.LDSFlagEventModalSuccess = LDSFlagEventModalSuccess;
