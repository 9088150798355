import { useContext } from "react";
import PropTypes from "prop-types";

import Icon from "@javascript/reactComponents/lds/Icon";
import ModalContext from "@javascript/reactComponents/lds/modal/ModalContext";

export function ModalHeader({ allowClose = true, title, children }) {
  const { onClose } = useContext(ModalContext);

  return (
    <div className="lds-modal-header" role="heading">
      <span className="lds-modal-header-title">{title}</span>
      {children}

      <div className="flex-grow-1" />

      {allowClose && (
        <span className="lds-modal-header-trailingAction" onClick={onClose}>
          <Icon name="cancel" role="dismiss" className="icon-trailing" />
        </span>
      )}
    </div>
  );
}

ModalHeader.propTypes = {
  title: PropTypes.string,
  allowClose: PropTypes.bool,
};
