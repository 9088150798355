import { clinicalStageLabels } from "../../../config/clinicalStages";

const AddClinicalStagePropertyPicker = (props) => {

  const handleChange = (event) => {
    EventModifyModalAction.addClinStage(event.target.value);
  };
  const properties = props.event.independent_properties;
  const currentStages = properties && properties.clin_stage ? properties.clin_stage : [];
  const unselectedStages = _.reject(_.keys(clinicalStageLabels), function (stage) {
    return _.contains(currentStages, stage);
  }, currentStages);

  const buttonStyle = {marginLeft: "3px"};

  if (unselectedStages.length > 0) {
    const stageOptions = unselectedStages.map(function (stage, index) {
      return <option key={index} value={stage}>{clinicalStageLabels[stage]}</option>;
    });
    return (
      <form className="form-inline">
        <select value="none" className="input-medium clin_stage_select" onChange={handleChange}>
          <option disabled value="none"> -- Select a stage --</option>
          {stageOptions}
        </select>
        <button style={buttonStyle} onClick={props.closeHandler}><i className="icon-ok"/></button>
      </form>
    );
  }
  else {
    return false;
  }
};

export default AddClinicalStagePropertyPicker;
