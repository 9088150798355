import ModifyColumnsRow from "../columns/modifyColumnsRow";
import NoMoreChoices from "./noMoreChoices";
import ChosenLi from "./chosenLi";
import stickybits from "stickybits";

const ChosenColumns = createReactClass({
  mixins: [Reflux.listenTo(ColumnsChosenStore,"update_chosen"), Reflux.listenTo(DragStore,"update_dragged"), Reflux.listenTo(pivotListColumnSettingsStore, 'updateSettingsState')],
  getInitialState: function(){
    return {
      columns_chosen:this.props.columns_chosen,
      dragged:{},
      interactableColumnsCount: 0
    }
  },
  update_chosen: function(columns_chosen) {
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      this.setState({
        columns_chosen: columns_chosen
      });
    }
  },
  update_dragged: function(dragged) {
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      this.setState({
        dragged: dragged
      });
    }
  },
  updateSettingsState: function(newState) {
    this.setState({
      interactableColumnsCount:newState.interactableColumnsCount
    });
  },
  componentDidMount:function(){
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      this.chosen.addEventListener("dragenter", this.drag_enter);
      Sortable.create(document.getElementById('columns_chosen'), {
        animation: 150,
        group: {
          name: "shared",
          put: false,
          pull: false,
          revertClone: false,
        },
        sort: true,
        draggable:'.draggable',
        onStart:function(evt){
          $('#' + evt.item.parentNode.id).toggleClass('no_hover_x');
          $('#' + evt.item.parentNode.id).find('.columns_chosen_link').toggleClass('fancy_link');
          $('#' + evt.item.parentNode.id).find('.columns_chosen_link').toggleClass('local_link');
        },
        onEnd:function(evt){
          $('#' + evt.item.parentNode.id).toggleClass('no_hover_x');
          $('#' + evt.item.parentNode.id).find('.columns_chosen_link').toggleClass('fancy_link');
          $('#' + evt.item.parentNode.id).find('.columns_chosen_link').toggleClass('local_link');
          const new_world_order = [];
          for (let i = 0; i < evt.to.children.length; i++) {
            new_world_order.push(evt.to.children[i].id.replace("chosen_", ""));
          }
          ColumnsChosenActions.updateOrder(new_world_order)
        }
      });
      stickybits($('.columns_chosen'), {stickyBitStickyOffset: 40});
    }
  },
  componentWillUnmount: function() {
    this.chosen.removeEventListener("dragenter", this.drag_enter);
  },
  drag_enter: function(event){
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      DragActions.setDragOver('choice');
    }
  },
  render: function(){
    let over_spot = 0;
    var class_name = '';
    var column_entries = _.extend(new Array(this.state.interactableColumnsCount).fill({column_name:'empty',full_name:''}), this.state.columns_chosen.slice(1));
    var content;

    if(this.state.dragged.over === 'choice' && this.state.dragged.type === 'available'){
      class_name = ' dragged_over';
      over_spot = 1;
    }
    if(this.state.columns_chosen.length === 0){
      content = ''
    }
    else{
      content = _.map(column_entries, function(element, index){
        var over_class = '';
        if(over_spot === 1 && element.column_name === 'empty'){
          over_spot = 0;
          over_class = ' pivot_drop_li';
        }
        return (
          <ChosenLi feature_flags={this.props.feature_flags} key={index} column_data={element} over_class={over_class} />
        );
      }.bind(this));
    }
    return(
      <div className={"columns_chosen" + class_name}>
        <p className="columns_instructions">Chosen columns (drag to sort, click to remove):</p>
        <ul ref={elem => this.chosen = elem} className="columns_chosen_ul" id="columns_chosen">
          {content}
        </ul>
        <ModifyColumnsRow feature_flags={this.props.feature_flags} list_id={this.props.list_id} columns_chosen={this.state.columns_chosen} />
        <NoMoreChoices columns_chosen={this.state.columns_chosen} />
      </div>
    )
  }
});

export default ChosenColumns;
