import FacetListContainer from "./facetListContainer";

/**
 * React higher order pure component that returns a FacetLIstContainer component. Broken down this way (wrapped if you will) to support a future where we use a config file to render the search UI.
 *
 *
 * @author
 * @namespace
 * @memberof
 *
 * @component
 * @example
 * return (
 *   <OrganizationSelector facet_list={facet_list} />
 * )
 *
 * @param {Object} facet_list a hash object of things related to the categories of facet list items, such as an array of individual items (detailing it's name/other characteristics) and other parameters related to the category of facet search terms and their display
 *
 */
const organizationFacetKey = 'organizations'
const OrganizationSelector = createReactClass({
  mixins: [Reflux.connectFilter(FacetSearchStore, organizationFacetKey, function(data) { return data.facets.organizations })],
  render() {
    if(!showSelector(this.state.organizations, organizationFacetKey)) {
      return null;
    }

    const facetList = decorateFacetList(this.state.organizations, organizationFacetKey)
    return (
        <FacetListContainer facetList={facetList} />
    )
  }
})

window.OrganizationSelector = OrganizationSelector;
export default OrganizationSelector;
