import React from "react";
import PropTypes from "prop-types";
import SelectionControl from "../selection_controls/SelectionControl";
import SelectionControlGroup from "./SelectionControlGroup";

const CHECKBOX_STATES = Object.freeze({
  unselected: "unselected",
  selected: "selected",
  indeterminate: "indeterminate",
});

const CHECKBOX_ICONS = Object.freeze({
  unselected: "checkbox",
  selected: "checkbox-filled",
  indeterminate: "checkbox-indeterminate-filled",
});

function CheckboxItem({
  className = "",
  id,
  label,
  nestedItems,
  onClick,
  selection = CHECKBOX_STATES.unselected,
  value,
  variant = "active", // TODO: replace

  ENABLE_LEGACY_CHECKBOX_SUPPORT = false,
  LEGACY_CHECKBOX_ID = "",
  styleOverride = {},
}) {
  const commonProps = { className, id, onClick, label, value, variant };

  const checkbox = (
    <>
      <SelectionControl
        icon={CHECKBOX_ICONS[selection]}
        {...commonProps}
        styleOverride={styleOverride}
      />
      {/*
        In order to support legacy HTML/jQuery use-cases,
        we keep a hidden checkbox synced with whatever the
        user selects.
      */}
      {ENABLE_LEGACY_CHECKBOX_SUPPORT ? (
        <input
          className={className}
          type="checkbox"
          checked={selection === CHECKBOX_STATES.selected}
          id={LEGACY_CHECKBOX_ID}
          readOnly
          style={{ display: "none" }}
        />
      ) : (
        <></>
      )}
    </>
  );

  return (
    <>
      {checkbox}
      {nestedItems ? (
        <SelectionControlGroup
          itemComponent={CheckboxItem}
          nested
          items={nestedItems}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export const CheckboxItemPropTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  nestedItems: PropTypes.array,
  onClick: PropTypes.func,
  selection: PropTypes.oneOf(["selected", "unselected", "indeterminate"]),
  value: PropTypes.string,
  variant: PropTypes.oneOf(["active", "disable"]),
  styleOverride: PropTypes.object,
};

CheckboxItem.propTypes = CheckboxItemPropTypes;
export default CheckboxItem;
export { CHECKBOX_STATES };
