import BootstrapRow from "./bootstrapRow";
import BootstrapSpan from "./bootstrapSpan";

const BootstrapFullWidthRow = (props) => {
  return (
    <BootstrapRow fluid={ props.fluid }>
      <BootstrapSpan wide="12">
        {props.children}
      </BootstrapSpan>
    </BootstrapRow>
  );
};

window.BootstrapFullWidthRow = BootstrapFullWidthRow;
export default BootstrapFullWidthRow;
