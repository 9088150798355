import React, {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";

import Popover from "@javascript/reactComponents/lds/Popover";

export const DropdownContext = createContext({
  maxHeight: "unset",
  maxWidth: "unset",
  onItemSelected: () => {},
  setTrigger: () => {},
  size: "large",
})

export const Dropdown = ({
  align = "start",
  bodyStyles = {},
  children,
  closeOnTriggerClick = true,
  dropdownClassName = "",
  /** The CSS gap value to apply between popover and the trigger */
  gap = "var(--dropdown-list-space-marginT)",
  id = "",
  maxHeight = "unset",
  maxWidth = "unset",
  nested = false,

  onDropdownClose = () => {},
  onDropdownOpen = () => {},
  onItemSelected = () => {},
  popoverClassName = "",
  popoverPosition = "bottom",

  size = "large",

  ENABLE_LEGACY_DROPDOWN_SUPPORT = false,
  LEGACY_DROPDOWN_ID = "",
}, ref) => {
  const parentDropdownContext = useContext(DropdownContext)

  if (nested) {
    onItemSelected = parentDropdownContext.onItemSelected;
    maxWidth = parentDropdownContext.maxWidth;
    maxHeight = parentDropdownContext.maxHeight;
    size = parentDropdownContext.size;
  }
  const [myTrigger, setTrigger] = useState(null)
  const [open, setOpen] = useState(false)

  const openDropdown = () => {
    setOpen(true);
    onDropdownOpen();
  };

  const closeDropdown = (force = false) => {
    // When true, we know this dropdown is the one
    // that we care about
    if (open || force) {
      setOpen(false);
      onDropdownClose();
    }
  }

  const handleTriggerClick = (event) => {
    if (closeOnTriggerClick && open) {
      closeDropdown()
    } else {
      openDropdown()
    }
  }

  const onUserSelectItem = (item) => {
    if (ENABLE_LEGACY_DROPDOWN_SUPPORT) {
      addItemToLegacyDropdown(LEGACY_DROPDOWN_ID, item.value || item.label)
    }

    closeDropdown();
    onItemSelected(item);
  }

  // Create a custom ref for Dropdown
  // https://react.dev/reference/react/useImperativeHandle#reference
  useImperativeHandle(
    ref,
    () => ({
      open: openDropdown,
      close: () => closeDropdown(true),
    }),
    []
  );

  const hasMaxHeight = maxHeight && maxHeight !== "unset"
  const dropdownListStyles = {
    ...bodyStyles,
    maxHeight,
    maxWidth,
    overflowY: hasMaxHeight ? "auto" : "unset",
  }

  // Sub-nav always open on hover
  const trigger =
    <div role="menutrigger" onClick={open ? openDropdown : closeDropdown} onMouseOver={nested ? openDropdown : null}>
      {myTrigger}
    </div>

  return (
    <DropdownContext.Provider
      value={{
        maxHeight,
        maxWidth,
        onItemSelected: onUserSelectItem,
        setTrigger,
        size,
      }}
    >
      <Popover
        align={nested ? "start" : align}
        dropdownClassName={dropdownClassName}
        gap={gap}
        maxWidth={maxWidth}
        nested={nested}
        onClickOutside={closeDropdown}
        onMouseLeave={() => nested && setOpen(false)}
        onTriggerClick={handleTriggerClick}
        open={open}
        popoverClassName={popoverClassName}
        popoverPosition={nested ? "right" : popoverPosition}
        trigger={trigger}
      >
        <div id={id} role="menu" className="lds-dropdown-list" style={dropdownListStyles}>
          {/* Lets triggers/list components mount so they can update state in parent <Dropdown /> */}
          {children}
        </div>
      </Popover>

      {/*
        In order to support legacy HTML/jQuery use-cases,
        we keep a hidden dropdown synced with whatever the
        user selects.
      */}
      {ENABLE_LEGACY_DROPDOWN_SUPPORT && (
        <select id={LEGACY_DROPDOWN_ID} style={{ display: "none" }} />
      )}
    </DropdownContext.Provider>
  );
};

export default forwardRef(Dropdown);

/** 
 * A component to wrap the dropdown trigger.
 * This component does not render anything,
 * but rather passes the child to be rendered
 * elsewhere.
 */
export const DropdownTrigger = ({ children }) => {
  const { setTrigger } = useContext(DropdownContext)

  useEffect(() => {
    setTrigger(children)
  }, [children])

  return null
};

const addItemToLegacyDropdown = (id, value) => {
  const legacyDropdown = document.getElementById(id)
  const changeEvent = new Event("change")

  // A <select> MUST have a child with matching value!
  // Otherwise the dropdowns `value` attribute is
  // always empty string.
  const dropdownOption = document.createElement("option")
  dropdownOption.value = value
  dropdownOption.selected = true

  // Simulate proper DOM behaviour
  const existingOptions = [...legacyDropdown.getElementsByTagName("option")]
  const alreadyExists = existingOptions.find(option => option.value === value)

  // Only one can be selected at a time
  existingOptions.forEach(option => { option.selected = false })

  // Prevent adding duplicates, some legacy jQuery MAY break idk
  if (alreadyExists) {
    alreadyExists.selected = true
  } else {
    legacyDropdown.appendChild(dropdownOption)
    legacyDropdown.value = value
  }

  // Useful for notifying any event listeners, such as in jQuery
  legacyDropdown.dispatchEvent(changeEvent)
};

Dropdown.propTypes = {
  align: Popover.propTypes.align,
  bodyStyles: PropTypes.object,
  dropdownClassName: PropTypes.string,
  gap: Popover.propTypes.gap,
  closeOnTriggerClick: PropTypes.bool,
  id: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  nested: PropTypes.bool,
  onDropdownClose: PropTypes.func,
  onDropdownOpen: PropTypes.func,
  onItemSelected: PropTypes.func,
  popoverClassName: PropTypes.string,
  popoverPosition: Popover.propTypes.popoverPosition,
  size: PropTypes.oneOf(["small", "medium", "large"]),

  ENABLE_LEGACY_DROPDOWN_SUPPORT: PropTypes.bool,
  LEGACY_DROPDOWN_ID: PropTypes.string,
};
