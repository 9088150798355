import Reflux from "reflux";

const FilteringActions = Reflux.createActions([
  "initialState",
  "toggleFilteringOptions",
  "changeSelectedColumn",
  "editFilter",
  "revertState",
]);

window.FilteringActions = FilteringActions;
export default FilteringActions;
