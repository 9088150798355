import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";


window.React = React;
window.PureRenderMixin = PureRenderMixin;
window.createReactClass = createReactClass;
window.React.createReactClass = createReactClass;
window.PropTypes = PropTypes;
window.ReactDOM = ReactDOM;
