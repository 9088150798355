import Reflux from "reflux";
import createReactClass from "create-react-class";

import UserLists from "./UserLists";
import ColleaguesLists from "./ColleaguesLists";
import SalesforceLists from "./SalesforceLists";
import ListPageStore from "../../../stores/listPageStore";
import ListPageActions from "../../../stores/actions/lists/listPageActions";
import TabbedView from "../../lds/tabs/TabbedView";
import Tab from "../../lds/tabs/Tab";
import TabGroup from "../../lds/tabs/TabGroup";

function NavigationTabs({ activeTab, onSelectTab, categories }) {
  const parentStyles = {
    backgroundColor: "var(--color-gray-200)",
    paddingTop: "var(--space-600)",
  };

  const tabContainerStyles = {
    paddingBottom: "var(--space-600)",
  };

  const tabsMapping = {
    "My Lists": UserLists,
    "Salesforce Autogenerated Lists": SalesforceLists,
    "Colleagues' Lists": ColleaguesLists,
  };

  const tabLabels = Object.keys(tabsMapping);
  const ActiveComponent = tabsMapping[activeTab] || <></>;

  return (
    <div id="list-tab-items">
      <div style={parentStyles}>
        <div className="container w-1200" style={tabContainerStyles}>
          <TabGroup selectedTab={activeTab} onTabSelected={onSelectTab}>
            {tabLabels.map((label) => (
              <Tab
                key={label}
                label={label}
                value={label}
                component={() => <></>}
              />
            ))}
          </TabGroup>
        </div>
      </div>

      <div>
        <ActiveComponent categories={categories} />
      </div>
    </div>
  );
}

function NavigationTabItem({ label, activeTab, onSelectTab }) {
  return (
    <li
      className={`nav-item ${activeTab === label ? "active" : ""}`}
      onClick={() => onSelectTab(label)}
    >
      <a className="nav-link active" aria-current="page" href="#">
        {label}
      </a>
    </li>
  );
}

const ListPage = createReactClass({
  mixins: [Reflux.connect(ListPageStore)],
  selectTab(tab) {
    ListPageActions.selectTab(tab);
  },

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    const id = urlParams.get('id');
    const navHeight = 100; // Spacing from page top

    if (tab) {
      ListPageActions.selectTab(tab);
    }

    if (id) {
      setTimeout(() => {
        const anchorElement = document.getElementById(`user_${id}`);
        if (anchorElement) {
          const elementPosition = anchorElement.getBoundingClientRect().top + window.scrollY;
          const desiredScrollPosition = elementPosition - navHeight; // because of nav bar, need to scroll a little further to see object
          window.scrollTo({ top: desiredScrollPosition, behavior: 'smooth' });
        }
      }, 2000); // Delay to ensure the tab is rendered before scrolling
    }
  },

  render() {
    const { activeTab } = this.state;
    return (
      <>
        <NavigationTabs
          activeTab={activeTab}
          onSelectTab={this.selectTab}
          categories={this.props.categories}
        />
      </>
    );
  },
});

window.ListPage = ListPage;
export default ListPage;
