const GenericModalBody = ({ style, children }) => {

  const styles = {
    flex: 1,
    padding: '18px',
    border: 'solid 1px rgba(0,0,0,0.1)',
    borderLeft: 'none',
    borderRight: 'none',
    ...style
  }

  return (
    <div style={styles}>
      { children }
    </div>
  )
}



window.GenericModalBody = GenericModalBody;
export default GenericModalBody;