const FlaggingThisEvent = (props) => {
  var styl = {
    backgroundColor: props.cat_colour,
    borderColor: props.cat_colour
  };
  if (props.cat_dark_on_light) {
    var styl = {
      color: props.cat_colour,
      borderColor: props.cat_colour,
    };
  }
  return (
    <div className="row-fluid">
      <div className="span12">
        <br />
        <div className="panel">
          <div className="panel_title" style={styl}>
            <h4 className="ev_title">{props.title}</h4>
          </div>
          <div className="panel_content">
            <div>
              <p><em>{props.event_date}</em></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlaggingThisEvent;
