import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import Button from "@javascript/reactComponents/lds/Button";
import Calendar from "@javascript/reactComponents/lds/datepicker/Calendar";
import CalendarActions from "@javascript/reactComponents/lds/datepicker/CalendarActions";
import DatePickerHeader from "@javascript/reactComponents/lds/datepicker/DatePickerHeader";

/** 
 * @param d1 {Date}
 * @param d2 {Date}
 */
export function datesEqual(d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

const DatePicker = ({
  dateStart,
  dateEnd,
  maxDate,
  minDate,
  // When a date is clicked by user, but NOT confirmed.
  onDateClick = () => {},
  onDateSelect = () => {},
  range = false,
}) => {


  if (range && !(_.isDate(dateEnd) && !_.isNaN(dateEnd.getTime()))) {
    console.error("Range: Invalid dateEnd provided to DatePicker");
    return null;
  }

  const [selectedStartDate, setSelectedStartDate] = useState(dateStart);
  const [selectedEndDate, setSelectedEndDate] = useState(dateEnd || null);

  const [rangeVariants, setRangeVariants] = useState({
    start: "primary",
    end: "primary",
    button: _.isDate(dateStart) ? "primary" : "disable",
  });

  const isBefore = (date, limit) => date && limit && date < limit;
  const isAfter = (date, limit) => date && limit && date > limit;

  const validateDates = (endDate, rangeIndicator, startDate) => {
    let startVariant = "primary";
    let endVariant = "primary";
    let buttonVariant = "primary";

    if (!startDate || !endDate) {
      if (!startDate && endDate) {
        startVariant = "critical";
      }
    } else if (rangeIndicator === "start") {
      if (startDate > endDate) {
        startVariant = "critical";
      }
    } else if (rangeIndicator === "end") {
      if (startDate > endDate) {
        endVariant = "critical";
      }
    }

    if (minDate) {
      if (isBefore(startDate, minDate) || isBefore(endDate, minDate)) {
        buttonVariant = "disable";
      }
    }

    if (maxDate) {
      if (isAfter(startDate, maxDate) || isAfter(endDate, maxDate)) {
        buttonVariant = "disable";
      }
    }

    if (startVariant === "critical" || endVariant === "critical") {
      buttonVariant = "disable";
    }

    if (!_.isDate(startDate)) {
      buttonVariant = "disable"
    }

    setRangeVariants({
      start: startVariant,
      end: endVariant,
      button: buttonVariant,
    });
  };

  const handleDateSelect = (
    dateSelected,
    rangeIndicator,
  ) => {
    if (rangeIndicator === "end") {
      setSelectedEndDate(dateSelected);
      validateDates(dateSelected, rangeIndicator, selectedStartDate);
    } else {
      setSelectedStartDate(dateSelected);
      validateDates(selectedEndDate, rangeIndicator, dateSelected);
    }
  };

  const handleConfirm = (event) => {
    event.preventDefault();

    if (onDateSelect) {
      if (range) {
        onDateSelect([selectedStartDate, selectedEndDate]);
      }
      else {
        onDateSelect([selectedStartDate]);
      }
    }
  };

  useEffect(() => {
    handleDateSelect(dateStart, "start");
  }, [dateStart]);

  useEffect(() => {
    handleDateSelect(dateEnd, "end");
  }, [dateEnd]);

  const buttonText = range ? "Confirm Date Range" : "Confirm Selection";

  return (
    <div className="lds-date-picker">
      {range ? (
        <div className="lds-date-picker-range">
          <div className="lds-date-picker-range-container">
            <div
              data-testid="lds-calendar-container"
              className="lds-calendar-container"
            >
              <DatePickerHeader>Start Date</DatePickerHeader>
              <Calendar
                initialDate={selectedStartDate}
                minDate={minDate}
                maxDate={maxDate}
                onDateSelected={(dateSelected) => {
                  onDateClick([dateSelected, selectedEndDate]);
                  handleDateSelect(dateSelected, "start");
                }}
                variant={rangeVariants.start}
              />
            </div>
            <div
              data-testid="lds-calendar-container"
              className="lds-calendar-container"
            >
              <DatePickerHeader>End Date</DatePickerHeader>
              <Calendar
                initialDate={selectedEndDate}
                minDate={minDate}
                maxDate={maxDate}
                onDateSelected={(dateSelected) => {
                  onDateClick([selectedStartDate, dateSelected]);
                  handleDateSelect(dateSelected, "end");
                }}
                variant={rangeVariants.end}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <DatePickerHeader>Select Date</DatePickerHeader>
          <Calendar
            initialDate={selectedStartDate}
            minDate={minDate}
            maxDate={maxDate}
            onDateSelected={(dateSelected) => {
              onDateClick([dateSelected]);
              handleDateSelect(dateSelected, null);
            }}
            variant={rangeVariants.start}
          />
        </>
      )}
      <CalendarActions>
        <Button
          key={"Confirm"}
          onClick={handleConfirm}
          size="small"
          variant={rangeVariants.button}
          width={"100%"}
        >
          {buttonText}
        </Button>
      </CalendarActions>
    </div>
  );
};

DatePicker.propTypes = {
  dateStart: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.oneOf([null, undefined])
  ]),
  dateEnd: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.oneOf([null, undefined])
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.oneOf([null, undefined])
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.oneOf([null, undefined])
  ]),
  onCancel: PropTypes.func,
  onDateClick: PropTypes.func,
  onDateSelect: PropTypes.func,
  range: PropTypes.bool,
};

export default DatePicker;
