import CumulativeFundingByYear from "./cumulativeFundingByYear";

/**
 * React component that displays an organization's cumulative funding for the past 3 years
 *
 * @author GJ <gordon@zymewire.com>
 * @namespace CumulativeFundingCalendarYears
 *
 * @component
 * @example
 * return (
 *   <CumulativeFundingCalendarYears {...props} />
 * )
 *
 * @param {object} props Component props
 * @param {array} props.yearsAvailable array of objects denoting of cumulative funding info for 3 years
 *
 */
const CumulativeFundingCalendarYears = (props) => {
  const cumulative_by_year = _.map(props.yearsAvailable, function(yearData, index){
    return <CumulativeFundingByYear key={index} year={index} yearData={yearData} />
  });
  return (
    <div>
      <div className="infographic_panel_subtitle">Total funding over the last 3 years ($USD)</div>
      <div className="cumulative_by_year">
        {cumulative_by_year}
      </div>
    </div>
  );
};

CumulativeFundingCalendarYears.propTypes = {
  yearsAvailable: PropTypes.array.isRequired,
};

window.CumulativeFundingCalendarYears = CumulativeFundingCalendarYears;
export default CumulativeFundingCalendarYears;
