import Reflux from "reflux";

import ColumnsChosenActions from "../actions/pivotLists/columnsChosenActions";

const ColumnsChosenStore = Reflux.createStore({
  listenables: ColumnsChosenActions,
  init: function() {
    this.state = []
  },
  onInitialState: function(chosen_columns){
    this.state = chosen_columns
  },
  onReset: function(reset){
    const resetFromHash = Object.keys(reset).map(key => reset[key]);
    this.state = resetFromHash;
    this.trigger(this.state);
  },
  onClearAll: function(){
    this.state = [this.state[0]];
    this.trigger(this.state);
  },
  onRemoveFromChosen: function(element_data){
    this.state = this.state.filter(function(current){
      return current.column_name !== element_data.column_name
    });
    this.trigger(this.state);
  },
  onUpdateOrder: function(order){
    this.trigger([]);
    this.onUpdateComplete(order)
  },
  onUpdateComplete: function(order){
    const sorting = order;
    const new_data = this.state.map(function(item) {
      const n = sorting.indexOf(item.column_name);
      sorting[n] = '';
      return [n, item]
    }).sort().map(function(j) {return j[1]});
    this.state = new_data;
    this.trigger(this.state);
  },
  onAddToChosen: function(element_data, interactableColumnsAvailable){
    if(this.state.length <= interactableColumnsAvailable){
      this.state.push(element_data);
      this.trigger(this.state);
    }
  }
});

window.ColumnsChosenStore = ColumnsChosenStore;
export default ColumnsChosenStore;
