import Reflux from "reflux";
import FacetSearchStore from "../../stores/facetSearchStore";
import { FacetSearchCompanyRelationshipActions } from "../../stores/actions/facetSearch/companyRelationshipActions";

/**
 * Component responsible for rendering the breadbox for when a "Hide your muted companies" is selected.
 * In this context, unlocked means you can remove the selected option from the breadbox.
 *
 * @param {object} mutedCompanyList
 * @param {string} mutedCompanyList.value
 * @param {string} mutedCompanyList.name
 * @param {boolean} mutedCompanyList.locked
 * @returns
 * <div>
 *  <MutedCompanyLockedList /> or <MutedCompanyUnlockedList />
 * </div>
 */

const FacetSearchCriteriaMutedCompany = createReactClass({
  mixins: [Reflux.connectFilter(FacetSearchStore,"selected", function(data){
    return data.relationships.selected
  })],

  render(){
    const selected = this.state.selected;

    const relationshipsSelected = _.sortBy(selected, (rel) => -rel.length);
    const lastRelationshipSelected = relationshipsSelected.length -1;
    const relationshipProps =  relationshipsSelected.map((relationship, index) => {
      return {
        name: Zymewire.relationship_properties[relationship],
        value: relationship,
        last: lastRelationshipSelected === index,
      }
    });

    let mutedCompanyList = null;

    if(relationshipProps.length > 0) {
      _.each(relationshipProps, function (prop) {
        if (prop.value === "exclude_muted_companies") {
          mutedCompanyList = prop;
        }
      });
    }

    if(!mutedCompanyList){
      return null;
    }

    let original = Zymewire.original_feed_query;

    let neededValuesPresent = original && original.relationships && original.relationships.length > 0;

    if(neededValuesPresent){
      mutedCompanyList.locked = _.contains(original.relationships, mutedCompanyList.value);
      $('#rels_panel').hide();
    }

    return (
      <div>
        { mutedCompanyList.locked
          ? <MutedCompanyLockedList listName={mutedCompanyList.name} />
          : <MutedCompanyUnlockedList listName={mutedCompanyList.name} listValue={mutedCompanyList.value}/>
        }
      </div>
    )
  }
})

const MutedCompanyLockedList = ({listName}) => {
  return (
    <div className="filter_item_property" title="You are filtering your search by events specifically involving your unmuted companies.">
      <div style={{ display: 'inline-block', width: '100%' }}>
        <div style={{ marginLeft: "-5px", fontSize: "13px", color: "#000000" }}>
          <div className="filter_item_close" style={{ display: 'inline-block' }}></div>
          <b>{ listName }</b>
        </div>
      </div>
    </div>
  )
}

const MutedCompanyUnlockedList = ({listName, listValue}) => {
  return (
    <a onClick={() => { FacetSearchCompanyRelationshipActions.removeCompanyRelationship(listValue); }} className="action_link">
      <div className="filter_item_property" title="You are filtering your search by events specifically involving your unmuted companies. Click to remove this filter.">
        <div style={{ display:'inline-block', width:'100%' }}>
          <div style={{ marginLeft: '-5px', fontSize: '13px' }}>
            <div className="filter_item_close" style={{ display: 'inline-block' }}>
              <i className="icon-remove"></i>
            </div>
            <b>{listName}</b>
          </div>
        </div>
      </div>
    </a>
  )
}

window.FacetSearchCriteriaMutedCompany = FacetSearchCriteriaMutedCompany;
export default FacetSearchCriteriaMutedCompany;
