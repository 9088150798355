const QueryClinStage = (props) => {
  if (props.search.clin_stage) {
    var count = 0;
    var facets = props.search.clin_stage.map(function (stage, index) {
      var stage_string = count > 0 ? "OR " + stage : stage;
      count = 1;
      return (<p key={index}><b>{stage_string}</b></p>);
    });

    return (<div>{facets}</div>);
  }
  else {
    return false;
  }
}

export default QueryClinStage;
