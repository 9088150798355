const PhaseBar = (props) => {
  const barClasses = ["barchart_phase_bar"];
  let pixels = 0;
  let phaseCountClass = "barchart_phase_count";

  let activeLink = props.phase_data.count;
  if(parseFloat(props.phase_data.count) === 0){
    barClasses.push("no_studies");
  }
  else{
    if(parseFloat(props.phase_data.count) > 99){
      phaseCountClass = "barchart_phase_count_10";
    }
    pixels = (props.phase_data.count/props.max_phase*320)+30
    const url = props.url + '/facet_search?query=registered&facets%5Borganizations%5D%5B%5D=' + encodeURI(props.org_name).replace(/&/g, "%26") + '&category=all_event&time_select=time_any&clin_stage%5B%5D=' + props.phase_data.query + '&clin_status%5B%5D=Recruiting&clin_status%5B%5D=Enrolling+by+invitation&clin_status%5B%5D=Active%2C+not+recruiting&scroll=0&feature_track=frominfographic'
    activeLink = <a className="fancy_link active_studies_phase_link" title="Click to view the initial clinicaltrials.gov registration event of each study and to see more details of study design." href={url}>{props.phase_data.count}</a>;
  }

  const barStyle = {
    width: pixels + "px"
  }
  return (
    <div className={props.phase_data.class}>
      <div className="barchart_phase_label">
        {props.phase_data.name}
      </div>
      <div>
        <div className={barClasses.join(" ")} style={barStyle}>
          <span className={phaseCountClass}>{activeLink}</span>
        </div>
      </div>
    </div>
  );
};

export default PhaseBar;
