const MutedCompanyHolder = (props) => {
  const labelClasses = ["multirow_selection_label"].concat(props.labelClasses);
  const link = <a className="local_link location_link fancy_link" id={"link_" + props.id}>{props.name}</a>;
  const div_id = props.id + "_div";
  return (
    <div className="multirow_selection_hover">
      <div onClick={(event) => props.onClick({id: props.id, name: props.name}, event)} id={div_id} className={labelClasses.join(' ')}>{link}</div>
    </div>
  )
};

const MultiSelectCompanyHolder = (props) => {
  const name = props.company.name;
  const id = props.company.id;
  const classNames = ["foldable"].concat(props.classNames);
  const companyHolder = <MutedCompanyHolder name={name} id={id} labelClasses={props.labelClasses} onClick={props.onClick} />
  return (
    <div className={classNames.join(" ")}>
      {companyHolder}
    </div>
  );
};

window.MultiSelectCompanyHolder = MultiSelectCompanyHolder;
export default MultiSelectCompanyHolder;
