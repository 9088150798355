
const ListTableVerticalScroll = createReactClass({
  mixins: [Reflux.listenTo(pivotListColumnSettingsStore, 'updateContainerHeightState'), Reflux.listenTo(ColumnControlsStore,"toggleControlsState")],
  getInitialState: function(){
    return {
      controlsOpen: '',
      listTableContainerHeight: pivotListColumnSettingsStore.state.listTableContainerHeight
    }
  },
  updateContainerHeightState: function(newState) {
    this.setState({
      listTableContainerHeight:newState.listTableContainerHeight
    });
  },
  toggleControlsState: function(controlsOpen) {

    if(controlsOpen === 'checked') {
      this.resetScroll();
    }

    this.setState({
      controlsOpen: controlsOpen
    });
  },
  componentDidMount: function() {
    this.scroller.addEventListener("scroll", this.scrollController);
  },
  componentWillUnmount: function() {
    this.scroller.removeEventListener("scroll", this.scrollController);
  },
  resetScroll: function() {

    const listEntriesScrollData = document.getElementById("list_entries_scroll_data")
    const listCompaniesScrollData = document.getElementById("list_companies_scroll_data")

    $(listEntriesScrollData).animate({scrollTop: 0}, 500);
    $(listCompaniesScrollData).animate({scrollTop: 0}, 500);
    $(this.scroller).animate({scrollTop: 0}, 500);

    const listEntriesScrollHeader = document.getElementById("list_entries_scroll_header");
    const listCompaniesScrollHeader = document.getElementById("list_companies_scroll_header");

    listEntriesScrollHeader.classList.remove('pivotlist_vertical_scroll_shadow');
    listCompaniesScrollHeader.classList.remove('pivotlist_vertical_scroll_shadow');

  },
  scrollController: function() {

    if(this.state.controlsOpen === 'checked') {
      return;
    }

    const listEntriesScrollData = document.getElementById("list_entries_scroll_data")
    const listCompaniesScrollData = document.getElementById("list_companies_scroll_data")
    listCompaniesScrollData.scrollTop = listEntriesScrollData.scrollTop = this.scroller.scrollTop;

    const listEntriesScrollHeader = document.getElementById("list_entries_scroll_header")
    const listCompaniesScrollHeader = document.getElementById("list_companies_scroll_header")

    if(this.scroller.scrollTop > 0) {
      listEntriesScrollHeader.classList.add('pivotlist_vertical_scroll_shadow');
      listCompaniesScrollHeader.classList.add('pivotlist_vertical_scroll_shadow');
    }
    else {
      listEntriesScrollHeader.classList.remove('pivotlist_vertical_scroll_shadow');
      listCompaniesScrollHeader.classList.remove('pivotlist_vertical_scroll_shadow');
    }
  },
  render: function () {

    const containerClassNames = ['pivotlist_vertical_scroll_container', 'pivotlist_scroll_container'];
    const headerHeight = 70;
    const rowHeight = 57;

    const rowsBeforeScrollerAppears = Math.floor((this.state.listTableContainerHeight - headerHeight)/rowHeight);

    if(this.state.controlsOpen !== 'checked' && this.props.listLength > rowsBeforeScrollerAppears) {
      containerClassNames.push('pivotlist_scroll_container_show');
    }
    else {
      containerClassNames.push('pivotlist_scroll_container_hide');
    }

    const scrollerPadding = 75;
    const scrollerStyle = {height: (this.state.listTableContainerHeight - scrollerPadding) + 'px'};

    return (
      <div className={containerClassNames.join(' ')}>
        <div ref={elem => this.scroller = elem} id='pivotlist_vertical_scroll' className='pivotlist_vertical_scroll' style={scrollerStyle}>
          <div id='list_table_vertical_scroll_inner' className='list_table_vertical_scroll_inner'></div>
        </div>
      </div>
    );
  }
});

export default ListTableVerticalScroll;
