const AdminModalFooter = (props) => {
  return (
    <div className="modal-footer">
      {props.children}
    </div>
  )
};

window.AdminModalFooter = AdminModalFooter;
export default AdminModalFooter;
