import ZoominfoPersonName from "./zoominfoPersonName";
import ZoominfoPersonCompany from "./zoominfoPersonCompany";
import BootstrapFullWidthRow from "../../generic/htmlElements/bootstrapFullWidthRow";
import ZoomInfoRevealed from "./zoomInfoRevealed";
import ZoominfoPersonRecordOpenCaret from "./zoominfoPersonRecordOpenCaret";
import ZoominfoPurchasedContactFurtherDetails from "./zoominfoPurchasedContactFurtherDetails";

const ZoominfoPurchasedContact = createReactClass({
  getInitialState: function () {
    return ({ open: false });
  },

  toggleOpenState: function (e) {
    if (!(_.contains(e.target.classList, 'dont_stop'))) {
      e.stopPropagation();
      e.preventDefault();
      var new_state = !this.state.open;
      this.setState({ open: new_state });
    }
  },

  handleAddToSF: function (e) {
    e.preventDefault();
    e.stopPropagation();
    var mixpanelOptions = { action: "add to salesforce modal triggered", location: "from contact search", type: "contact or lead selection" };
    ContactSearchAction.addContactToSalesforce(this.props.entity_id, mixpanelOptions);
  },

  render: function () {
    var further_details;
    if (this.state.open) {
      further_details = <ZoominfoPurchasedContactFurtherDetails {...this.props} />;
    }
    var handleAddToSF;
    if (this.props.can_use_salesforce) {
      handleAddToSF = this.handleAddToSF;
    }

    return (
      <BootstrapFullWidthRow fluid>
        <div className="zi_person_card cs_person_card">
          <table className="zi_person_table" width="100%">
            <tbody>
              <tr className="topRow" onClick={this.toggleOpenState}>
                <ZoominfoPersonName
                  zi_contact_id={this.props.entity_id}
                  org_id={this.props.org_id}
                  FirstName={this.props.first_name}
                  LastName={this.props.last_name}
                  JobTitle={this.props.designation}
                  handleAddToSF={handleAddToSF}
                  localAddress={this.props.address}
                  revealed_date={this.props.current_contact.created_at}
                  feature_flags={this.props.feature_flags}
                  deliverability={this.props.deliverability}
                  sales_force_contacts={this.props.current_contact.sales_force_contacts}
                  sales_force_leads={this.props.current_contact.sales_force_leads} />

                <ZoominfoPersonCompany CompanyName={this.props.company.name} />

                <ZoomInfoRevealed {...this.props} revealedSection={true} />
                <ZoominfoPersonRecordOpenCaret open={this.state.open} handleClick={this.toggleOpenState} />
              </tr>
              {further_details}
            </tbody>
          </table>
        </div>
      </BootstrapFullWidthRow>
    );
  }
});

export default ZoominfoPurchasedContact