import SfAccountTeam from "./sfAccountTeam";

const SfAccountName = (props) => {
  const account_click = (e) => {
    e.stopPropagation()
    e.preventDefault()
    SFPanelActions.trackEngagementEvent('Go to account in SF', {});
    window.open(props.url, '_blank')
  };
  const owner_is_zw =  props.owner?.zw_user ? props.owner?.name : props.owner?.name + "(Non "+ I18n.t("brand.name") + " User)";
  return (
    <div className="sf_account_name">
      <a className="fancy_link sf_account_link fullstory_excluded" href={props.url} onClick={account_click} target="blank">{props.account.Name}</a>
      <br />
      <span className="sf_account_owner"><b>Account owner:</b> {owner_is_zw}</span>
      <SfAccountTeam account_team={props.account_team} />
    </div>
  );
};

export default SfAccountName;
