const SfContactPhone = (props) => {
  if(props.phone){
    return <p className="sf_contact_details"><a href={"tel:" + props.phone}>{props.phone}</a></p>;
  }
  else{
    return false;
  }
};

export default SfContactPhone;
