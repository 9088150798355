const SfContactURL = (props) => {
  const trackContact = () => {
    SFAccountActions.trackEvent('see_contact_in_salesforce','User clicked link to see a Contact in  SF', {});
    SFAccountActions.trackEngagementEvent('View contact in SF', {});
  };
  let title;
  if(props.title){
    title = ", " + props.title;
  }
  return (<p className="sf_contact_details"><a href={props.url} onClick={trackContact} target="blank">{props.name}</a>{title}</p>);
};

export default SfContactURL;
