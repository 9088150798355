import Reflux from "reflux";

import RowRemovalActions from "../actions/pivotLists/rowRemovalActions";

const RowRemovalStore = Reflux.createStore({
  listenables: RowRemovalActions,
  init: function() {
    this.state = [];
    this.removed_companies = {}
  },
  onRemoveCompany: function(list_id, entity_id){
    $("body").addClass("wait");
    $.get("/lists/" + list_id + "/remove_org_from_list", {entity_id: entity_id}, function (e) {
      $("body").removeClass("wait");
      this.onRefreshRemove(entity_id, e.list_entry)
    }.bind(this));
  },
  onRestoreCompany: function(company_id){
    $("body").addClass("wait");
    $.get('/lists/add_entry_back_to_list', {entry: this.removed_companies[company_id]}, function () {
      $("body").removeClass("wait");
      this.onRefreshRestore(company_id)
    }.bind(this));
  },
  onRefreshRemove: function(company_id,list_entry){
    this.removed_companies[company_id] = list_entry;
    this.state.push(company_id);
    this.trigger(this.state);
  },
  onRefreshRestore: function(company_id){
    this.state.remove(company_id);
    this.trigger(this.state);
  }
});

window.RowRemovalStore = RowRemovalStore;
export default RowRemovalStore;
