import Reflux from "reflux";

const ContactSearchAction = Reflux.createActions([
  "search",
  "buyContact",
  "checkDeliverability",
  "initialState",
  "addContactToSalesforce",
  "clickedEmail",
  "clickedPhone",
  "contactAddedToSalesforce",
  "leadAddedToSalesforce",
  "generateEmail",
  "changePage",
  "updateRevealedContacts",
]);

window.ContactSearchAction = ContactSearchAction;
export default ContactSearchAction;
