function ListSearchDescription({searchValue, selectedCategory, onClearSearch}) {
  return (
    <div style={{ marginBottom: "var(--space-600)" }} id="list-search-description">
      <span className="type-body-large">
        Showing results for <span className="type-body-bold-large">
          {searchValue}
        </span> in <span className="type-body-bold-large">
          {selectedCategory}
        </span>
        &emsp;
        <a className="text-button" onClick={() => onClearSearch(searchValue)}>Clear Search</a>
      </span>
    </div>
  )
}

export default ListSearchDescription;
