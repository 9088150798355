const ListTableHorizontalScroll = createReactClass({
  mixins: [Reflux.listenTo(ColumnControlsStore,"toggleControlsState")],
  getInitialState: function(){
    return {
      controlsOpen: '',
    }
  },
  toggleControlsState: function(controlsOpen) {

    if(controlsOpen === 'checked') {
      this.resetScroll();
    }

    this.setState({
      controlsOpen: controlsOpen
    });
  },
  componentDidMount: function() {
    this.scroller.addEventListener("scroll", this.scrollController);

    const sortField = getParameterByName('sort_field');

    if(sortField !== null) {
      const findSortIndex = function (columnsChosen, sortField) {
        for (let i = 0; i < columnsChosen.length; i++) {
          if  (columnsChosen[i].column_name === sortField) {
            return i;
          }
        }
        return 0;
      };

      let sortIndex = findSortIndex(this.props.columnsChosen, sortField);

      if(sortIndex > 4) {

        const scrollerPadding = 22;
        const scrollToHere = ((this.props.columnWidths[this.props.columnWidthsIndex] + scrollerPadding) * sortIndex);

        setTimeout(function(){$(this.scroller).animate({scrollLeft: scrollToHere}, 500);}.bind(this), 750);
      }

    }
  },
  componentWillUnmount: function() {
    this.scroller.removeEventListener("scroll", this.scrollController);
  },
  resetScroll: function() {

    const listEntriesScrollData = document.getElementById("list_entries_scroll_data");
    const listEntriesScrollHeader = document.getElementById("list_entries_scroll_header");
    const listCompaniesScrollHeader = document.getElementById("list_companies_scroll_header");

    const listCompaniesScrollData = document.getElementById("list_companies_scroll_data")
    const headerForCompanies = document.getElementById("header_for_companies")

    listCompaniesScrollData.classList.remove('pivotlist_horizontal_scroll_shadow');
    headerForCompanies.classList.remove('pivotlist_horizontal_scroll_shadow');
    listCompaniesScrollHeader.classList.remove('pivotlist_horizontal_scroll_shadow');

    $(listEntriesScrollData).animate({scrollLeft: 0}, 500);
    $(listEntriesScrollHeader).animate({scrollLeft: 0}, 500);
    $(listCompaniesScrollHeader).animate({scrollLeft: 0}, 500);
    $(this.scroller).animate({scrollLeft: 0}, 500);

  },
  scrollController: function() {

    if(this.state.controlsOpen === 'checked') {
      return;
    }

    const listEntriesScrollData = document.getElementById("list_entries_scroll_data");
    const listEntriesScrollHeader = document.getElementById("list_entries_scroll_header");
    const listCompaniesScrollHeader = document.getElementById("list_companies_scroll_header");

    listEntriesScrollData.scrollLeft = listEntriesScrollHeader.scrollLeft = this.scroller.scrollLeft;

    const listCompaniesScrollData = document.getElementById("list_companies_scroll_data")
    const headerForCompanies = document.getElementById("header_for_companies")

    if(this.scroller.scrollLeft > 0) {
      listCompaniesScrollData.classList.add('pivotlist_horizontal_scroll_shadow');
      headerForCompanies.classList.add('pivotlist_horizontal_scroll_shadow');
      listCompaniesScrollHeader.classList.add('pivotlist_horizontal_scroll_shadow');
    }
    else {
      listCompaniesScrollData.classList.remove('pivotlist_horizontal_scroll_shadow');
      headerForCompanies.classList.remove('pivotlist_horizontal_scroll_shadow');
      listCompaniesScrollHeader.classList.remove('pivotlist_horizontal_scroll_shadow');
    }
  },
  render: function () {

    const containerClassNames = ['pivotlist_horizontal_scroll_container', 'pivotlist_scroll_container'];

    if(this.state.controlsOpen !== 'checked' && this.props.columnWidthsIndex >= 5) {
      containerClassNames.push('pivotlist_scroll_container_show');
    }
    else {
      containerClassNames.push('pivotlist_scroll_container_hide');
    }

    const scrollerPadding = 22;
    const scrollerWidth = ((this.props.columnWidths[this.props.columnWidthsIndex] + scrollerPadding) * this.props.columnWidthsIndex) + 'px';
    const scrollerStyle = {width: scrollerWidth, minWidth: scrollerWidth, maxWidth: scrollerWidth};

    return (
      <div className={containerClassNames.join(' ')}>
        <div ref={elem => this.scroller = elem} id='pivotlist_horizontal_scroll' className='pivotlist_horizontal_scroll'>
          <div id='list_table_horizontal_scroll_inner' className='list_table_horizontal_scroll_inner' style={scrollerStyle}></div>
        </div>
      </div>
    );
  }
});

export default ListTableHorizontalScroll;
