const ZoominfoEmailAvailable = function(props) {
  // Show the text only if the company that we are in has
  // domain or the email is present from the contact provider
  // We are doing this can the validity checker doesnt send back
  // unless we have a domain. This is a degradation in the User experience.
  var email = 'Email';

  if (!props.showEmail) {
    email = null;
  }

  if(props.current_contact && props.current_contact.email){
    var email_add = props.current_contact.email;
    var mail_to = "mailto:" + email_add;
    email = <a href={mail_to} target="_blank" className="dont_stop cs_revealed_contact_email"><i className="fa fa-envelope"/> Revealed</a>
  }

  return (
    <td  className="action_link cs_email_available" width="80px">
      {email}
    </td>
  )
}

export default ZoominfoEmailAvailable;
