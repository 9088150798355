function EntityListDisplay ({entityList}) {
  var entityListItems = entityList.map(function (entity) {
    var name = shortenText(27, entity.name);
    const key = name + "_" + entity.count;

    return (
      <li key={key}>
        {name} ({entity.count})
      </li>
    );
  });
  return <ul className="simple-list">{entityListItems}</ul>;
};

export default EntityListDisplay;
