import CheckboxItem from "../lds/selection_controls/CheckboxItem";
import useCheckbox from "../lds/hooks/useCheckbox";

export default function IncludeFeedsOnHomePageCheckbox() {
  const checkbox = useCheckbox(true);

  return (
    <CheckboxItem
      {...checkbox}
      label="Include new events from this feed on your home page"
      ENABLE_LEGACY_CHECKBOX_SUPPORT
      LEGACY_CHECKBOX_ID="display_checkbox"
      styleOverride={{
        label: {
          fontSize: "unset", // Adoption guidelines require us to keep current size
        },
      }}
    />
  );
}

window.IncludeFeedsOnHomePageCheckbox = IncludeFeedsOnHomePageCheckbox;
