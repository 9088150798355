import FacetCheckboxContainer from "./facetCheckboxContainer";

/**
 * React component that displays a list of search inputs (checkboxes) for "Clinical Trial Status"
 *
 * @params: none
 *
 */
const ClinicalTrialStatusSelector = createReactClass({
  mixins: [
      Reflux.connectFilter(
          FacetSearchStore,
          function(data) {
            return {
              clin_status: data.facets.clin_status,
              clinicalTrialStatuses: data.clinicalTrialStatuses
            }
          }
      )
  ],
  /** Important: this.state is an empty object on the first render. Once the store connects, it then gets populated.*/
  getInitialState() {
    return {}
  },
  render() {
    const { clinicalTrialStatuses, clin_status } = this.state;

    const show = clin_status && clin_status.buckets && clin_status.buckets.length > 0;

    if (!show) {
      return null;
    }

    const updateClinicalTrialStatusSelected = (e, value) => {
      e.stopPropagation();

      const clinicalTrialStatusesSelected = [...this.state.clinicalTrialStatuses.selected];
      const selectedIndex = clinicalTrialStatusesSelected.indexOf(value);

      if (selectedIndex > -1) {
        // remove the clinical trial stage from the array... we no longer wish to represent it as checked
        clinicalTrialStatusesSelected.splice(selectedIndex, 1);
      } else {
        // add the clinical trial stage to the array... it should be checked now
        clinicalTrialStatusesSelected.push(value);
      }

      FacetSearchStoreActions.clinicalTrialStatusesUpdate(
        {selected: clinicalTrialStatusesSelected},
        {doSearch: true, triggerStateUpdate: true}
      );
    }

    const checkboxTitle = "Filter events by trials with current status of '%%TERM%%'";
    const facetTitle = "Filter events based on whether they reference a trial currently registered with the state at clinicaltrials.gov.";
    const facetName = "Current Trial Status";
    const facetType = "trial_status";
    const facetClasses = clinicalTrialStatuses.selected.length > 0 ? ["facet_panel_open"] : [];
    const { display, selected } = clinicalTrialStatuses;

    return (
      <FacetCheckboxContainer
        checkboxList={display}
        checkboxTitle={checkboxTitle}
        checkedBoxes={selected}
        facetClasses={facetClasses}
        facetName={facetName}
        facetTitle={facetTitle}
        facetType={facetType}
        onToggle={updateClinicalTrialStatusSelected}
      />
    )
  }
});

window.ClinicalTrialStatusSelector = ClinicalTrialStatusSelector;
export default ClinicalTrialStatusSelector;
