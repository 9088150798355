const SfOpportunity = (props) => {
  const trackOpportunity = () => {
    SFAccountActions.trackEvent("see_opportunity_in_salesforce", "User clicked link to see an opportunity in  SF", {});
    SFAccountActions.trackEngagementEvent("Go to opportunity in Salesforce", {});
  };
  const actDate = moment(props.last_activity).add(12, 'h');
  return (
    <div className="sf_holder">
      <p><a href={props.url} onClick={trackOpportunity} target="blank">{props.name}</a></p>
      <p>Status: {props.status}  (last activity {actDate.fromNow()}, on {actDate.format("dddd, Do MMMM YYYY")})</p>
    </div>
  );
};

export default SfOpportunity;
