/**
 * React component that displays an individual facet search checkbox
 *
 * @author
 * @namespace
 * @memberof
 *
 * @component
 * @example
 * return (
 *   <FacetCheckboxItem
 *         key={index}
 *         checkboxValue={checkboxValue}
 *         checkboxLabel={checkboxLabel}
 *         checked={checked}
 *         toggleCheckbox={toggleCheckbox}
 *         checkboxTitle={checkboxTitleTranslated}
 *         checkboxClasses={checkboxClasses}
 *         checkboxLabelClasses={checkboxLabelClasses}
 *         />
 * )
 *
 * @param {Number} key as this is one of a series of items, this is usually the index of the item in the array. But can be anything unique.
 * @param {String} checkboxValue the value of the checkbox
 * @param {String} checkboxLabel the label (AKA display name) of the checkbox
 * @param {String} checked is the checkbox checked
 * @param {Method} toggleCheckbox a method to manage the state of this checkbox (checked or not) via store -- this store is required because, among other things, we intercept browser history and have the need to update a checkbox's state based on query portion of the URL in the browser state
 * @param {String} checkboxTitle a phrase used for tooltip and contains the checkboxValue regexed into the string
 * @param {Array} checkboxClasses array of CSS classes to use with this generic checkbox
 * @param {Array} checkboxLabelClasses array of CSS classes to use with this generic checkbox's label
 */

const GenericCheckboxItem = createReactClass({
  getInitialState() {
    return {
      checked: this.props.checked
    };
  },
  handleChange(e) {
    this.setState({checked: !this.state.checked});
    this.props.toggleCheckbox(e, this.props.checkboxValue);
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.checked !== this.state.checked) {
      this.setState({ checked: nextProps.checked });
    }
  },
  render() {

    const classNames = ["standard_checkbox"].concat(this.props.checkboxClasses);
    const labelClassNames = ["label_for_checkbox"].concat(this.props.checkboxLabelClasses);

    return (
      <div>
        <input checked={this.state.checked} id={this.props.checkboxValue} className={classNames.join(' ')}
               type="checkbox" onChange={this.handleChange} value={this.props.checkboxValue}/>
        <label htmlFor={this.props.checkboxValue} className={labelClassNames.join(' ')}
               title={this.props.checkboxTitle}>
          {this.props.checkboxLabel}
        </label>
      </div>
    );
  }
});

export default GenericCheckboxItem;
