import Reflux from "reflux";
import FacetSearchStore from "../../stores/facetSearchStore";
import { FacetSearchAffiliatedWithActions } from "../../stores/actions/facetSearch/affiliatedWithActions";
import { FacetSearchListActions } from "../../stores/actions/facetSearch/listActions";

/**
 * Component responsible for rendering the breadbox for when a list is selected: 'Your Lists'.
 *
 * @param {object} props
 * @param {object} props.list - The selected OrgList.
 */
const FacetSearchCriteriaList = createReactClass({
  mixins: [
    Reflux.connectFilter(
      FacetSearchStore, 'list', function(data) {
        if (Zymewire.user.feature_tags.includes("list_of_other_entities")) {
          return data.userLists.selected
        } else {
          return data.orgLists.selected
        }
      }
    )
  ],
  getInitialState() {
    let data = {};

    if (Zymewire.user.feature_tags.includes("list_of_other_entities")) {
      data.list = FacetSearchStore.data.userLists.selected;
    } else {
      data.list = FacetSearchStore.data.orgLists.selected;
    }

    return data;
  },
  render() {
    const list = this.state.list
    if (list === undefined || list === null) {
      return null
    }

    const originalListId = (
      Zymewire.original_feed_query &&
      (Zymewire.original_feed_query.org_list_id || Zymewire.original_feed_query.user_list_id)
    )

    if (originalListId) {
      list.locked = true
    }

    const isPublicList = list.listType === "public";
    const canSeeCmoLists = _.contains(
      Zymewire.user.feature_tags,
      "cmo_concepts_and_associations"
    );

    return (
      <div>
        {list.locked ? (
          <LockedList
            list={list}
            isPublicList={isPublicList}
            canSeeCmoLists={canSeeCmoLists}
          />
        ) : (
          <UnlockedList
            list={list}
            isPublicList={isPublicList}
            canSeeCmoLists={canSeeCmoLists}
          />
        )}
      </div>
    );
  }
})

/**
 * Component responsible for rendering the breadbox for when a list is selected: 'Your Lists'.
 * In this context, locked means you cannot remove the selected option from the breadbox.
 *
 * @param {object} props
 * @param {object} props.list - The selected OrgList.
 * @param {boolean} props.isPublicList -Public list? (true or false)
 * @param {boolean} props.canSeeCmoLists - Feature flag enabled? (true or false)
 * @returns
 */
const LockedList = ({ list, isPublicList, canSeeCmoLists }) => {
  const entity_type = String(list.entity_type);

  return (
    <div>
      {isPublicList && canSeeCmoLists ? (
        <div>
          <p>the companies affiliated with:</p>
          <div className="selection_label event_sub_category affiliation_selection false_fold capitalize_words rspec_public_list_filter">
            {list.name.replace("Drug sponsors affiliated with ", "")}
          </div>
        </div>
      ) : (
        <div
          className="rspec_filter_by_list filter_item filter_item_entity"
          title={`You are filtering your search by ${entity_type.toLowerCase()} currently on this list.`}
        >
          <div
            className="filter_item_close"
            style={{ display: "inline-block" }}
          />
          <div style={{ display: "inline-block" }}>
            <p style={{ marginLeft: "-5px", fontSize: "14px", color: "#000" }}>
              <i className="icon-list" />
              <b>
                <span className="fullstory_excluded">
                  {` ${entity_type} listed on '${list.name}'`}
                </span>
              </b>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * Component responsible for rendering the breadbox for when a list is selected: 'Your Lists'.
 * In this context, unlocked means you can remove the selected option from the breadbox.
 *
 * @param {object} props
 * @param {object} props.list - The selected OrgList.
 * @param {boolean} props.isPublicList -Public list? (true or false)
 * @param {boolean} props.canSeeCmoLists - Feature flag enabled? (true or false)
 * @returns
 */
const UnlockedList = ({ list, isPublicList, canSeeCmoLists }) => {
  const removeAffiliation = (e) => {
    e.preventDefault();
    e.stopPropagation();
    FacetSearchAffiliatedWithActions.affiliationRemoved({ doSearch: true });

    return false;
  };

  const entity_type = String(list.entity_type);

  return (
    <div>
      {isPublicList && canSeeCmoLists ? (
        <div>
          <p>the companies affiliated with:</p>
          <div
            onClick={removeAffiliation}
            className="selection_label event_sub_category affiliation_selection false_fold capitalize_words rspec_public_list_filter cat_filter_enabled"
          >
            {list.name.replace("Drug sponsors affiliated with ", "")}
            <div className="remove_filter" />
          </div>
        </div>
      ) : (
        <a
          onClick={() => {
            FacetSearchListActions.unselectList({doSearch: true});

            return false;
          }}
        >
          <div
            className="rspec_filter_by_list filter_item filter_item_entity"
            title={`You are filtering your search by ${entity_type.toLowerCase()} currently on this list. Click to remove this filter.`}
          >
            <div
              className="filter_item_close"
              style={{ display: "inline-block" }}
            >
              <i className="icon-remove" />
            </div>
            <div style={{ display: "inline-block" }}>
              <p style={{ marginLeft: "-5px", fontSize: 14 }}>
                <i className="icon-list" />
                <b>
                  <span className="fullstory_excluded">
                    {` ${entity_type} listed on '${list.name}'`}
                  </span>
                </b>
              </p>
            </div>
          </div>
        </a>
      )}
    </div>
  );
};

window.FacetSearchCriteriaList = FacetSearchCriteriaList;
export default FacetSearchCriteriaList;
