import stickybits from "stickybits";
import AvailableLi from "./availableLi";
import ColumnChoicePagination from "./columnsChoicePagination";
import FillerLi from "./fillerLi";
import SearchInput from "../../../generic/searchInput";
import Fuse from "fuse.js";

const AvailableColumns = createReactClass({
  mixins: [Reflux.listenTo(pivotListColumnSettingsStore, 'updateSettingsState'), Reflux.listenTo(ColumnsChosenStore,"update_chosen"), Reflux.listenTo(DragStore,"update_dragged")],
  getInitialState: function(){
    let columnsAvailable = this.props.columns_available.slice();
    let filteredColumnsAvailable = this.props.columns_available.slice();
    return {
      columnsChosen:this.props.columns_chosen.map(function(item) {
        return item.column_name
      }),
      dragged: {type:null, over:false, data:{}},
      current_page: 1,
      search_string: '',
      filteredColumnsAvailable: filteredColumnsAvailable,
      columnsAvailable: columnsAvailable,
      totalColumnChoicePages: pivotListColumnSettingsStore.state.totalColumnChoicePages,
      columnChoicesPerPage: pivotListColumnSettingsStore.state.columnChoicesPerPage,
      interactableColumnsCount: pivotListColumnSettingsStore.state.interactableColumnsCount,
      fixedColumnsCount: pivotListColumnSettingsStore.state.fixedColumnsCount,
      listTableContainerHeight: pivotListColumnSettingsStore.state.listTableContainerHeight
    }
  },
  updateSettingsState: function(newState) {
    this.setState({
      listTableContainerHeight:newState.listTableContainerHeight,
      totalColumnChoicePages: newState.totalColumnChoicePages,
      columnChoicesPerPage: newState.columnChoicesPerPage,
      interactableColumnsCount: newState.interactableColumnsCount,
      fixedColumnsCount: newState.fixedColumnsCount
    });
  },
  update_chosen: function(columnsChosen) {
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      this.setState({
        columnsChosen: columnsChosen.map(function(item) {
          return item.column_name
        })
      });
    }

  },
  update_dragged: function(dragged) {
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      this.setState({
        dragged: dragged
      });
    }
  },
  componentDidMount:function(){
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      this.options_available.addEventListener("dragenter", this.drag_enter);
      Sortable.create(document.getElementById('columns_available'), {
        animation: 150,
        group: {
          name: "shared",
          put: false,
          pull: "clone",
          revertClone: false,
        },
        sort: false,
        draggable:'.column_choice_enabled'
      });
    }
    stickybits($('.columns_available'), {stickyBitStickyOffset: 40});
  },
  componentWillUnmount: function() {
    this.options_available.removeEventListener("dragenter", this.drag_enter);
  },
  drag_enter: function(event){
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      DragActions.setDragOver('available');
    }
  },
  jump_to: function(page){
    this.go_to_page(page);
  },
  jump_left: function(e){
    e.preventDefault();
    this.go_to_page(this.state.current_page - 3)
  },
  jump_right: function(e){
    e.preventDefault();
    this.go_to_page(this.state.current_page + 3)
  },
  go_to_page:function(page) {
    const current_page = Math.max(0, Math.min(page, this.state.totalColumnChoicePages));
    const start = (current_page-1)*this.state.columnChoicesPerPage;
    const end = current_page*this.state.columnChoicesPerPage;
    this.setState({current_page:current_page, columnsAvailable:this.state.filteredColumnsAvailable.slice(start,end)});
  },
  searchColumns:function(search_string) {
    let preFilteredColumnsAvailable = this.props.columns_available.slice();
    const filteredColumnsAvailable = !search_string
      ? preFilteredColumnsAvailable
      : this.getSearchResults(preFilteredColumnsAvailable, search_string);
    let columnsAvailable = filteredColumnsAvailable.slice();
    this.setState({columnsAvailable:columnsAvailable.splice(0,this.state.columnChoicesPerPage), totalColumnChoicePages:Math.ceil(parseFloat(filteredColumnsAvailable.length/this.state.columnChoicesPerPage)), current_page:1, search_string:search_string, filteredColumnsAvailable:filteredColumnsAvailable});
    Zymewire.mixpanel_track('search pivotlist columns', {search_string:search_string})
  },
  getSearchResults:function(preFilteredColumnsAvailable,search_string){
    const fuseOptions = {
      keys: ['full_name','synonyms'],
      includeScore: true,
      ignoreLocation: true,
      threshold: 0.2
    };
    const fuse = new Fuse(preFilteredColumnsAvailable, fuseOptions);
    const searchResults = fuse.search(search_string);
    let filteredColumnsAvailable = preFilteredColumnsAvailable.filter(function (column) {
      return searchResults.some(result => result.item.full_name.toLowerCase() === column.full_name.toLowerCase());
  });
    return filteredColumnsAvailable;
  },
  render: function() {
    let number_of_column_options = this.state.filteredColumnsAvailable.length + ' column options';
    let over_spot = 0;
    let class_name = '';
    let interactableColumnsAvailable = this.state.interactableColumnsCount + this.state.fixedColumnsCount;
    if(this.state.dragged.over === 'available' && this.state.dragged.type === 'choice'){
      class_name = ' dragged_over';
      over_spot = 1;
    }
    let columnsAvailable = this.state.columnsAvailable.slice();
    columnsAvailable = columnsAvailable.splice(0, this.state.columnChoicesPerPage);
    if(this.state.search_string !== ''){
      number_of_column_options = (this.state.filteredColumnsAvailable.length === 1) ? this.state.filteredColumnsAvailable.length + ' MATCHING option' :  this.state.filteredColumnsAvailable.length + ' MATCHING options';
    }
    if(columnsAvailable.length < this.state.columnChoicesPerPage){
      columnsAvailable = array_pad(columnsAvailable, this.state.columnChoicesPerPage, {column_name:'FILLER'});
    }
    const content = _.map(columnsAvailable.splice(0), function(element, index){
      if(element.column_name.startsWith('FILLER')){
        return (
          <FillerLi key={index} />
        );
      }
      else if(!element.column_name.startsWith('sf_') || this.props.display_sf === true){
        let over_class = '';
        if(over_spot === 1 && (typeof this.state.dragged.data !== 'undefined')){
          if(element.column_name === this.state.dragged.data.column_data.column_name){
            over_spot = 0;
            over_class = ' pivot_drop_li';
          }
        }
        const class_name = (this.state.columnsChosen.length === interactableColumnsAvailable || this.state.columnsChosen.includes(element.column_name, 1)) ? 'column_choice_disabled' : 'column_choice_enabled';
        return (
          <AvailableLi feature_flags={this.props.feature_flags} key={index} column_data={element} class_name={class_name} over_class={over_class} search_string={this.state.search_string}/>
        );
      }
    }.bind(this));
    const selectionsRemaining = (interactableColumnsAvailable-this.state.columnsChosen.length === 1) ? 'selection' : 'selections';
    const selectionsRemainingCount = interactableColumnsAvailable-this.state.columnsChosen.length;
    return(
      <div className={"columns_available" + class_name}>
        <p className="columns_instructions pivotlist_search_matches">{number_of_column_options} (<span className="some_columns_remaining">{interactableColumnsAvailable-this.state.columnsChosen.length} {selectionsRemaining} remaining):</span></p>
        {/* TODO: REACTAPACKER */}
        <SearchInput id='pivotlist_column_search_bar' searchFunction={this.searchColumns} feature_flags={this.props.feature_flags} placeHolder='column option'  width="387px" size="small"/>
        <ul ref={elem => this.options_available = elem} className="columns_available_ul" id="columns_available">
          {content}
        </ul>
        <ColumnChoicePagination totalColumnChoicePages={this.state.totalColumnChoicePages} current_page={this.state.current_page} jump_left={this.jump_left} jump_right={this.jump_right} jump_to={this.jump_to} />
      </div>
    )
  }
});

export default AvailableColumns;
