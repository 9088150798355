import Reflux from "reflux";
import AutocompleteSearchPanel from "./autocompleteSearchPanel";
import AutocompleteResults from "./autocompleteResults";
import { eventEditModalStyle } from "../../../config/administrateEvent";

const FindNewEntityPanel = createReactClass({
  mixins: [Reflux.connect(EntSearchStore)],
  getInitialState: function () {
    return EntSearchStore.data;
  },
  render: function(){
    return (
      <table style={eventEditModalStyle.table}>
        <tbody>
          <tr>
            <td style={eventEditModalStyle.cell}>
              <AutocompleteSearchPanel search_string={this.state.search_string}/>
            </td>
            <td style={eventEditModalStyle.cell} id={'rspec_autocomplete_results'}>
              <AutocompleteResults results={this.state.results} />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
});

export default FindNewEntityPanel;
