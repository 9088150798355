import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import DropdownItemGroup from "./DropdownItemGroup";
import DropdownItem from "./DropdownItem";

export const DropdownListContext = createContext({
  leadingIcon: undefined,
  trailingIcon: undefined
})

/**
 * A component to render list of dropdown items.
 */
export default function DropdownList({
  children,
  hasNoneSelection = false,
  items,
  leadingIcon,
  maxHeight,
  trailingIcon
}) {
  const parentContext = useContext(DropdownListContext);

  leadingIcon ||= parentContext.leadingIcon;
  trailingIcon ||= parentContext.trailingIcon;

  return (
    <DropdownListContext.Provider value={{ leadingIcon, trailingIcon }}>
      <div
        className="lds-dropdown-list-container"
        style={{ maxHeight, overflowY: maxHeight ? "auto" : "unset" }}
      >
        {children && children}

        {!children && hasNoneSelection && (
          <DropdownItem noneSelection label="" value={null} />
        )}
        {!children &&
          items.map((item) => {
            const grouped = !!item.groupedItems;

            return grouped ? (
              <DropdownItemGroup key={item.value} group={item} />
            ) : (
              <DropdownItem
                key={item.value}
                leadingIcon={leadingIcon}
                trailingIcon={trailingIcon}
                {...item}
              />
            );
          })}
      </div>
    </DropdownListContext.Provider>
  );
}

DropdownList.propTypes = {
  hasNoneSelection: PropTypes.bool,
  items: PropTypes.array,
  leadingIcon: PropTypes.string,
  maxHeight: PropTypes.string,
  trailingIcon: PropTypes.string
};
