// future home of zymewire.com/lists
// for now, simply the modal confirming multiple deletes at once

var DeleteListsConfirmationModal = (props) => {
    const deleted_selected_lists = function(){
        $("body").addClass("wait");
        var data = {lists_selected: props.lists_selected}
        $.ajax({
            url: '/lists/delete_lists',
            type: 'POST',
            data: data,
            success: function () {
                window.location.href = '/lists';
                // close_modal_control();
            }
        });
    }
    
    const cancel_delete = function(){
        close_modal_control();
    }

    var lists = 'that list';
    var list = 'that list';
    if(props.lists_selected.length > 1){
        lists = 'all ' + props.lists_selected.length + ' lists';
        list = 'those lists';
    }
    return (
        <div className="modal_wrapper">
            <div className="modal-header modal_header">
                <button className="close" onClick={cancel_delete} data-dismiss="modal">×</button>
                <h3 id="modal_flag_title">Are you sure?</h3>
            </div>
            <div className="modal-body modal_body">
                <div className="container-fluid" id="modal_content_holder">
                    <div className="row-fluid">
                        <div className="span12">
                            <h3>Are you sure you wish to delete {lists}? Any feeds that depend on {list} will be deleted or modified. <span className="alert_text">This action cannot be undone and may take up to 5 minutes, depending on the size of the lists.</span></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer buttonGroup-small">
                <button onClick={cancel_delete} data-dismiss="modal" className="button-small button-secondary">Cancel</button>
                <button onClick={deleted_selected_lists} className="button-small button-critical">Delete</button>
            </div>
        </div>
    );
}

window.DeleteListsConfirmationModal = DeleteListsConfirmationModal;
export default DeleteListsConfirmationModal;
