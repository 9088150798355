import RemoveIndustryLink from "./removeIndustryLink";

const RemoveIndustryLinks = (props) => {
  const industries = props.industries.map(function (industry, index) {
    return <RemoveIndustryLink key={index} industry={industry}/>;
  });
  return (
    <div>{industries}</div>
  )
};

export default RemoveIndustryLinks;
