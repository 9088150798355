const QueryList = (props) => {
  if (props.search.list) {
    return (<p>Listed on <span className="fullstory_excluded">'<b>{props.search.list}</b>'</span></p>);
  }
  else {
    return false;
  }
}

export default QueryList;
