import Button from "@javascript/reactComponents/lds/Button";
import ButtonGroup from "@javascript/reactComponents/lds/ButtonGroup";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@javascript/reactComponents/lds/modal/Modal";

const GDPRModal = function (props) {
  var contactLocation = props.location || "an unlisted location";

  var gdprThe = ["Czech Republic", "Netherlands", "United Kingdom"];
  if (gdprThe.includes(contactLocation)) {
    contactLocation = "the " + contactLocation;
  }

  return (
    <>
      <ModalHeader title="Please confirm usage" />

      <ModalBody className="flex-col">
        <p>
          This person appears to be from {contactLocation}. As data processors,
          neither our 3rd party contact providers, nor {I18n.t("brand.name")}{" "}
          has explicit consent from this person for you to email them. To help
          you stay compliant with GDPR, please confirm that you are reaching out
          for business-to-business purposes between your business and this
          person's employer for one of the following reasons:
        </p>
        <div className="contact-search-privacy-clauses">
          <p>
            <b>1)</b> - Any reason, because I have confirmed this person is not
            in fact in the European Union (GDPR does not apply);
          </p>
          <p>
            <b>OR 2a)</b> usage is based on the person having given consent to
            the use of her or his personal data for one or more specific
            purposes;
          </p>
          <p>
            <b>OR 2b)</b> usage is necessary for the performance of a contract
            to which this person is party or in order to take steps at the
            request of this person prior to entering into a contract;
          </p>
          <p>
            <b>OR 2c)</b> processing is necessary for compliance with a legal
            obligation to which my company is subject;
          </p>
          <p>
            <b>OR 2d)</b> processing is necessary in order to protect the vital
            interests of this person or of another natural person;
          </p>
          <p>
            <b>OR 2e)</b> processing is necessary for the performance of a task
            carried out in the public interest or in the exercise of official
            authority vested in my company;
          </p>
          <p>
            <b>OR 2f)</b> processing is necessary for the purposes of the
            legitimate interests pursued by my company or by a third party,
            except where such interests are overridden by the interests or
            fundamental rights and freedoms of this person which require
            protection of personal data, in particular where the data subject is
            a child.
          </p>
        </div>
      </ModalBody>

      <ModalFooter align="end">
        <ButtonGroup size="medium">
          <Button
            id="rspec-cancel-gdpr-modal"
            size="medium"
            variant="secondary"
            onClick={props.onClose}
          >
            Cancel
          </Button>

          <Button
            id="rspec-confirm-gdpr-modal"
            size="medium"
            variant="primary"
            onClick={props.onConfirm}
          >
            Confirm
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};

export default GDPRModal;
