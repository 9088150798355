import FacetListHeader from "./facetListHeader";

/**
 * React component
 *
 * Shows checkboxes to filter the search by relationship to company.
 * Displays according to options set through the FacetSearchStore. Will trigger a new facet search on change.
 *
 * @component
 * */

// This should be consolidated into genericCheckboxList
//   -- GJ 2022.09.08
// except now it can't - there are competing onClick handlers now and genericCheckboxList is designed for standard
// list of checkboxes that toggle the state of one element, not multiple

const CompanyRelationshipSelector = createReactClass({
  mixins: [Reflux.connectFilter(FacetSearchStore, function (data) { return data.relationships } )],
  getInitialState() {
    return {
      ...FacetSearchCompanyRelationshipSlice
    }
  },
  handleCheckboxChange: function (event) {
    event.stopPropagation()
    const $checkbox = event.target;

    if ($checkbox.checked) {
      FacetSearchCompanyRelationshipActions.addCompanyRelationship($checkbox.value);
    } else {
      FacetSearchCompanyRelationshipActions.removeCompanyRelationship($checkbox.value);
    }
  },
  handleMutedCompaniesChange: function (event) {
    const $checkbox = event.target;
    if ($checkbox.checked) {
      Zymewire.external_track_action(
        'exclude_muted_companies applied in search'
      )
    }

    this.handleCheckboxChange(event)
  },
  handleEditMutedCompanies: function(event) {
    event.preventDefault();
    event.stopPropagation();
    locationRedirect('/settings/muted_companies/edit');
  },
  render: function() {
    const crmKey = 'crm',
      neglectedKey = 'exclude_recent_sponsor',
      mutedKey = 'exclude_muted_companies',
      relationshipsSelected = this.state.selected;

    const shouldDisplay = _.contains(Zymewire.user.feature_tags, "can_use_muted_companies") || (
      this.state.salesforceCurrentlyProcessing && this.state.authorizedSalesForce
    )

    if (!shouldDisplay) {
      return (false);
    }

    return (
      <div id="rels_panel" className="facet_panel">
        <FacetListHeader
          id="relationships_facets"
          title="Filter events based on your relationships to the organizations involved."
          facetName="Company Relationship "
          show={false}
        />

        <div className="facet_body">
          <form id="relationship_form">
            {
              (this.state.authorizedSalesForce && this.state.salesforceCurrentlyProcessing) &&
              <label htmlFor={crmKey} id="salesforce_checkbox_label" className="checkbox" title="Filter events by companies you have in your Salesforce">
                <input id={crmKey} className="relationship_checkbox" type="checkbox" value={crmKey} checked={ _.contains(relationshipsSelected, crmKey) } onChange={ this.handleCheckboxChange }/>Companies in your Salesforce
                <img src="/images/salesforce_lit.png" className="facet_filter_sf_icon company_relationship_filter" alt="Salesforce"/>
              </label>
            }

            { _.contains(Zymewire.user.feature_tags, 'sponsor_with_no_team_activity') && this.state.authorizedSalesForce &&
              <label htmlFor={neglectedKey} id="sponsor_no_team_activity_checkbox_label" className="checkbox" title={ 'Filter events by companies that do not have Salesforce Activity by your ' + I18n.t('brand.name') + ' team in the last 6 months' }>
                <input id={neglectedKey} className="relationship_checkbox" type="checkbox" value={neglectedKey} checked={ _.contains(relationshipsSelected, neglectedKey) } onChange={ this.handleCheckboxChange }/> { I18n.t('terminology.neglected_target_label') } <sup>TM</sup>
              </label>
            }

            { _.contains(Zymewire.user.feature_tags, "can_use_muted_companies") &&
              <label htmlFor={mutedKey} id="muted_companies_checkbox_label" className="checkbox" title={ 'Filter events to show your unmuted companies only.' }>
                <input id={mutedKey} className="relationship_checkbox" type="checkbox" value={mutedKey} checked={ _.contains(relationshipsSelected, mutedKey) } onChange={ this.handleMutedCompaniesChange }/> Hide My Muted Companies
                (<a id="edit_muted_companies" className="override_page_link fancy_link" onClick={this.handleEditMutedCompanies}>edit</a>)
              </label>
            }
          </form>
        </div>
      </div>
    )
  }
});

window.CompanyRelationshipSelector = CompanyRelationshipSelector;
export default CompanyRelationshipSelector;
