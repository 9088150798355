import TherapeuticArea from "./therapeuticArea";

const TherapeuticAreaCard = (props) => {
  const therapeuticAreas = _.map(props.therapeutic_areas, function(therapeutic_data, index){
    return <TherapeuticArea key={index} therapeutic_data={therapeutic_data} max_therapies={props.max_therapies}/>
  }.bind(this));
  return (
    <div className="infographic_card_content" id="therapeutic_area">
      <input type="checkbox" className="infographic_checkbox" id="more_info_therapeutic_area"/>
      <div className="card_top_section">
        <label className="infographic_more_info" htmlFor="more_info_therapeutic_area">
          <div className="more_info_menu">
            <div className="more_info_menu_line more_info_menu_half more_info_menu_start"></div>
            <div className="more_info_menu_line"></div>
            <div className="more_info_menu_line more_info_menu_half more_info_menu_end"></div>
          </div>
        </label>
        <div className="cards_more_content">
          <div className="cards_more_data">
            <div className="cards_more_top">
              <p className="infographic_panel_title">Therapeutic Area of Focus</p>
              <p className="infographic_panel_subtitle">in order of focus</p>
            </div>
            <div className="cards_more_graph">
              <div className="cards_more_info">
                {props.org_name} has focus in the following therapeutic areas, sorted by the company's focus.  Areas of focus that are greyed out indicated no events have taken place in that therapeutic area.
              </div>
            </div>
          </div>
          <p className="infographic_panel_title">Therapeutic Area of Focus</p>
          <p className="infographic_panel_subtitle">in order of focus</p>
          <div className="therapeutic_areas">
            {therapeuticAreas}
          </div>
          <div className="cards_more_float"></div>
        </div>
      </div>
    </div>
  );
};

window.TherapeuticAreaCard = TherapeuticAreaCard;
export default TherapeuticAreaCard;
