const FilterOptions = function(props) {
  const content = _.map(props.column_filter_options, function(column_filter_option, index){
    if(index !== 0){  // skip filtering on companies for now
      return (
        <FilterOption key={index} column_filter_option={column_filter_option} index={index} />
      );
    }
  });

  return (
    <div className="pivotlist_filter_control_options">
      {content}
    </div>
  );
}

const FilterOption = createReactClass({
  mixins: [Reflux.listenTo(FilteringActionsStore, 'change_state')],
  getInitialState(){
    return {explanation: false, column_selected: FilteringActionsStore.state.column_selected, column_filter: FilteringActionsStore.state.column_filters[this.props.index]};
  },
  change_state(new_state) {
    this.setState(state => (
      {...state, column_selected:new_state.column_selected}
    ));
  },
  toggle_explanation(){
    this.setState(state => (
      {...state, explanation:!state.explanation}
    ));
  },
  render(){
    const class_names = ['column_filter_option'];
    if(this.state.column_selected === this.props.index){
      class_names.push('column_filter_option_selected');
    }


    let filter_option = '';
    const explanation_class_names = ['filter_options_explained'];
    const description_class_names = ['column_filter_description'];
    if(this.state.explanation){
      explanation_class_names.push('filter_options_close_explanation');
      description_class_names.push('column_filter_description_show');
    }
    if(this.props.column_filter_option.type === 'boolean'){
      filter_option = <FilterBoolean {...this.props}/>
    }
    else{
      filter_option = 'This column is not filterable.';
    }
    return (
      <div className={class_names.join(' ')}>
        <div className="column_filter_header">Filter Options <span onClick={this.toggle_explanation} className={explanation_class_names.join(' ')}></span></div>
        {filter_option}
        <div className={description_class_names.join(' ')}>{this.props.column_filter_option.description}</div>
      </div>
    );
  }
});

const FilterBoolean = function(props) {
  const content = _.map(props.column_filter_option.options, function(option, index){
    let toggled = true;
    if (props.column_filter_option.current_filter.includes(option.value)){
      toggled = false;
    }
    return (
      <FilterToggle key={index} theme="green" size="16" toggled={toggled} filter_option={option} index={props.index}/>
    );
  });

  return (
    <div>
      {content}
    </div>
  );
}

const FilterToggle = createReactClass({
  getInitialState(){
    return {toggled: this.props.toggled, current_filter:FilteringActionsStore.state.column_filters[this.props.index]};
  },
  toggle_filter(e) {
    e.stopPropagation();
    FilteringActions.editFilter(this.props.index, this.props.filter_option.value, this.state.toggled);  // previous toggle state, therefore if true (IE toggled and therefore included) we should add the value to the array (as this array will be used to search for entries *not* containing the value) and if false (IE not toggled and therefore excluded) we should remove the value from the array as we no longer wish to exclude the value from our search... capiche? JSON.stringify(obj1) === JSON.stringify(obj2)
    this.setState(state => (
      {...state, toggled:!state.toggled}
    ));
  },
  render(){
    const classNames = ['switch-toggle'];
    if(this.state.toggled){
      classNames.push('switch-toggle--on');
    }
    else{
      classNames.push('switch-toggle--off');
    }
    const toggleClassNames = ['switch switch--' + this.props.theme, 'switch--' + this.props.size];
    return (
      <div className="filter_toggle_holder">
        <span className="filter_toggle_switch">
          <div className={toggleClassNames.join(' ')} onClick={this.toggle_filter}>
            <div className={classNames.join(' ')}></div>
          </div>
        </span>
        <span className="filter_toggle_label">{this.props.filter_option.label}</span>
      </div>
    );
  }
});

export default FilterOptions;
