import SfAccount from "./sfAccount";

const SfAccountLoader = createReactClass({
  mixins: [Reflux.listenTo(SFAccountLoaderActionsStore,"refreshAccount")],
  componentDidMount: function() {
    const url = "/company/" + this.props.company_id + "/sales_force_account_data";
    $.get(url, {account_id: this.props.account_id}, function(account_data) {
      this.setState({account: account_data});
    }.bind(this));
  },
  refreshAccount: function(account_id){
    if(this.props.account_id === account_id){
      const url = "/company/" + this.props.company_id + "/sales_force_account_data";
      $.get(url, {account_id: this.props.account_id}, function(account_data) {
        this.setState({account: account_data});
      }.bind(this));
    }
  },
  render: function(){
    if(this.state && this.state.account) {
      for (var i = 0; i < this.props.accounts.length; i++) {
        if (this.props.accounts[i].account_id === this.state.account.account.Id) {
          this.props.accounts[i].opps = this.state.account.opportunities.length;
          this.props.accounts[i].owner = this.state.account.owner.name;
          this.props.accounts[i].loaded = true;
          if (this.state.account.activity_history && this.state.account.activity_history.length > 0) {
            this.props.accounts[i].last_activity = this.state.account.activity_history[0].due_date ? this.state.account.activity_history[0].due_date : this.state.account.activity_history[0].sort_date
          }
        }
      }
      SFAccountActions.updateAccounts(this.props.accounts)
      return (
        <SfAccount {...this.state.account} lead_count={this.props.lead_count} tab_selected={this.props.tab_selected} accounts_count={this.props.accounts.length} company_id={this.props.company_id} sf_user_id={this.props.sf_user_id}/>
      );
    }
    else{
      return (<div className="sf_loading_spinner"><img src='/images/ajax-loader.gif' alt='Loading, please be patient' style={{height:"24px", marginRight:"7px"}}/> retrieving account information from Salesforce for {this.props.account_name}</div>)
    }
  }
});

export default SfAccountLoader;
