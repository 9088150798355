import FlaggingEventUserList from "./flaggingEventUserList";
import FlaggingEventComment from "./flaggingEventComment";
import FlaggingThisEvent from './flaggingThisEvent';
import FlaggingEventReminder from "./flaggingEventReminder";

const FlaggingEventModalBody = (props) =>
  <div id="flag_event_modal_body">
    <form id="flag_for_form">
      <input type='hidden' name='authenticity_token' value={props.authenticity_token} />
      <FlaggingEventUserList userList={props.userList} toggleUser={props.toggleUser} />
      <FlaggingThisEvent {...props.event} />
      <FlaggingEventComment comment={props.comment} updateComment={props.updateComment} />
    </form>
  </div>

export default FlaggingEventModalBody;