const EditEventDateForm = createReactClass({
  getInitialState: function () {
    return {
      eventDateString: moment(parseInt(this.props.event.dateMilli)).format("DD/MM/YYYY HH:mm"),
      validDate: false,
      errors: ""
    }
  },
  handleDateChange: function (ev) {
    const eventDateString = ev.target.value;
    const momentValue = moment(eventDateString, "DD/MM/YYYY HH:mm", true)

    if (momentValue.isAfter(moment())) {
      this.setState({
        eventDateString: eventDateString,
        validDate: false,
        errors: "Event date cannot be in the future"
      });
    }
    else {
      this.setState({
        eventDateString: eventDateString,
        validDate: momentValue.isValid(),
        errors: ""
      });
    }

  },
  submitHandler: function (e) {
    e.preventDefault();
    const momentValue = moment(this.state.eventDateString, "DD/MM/YYYY HH:mm", true);
    if (momentValue.isValid()) {
      EventModifyModalAction.updateDate(momentValue);
      this.props.closeForm(e);
    }
  },
  setDateToNow: function (e) {
    e.preventDefault();
    const now = moment();
    EventModifyModalAction.updateDate(now);
    this.props.closeForm(e);
  },
  render: function () {
    const buttonStyle = {marginLeft: "3px"};
    const submitIcon = this.state.validDate ? "icon-ok" : "icon-ok icon-white";
    return (
      <form className="form-horizontal event-date-form" onSubmit={this.submitHandler}>
        <div>
          <input type="text" value={this.state.eventDateString} onChange={this.handleDateChange}/>
          <button style={buttonStyle} disabled={!this.state.validDate} type="submit"><i className={submitIcon}/>
          </button>
          <button style={buttonStyle} onClick={this.props.closeForm}><i className="icon-remove"/></button>
          <button style={buttonStyle} onClick={this.setDateToNow}>Now</button>
        </div>
        <label style={{marginTop: "5px", color: "red"}}>{this.state.errors}</label>
      </form>
    );
  }
});

export default EditEventDateForm;
