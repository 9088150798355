import Reflux from "reflux";

import SFPanelWrapperActions from "../actions/salesForce/sFPanelWrapperActions";

const SFPanelWrapperActionsStore = Reflux.createStore({
  listenables: SFPanelWrapperActions,
  onUpdateAccounts: function(new_account_set){
    this.trigger('accounts', new_account_set);
  },
  onUpdateLeads: function(new_lead_set){
    this.trigger('leads', new_lead_set);
  }
});

window.SFPanelWrapperActionsStore = SFPanelWrapperActionsStore;
export default SFPanelWrapperActionsStore;
