import SfContactURL from "./sfContactUrl";
import SfContactLastActivity from "./sfContactLastActivity";
import SfContactEmail from "./sfContactEmail";
import SfContactPhone from "./sfContactPhone";

const SfContact = (props) => {
  return (
    <div className="span6 sf_contact_cell">
      <div className="sf_holder sf_contact">
        <SfContactURL {...props.contacts}/>
        <SfContactLastActivity {...props.contacts}/>
        <SfContactEmail {...props.contacts} />
        <SfContactPhone {...props.contacts} />
      </div>
    </div>
  );
};

export default SfContact;
