import Reflux from "reflux";
import ListPageActions from "./actions/lists/listPageActions";

const ListPageStore = Reflux.createStore({
  listenables: [ListPageActions],
  getInitialState: function () {
    return {
      activeTab: "My Lists",
    };
  },
  onSelectTab: function (tab) {
    this.trigger({ activeTab: tab });
  },
});

export default ListPageStore;
