import Reflux from "reflux";

// this seems poorly named -- or the rest of the column state management objects are. Probably the latter. Refactor required.
// -- GJ 2023.08.30

const pivotListColumnSettingsActions = Reflux.createActions([
  "initialState",
]);

// We should import the actions in the files they're used in, thereby doing away with this window.hack
// But because the imports are used in files loaded by app/javascripts/applicaiton.js via //= require components
// the import statement required for this is sprocketed into a require statement: not supported by the browser
window.pivotListColumnSettingsActions = pivotListColumnSettingsActions;
export default pivotListColumnSettingsActions;
