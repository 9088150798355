import { useState, useEffect } from "react";
import ListTable from "../ListTable";
import ColleaguesListsStore from "../../../../stores/lists/ColleagueListStore";

/** An expansion panel that grows to show a table */
export default function ColleaguesListRow({ lists, evenChild }) {
  const [open, setOpen] = useState(false);
  const { name, id } = lists[0].user;
  const panelStyle = {
    backgroundColor: evenChild ? "var(--color-gray-200)" : "white",
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('id') === id) {
      setOpen(true);
    }
  }, [id]);

  return (
    <div id={"user_"+id}>
      <div
        className="colleagues-list-expansion-panel"
        style={panelStyle}
        onClick={() => setOpen(!open)}
      >
        <span className="type-body-bold-large">
          {name} ({lists.length})
        </span>

        <div className="colleagues-expansion-panel-icon-container">
          <span className={open ? "icon-chevron-up" : "icon-chevron-down"} />
        </div>
      </div>

      {open && <ExpansionPanelBody lists={lists} />}
    </div>
  );
}

function ExpansionPanelBody({ lists }) {
  return (
    <div className="colleagues-list-expansion-panel-body">
      <ListTable
        lists={lists}
        showTag={ColleaguesListsStore.data.selectedCategory === "All"}
        editable={false}
        showHeader={false}
      />
    </div>
  );
}
