import Reflux from "reflux";
import ColleaguesListsActions from "../actions/lists/ColleagueListsActions";
import { DEFAULT_CATEGORY } from "../../constants/lists/list_constants";

const ColleaguesListsStore = Reflux.createStore({
  listenables: [ColleaguesListsActions],
  init() {
    this.data = {
      categories: {},
      selectedCategory: DEFAULT_CATEGORY,
      lists: [],
      _originalLists: [],
    };
  },
  async onInitialState({ categories }) {
    this.data.categories = {};
    this.data.selectedCategory = DEFAULT_CATEGORY;
    this.data.lists = [];
    this.data._originalLists = [];

    await this.configureCategoryOptions(categories);
    await this.getColleaguesLists();

    this.trigger(this.data);
  },
  onSelectCategory(category) {
    this.data.selectedCategory = category;

    // Filter
    let lists = [...this.data._originalLists];

    if (category !== "All") {
      lists = this.data._originalLists.filter(
        (list) => list.type_ref === category
      );
    }

    this.data.lists = this.formatUserLists(lists);
    this.trigger(this.data);
  },
  async configureCategoryOptions(categories) {
    this.data.categories = categories.reduce((acc, current) => {
      acc[current] = 0;
      return acc;
    }, {});
  },
  async getColleaguesLists() {
    const response = await fetch("/colleagues_lists");

    if (response.ok) {
      const { lists } = await response.json();
      this.data._originalLists = lists;

      this.setcategories(lists);
      this.data.lists = this.formatUserLists(lists);
    } else {
      // TODO: Error handling
    }
  },
  /** Indexes the lists based on the first letter of each name */
  formatUserLists(lists) {
    const formatted = {};

    // Populate first-level index
    lists.forEach((list) => {
      const firstLetter = list.user.name[0].toUpperCase();
      formatted[firstLetter] ||= [];

      formatted[firstLetter].push(list);
    });

    // Sort each bucket
    Object.keys(formatted).forEach((letter) => {
      const bucket = formatted[letter];

      bucket.sort((listA, listB) =>
        listA.user.name > listB.user.name ? 1 : -1
      );
    });

    return formatted;
  },
  setcategories(lists) {
    Object.keys(this.data.categories).forEach((category) => {
      const count = lists.filter((list) => list.type_ref === category).length;
      this.data.categories[category] = count;
    });
  },
});

export default ColleaguesListsStore;
