import Reflux from "reflux";

import SFPanelActions from "../actions/salesForce/sFPanelActions";

const SFPanelActionsStore = Reflux.createStore({
  listenables: SFPanelActions,
  init: function(){
    this.data_store = {company_id:null, company_name:null, selected:null}
  },
  onInitialState: function(company_id, company_name){
    this.data_store = {company_id:company_id, company_name:company_name}
  },
  onTrackEvent: function(ref, message, params){
    track(ref, message, _.extend(params, {organisation_id: this.data_store.company_id, org_name: this.data_store.company_name}))
  },
  onTrackEngagementEvent: function(message, params){
    track_engagement(message, _.extend(params, {organisation_id: this.data_store.company_id, org_name: this.data_store.company_name}))
  },
  onJump: function (account_id){
    $('html,body').animate({scrollTop: $('#' + account_id).offset().top-183}, 250);
    return false;
  }
});

window.SFPanelActionsStore = SFPanelActionsStore;
export default SFPanelActionsStore;
