import Reflux from "reflux";
import ReactDOM from "react-dom";

import AdminModal from "../../generic/modals/adminModal";
import AdminModalHeader from "../../generic/modals/adminModalHeader";
import AdminModalBody from "../../generic/modals/adminModalBody";
import EventDateHandler from "./eventDateHandler";
import EditEventEntities from "./editEventEntities";
import AddEntityPanel from "./addEntityPanel";
import FindNewEntityPanel from "./findNewEntityPanel";
import EditIndustryPanel from "./editIndustryPanel";
import EditEventFooter from "./editEventFooter";

const EventModifyModal = createReactClass({
  mixins: [Reflux.connect(EventModifyModalStore)],
  getInitialState: function () {
    const eventCopy = jQuery.extend(true, {}, this.props.event);
    return {event: eventCopy, eventData: EventModifyModalStore.eventData};
  },
  UNSAFE_componentWillMount: function () {
    const eventCopy = jQuery.extend(true, {}, this.props.event);
    EventModifyModalAction.initialLoad(eventCopy, this.props.handleHideModal, this.props.admin_industry);
  },
  componentDidMount() {
    setTimeout(
      function() {
        let eventElement = document.getElementById('modal_source_content_highlight');
        eventElement.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});	// The 'true' parameter will make it align-top; remove it if you experience any issues
      },300);
  },
  componentWillUnmount: function () {
    let modal = ReactDOM.findDOMNode(this.refs.event_modify_modal);
    $(modal).modal('hide');
  },
  eventHighlight: function () {
    let evContent = this.state.event.content.replace(/\s?\"?\.\.\.\"?\s?/g, "");
    let evSourceContent = this.state.event.source_content;
    let splitContent = evSourceContent.split(evContent);
    let preEventText = splitContent[0];
    splitContent.shift();

    let boldEvent = <b id='modal_source_content_highlight'>{evContent}</b>;

    if (evSourceContent === preEventText) { // Auto-generated events may not have an exact match with their source doc.
      return  (
        <div>
          <p>
            <h3>Event</h3>
            {boldEvent}
            <hr/>
            <h3>Source Document</h3>
            {evSourceContent}
          </p>
        </div>
      );
    } else {
      return  (
        <div>
          <p>
            {preEventText}
            {boldEvent}
            {splitContent.join(evContent)}
          </p>
        </div>
      );
    };
  },
  render: function () {
    return (
      <AdminModal ref="event_modify_modal" handleHideModal={this.props.handleHideModal}>
        <AdminModalHeader>Modifying event</AdminModalHeader>
        <AdminModalBody style={{maxHeight: "600px", paddingTop: '20px', paddingBottom: '20px'}}>
          <div>
            <p><b>{this.state.event.title}</b></p>
          </div>
          <div className="source_content_modal_window">
            {this.eventHighlight()}
          </div>
          <EventDateHandler {...this.state} />
          <EditEventEntities event={this.state.event}/>
          <AddEntityPanel event={this.state.event}/>
          <FindNewEntityPanel />
          <EditIndustryPanel event={this.state.event}/>
        </AdminModalBody>
        <EditEventFooter {...this.state} />
      </AdminModal>
    );
  }
});

export default EventModifyModal;
