const ZoominfoPersonLinks = createReactClass({
  clickToViewLIProfile: function () {
    track('zoominfo_click_out_to_LI_profile', 'Search LI from revealed contact', { profile_url: this.props.provider_url });
    track_engagement('Search LI from revealed contact', { profile_url: this.props.provider_url });
  },
  render: function () {
    if(this.props.linked_in_url){
      return (
        <td>
          <a href={this.props.linked_in_url} className="cs_zoom_person_link" target="_blank" onClick={this.clickToViewLIProfile}>View on LinkedIn</a>
        </td>
      );
    }
    else {
      const li_url = 'https://www.linkedin.com/search/results/all/?keywords=' + encodeURIComponent([this.props.first_name, this.props.last_name, this.props.company_name].join(' ')) + '&origin=GLOBAL_SEARCH_HEADER';
      return (
        <td>
          <a href={li_url} className="cs_zoom_person_link" target="_blank" onClick={this.clickToViewLIProfile}>Check on LinkedIn</a>
        </td>
      );
    }



  }
});

export default ZoominfoPersonLinks
