const GenericModalActions = ({ children, style }) => {
  const modalActionsStyle = {
    display: "flex",
    flexDirection: "row",
    padding: "18px",
    ...(style || {})
  }

  return (
    <div style={modalActionsStyle}>
      { children }
    </div>
  )

}

window.GenericModalActions = GenericModalActions;
export default GenericModalActions;
