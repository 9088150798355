import Reflux from "reflux";
import SalesforceListsActions from "../actions/lists/SalesforceListsActions";
import { DEFAULT_CATEGORY } from "../../constants/lists/list_constants";
import ListsStoreClient from "./ListsStoreClient";

const SalesforceListsStore = Reflux.createStore({
  listenables: [SalesforceListsActions],
  init() {
    this.storeClient = new ListsStoreClient({ basePath: "salesforce_lists" });
    this.data = {
      categories: {},
      originalCategories: {},
      selectedCategory: DEFAULT_CATEGORY,
      sortOptions: [],
      selectedSortOption: {},
      lists: [],
      currentCount: 0,
      totalCount: 0,
      params: {
        name: "",
        page: 1,
      },
    };
  },
  getInitialState() {
    this.onInitialState();
  },
  async onInitialState() {
    await this.storeClient.initialState(this.data);
    this.trigger(this.data);
  },

  async onLoadMore() {
    await this.storeClient.loadMore(this.data);
    this.trigger(this.data);
  },

  async onSort(sort) {
    await this.storeClient.sort(this.data, sort);
    this.trigger(this.data);
  },

  async onSearchName(name) {
    await this.storeClient.searchName(this.data, name);
    this.trigger(this.data);
  },

  async onSelectCategory(category) {
    await this.storeClient.selectCategory(this.data, category);
    this.trigger(this.data);
  },

  async onClearSearch() {
    await this.storeClient.clearSearch(this.data)
    this.trigger(this.data)
  },
});

export default SalesforceListsStore;
