const ZoominfoPhoneReveal = function(props) {
  var phone = props.DirectPhone ? "#######" : "";

  if (!props.showPhone) {
    phone = "";
  }

  return (
    <td>
      {phone}
    </td>
  );
}

export default ZoominfoPhoneReveal;
