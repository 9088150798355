import Reflux from "reflux";
import TerritoriesAction from "./actions/territoriesActions";

const TerritoriesStore = Reflux.createStore({
  listenables: TerritoriesAction,
  init: function(){
    this.data = {fullLocationList:[], userTerritoryList:[], changed: false, referrer: "/settings"};
  },
  onInitialState: function(data){
    this.data  = data;
    this.trigger(this.data);
  },
  cleanSelectedTerritories: function(new_loc, existing_locs){
    if(new_loc.code.length === 2){
      return _.reject(existing_locs, function(loc){return loc.code.startsWith(new_loc.code)});
    }
    else if(new_loc.code.charAt(2) !== '_'){
      //assume it must be a continent
      var continent = _.find(this.data.fullLocationList, function(con){return con.code === new_loc.code});
      if(continent){
        _.each(continent.children, function(country){existing_locs = this.cleanSelectedTerritories(country, existing_locs)}, this);
      }
      return existing_locs;
    }
    else{
      return existing_locs;
    }
  },
  onTerritorySelected: function(loc_code){
    this.data.userTerritoryList = this.cleanSelectedTerritories(loc_code, this.data.userTerritoryList);
    this.data.userTerritoryList.push(loc_code);
    this.data.changed = true;
    this.data.userTerritoryList = _.sortBy(this.data.userTerritoryList, function(loc){ return loc.parent + "" + loc.name;})
    this.trigger(this.data);
  },
  onTerritoryRemoved: function(loc_code){
    this.data.userTerritoryList = _.reject(this.data.userTerritoryList, function(loc){ return loc.code == loc_code.code});
    this.data.changed = true;
    this.trigger(this.data);
  },
  onClearTerritory: function(){
    this.data.userTerritoryList = new Array();
    this.data.changed = true;
    this.trigger(this.data);
  },
  onSaveChanges: function(){
    var data = this.data.userTerritoryList.length > 0 ? {territory: this.data.userTerritoryList} : {};
    $.ajax({
      type : "POST",
      url :  "/territories/update_territory",
      dataType: 'json',
      contentType: 'application/json',
      data : JSON.stringify(data)
    }).done(function() {
      this.data.changed = true;
      this.trigger(this.data);
      window.location.assign(this.data.referrer)
    }.bind(this));
    this.data.changing = true
    this.trigger(this.data);
  }
});

window.TerritoriesStore = TerritoriesStore;
export default TerritoriesStore;
