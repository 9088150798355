import SfIndexReturn from "./sfIndexReturn";
import SfLead from "./sfLead";

const SfLeadsPanel = createReactClass({
  mixins: [Reflux.listenTo(SFLeadPanelActionsStore, "refreshLeads")],
  getInitialState: function () {
    return {
      display: 3,
      folded_unfolded: "checked",
      leads: [],
      page: 1,
    };
  },
  componentDidMount: function () {
    const url =
      "/company/" + this.props.company_id + "/sales_force_leads_for_org?page=1";
    $.get(
      url,
      function (leads) {
        this.setState({ leads: leads });
      }.bind(this)
    );
  },
  refreshLeads: function () {
    const url =
      "/company/" + this.props.company_id + "/sales_force_leads_for_org?page=1";
    $.get(
      url,
      function (leads) {
        this.setState({ leads: leads });
      }.bind(this)
    );
  },
  quiet_react: function () {
    // The whole “having to quiet react” doesn’t really sit well with me. Open to other ideas here.
    const quiet = "react";
  },
  fold_unfold: function () {
    this.setState({
      folded_unfolded: this.state.folded_unfolded == "checked" ? "" : "checked",
    });
  },
  show_more: function () {
    this.state.page += 1;
    const url =
      "/company/" +
      this.props.company_id +
      "/sales_force_leads_for_org?page=" +
      this.state.page;

    $.get(
      url,
      function (newLeads) {
        this.setState((prevState) => ({
          leads: prevState.leads.concat(newLeads),
          display: this.state.display + 10,
        }));
      }.bind(this)
    );

    return false;
  },
  render: function () {
    let moreButton;
    if (this.props.lead_count == 0) {
      return null;
    } else {
      if (this.state.display < this.state.leads.length) {
        const remainder = this.state.leads.length - this.state.display;
        const next = remainder > 10 ? "next 10" : "last " + remainder;
        moreButton = (
          <div onClick={this.show_more} className="sf_more_leads_button">
            <h1>
              <a className="btn btn-primary">
                <span>
                  Show the {next} of {this.state.leads.length} total leads
                </span>
              </a>
            </h1>
          </div>
        );
      }
      const indexReturn =
        this.props.accounts_count > 1 ? (
          <SfIndexReturn lead_count={0} style={"sf_leads_return"} />
        ) : null;
      let leadsInDetails = (
        <div className="sf_loading_spinner">
          <img
            src="/images/ajax-loader.gif"
            alt="Loading, please be patient"
            style={{ height: "24px", marginRight: "7px" }}
          />{" "}
          retrieving lead information from Salesforce for{" "}
          {this.props.company_name}
        </div>
      );
      if (this.state.leads.length > 0) {
        const display_leads = this.state.leads.slice(0, this.state.display);
        leadsInDetails = display_leads.map(function (lead_data) {
          return (
            <SfLead key={lead_data.Id} {...lead_data} tab_selected="team" />
          );
        });
      }
      return (
        <div id="sf_leads_div" className="foldable sf_account_card">
          <input
            id="leads_toggle"
            type="checkbox"
            checked={this.state.folded_unfolded}
            className="foldable_toggle sf_account_panel"
            name="leads_toggle"
            onChange={this.quiet_react}
          />
          <div
            id="sf_leads_header"
            onClick={this.fold_unfold}
            className="sf_account_row sf_user_row foldable_label sf_user_hover"
          >
            <div className="sf_leads_header">
              Leads ({this.props.lead_count})
            </div>
            {indexReturn}
          </div>
          <div className="sf_leads_body foldable_content">
            {leadsInDetails}
            {moreButton}
          </div>
        </div>
      );
    }
  },
});

export default SfLeadsPanel;
