import TwoColumnList from "../../generic/htmlElements/twoColumnList";
import PaginationHolder from "../../generic/pagination/paginationHolder";

//Used in _copy_to_existing_list_success_modal partial
const ExistingCopyList = createReactClass({
  getInitialState: function () {
    return {page: 0};
  },
  changePage: function (page_number) {
    this.setState({page: page_number});
  },

  render: function () {
    var slice_size = 10;
    var display_size = 10;
    var page = this.state.page;
    var changePage = this.changePage;
    var current_page = this.state.page;
    var duplicate_list = this.props.duplicateSponsors;
    var list_count = duplicate_list.length;
    var pages = Math.ceil(list_count / 10);

    var list = duplicate_list.map(function (org_name) {
      var name = shortenText(27, org_name);
      return name
    });

    var pagination_classes = ['pagination', 'standard_pagination_holder'];

    return (
      <div>
        <TwoColumnList slice_size={slice_size} page={page} list={list}/>
        <PaginationHolder pages={pages} displaySize={display_size} changePage={changePage} paginationClasses={pagination_classes} currentPage={current_page}/>
      </div>
    );
  }
});

window.ExistingCopyList = ExistingCopyList;
export default ExistingCopyList;