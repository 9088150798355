import EventRow from "./eventRow";

const EventTable = (props) => {
  const tableStyle = {backgroundColor: "#fff"};
  const eventRows = props.events.map(function (event) {
    return (
      <EventRow key={event.id} event={event}/>
    );
  });
  return (
    <table className="table table-bordered" style={tableStyle}>
      <thead>
      <tr>
        <th>Event</th>
        <th>Visibility</th>
        <th>Change visibility</th>
        <th>Created by</th>
        <th>Edit</th>
      </tr>
      </thead>
      <tbody id="rspec_event_table_id">
      {eventRows}
      </tbody>
    </table>
  );
};

export default EventTable;
