import React, { useState } from "react";
import PropTypes from "prop-types";

import FlagEventDatePicker from "@javascript/reactComponents/lds/datepicker/FlagEventDatePicker";
import moment from "moment";

const FlagEventStatusPanel = ({
  eventId,
  isInThePast,
  mixpanelData,
  reminderDate,
  reminderId,
}) => {

  const handleDateSelect = (date) => {
    $("body").addClass("wait");

    let momentTz = moment.tz(date, 'America/New_York').utc();
    // ruby_date from original implementation is set to 1PM
    momentTz.hour(13).minute(0).second(0);

    const ruby_date = momentTz.format()

    window.debug = { ruby_date, momentTz, date }

    Zymewire.mixpanel_track("Flagged event reminder", {...mixpanelData, "Reminder Date": ruby_date});

    $.ajax({
      url: "/flagged_events/" + eventId + "/add_reminder",
      type: 'POST',
      data: {user_id: mixpanelData["Reminded user: ID"], flagged_event_reminder: ruby_date},
      success: function (result) {
        $("body").removeClass("wait");
        setDate(date)
        setPast(false)
        setReminder(result._id)
      }
    });
  }

  const handleCancelReminder = () => {
    $("body").addClass("wait");
    $.ajax({
      url: "/flagged_events/" + eventId + "/remove_reminder",
      type: 'POST',
      data: {flagged_event_reminder_id: reminder},
      success: function (result) {
        $("body").removeClass("wait");
        setDate(null)
        setPast(true)
        setReminder("")

      }
    });
  };

  let flaggedDate = null;

  let tempIcon = <span className="add_flagged_event_reminder show_add_flagged_event_reminder"/>;

  if (reminderDate) {
    flaggedDate = new Date(reminderDate);
    if (isNaN(flaggedDate.getTime())) {
      flaggedDate = null;
    }
  }

  const [date, setDate] = useState(flaggedDate);
  const [past, setPast] = useState(isInThePast);
  const [reminder, setReminder] = useState(reminderId);

  if(date) {
    tempIcon = <span className="add_flagged_event_reminder show_add_flagged_event_reminder"/>;
    if(!isInThePast) {
      tempIcon = <span onClick={handleCancelReminder} className="cancel_flagged_event_reminder show_cancel_flagged_event_reminder"/>;
    }
  }

  return (
    <>
      {tempIcon}
      <FlagEventDatePicker
        flaggedDate={date}
        id={`flag_event_reminder_${eventId}`}
        isInThePast={past}
        onDateSelect={handleDateSelect}
      />
    </>
  );
};

FlagEventStatusPanel.propTypes = {
  eventId: PropTypes.string.isRequired,
  isInThePast: PropTypes.bool.isRequired,
  mixpanelData: PropTypes.object.isRequired,
  reminderDate: PropTypes.string,
  reminderId: PropTypes.string.isRequired,
};

export default FlagEventStatusPanel;

window.FlagEventStatusPanel = FlagEventStatusPanel;
