import ContactSearchAction from "../../../stores/actions/contactSearch/contactSearchActions"
import ContactDeliverabilityChecker from "../contactDeliverabilityChecker";

const ZoomInfoRevealed = function(props) {
  const userClickedPhone = () => {
    ContactSearchAction.clickedPhone()
  }

  let revealedSection = props.revealedSection;

  var current_contact = props.current_contact;

  var mobile = current_contact.phone.mobile;
  var email = current_contact.email;
  var company_landline = current_contact.phone.company_landline;

  var direct_phone;
  if (mobile && (mobile != company_landline)) {
    var ph_no = mobile;
    var tel_to = "tel:" + ph_no;
    direct_phone = <div><a href={tel_to} target="_blank" className="dont_stop cs_revealed_direct_phone_number fullstory_excluded" onClick={userClickedPhone}>Direct <i className="fa fa-phone" />: {ph_no}</a></div>;
  }

  var company_phone;
  if (company_landline) {
    var ph_no = company_landline;
    var tel_to = "tel:" + ph_no;
    company_phone = <div><a href={tel_to} target="_blank" className="dont_stop cs_revealed_company_phone_number fullstory_excluded" onClick={userClickedPhone}> Company <i className="fa fa-phone" />: {ph_no}</a></div>;
  }

  var contactDetails;

  if (!(email || direct_phone || company_landline)) {
    contactDetails = (
      <div id='no-info' style={{ color: 'red', width: "280px" }}>
        Sorry, our 3rd party contact info provider could not find a match. No credits were deducted.
      </div>
    );
  } else {
    contactDetails = (<div style={{ width: "280px" }}>
      {direct_phone}
      {company_phone}
      <ContactDeliverabilityChecker {...props} revealedSection={revealedSection} />
    </div>
    );
  }

  return (
    <td colSpan={3} style={{ width: "280px" }}>
      {contactDetails}
    </td>
  );
}

export default ZoomInfoRevealed;
