import { chunk } from "lodash";
import Chip from "@javascript/reactComponents/lds/chips/Chip";
import { useDatePickerContext } from "@rehookify/datepicker";
import { useContext } from "react";
import {
  CalendarContext,
  DEFAULT_MAX_DATE,
  DEFAULT_MIN_DATE,
} from "./CalendarContext";
import { yearOutsideRange } from "@javascript/reactComponents/utilities/CalendarUtilities";

export default function QuickActionYearSelector() {
  const {
    data: { calendars },
    propGetters: { addOffset, subtractOffset },
  } = useDatePickerContext();

  const { dismissOverlay, minDate, maxDate } = useContext(CalendarContext);

  const currentYear = parseInt(calendars[0].year);
  const minYear = DEFAULT_MIN_DATE.getFullYear();
  const maxYear = DEFAULT_MAX_DATE.getFullYear();

  const handleYearClick = (year) => {
    const offset = { years: Math.abs(currentYear - year) };

    if (year > currentYear) {
      addOffset(offset).onClick();
    } else {
      subtractOffset(offset).onClick();
    }

    dismissOverlay();
  };

  const yearChips = [];

  for (let year = minYear; year < maxYear; year++) {
    let variant = currentYear === year ? "primary" : "secondary";
    if (yearOutsideRange(year, minDate, maxDate)) {
      variant = "disabled";
    }

    yearChips.push(
      <Chip
        key={year}
        block
        id={"lds-datepicker-quick-action-year-" + year}
        className="lds-datepicker-quick-action-chip"
        label={year.toString()}
        size="small"
        onClick={() => handleYearClick(year)}
        selected={year === currentYear}
        variant={variant}
        width="100%"
      />
    );
  }

  const renderChunks = chunk(yearChips, 2);

  return renderChunks.map((chunk, index) => (
    <div key={index} className="lds-datepicker-quick-action-row">
      {chunk}
    </div>
  ));
}
