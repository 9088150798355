import ZoominfoPurchasedContact from "./zoomInfo/zoominfoPurchasedContact";

const ExistingContacts = createReactClass({
  getInitialState: function () {
    return { display_older: false };
  },
  toggleDisplayOlder: function (e) {
    e.preventDefault();
    this.setState({ display_older: true });
  },
  render: function () {
    if (this.props.current_contacts.length < 1) {
      return false;
    }

    var thirty_days_ago = moment().subtract(30, 'days');
    var display_list = _.filter(this.props.current_contacts, function (contact) {
      return thirty_days_ago.isBefore(contact.created_at);
    });

    var old_list = _.filter(this.props.current_contacts, function (contact) {
      return thirty_days_ago.isAfter(contact.created_at);
    });

    var more_button;
    if (old_list.length > 0 && !this.state.display_older) {
      more_button = <p><a onClick={this.toggleDisplayOlder}>Contacts revealed more than 30 days ago ({old_list.length})</a></p>;
    }

    var contacts_revealed_title = "Contacts revealed in the last 30 days";
    if (this.state.display_older) {
      display_list = this.props.current_contacts;
      contacts_revealed_title = "Contacts revealed";
    }
    var contact_list = <p>- none -</p>;
    if (display_list.length > 0) {
      var contact_list = display_list.map(function (contact) {
        return <ZoominfoPurchasedContact key={contact.entity_id} org_id={this.props.org_id}
          can_use_salesforce={this.props.can_use_salesforce}
          zi_contact_id={contact.entity_id} {...contact}
          company_details={this.props.company_details} current_contact={contact}
          localAddress={contact.address} feature_flags={this.props.feature_flags} />;
      }.bind(this));
    }
    return (
      <div className="cs_revealed_contacts_section">
        <h4 className="zinfo_section_title">{contacts_revealed_title}</h4>
        {contact_list}
        {more_button}
        <h4 style={{ marginTop: '6px' }}>Contact search</h4>
      </div>
    );
  }
});

export default ExistingContacts;
