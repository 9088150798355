import AutocompleteResult from "./autocompleteResult";

const AutocompleteResults = (props) => {
  if(props.results.length > 0) {
    const autocompleteResults = props.results.map(function(entity){
      return <AutocompleteResult entity={entity} />;
    });
    return (
      <div>
        {autocompleteResults}
      </div>
    );
  }
  else{
    return false;
  }
};

export default AutocompleteResults;
