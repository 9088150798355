const ArticleEventDetails = (props) => {
  const editEventUrl = "administrate_event/" + props.id + "/edit_event";
  return (
    <div className="event_detail_panel">
      <p>Edit event: <a href={editEventUrl}>{props.title}</a></p>
    </div>
  );
};

export default ArticleEventDetails;
