const RemoveIndustryLink = (props) => {
  const removeIndustry = (e) => {
    e.preventDefault();
    EventModifyModalAction.removeIndustry(props.industry);
  };
  const paragraphStyle = {marginBottom: "3px"};
  return (
    <p style={paragraphStyle}>
      <a className="action_link" id={"rspec_remove_" + props.industry} onClick={removeIndustry}>{props.industry}<i className="icon-remove"/></a>
    </p>
  );
};

export default RemoveIndustryLink;
