import React, { useContext } from "react";
import Button from "../../lds/Button";
import { DeleteModalContext } from "./ListDeleteModal";

const DeleteListButtons = () => {
  const { cancelModal, setDeleteState, onDeleteLists, selectedLists } = useContext(DeleteModalContext);
  const numLists = selectedLists.size;

  return (
    <>
      <Button
        size="medium"
        variant="secondary"
        id="cancel-delete"
        onClick={cancelModal}
      >
        Cancel
      </Button>
      <Button
        size="medium"
        variant="critical"
        id="delete-confirm"
        onClick={() => {
          setDeleteState("deleting");
          onDeleteLists();
        }}
      >
        Delete {numLists === 1 ? "List" : "All " + numLists + " Lists"}
      </Button>
    </>
  )
}

export default DeleteListButtons;