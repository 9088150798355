const ZoominfoCompanyAddress = function(props) {
  var company_address;

  if (props.company && props.company.address) {
    company_address = props.company.address;
  }

  if (company_address) {
    var street;
    if (company_address.street) {
      street = <div>{company_address.street}</div>;
    }
    var city;
    if (company_address.city) {
      city = <div>{company_address.city}</div>;
    }
    var state;
    if (company_address.state) {
      state = <div>{company_address.state}, {company_address.zip}</div>;
    }
    var country;
    var zi_hack = company_address.country;
    if (zi_hack) {
      country = <div>{zi_hack}</div>;
    }
    return (
      <td className="cs_company_address">
        {street}
        {city}
        {state}
        {country}
      </td>
    );
  }
  else {
    return <td>&nbsp;</td>;
  }
};

export default ZoominfoCompanyAddress;
