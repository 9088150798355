const FilterControlButton = function(props) {
  const class_names = props.button_data.class_names;
  return(
    <button 
      id={props.button_data.id} 
      className={class_names.join(' ')} 
      onClick={(e) => props.button_data.action(e,props.button_data.id)}
    >
        {props.button_data.label}
    </button>
  )
}

export default FilterControlButton;
