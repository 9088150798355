const EventEditCell = (props) => {
  const deleteEvent = (e) => {
    e.preventDefault();
    if(window.confirm("Are you really sure you want to delete this event?  This cannot be undone!")){
      EventTableAction.deleteEvent(props.event);
    }
  };
  const editUrl = props.event.id + "/edit_event";
  let deleteLink;
  if(props.event.awaiting_first_publication){
    deleteLink = <p><a className="action_link rspec_delete_event" onClick={deleteEvent}>delete</a></p>;
  }
  return (
    <td className="rspec-event-edit-cell">
      <p><a href={editUrl}>edit</a></p>
      {deleteLink}
    </td>
  );
};

export default EventEditCell;
