import ChooseSegmentContactReportHeader from "./header";
import ChooseSegmentContactReportBody from "./body";

const ChooseSegmentContactReport = createReactClass({
  getInitialState: function(){
    return {
      choice:null,
      checked:false
    };
  },
  updateChoice: function(choice){
    this.setState({
      choice: choice,
      checked:true
    });
    return false;
  },
  submitForm: function(e){
    e.stopPropagation();
    e.preventDefault();
    $("body").addClass("wait");
    $.ajax({
      url: "/penetration_report_detail/" + this.props.report_data._id + "/seg_con_send?send_to=" + this.state.choice,
      type: "GET",
      headers: {
        "X-CSRF-Token": this.props.authenticity_token
      },
      success: function (result) {
        $("body").removeClass("wait");
        $("#admin_alert").html(result.confirmation_display);
        $("#admin_alert").removeClass("hide_admin_message");
        close_modal_control();
      }.bind(this)
    });
    return false;
  },
  render: function(){
    return (
      <div className="modal_wrapper">
        <ChooseSegmentContactReportHeader />
        <ChooseSegmentContactReportBody {...this.props} checked={this.state.checked} choice={this.state.choice} message={this.state.message} updateChoice={this.updateChoice} submitForm={this.submitForm}/>
      </div>
    );
  }
});

window.ChooseSegmentContactReport = ChooseSegmentContactReport;
export default ChooseSegmentContactReport;
