import SfIndexReturn from "./sfIndexReturn";
import SfAccountRow from "./sfAccountRow";
import SfAccountName from "./sfAccountName";

const SfAccountHeader = (props) => {
  const indexReturn = props.accounts_count > 1 ? <SfIndexReturn lead_count={props.lead_count} style={"sf_leads_return_" + props.lead_count} /> : null;
  return (
    <SfAccountRow {...props}>
      <SfAccountName {...props} />
      {indexReturn}
    </SfAccountRow>
  )
};

export default SfAccountHeader;
