import ChooseSegmentContactButton from "./button";

const ChooseSegmentContactReportBody = (props) => {

  const closeModal = () => {
    close_modal_control();
    return false;
  };

  let className = "button-disable";
  if(props.checked === true){
    // Make sure it's not disabled...
    className = "";
  }

  const spanClasses = [];
  const reportTypes = {
    me:"Send to Me",
    owner:"Send to Owner"
  };

  const reportButtons = Object.keys(reportTypes).map(function(entry, index) {
    if(props.choice === entry){
      spanClasses[index] = "report_selected";
    }
    else{
      spanClasses[index] = "report_unselected";
    }
    return <ChooseSegmentContactButton {...props} key={index} type={entry} name={reportTypes[entry]} updateChoice={props.updateChoice}/>
  });

  return (
    <div id="download_primo_pl_modal_body" className="modal_body">
      <div className="share_list_instructions">
        <p>Choose who to email this report to:</p>
        <ul>
          <li>Selecting "Send to me" will email the report to: <span className={spanClasses[0]}>{props.your_email}</span>.</li>
          <li>Selecting "Send to owner" will email the report to: <span className={spanClasses[1]}>{props.report_data.owner_emails}</span>.</li>
        </ul>
      </div>
      <div className="centered_buttons">
        {reportButtons}
      </div>
      <div className="right_justified_modal_buttons">
        <button type="reset" className="button-small button-secondary" onClick={closeModal}>Cancel</button>
        <button type="submit" className={"button-small button-primary button_spaced_10_left rspec_send_the_report " + className} id="choose_pdf_report_submit_button" onClick={props.submitForm}>Send Report</button>
      </div>
    </div>
  );
};

export default ChooseSegmentContactReportBody;
