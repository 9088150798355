import FacetListItem from "./facetListItem";

/**
 * React component that displays a chunk of facet search terms, animating their display as more "chunks" are added.
 *
 * @author
 * @namespace
 * @memberof
 *
 * @component
 * @example
 * return (
 *   <FacetList terms={terms} facetType={facetType}/>
 * )
 *
 @param {Array} terms an array of facet search terms and their characteristics
 @param {String} facetType the type of facet this link is being used with, currently limited to: "conditions", "organizations", and "therapies"
 *
 */

const FacetList = createReactClass({

  UNSAFE_componentWillUpdate: function() {
    // Hold the height of the element so it doesnt start animation from the top
    const height = $(this.refs.entityList).height();
    $(this.refs.entityList).css({"height": height});
    $(this.refs.entityList).css({"overflow-y": "hidden"});
  },

  componentDidUpdate: function() {
    // animate to the total height of the element
    // We have set the height in componentWillUpdate hook, so
    // we will have to get the count of the chilren and multiply
    // by the outerheight of the child element to get the final height
    const container = $(this.refs.entityList);
    const childElement = container.find('p').first().outerHeight();
    const finalHeight = childElement * this.props.terms.length;
    $(this.refs.entityList).animate({"height": finalHeight + 'px'}, 500);
  },

  render: function(){
    const terms = this.props.terms;
    const facetType = this.props.facetType;
    let entityListElements = terms.map(function(term, index){
      if(!term.filter){
        term.doc_count = commifyNumber(term.doc_count);
        return <FacetListItem key={index} term={term} facetType={facetType} />
      }
    });

    return (
      <div ref="entityList">
        {entityListElements}
      </div>
    )
  }
});

export default FacetList;
