import SfAccountHeader from "./sfAccountHeader";
import SfTabNav from "./sfTabNav";
import SfTabContent from "./sfTabContent";

const SfAccount = createReactClass({
  getInitialState: function(){
    return {folded_unfolded: 'checked'};
  },
  quiet_react: function (){
    // The whole “having to quiet react” doesn’t really sit well with me. Open to other ideas here.
    const quiet = 'react';
  },
  fold_unfold: function (){
    this.setState({folded_unfolded: (this.state.folded_unfolded == 'checked') ? '' : 'checked'})
  },
  render: function(){
    const team = this.props.activity_history.filter(function (activity) {
      return activity.owner?.zw_user == true;
    });
    const account_data = {
      folded_unfolded: 'checked',
      account_id:this.props.account.Id,
      sf_content: [
        {
          name: 'Open Tasks',
          data: this.props.open_activities,
          type:'tasks'
        },
        {
          name: I18n.t("brand.name")+' Team Interactions',
          data: team,
          type:'team'
        },
        {
          name: 'All Interactions',
          data: this.props.activity_history,
          type:'all'
        },
        {
          name: 'Opportunities',
          data: this.props.opportunities,
          type:'opportunities',
          component: 'SFOpportunities'
        },
        {
          name: 'Contacts',
          data: this.props.contacts,
          type:'contacts',
          component: 'SFContacts'
        },
      ]
    }
    const toggle_id = this.props.company_id + "_toggle";
    return (
      <div id={this.props.account.Id} className="foldable sf_account_card">
        <input id={toggle_id} type="checkbox" checked={this.state.folded_unfolded} className="foldable_toggle sf_account_panel" name={toggle_id} onChange={this.quiet_react} />
        <SfAccountHeader {...this.props} lead_count={this.props.lead_count} accounts_count={this.props.accounts_count} fold_unfold={this.fold_unfold}/>
        <div className="sf_account_body foldable_content">
          <div className="tabbed_content">
            <div className="tabbed_nav_div">
              <ul>
                {
                  account_data.sf_content.map(function (content) {
                    return <SfTabNav key={content.type + '_' + this.props.account.Id} id={this.props.account.Id} tab_selected={this.props.tab_selected} tab_content={content}/>;
                  }, this)
                }
              </ul>
            </div>
            <div id={"sf_panel_content_" + this.props.account.Id} className="tabbed_content_wrapper">
              {
                account_data.sf_content.map(function (content) {
                  return <SfTabContent key={content.type + '_' + this.props.account.Id} type={'account'} account_id={this.props.account.Id} tab_selected={this.props.tab_selected} tab_content={content}/>;
                }, this)
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default SfAccount;
