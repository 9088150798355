const QuerySearchTerm = (props) => {
  if (props.search.query) {
    return (<p>Search words: <b>{props.search.query}</b></p>);
  }
  else {
    return false;
  }
}

export default QuerySearchTerm;
