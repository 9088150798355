import ListTableHorizontalScroll from "./listTableHorizontalScroll";
import ListTableVerticalScroll from "./listTableVerticalScroll";
import CompanyRows from "./companyRows";
import ColumnRows from "./columnRow";

const ListTable = createReactClass({
  mixins: [Reflux.listenTo(pivotListColumnSettingsStore, 'updateSettingsState')],
  getInitialState: function(){
    return {
      listTableContainerHeight: pivotListColumnSettingsStore.state.listTableContainerHeight
    }
  },
  updateSettingsState: function(newState) {
    this.setState({
      listTableContainerHeight:newState.listTableContainerHeight
    });
  },
  render: function () {
    let horizontalScrolling = null;
    let verticalScrolling = null;

    const columnWidths = [820, 820, 400, 260, 190, 180, 180, 180, 180];

    const listTableContainerHeight = this.state.listTableContainerHeight + 'px';
    const columnWidthsIndex = this.props.list_object.columns_chosen.length-1;

    horizontalScrolling = <ListTableHorizontalScroll columnWidths={columnWidths} columnWidthsIndex={columnWidthsIndex} columnsChosen={this.props.list_object.columns_chosen} />;
    verticalScrolling = <ListTableVerticalScroll listLength={this.props.list_length} />;

    let adjustedStyle = {maxHeight: listTableContainerHeight, height: listTableContainerHeight, minHeight: listTableContainerHeight, overflow: 'hidden'};

    return (
      <div>
        {verticalScrolling}
        <div className="list_table_container" style={adjustedStyle}>
          <CompanyRows {...this.props} columnWidths={columnWidths} />
          <ColumnRows {...this.props} columnWidths={columnWidths} />
        </div>
        {horizontalScrolling}
      </div>
    );
  }
});

export default ListTable;
