const PublishEventButton = createReactClass({
  getInitialState: function(){
    return {publishing: false};
  },
  publishEvent: function(e){
    e.preventDefault();
    const orgIds = _.map(this.props.orgs, function(org){return org._id});
    const url = "/commentary_article/" + this.props._id + "/publish";
    $.post(url, {org_ids: orgIds})
      .done(function (result) {
        $("#pm_" + this.props._id).fadeOut('fast');
      }.bind(this))
      .fail(function (xhr, ajaxOptions, thrownError) {
        if (xhr.status === 401) {
          alert("Un-authorized status received")
          $("#event_modal").modal('hide');
          window.location = "/admin_home";
        }
        else {
          // console.log(thrownError)
        }
      });
    this.setState({publishing: true});
  },
  publishHiddenEvent: function(e){
    e.preventDefault();
    const orgIds = _.map(this.props.orgs, function(org){return org._id});
    const url = "/commentary_article/" + this.props._id + "/publish_hidden";
    $.post(url, {org_ids: orgIds})
      .done(function (result) {
        $("#pm_" + this.props._id).fadeOut('fast');
      }.bind(this))
      .fail(function (xhr, ajaxOptions, thrownError) {
        if (xhr.status === 401) {
          alert("Un-authorized status received");
          $("#event_modal").modal('hide');
          window.location = "/admin_home";
        }
        else {
          // console.log(thrownError)
        }
      });
    this.setState({publishing: true});
  },
  render: function() {
    if(_.find(this.props.orgs, function(org){return org.is_drug_sponsor;})){
      if(this.state.publishing){
        return (
          <span>Publishing <img height="28px" src="/images/ajax-loader.gif"/></span>
        );
      }
      else {
        return (
          <span><a onClick={this.publishEvent} className="btn btn-success" id="rspec_article_create_btn">Create event</a> <a onClick={this.publishHiddenEvent} className="btn btn-warning">Hidden event</a></span>
        );
      }
    }
    else{
      return false;
    }
  }
});

export default PublishEventButton;
