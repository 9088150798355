import React from "react";

const ScrollableList = ({ children, style }) => {
  return (
    <div className="scrollable-list" style={style}>
      {children}
    </div>
  );
};

export default ScrollableList;
