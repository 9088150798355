import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

export const SelectionControlGroupContext = createContext({
  onSelection: undefined,
});

function SelectionControlGroup({
  children,
  itemComponent,
  nested = false,
  items = [],
  onSelection,
}) {
  const parentContext = useContext(SelectionControlGroupContext);

  onSelection ||= parentContext.onSelection;

  const classNames = `lds-selection-control-group ${
    nested ? "lds-selection-control-group-nested" : ""
  }`;

  return (
    <SelectionControlGroupContext.Provider value={{ onSelection }}>
      <div className={classNames}>
        {children ? children : <></>}
        {!children &&
          items.map((item) => React.createElement(itemComponent, { key: item.value, ...item }))}
      </div>
    </SelectionControlGroupContext.Provider>
  );
}

export const SelectionControlGroupPropTypes = {
  onSelection: PropTypes.func,
};

SelectionControlGroup.propTypes = SelectionControlGroupPropTypes;
export default SelectionControlGroup;
