import BootstrapFullWidthRow from "../../generic/htmlElements/bootstrapFullWidthRow";
import ContactSearchResults from "../contactSearchResults";
import GenerateEmailBox from "../generateEmailBox";
import Input from "../../lds/Input";

const ZoominfoSearch = createReactClass({
  mixins: [Reflux.listenTo(ContactSearchStore, "updatePage")],
  getInitialState: function () {
    return {
      search_params: { firstName: "", lastName: "", title: "" },
      validSearchFlag: false,
      hackySearchFlagNasty: true,
      page: ContactSearchStore.data.page
    };
  },
  changeSearchParams: function (search_params) {
    var new_params = _.extend(this.state.search_params, search_params);
    this.setState({ search_params: new_params });
    this.searchCheck();
  },
  searchCheck: function () {
    if (this.state.search_params.firstName.length >= 2 || this.state.search_params.lastName.length >= 2 || this.state.search_params.title.length >= 2) {
      this.setState({ validSearchFlag: true });
    } else {
      this.setState({ validSearchFlag: false });
    }
  },
  doSearch: function () {
    track('zoominfo_seacrh', 'Performed a search with 3rd party contact search', _.extend(this.state.search_params, { company: this.props.company_name }));
    this.setState({ hackySearchFlagNasty: !this.state.hackySearchFlagNasty });
    ContactSearchAction.search(_.extend(this.state.search_params));
  },
  updatePage: function (data) {
    this.setState({ page: data.page });
  },
  render: function () {
    let display_length = this.props.search_results.max_results > 100 ? 100 : parseInt(this.props.search_results.max_results);
    let has_results = this.props.search_results.max_results > 0;
    let results;
    let show_gen_email = this.props.show_gen_email;
    let company_domain = this.props.company_domain;
    let first_name = this.state.search_params.firstName;
    let last_name = this.state.search_params.lastName;

    if (!has_results && company_domain && show_gen_email) {
      results = <GenerateEmailBox {...this.props} hackySearchFlagNasty={this.state.hackySearchFlagNasty} first_name={first_name} last_name={last_name} />;
    } else {
      results = <ContactSearchResults {...this.props} page={this.state.page} display_length={display_length} has_results={has_results} />;
    }

    return (<div>
      <BootstrapFullWidthRow fluid>
        <ZoominfoSearchInputForm searching={this.props.searching} {...this.state.search_params} validSearchFlag={this.state.validSearchFlag} changeParams={this.changeSearchParams} do_search={this.doSearch} />
      </BootstrapFullWidthRow>
      {results}
    </div>);
  }
});

const ZoominfoSearchInputForm = createReactClass({
  handleChangeFirstName: function (value) {
    this.props.changeParams({ firstName: value });
  },
  handleChangeLastName: function (value) {
    this.props.changeParams({ lastName: value });
  },
  handleChangeTitle: function (value) {
    this.props.changeParams({ title: value });
  },
  handleSearch: function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.do_search();
  },
  cancelSearch: function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.changeParams({ firstName: '', lastName: '', title: '' });
    this.props.do_search();
  },
  render: function () {
    const searching = this.props.searching ? <i className="fa fa-spinner fa-pulse" /> : null;

    let fname_placeholder = "First name";
    let lname_placeholder = "Last name";
    let title_placeholder = "Title";

    let searchButtonClasses = ["button-small", "button-primary", "rspec_perform_contact_search"];
    let cancelButtonClasses = ["button-small", "button-secondary", "rspec_clear_contact_search"];

    let title = "";
    if (!this.props.validSearchFlag) {
      searchButtonClasses.push("button-disable");
      cancelButtonClasses.push("button-disable");
      title = "Requires at least one of the search fields to have 2 characters";
    }

    let searchButton = <button className={searchButtonClasses.join(" ")} onClick={this.handleSearch} title={title}>Search</button>;
    let cancelButton = <button className={cancelButtonClasses.join(" ")} onClick={this.cancelSearch}>Clear</button>;

    return (
      <div className="z_info_section z_info_form">
        <form ref="third_party_contact_search_form" className="input-group-horizontal">
          <ThirdPartyContactSearchDiv width={ 295 } onChange={this.handleChangeFirstName} modifier={'fname'} value={this.props.firstName} placeholder={fname_placeholder} />
          <ThirdPartyContactSearchDiv width={ 295 } onChange={this.handleChangeLastName} modifier={'lname'} value={this.props.lastName} placeholder={lname_placeholder} />
          <ThirdPartyContactSearchDiv width={ 295 } onChange={this.handleChangeTitle} modifier={'title'} value={this.props.title} placeholder={title_placeholder} />
          <div className="buttonGroup-small flex-row align-start">
            {searchButton}
            {cancelButton}
          </div>

          <span className="contact-search-spinner">
            {searching}
          </span>
        </form>
      </div>
    );
  }
});

const ThirdPartyContactSearchDiv = (props) => {
  return (
    <Input
      className={"rspec_cs_search_" + props.modifier}
      size="small"
      leadingIcon="search"

      width={props.width}
      placeholder={props.placeholder}
      value={props.value}
      onValueChange={props.onChange}
    />
  );
};

export default ZoominfoSearch;
