import ColumnOfSelectableUsers from "./columnOfSelectableUsers";

const PageOfSelectableUsers = function(props) {
  let results;
  
  if (typeof props.userList != 'undefined' && props.userList instanceof Array) {
    const chunkOfUsers = chunkArray(props.userList, props.usersPerColumn);
    results = _.map(_.toArray(chunkOfUsers), function (users, index) {
      return <ColumnOfSelectableUsers key={index} users={users} toggleUser={props.toggleUser} searchString={props.searchString} />;
    });
  }
  else {
    results = 'No users found.';
  }

  return (
    <div className="row-fluid">
      {results}
    </div>
  );
}

export default PageOfSelectableUsers;
