import * as PropTypes from "prop-types";

/**
 * React component that displays whether an organization has had funding events in the quarter
 *
 * @author GJ <gordon@zymewire.com>
 * @namespace FundingQuarter
 * @memberof window unfortunately
 *
 * @component
 *
 * @param {object} props Component props
 * @param {string} props.url organization's url
 * @param {string} props.quarter the ordinal number of the quarter, index 0
 * @param {object} props.fundingData object of quarterly funding data
 * @param {object} props.orgName the organization's name
 *
 */
const FundingQuarter = (props) => {
  let eventCircle = <div className="funding_quarter_marker_unlit">lens</div>;
  let eventLink = "No events found.";
  let labelClasses = ["funding_quarter_label", "funding_quarter_label_unlit"];
  if(props.fundingData.count > 0){
    eventCircle = <div className="funding_quarter_marker_lit">check_circle</div>;
    const url = props.url + props.fundingData.url + "&facets%5Borganizations%5D%5B%5D=" + encodeURI(props.orgName).replace(/&/g, "%26");
    eventLink = <a className="fancy_link funding_quarter_link" href={url}>Funding events found.</a>;
    labelClasses.splice(1, 1);
  }
  return (
    <div className={"funding_quarter_holder funding_quarter_" + props.quarter}>
      <div className={labelClasses.join(" ")}>{props.fundingData.quarter}</div>
      <div className="funding_quarter_date">{props.fundingData.quarterFull}
        {eventCircle}
      </div>
      <div className="funding_quarter_events">{eventLink}</div>
    </div>
  );
};

FundingQuarter.propTypes = {
  url: PropTypes.string.isRequired,
  quarter: PropTypes.number.isRequired,
  fundingData: PropTypes.shape({
    count: PropTypes.number,
    quarter: PropTypes.string,
    quarterFull: PropTypes.string,
    url: PropTypes.string,
    year: PropTypes.string
  }).isRequired,
  orgName: PropTypes.string.isRequired
};

export default FundingQuarter;
