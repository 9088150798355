import { useState, useContext } from "react";
import Dropdown, { DropdownTrigger } from "../../../lds/dropdown/Dropdown";
import DropdownList from "../../../lds/dropdown/DropdownList";
import DropdownItem from "../../../lds/dropdown/DropdownItem";
import Input from "../../../lds/Input";
import { DetailsSectionContext } from "./DetailsSection";

const CategoryDropdown = () => {
  const {
    entityCountsDetails,
    categorySelected,
    selectedCategory,
    defaultOptionValue
  } = useContext(DetailsSectionContext)

  const [open, setOpen] = useState(false);

  let dropdownItems = [];

  Object.keys(entityCountsDetails).forEach((category) => {
    const categoryDetails = entityCountsDetails[category];
    const label = `${categoryDetails.title} (${categoryDetails.total_count})`
    const selected = selectedCategory === category;

    dropdownItems.push(
      <DropdownItem selected={selected} leadingIcon={"radio"} key={category} label={label} value={category}></DropdownItem>
    )
  });

  let label = "Select a list type";

  if (selectedCategory !== defaultOptionValue) {
    label = `${entityCountsDetails[selectedCategory].title} (${entityCountsDetails[selectedCategory].total_count})`
  }

  return (
    <Dropdown
      className="category-dropdown"
      onItemSelected={(item) => categorySelected(item.value)}
      onDropdownClose={() => setOpen(false)}
    >
      <DropdownTrigger>
        <div onClick={() => setOpen(true)}>
          <Input
            id={"user_list_create_category_dropdown"}
            readOnly
            onValueChange={() => {}}
            size="large"
            showLabel={false}
            helperTextVariant="hidden"
            trailingIcon={"chevron-" + (open ? "up" : "down")}
            value={label}
            width="100%"
          />
        </div>
      </DropdownTrigger>
      <DropdownList>
        <DropdownItem label={"Select a list type"} value={defaultOptionValue} noneSelection />
        {dropdownItems}
      </DropdownList>
    </Dropdown>
  );
}

export default CategoryDropdown;
