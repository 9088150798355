import Button from "@javascript/reactComponents/lds/Button";
import ButtonGroup from "@javascript/reactComponents/lds/ButtonGroup";

import LegacyModalService from "@javascript/globals/LegacyModalService";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@javascript/reactComponents/lds/modal/Modal";

const SessionExpiredContent = ({
  extendSession,
}) => (
  <Modal
    open={true}
  >
    <ModalHeader
      allowClose={false}
    >
      Session Expired
    </ModalHeader>
    <ModalBody>
      Your session has expired.
    </ModalBody>
    <ModalFooter align="flex-end">
      <Button
        id="reload_page"
        onClick={extendSession}
        size="medium"
        variant="primary"
      >
        Reload Page
      </Button>
    </ModalFooter>
  </Modal>
);

const SessionExtendContent = ({
  onCancel,
  extendSession,
  signOut,
  ttl,
}) => (
  <Modal
    onClose={onCancel}
    open={true}
  >
    <ModalHeader>
      Session Expiration Warning
    </ModalHeader>
    <ModalBody>
      Your session will expire in {ttl} seconds. Do you want to extend the session?
    </ModalBody>
    <ModalFooter align="flex-end">
      <ButtonGroup size="medium">
        <Button
          id="logout_session_timeout"
          onClick={signOut}
          size="medium"
          variant="secondary"
        >
          Logout Now
        </Button>
        <Button
          id="extend_session"
          onClick={extendSession}
          size="medium"
          variant="primary"
        >
          Extend Session
        </Button>
      </ButtonGroup>
    </ModalFooter>
  </Modal>
);

const SessionTimeoutModal = createReactClass({
  mixins: [
    Reflux.connectFilter(UserStore, 'ttl', function(data) { return data.session.ttl }),
  ],
  getInitialState() {
    return {
      ttl: Infinity,
      allowModal: true
    };
  },
  componentDidMount() {
    UserActions.startSessionCheck()
  },
  cancelModal() {
    this.setState({ allowModal: false })
  },
  extendSession() {
    if (this.state.ttl <= 0) {
      window.location.reload();
    }
    else {
      $.ajax({
        url: '/extend_session',
        method: 'POST',
        success() {
          UserStore.checkUserSession();
        },
      })
    }
  },
  signOut() {
    $.ajax({
      url: '/users/sign_out',
      method: 'DELETE',
      success() { window.location.reload() }
    })
  },
  render() {
    const { allowModal, ttl } = this.state;
    const isSessionExpired = ttl <= 0;
    const isSessionExpiring = allowModal && ttl <= 60;

    if (isSessionExpired) {
      return (
        <SessionExpiredContent
          extendSession={this.extendSession}
        />
      );
    }

    if (isSessionExpiring) {
      return (
        <SessionExtendContent
          onCancel={this.cancelModal}
          extendSession={this.extendSession}
          signOut={this.signOut}
          ttl={ttl}
        />
      );
    }

    return null;
  }
})

window.SessionTimeoutModal = SessionTimeoutModal
export default SessionTimeoutModal;
