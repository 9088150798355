const SalesforceContactLink = props => {
  let sf_contact = null;
  let title = 'Contact not in salesforce';
  let className = 'ep_sf_unlit';
  let SFUrl = null;

  const redirectLink = (SFUrl) => {
    if(!SFUrl){
      return;
    }

    window.open(SFUrl, '_blank');
  }

  let contacts_exist = props.sales_force_contacts && props.sales_force_contacts.length;
  let leads_exists = props.sales_force_leads && props.sales_force_leads.length;

  if (contacts_exist || leads_exists ) {

    className = 'ep_sf_lit';
    // Assign SFUrl and title based on if there are contacts or leads data available
    // Check if contacts has length > 0, we can use the exists variables above since they consider length
    // If contacts_exists is false, then leads_exists must be true. The associated lead url and title will be used.
    SFUrl = contacts_exist ? props.sales_force_contacts[0].url : props.sales_force_leads[0].sf_url;
    title = contacts_exist ? 'Contact already added to your salesforce' : 'Lead already added to your salesforce';

  }

  sf_contact = <a href={SFUrl} onClick={() => redirectLink(SFUrl)}>
    <div className={`salesforce_contact_link ep_icon_front ${className}`}></div>
  </a>

  return (
    <span className="event_panel_icon_div" title={title}>
      {sf_contact}
    </span>
  )
};

export default SalesforceContactLink;
