import { useState, useRef, useEffect } from "react";

import Chip from "@javascript/reactComponents/lds/chips/Chip";
import SfFormFields from "@javascript/reactComponents/companyDashboard/salesforce/sfFormFields";
import SfTwoColumnFormFields from "@javascript/reactComponents/companyDashboard/salesforce/SfTwoColumnFormFields";
import Heading from "@javascript/reactComponents/lds/typography/Heading";

const SfFormFieldsOptional = ({
  errors,
  fields,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [render, setRender] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const headingRef = useRef(null);

  if (fields.length < 1) {
    return null;
  }

  const toggleExpand = () => {
    if (!expanded) {
      setRender(true);
      setTimeout(() => setExpanded(true), 50); // Small delay to ensure render happens first
    } else {
      setExpanded(false);
      setTimeout(() => setRender(false), 300); // Delay to match transition duration
    }
    if (!hasInteracted) {
      setHasInteracted(true);
    }
  };

  const trailingAction = (
    <Chip
      className="lds-sf-form-toggle-chip"
      label={expanded ? "Hide" : "Show"}
      size="medium"
      onClick={toggleExpand}
      selected={expanded}
      variant={"primary"}
    />
  );

  useEffect(() => {
    if (hasInteracted && headingRef.current) {
      headingRef.current.scrollIntoView({
        behavior: 'smooth',
        block: expanded ? 'start' : 'end'
      });
    }
  }, [expanded, hasInteracted]);

  return (
    <div>
      <Heading
        ref={headingRef}
        size={"h2"}
        trailingAction={trailingAction}
      >
        {fields.length} Custom Optional Fields
      </Heading>
      {render && (
        <div
          style={{
            marginTop:"var(--inputGroup-space-gapV)",
            opacity: expanded ? 1 : 0,
            transition: "opacity 0.3s ease-out",
          }}
        >
          <SfTwoColumnFormFields
            className={"sf-form-fields-optional"}
            Component={SfFormFields}
            errors={errors}
            fields={fields}
          />
        </div>
      )}
    </div>
  );
};

SfFormFieldsOptional.propTypes = {
  errors: SfFormFields.propTypes.errors,
  fields: SfFormFields.propTypes.fields,
};

export default SfFormFieldsOptional;
