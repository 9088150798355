import FlagThisEvent from "./flagThisEvent";
import FlagEventComment from "./flagEventComment";
import FlagEventReminder from "./flagEventReminder";

const FlagEventModalBody = (props) => (
  <form id="flag_for_form" onSubmit={props.submit_form}>
    <input
      type="hidden"
      name="authenticity_token"
      value={props.authenticity_token}
    />
    <FlagThisEvent {...props.event} />
    <PaginatedSelectableListOfUsers
      userList={props.userList}
      toggleUser={props.toggleUser}
      featureFlags={props.feature_flags}
      uniqueId="flag_event"
      mixpanelActions={["search colleagues on flagged event"]}
      columnsPerPage="2"
      usersPerColumn="5"
      legend="To"
      width="355px"
      size="small"
    />
    <FlagEventComment
      comment={props.comment}
      updateComment={props.updateComment}
    />
    <FlagEventReminder
      updateDate={props.updateDate}
      flagged_event_id={props.flagged_event_id}
      flagged_event_reminder={props.flagged_event_reminder}
      users={props.users}
    />
  </form>
);

export default FlagEventModalBody;
