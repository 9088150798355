import Reflux from "reflux";
import FacetSearchStore from "../../stores/facetSearchStore";
import { FacetSearchTimeFrameActions } from "../../stores/actions/facetSearch/timeFrameActions";
import Dropdown, { DropdownTrigger } from "../lds/dropdown/Dropdown";
import DropdownList from "../lds/dropdown/DropdownList";
import Input from "../lds/Input";
import { useState } from "react";
import createReactClass from "create-react-class";

function TimeSelectDropdownInner({ dropdownRef, onCustomTimeSelect }) {
  const [open, setOpen] = useState(false);

  const items = [
    { label: "Anytime", value: "time_any" },
    { label: "Last 24 hours", value: "time_last_24h" },
    { label: "Last 3 days", value: "time_last_3d" },
    { label: "Last week", value: "time_last_wk" },
    { label: "Last month", value: "time_last_mon" },
    { label: "Last 3 months", value: "time_last_3m" },
    { label: "Last 6 months", value: "time_last_6m" },
    { label: "Last year", value: "time_last_1y" },
    { label: "Custom date range", value: "time_custom" },
  ];

  const selectedValue = FacetSearchStore.data.timeFrame.selected || "time_any";

  const selectedLabel = items.find(
    (item) => item.value === selectedValue
  ).label;

  const fullItems = items.map((item) => ({
    ...item,
    selected: item.value === selectedValue,
  }));

  const selectItem = (item) => {
    setOpen(false);

    const isTimeCustom = item.value === "time_custom";

    if (isTimeCustom) {
      onCustomTimeSelect(true);
    }

    FacetSearchTimeFrameActions.timeFrameUpdate({
      doSearch: !isTimeCustom,
      timeSelectValue: item.value,
    });
  };

  const handleDropdownClose = () => {
    // When custom time is selected, go back to Date range view
    if (open && selectedValue === "time_custom") {
      onCustomTimeSelect();
    }

    setOpen(false);
  };

  return (
    <Dropdown
      id="time_select_dropdown"
      size="small"
      onItemSelected={selectItem}
      onDropdownOpen={() => setOpen(true)}
      onDropdownClose={handleDropdownClose}
      ref={dropdownRef}
    >
      <DropdownTrigger>
        <div>
          <Input
            id="time_select_dropdown_input"
            readOnly
            value={selectedLabel}
            onValueChange={() => {}}
            size="small"
            trailingIcon={"chevron-" + (open ? "up" : "down")}
            showClearIcon={false}
            width="100%"
            focused={open}
          />
        </div>
      </DropdownTrigger>

      <DropdownList leadingIcon="radio" items={fullItems} />
    </Dropdown>
  );
}

// Wrap with a class component in order to use Reflux.connect
const TimeSelectDropdown = createReactClass({
  mixins: [
    Reflux.connectFilter(FacetSearchStore, function (data) {
      return {
        timeFrame: data.timeFrame,
      };
    }),
  ],

  render() {
    return (
      <TimeSelectDropdownInner
        dropdownRef={this.props.dropdownRef}
        onCustomTimeSelect={this.props.onCustomTimeSelect}
      />
    );
  },
});

export default TimeSelectDropdown;

window.TimeSelectDropdown = TimeSelectDropdown;
