import AutocompleteSuggestions from "../generic/autocomplete/autocompleteSuggestions";
import SearchInput from "../generic/searchInput";

const MutedCompaniesSearch = createReactClass({
  getInitialState: function(){
    return {
      userInput: '',
      filteredIds: null,
      filteredSuggestions: null
    }
  },
  searchCompanies: function(searchString) {
    const query = searchString.trim();
    if(query.length > 2){
      let excluded_orgs = MutedCompaniesStore.data.currentMuted.map(company => company.id)
      $.getJSON("/autocomplete/organizations", {text: query, excluded_orgs: excluded_orgs}, function(response){
        const ids = [];
        const names = [];
        response.results.forEach(function(result, index){
          ids.push(result.id)
          names.push(result.official_name)
        });
        this.setState({userInput: query, filteredSuggestions: names, filteredIds: ids});
      }.bind(this));
    }
    else{
      this.setState({userInput: query, filteredSuggestions: null, filteredIds: null});
    }
  },
  onClick: function(e) {

    const companyName = e.currentTarget.innerText;
    const companyIdIndex = this.state.filteredSuggestions.indexOf(companyName);
    const companyId = this.state.filteredIds[companyIdIndex];

    MutedCompaniesActions.selectionAdded({id: companyId, name: companyName});

    this.setState(prevState => ({
      filteredSuggestions: _.without(prevState.filteredSuggestions, companyName),
      filteredIds: _.without(prevState.filteredIds, companyId)
    }));
  },
  render: function(){
    let classNames = this.props.classNames;
    let placeHolder = this.props.placeHolder;
    let suggestions = this.state.filteredSuggestions ? <AutocompleteSuggestions filteredSuggestions={this.state.filteredSuggestions} classNames={classNames} userInput={this.state.userInput} onClick={this.onClick} placeHolder={placeHolder} /> : null;
    return (
      <div>
        <div className={classNames.join(' ')}>
          <SearchInput id='autocomplete_search_bar' classNames={classNames} searchFunction={this.searchCompanies} placeHolder={placeHolder} width="375px" size="small"/>
        </div>
        {suggestions}
      </div>
    );
  }
});

window.MutedCompaniesSearch = MutedCompaniesSearch;
export default MutedCompaniesSearch;
