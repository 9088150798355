import React, { useContext } from "react";
import PropTypes from "prop-types";

import moment from "moment";
import _ from "lodash";

import { CalendarContext } from "./CalendarContext";
import { useDatePickerContext } from "@rehookify/datepicker";
import Icon from "@javascript/reactComponents/lds/Icon";

export const MONTH_NAMES = moment.months().map((month) => month.toUpperCase());
const INNER_YEAR_WIDTH = 32;
const INNER_MONTH_WIDTH = 28;

export function CalendarHeaderMenu({
  label,
  open = false,
  disabled = false,
  disabledPrev = false,
  disabledNext = false,
  onStep = () => {},
  onOpen = () => {},
  innerWidth = INNER_YEAR_WIDTH,
  id = "",
}) {
  disabledPrev = disabledPrev || disabled;
  disabledNext = disabledNext || disabled;

  const prevClass = disabledPrev ? "disabled" : "";
  const nextClass = disabledNext ? "disabled" : "";

  const onPrev = () => {
    if (!disabledPrev) {
      onStep("prev");
    }
  };

  const onNext = () => {
    if (!disabledNext) {
      onStep("next");
    }
  };

  const testId = `lds-calendar-header-menu${id ? `-${id}` : ""}`;

  return (
    <div className="lds-calendar-header-menu" data-testid={testId}>
      <span
        className={`lds-calendar-header-icon-container ${prevClass}`}
        onClick={onPrev}
        role="button"
        aria-label={`Previous ${id}`}
        aria-disabled={disabledPrev}
        >
        <Icon name="chevron-left" />
      </span>

      <span
        className="lds-calendar-header-menu-inner"
        onClick={onOpen}
        role="button"
        >
        <span style={{ width: innerWidth, textAlign: "right" }}>{label}</span>
        <Icon name={`arrow-triangle-${open ? "up" : "down"}`} />
      </span>

      <span
        className={`lds-calendar-header-icon-container ${nextClass}`}
        onClick={onNext}
        role="button"
        aria-label={`Next ${id}`}
        aria-disabled={disabledNext}
        >
        <Icon name="chevron-right" />
      </span>
    </div>
  );
}

export default function CalendarHeader({ width }) {
  const {
    activeOverlay,
    minDate,
    maxDate,
    toggleMonthOverlay,
    toggleYearOverlay,
  } = useContext(CalendarContext);

  const {
    data: { calendars },
    propGetters: { addOffset, subtractOffset },
  } = useDatePickerContext();

  const styles = {};

  if (width) {
    styles.width = width;
  }

  const currentYear = calendars[0].year;
  const yearInt = parseInt(currentYear);

  const currentMonth = calendars[0].month.toUpperCase();
  const monthInt = MONTH_NAMES.indexOf(currentMonth);


  const disableNextYear = _.isDate(maxDate) && yearInt >= maxDate.getFullYear();
  const disablePrevYear = _.isDate(minDate) && yearInt <= minDate.getFullYear();

  // Disable the month when year is disabled and clicking would roll year over
  // (jan when first allowed year, dec when last allowed year)
  const disableNextMonth = disableNextYear && monthInt === 11;
  const disablePrevMonth = disablePrevYear && monthInt === 0;

  const stepOffset = (type, direction) => {
    const offset = { [type]: 1 };

    if (direction === "next") {
      addOffset(offset).onClick();
    } else {
      subtractOffset(offset).onClick();
    }
  };

  const stepYear = (direction) => {
    stepOffset("years", direction);
  };

  const stepMonth = (direction) => {
    stepOffset("months", direction);
  };

  return (
    <div className="lds-calendar-header" style={styles}>
      <CalendarHeaderMenu
        label={currentYear}
        open={activeOverlay === "year"}
        disabled={!!activeOverlay}
        disabledPrev={disablePrevYear}
        disabledNext={disableNextYear}
        onStep={stepYear}
        onOpen={toggleYearOverlay}
        innerWidth={INNER_YEAR_WIDTH}
        id="year"
      />
      <CalendarHeaderMenu
        label={currentMonth.slice(0, 3)}
        open={activeOverlay === "month"}
        disabled={!!activeOverlay}
        disabledPrev={disablePrevMonth}
        disabledNext={disableNextMonth}
        onStep={stepMonth}
        onOpen={toggleMonthOverlay}
        innerWidth={INNER_MONTH_WIDTH}
        id="month"
      />
    </div>
  );
}

CalendarHeader.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
