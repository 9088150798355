/**
 * A schema to describe the Auto Complete slice.
 * Initially the slice will only have alias search. We will move
 * other autocomplete searches into this slice as we refactor.

**/
export const FacetSearchAliasSearchSlice = {
  aliasSearchRefs: [],
  selected: null
}
