import OrgListCountDescription from "./orgListCountDescription";
import PagingOrgList from "./pagingOrgList";

const OrgListDisplayPanel = function(props){
  return (<div>
    <OrgListCountDescription {...props} />
    <PagingOrgList {...props} />
    </div>
  );
};

window.OrgListDisplayPanel = OrgListDisplayPanel;
export default OrgListDisplayPanel;
