import PaginationHolder from "../generic/pagination/paginationHolder";
import RelatedTherapiesTable from "./relatedTherapiesTable";

const RelatedTherapiesFromList = (props) => {
  const paginationClasses = ["pagination", "standard_pagination_holder"];
  const displaySize = 10;
  return(
    <div>
      <p>Found {props.totalNumberOfTherapies} drugs.</p>
      <PaginationHolder pages={props.numberOfPages} displaySize={displaySize} changePage={props.changePage} paginationClasses={paginationClasses} currentPage={props.currentPage}/>
      <RelatedTherapiesTable listOfTherapies={props.listOfTherapies} totalNumberOfTherapies={props.totalNumberOfTherapies} maxNameLength={props.maxNameLength} errorCallback={props.errorCallback} therapiesReported={props.therapiesReported}/>
    </div>
  );
};

export default RelatedTherapiesFromList;
