
const ModifyColumnsButton = createReactClass({
  mixins: [Reflux.listenTo(ColumnsChosenStore,"change_button_state")],
  getInitialState: function(){
    return {
      columns_chosen:this.props.columns_chosen,
      submit_form:false
    }
  },
  change_button_state: function(columns_chosen) {
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      this.setState({
        columns_chosen:columns_chosen
      });
    }
  },
  submit_form: function(e) {
    e.preventDefault();
    if(this.state.submit_form === false){
      this.submit_changes();
    }
  },
  submit_changes: function (){
    if(this.props.feature_flags.pivotlist_enabled !== -1){
      Zymewire.mixpanel_track("Lists: columns selected", {choice: this.state.columns_chosen.filter(function(entry){
          return entry.full_name !== "Company: Name"
        }).map(function(obj) {
          return obj.full_name;
        })});
      $("body").addClass("wait");
      $("html, body").animate({ scrollTop: 0 }, 'fast');
      NProgress.configure({parent:'.modify_list_header', showSpinner:false});
      NProgress.start();
      this.setState({
        submit_form:true
      });
      SubmitFormActions.disableButtons();

      const fd = new FormData()

      // Array field
      this.state.columns_chosen.forEach(column => {
        fd.append("columns_chosen[]", column.column_name)
      })

      $.ajax({
        type: 'post',
        url: "/lists/" + this.props.list_id + "/manage_chosen_columns",
        processData: false,
        contentType: false,
        data: fd,
        complete: (xhr) => {
          if (xhr.status == 404) {
            window.location.replace("/lists");
          } else {
            window.location.replace("/lists/"+this.props.list_id+"?page=1");
          }
        }
      })
    }
  },
  render: function () {


    let classNames = ["modify_columns_button", "button-small", "button-primary"];
    if(this.state.columns_chosen.length < 1 || this.state.submit_form === true){
      classNames.push("button-disable");
    }

    const columns_chosen_ordered = this.state.columns_chosen.map(function(entry, index){
      return (
        <input key={index} type="hidden" name="columns_chosen[]" value={entry.column_name} />
      );
    }.bind(this));

    return (
      <form role='form' onSubmit={this.submit_form} id="chosen_columns_form" acceptCharset="UTF-8">
        <input type='hidden' name='authenticity_token' value={this.props.authenticity_token} />
        {columns_chosen_ordered}
        <button type="submit" className={classNames.join(" ")}>Done</button>
      </form>
    );
  }
});

export default ModifyColumnsButton;
