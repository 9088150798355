import React from "react";
import PropTypes from "prop-types";

import Banner from "./Banner";
import BannerHeading from "./BannerHeading";
import Icon from "../Icon";

const Toast = ({
  headingClassName,
  id,
  title,
  trailingAction = null,
  inverse = false,
  variant = "info",
}) => {

  const headingClassNames = ["lds-banner-heading-light-font"];

if(headingClassName) {
    headingClassNames.push(headingClassName);
  }

  const leadingIcon = <Icon filled={inverse} className={"icon-leading"} name={variant} />

  return (
    <Banner id={id} variant={variant} inverse={inverse}>
      <BannerHeading className={headingClassNames.join(" ")} leadingIcon={leadingIcon} trailingAction={trailingAction} variant={variant} >
        {title}
      </BannerHeading>
    </Banner>
  );
}

Toast.propTypes = {
  className: Banner.propTypes.className,
  id: Banner.propTypes.id,
  title: PropTypes.string,
  trailingAction: BannerHeading.propTypes.trailingAction,
  inverse: PropTypes.bool,
  variant: Banner.propTypes.variant,
};

window.Toast = Toast;
export default Toast;
