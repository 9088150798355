//
// Example props passed to SignInLinks
//
// const signInLinks = [
//    { id:'sessions', title: 'Sign in', sub_title:'Already registered?', desc:'You can access Zymewire by signing in here.', url:'<%= new_session_path(resource) %>' },
//    { id:'registrations', title: 'Sign up', sub_title:'First time to Zymewire?', desc:'Create a new account by signing up here.', url:'<%= new_registration_path(resource) %>' },
//    { id:'passwords', title: 'Forgot your password?', sub_title:'It happens...', desc:'You can reset it here.', url:'<%= new_password_path(resource) %>' },
//    { id:'confirmations', title: 'Confirmation email?', sub_title:'Didn\'t receive your sign up confirmation email?', desc:'Request another one here.', url:'<%= new_confirmation_path(resource) %>' },
//    { id:'info_form', title: 'Help', sub_title:'Have questions?', desc:'Contact the Zymewire Team.', url:'/contact' },
//];
//
// 	see app/views/layouts/guest_pages.html.erb
// 	for usage

import SignInLink from "./signInLink";
import LinkBridge from "./linkBridge";

const SignInLinks = createReactClass({
  getInitialState: function(){
    return { current : this.props.current };
  },
  changeCurrent: function( dest ){
    this.setState({current: dest });
  },
  render: function() {
    const signInLinks = _.map(this.props.signInLinks, function(linkdata, index){
      return <SignInLink key={index} id={linkdata.id} title={linkdata.title} sub_title={linkdata.sub_title} desc={linkdata.desc} url={linkdata.url} changeCurrent={this.changeCurrent} current={this.state.current}/>;
    }.bind(this));
    return (
      <div id="link_content">
        <LinkBridge current={this.state.current} />
        <div id="zymewire_links" className="pad_container">
          {signInLinks}
        </div>
      </div>
    );
  }
});

window.SignInLinks = SignInLinks;
export default SignInLinks;
