import Reflux from "reflux";

import SFAccountActions from "../actions/salesForce/sFAccountActions";

const SFAccountActionsStore = Reflux.createStore({
  listenables: SFAccountActions,
  init: function(){
    this.data_store = {accounts:[]}
  },
  onUpdateAccounts: function(accounts){
    this.data_store.accounts = accounts
    this.trigger(this.data_store.accounts);
  }
});

window.SFAccountActionsStore = SFAccountActionsStore;
export default SFAccountActionsStore;
