import BootstrapRow from "../generic/htmlElements/bootstrapRow";
import BootstrapSpan from "../generic/htmlElements/bootstrapSpan";

const TerritorySelectorHeader = () => {
  return (
    <BootstrapRow>
      <BootstrapSpan wide="5">
        <p>Look up countries or states then click to add them to your territory</p>
      </BootstrapSpan>
      <BootstrapSpan wide="2">
        &nbsp;
      </BootstrapSpan>
      <BootstrapSpan wide="5">
        <h3 >Your territory</h3>
        <p>Click to remove countries or states</p>
      </BootstrapSpan>
    </BootstrapRow>
  );
};

export default TerritorySelectorHeader;
