import Reflux from "reflux";

import SubmitFormActions from "../actions/pivotLists/submitFormActions";

const SubmitFormStore = Reflux.createStore({
  listenables: SubmitFormActions,
  init: function() {
    this.state = false
  },
  onDisableButtons: function(){
    this.state = true;
    this.trigger(this.state);
  }
});

window.SubmitFormStore = SubmitFormStore;
export default SubmitFormStore;

