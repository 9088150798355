import moment from "moment";
import Chip from "@javascript/reactComponents/lds/chips/Chip";
import { chunk } from "lodash";
import { useDatePickerContext } from "@rehookify/datepicker";
import { useContext } from "react";
import { CalendarContext } from "./CalendarContext";

const MONTHS_IN_YEAR = moment.months().map((month) => month.toUpperCase());

export default function QuickActionMonthSelector() {
  const {
    data: { calendars },
    propGetters: { addOffset, subtractOffset },
  } = useDatePickerContext();

  const { dismissOverlay } = useContext(CalendarContext);

  const currentMonth = calendars[0].month;

  const handleMonthClick = (month) => {
    const currentMonthIndex = MONTHS_IN_YEAR.indexOf(
      currentMonth.toUpperCase()
    );
    const desiredMonthIndex = MONTHS_IN_YEAR.indexOf(month);

    const offset = { months: Math.abs(currentMonthIndex - desiredMonthIndex) };
    if (desiredMonthIndex > currentMonthIndex) {
      addOffset(offset).onClick();
    } else {
      subtractOffset(offset).onClick();
    }

    dismissOverlay();
  };

  const monthChips = MONTHS_IN_YEAR.map((month) => {
    const selected =
      currentMonth.toLocaleLowerCase() === month.toLocaleLowerCase();
    return (
      <Chip
        key={month}
        block
        className="lds-datepicker-quick-action-chip"
        label={month.substring(0, 3)}
        size="small"
        onClick={() => handleMonthClick(month)}
        selected={selected}
        variant={selected ? "primary" : "secondary"}
        width="100%"
      />
    );
  });

  const renderChunks = chunk(monthChips, 2);

  return renderChunks.map((chunk, index) => (
    <div key={index} className="lds-datepicker-quick-action-row">
      {chunk}
    </div>
  ));
}
