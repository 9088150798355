import SfContact from "./sfContact";

const SfContacts = createReactClass({
  getInitialState: function(){
    return {display: 10}
  },
  show_more: function(){
    this.setState({display: this.state.display + 10});
    return false;
  },
  render: function () {
    if(_.isUndefined(this.props.tab_content.data) || this.props.tab_content.data.length < 1) {
      return (
        <div className="sf_accounts_section">
          There are no contacts listed in Salesforce for this account.
        </div>
      )
    }
    let moreButton;
    let displayContacts = Object.keys(this.props.tab_content.data)
    if(this.state.display < displayContacts.length){
      var remainder = displayContacts.length - this.state.display;
      var next = remainder > 10 ? "next 10" : 'last ' + remainder;
      moreButton = (
        <div onClick={this.show_more}>
          <h1>
            <a className="btn btn-primary">
              <span>Show the {next} of {displayContacts.length} total contacts</span>
            </a>
          </h1>
        </div>
      )
    }
    displayContacts = this.props.tab_content.data.slice(0, this.state.display);
    const pairs = [];
    _.eachSlice(displayContacts, 2, function(pair){
      pairs.push(pair[0]);
      if(pair[1]){
        pairs.push(pair[1]);
      }
    });
    const contactRows = pairs.map(function (pair) {
      const key = "pair" + pair.url;
      return <SfContact key={key} contacts={pair} /> ;
    }.bind(this));
    return (
      <div>
        {contactRows}
        {moreButton}
      </div>
    );
  }
});

export default SfContacts;
