const PaginationLinks = createReactClass({
  change: function(e){
    e.preventDefault();
    e.stopPropagation();
    this.props.changePage(this.props.page_number)
  },
  render: function () {
    const displayNumber = this.props.page_number + 1;
    const className = this.props.active ? "active" : "";
    return <li className={className}>
      <a href="#" id={"rspec_generic_pagination_link_" + this.props.page_number} onClick={this.change}>{displayNumber}</a>
    </li>;
  }
});

window.PaginationLinks = PaginationLinks;
export default PaginationLinks;
