import ZoominfoPersonLinks from "./zoominfoPersonLinks";
import ZoominfoCompanyAddress from "./zoominfoCompanyAddress";
import ZoominfoContactPurchasedDetails from "./zoominfoContactPurchasedDetails";

const ZoominfoPurchasedContactFurtherDetails = function(props) {
  return (
    <tr className="bottomRow rspec_revealed_row">
      <ZoominfoPersonLinks {...props} />
      <ZoominfoCompanyAddress {...props} />
      <ZoominfoContactPurchasedDetails {...props} />
    </tr>
  );
}

export default ZoominfoPurchasedContactFurtherDetails