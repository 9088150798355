import Reflux from "reflux";
import FacetSearchStore from "../../stores/facetSearchStore";
import { FacetSearchCompanyRelationshipActions } from "../../stores/actions/facetSearch/companyRelationshipActions";

/**
 * @param relationshipProps {{ name: string, value: string, last?: boolean, locked?: boolean }[]}
 */
const FacetSearchCriteriaRelationship = createReactClass({
  mixins: [
    Reflux.connectFilter(FacetSearchStore, function(data) { return data.relationships })
  ],

  getInitialState() {
    return { ...(FacetSearchStore.data.relationships) }
  },

  render () {
    const { selected, salesforceCurrentlyProcessing, authorizedSalesForce } = this.state;

    if (selected == null) return null

    let relationshipsSelected = selected.filter((rel) => rel !== "exclude_muted_companies");

    const shouldDisplay = (_.contains(Zymewire.user.feature_tags, "can_use_muted_companies") || (
      salesforceCurrentlyProcessing && authorizedSalesForce
    )) && relationshipsSelected.length !== 0

    if (!shouldDisplay) return null

    const lastRelIndex = relationshipsSelected.length - 1;
    const originalRels = (Zymewire.original_feed_query || {}).relationships || []

    relationshipsSelected = _.sortBy(relationshipsSelected, (rel) => -rel.length)

    relationshipsSelected = relationshipsSelected.map((relationship, index) => {
      return {
        name: Zymewire.relationship_properties[relationship],
        value: relationship,
        last: lastRelIndex === index,
        locked: _.contains(originalRels, relationship),
      }
    });

    const componentFor = (prop) => {
      let component
      const keyword = prop.value === 'exclude_recent_sponsor' ? ', AND' : ', OR'

      if (prop.locked) {
        component =
          <LockedRelationship key={prop.value} prop={prop}>
            <b>{prop.name}</b>
            { !prop.last && <b>{keyword}</b> }
          </LockedRelationship>
      } else {
        component =
          <UnlockedRelationship key={prop.value} prop={prop}>
            <b>{prop.name}</b>
            { !prop.last && <b>{keyword}</b> }
          </UnlockedRelationship>
      }

      return component
    }

    return(
      <div>
        <p style={{ marginBottom: 0 }}>Involves a Company your team has</p>
        { relationshipsSelected.map((prop) => componentFor(prop)) }
      </div>
    );
  }
})

/**
 * @param prop {{ name: string, value: string, locked: true, last?: boolean }}
 */
const LockedRelationship = ({ prop, children }) =>
  <div className="filter_item_property" title={`You are filtering your search by events specifically involving a company your team has '${prop.name}'.`}>
    <div style={{ display: 'inline-block', width: '100%' }}>
      <p style={{ fontSize: '13px', lineHeight: '18px', color: '#000000' }}>
        { children }
      </p>
    </div>
  </div>

/**
 * @param prop {{ name: string, value: string, last?: boolean }}
 */
const UnlockedRelationship = ({ prop, children }) =>
  <div onClick={() => FacetSearchCompanyRelationshipActions.removeCompanyRelationship(prop.value)} className="action_link">
    <div className="filter_item_property" title={`You are filtering your search by events specifically involving a company your team has '${prop.name}'. Click to remove this filter.`}>
      <div style={{ display: 'inline-block', width: '100%' }}>
        <div style={{ fontSize: '13px', lineHeight: '18px' }}>
          <div className="filter_item_close" style={{ display: 'inline-block' }}>
            <i className="icon-remove"></i>
          </div>
          { children }
        </div>
      </div>
    </div>
  </div>

window.FacetSearchCriteriaRelationship = FacetSearchCriteriaRelationship;
export default FacetSearchCriteriaRelationship;
