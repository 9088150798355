import { createContext, useState } from "react";

export const FeedsIndexContext = createContext({
  getCheckboxArray: () => [],
  getButtonState: () => "disable",
  toggleCheckbox: () => {},
  toggleSelectAll: () => {},
});

export const FeedsIndexProvider = ({ feeds, setFeeds, children }) => {
  const [checkboxes, setCheckboxes] = useState({
    feeds: [],
    lists: [],
  });

  const toggleCheckbox = (group, value) => {
    setCheckboxes((prevCheckboxes) => {
      const updatedCheckboxes = {
        ...prevCheckboxes,
        [group]: prevCheckboxes[group].includes(value)
          ? prevCheckboxes[group].filter((item) => item !== value)
          : [...prevCheckboxes[group], value],
      };
      return updatedCheckboxes;
    });
  };

  const toggleSelectAll = (allIds, group) => {
    const currentCheckboxArray = getCheckboxArray(group);

    if (currentCheckboxArray.length === allIds.length) {
      setCheckboxes((prevCheckboxes) => ({
        ...prevCheckboxes,
        [group]: [],
      }));
    } else {
      setCheckboxes((prevCheckboxes) => ({
        ...prevCheckboxes,
        [group]: allIds,
      }));
    }
  };

  const getCheckboxArray = (group) => checkboxes[group];

  const getSelectedNames = (checkboxArray) => {
    return checkboxArray.map(
      (checkboxId) =>
        feeds.find((feed) => feed.ids.savedFeed === checkboxId)?.name
    );
  };

  const removeFeedsFromView = (feedIds) => {
    setFeeds(feeds.filter((feed) => !feedIds.includes(feed.ids.savedFeed)));
  };

  return (
    <FeedsIndexContext.Provider
      value={{
        checkboxes,
        getCheckboxArray,
        toggleCheckbox,
        toggleSelectAll,
        getSelectedNames,
        removeFeedsFromView,
      }}
    >
      {children}
    </FeedsIndexContext.Provider>
  );
};
