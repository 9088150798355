export const updateUserHomeSettings = (id) => {
  const url = `/feeds/${id}/change_home_feed_display`;
  $.ajax({
    url: url,
    type: 'POST',
    success: function () {
      return true;
    },
    error: function (xhr, ajaxOptions, thrownError) {
      if (xhr.status == 401) {
        alert("Un-authorized status received")
        window.location = "/users/sign_in"
      }
      else {
        console.log(thrownError)
      }
      return false;
    }
  });
};

