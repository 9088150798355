const FlagUserCheckbox = (props) => {
  const changed = function () {
    props.toggleUser(props.user.id);
  }

  var full_name = props.user.name;
  if (props.search_string) {
    var regex = new RegExp(props.search_string, 'gi');
    var new_name = full_name.replace(regex, "<span class='generic_search_string_highlight'>$&</span>");
    full_name = new_name;
  }
  var checkbox_id = 'selectable_user_' + props.user.id;
  var label_class = props.user.currently_flagged ? "fullstory_excluded disabled_check checkbox rspec_fullstory" : "fullstory_excluded checkbox rspec_fullstory";
  return (
    <label className={label_class}>
      <input data-id={props.user.id} id={checkbox_id} type="checkbox" onChange={changed} className="rspec_selectable_user_check fancy_checkbox" checked={props.user.checked} /> <span className="rspec_colleague_name" dangerouslySetInnerHTML={{ __html: full_name }}></span>
    </label>
  );
}

export default FlagUserCheckbox;
