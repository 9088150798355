import NonOrgEntity from "./nonOrgEntity";

const EventNonOrgEntities = (props) => {
  const nonOrgEntities = props.ents.map(function (ent) {
    return <NonOrgEntity key={ent.rec_id}  ent={ent}/>;
  });
  return (
    <div className="rspec-event-non-org-entities">{nonOrgEntities}</div>
  );
};

export default EventNonOrgEntities;
