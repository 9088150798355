const QueryAliasSearchRefs = (props) => {
  if (props.search.alias_search_hash) {
    const alias_search_names = Object.values(props.search.alias_search_hash)

    var aliasSearchNames = alias_search_names.map(function (aliasSearchName, index) {
      return (<p className="rspec_alias_search_description" key={index}><b>{aliasSearchName}</b></p>);
    });
    return (<div>{aliasSearchNames}</div>);
  }
  return false;
}

export default QueryAliasSearchRefs;
