import ModifyColumnsButton from "./modifyColumnsButtons";
import ClearColumnsButton from "./clearColumnsButton";

const ModifyColumnsRow = (props) => {
  const modify_columns_button = <ModifyColumnsButton {...props}/>;
  const clear_columns_button = <ClearColumnsButton {...props}/>;

  return (
    <div className="modify_columns_row buttonGroup-small">
      {clear_columns_button}
      {modify_columns_button}
    </div>
  );
}

export default ModifyColumnsRow;
