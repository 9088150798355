import Reflux from "reflux";

import ColumnSettingsActions from "../actions/pivotLists/columnSettingsActions";

const ColumnSettingsStore = Reflux.createStore({
  listenables: ColumnSettingsActions,
  init: function() {
    this.state = []
  },
  onAdjustColumnSettings: function(column_settings){
    this.state = column_settings;
    this.trigger(this.state);
  }
});

window.ColumnSettingsStore = ColumnSettingsStore;
export default ColumnSettingsStore;
