const ZoominfoLastReviewed = function(props) {
  if(props.LastUpdatedDate && props.LastUpdatedDate.trim().length > 0) {
    var mentioned = moment(props.LastUpdatedDate, "YYYY-MM-DD").fromNow();
    return (
      <td width="120px" className="cs_last_mentioned">
        Last mentioned
        <br/>
        {mentioned}
      </td>
    )
  }
  else{
    return (<td width="120px">&nbsp;</td>);
  }
}

export default ZoominfoLastReviewed;
