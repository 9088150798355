import React, {
  useEffect,
  useRef,
} from "react";

import {
  useClickAway,
  useWindowSize,
  useWindowScroll,
} from "@uidotdev/usehooks";


import PropTypes from "prop-types";


import {
  elementOverlapsViewport,
  findScrollableElement,
} from "@javascript/reactComponents/utilities/DOMUtilities";

import {
  createIntersectionObserverFor,
  listenToScrollOnElement,
  openBottom,
  openLeft,
  openRight,
  openTop,
} from "@javascript/reactComponents/utilities/PopoverUtilities";

/** A component to render a surface next to an existing trigger. */


export default function Popover({
  align = "start",
  children,
  dropdownClassName = "",
  gap = "var(--dropdown-list-space-marginT)",
  maxWidth = null,
  nested = false,
  onClickOutside = () => {},
  onMouseLeave = () => {},
  onTriggerClick = () => {},
  open = false,
  popoverClassName = "",
  popoverPosition = "bottom",
  trigger,
}) {
  const handleClickOutside = (event) => {
    const target = event.target;

    // If click was on trigger, ignore
    if (!triggerRef.current.contains(target)) {
      onClickOutside();
    }
  };


  const hiddenStyles = {
    visibility: "none",
    opacity: 0,
    pointerEvents: "none",
  };

  const clickOutsideRef = useClickAway(handleClickOutside);
  const windowDimensions = useWindowSize();
  const [{ x, y }, scrollTo] = useWindowScroll();

  const triggerRef = useRef(null);
  const bodyRef = useRef(null);

  const parentStyles = {
    position: "relative",
  };

  const triggerWidth = triggerRef.current?.getBoundingClientRect().width + "px";

  const bodyStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 999,
    overflowX: "visible",

    // Note: Nested children don't have any bounds on their width,
    // rather they will grow/shrink with the content provided.
    width: "max-content",
    minWidth: !nested && triggerWidth,
    maxWidth: !nested && (maxWidth || triggerWidth),
  };

  const positionToActionMapping = {
    top: openTop,
    bottom: openBottom,
    left: openLeft,
    right: openRight,
  };

  const openToPosition = (position) => {
    const action = positionToActionMapping[position];
    action &&
      action(bodyRef.current, triggerRef.current, {
        align,
        gap: nested ? 0 : gap,
      });
  };

  const updatePopoverPosition = () => {
    const positionReverseMapping = {
      top: "bottom",
      bottom: "top",
      left: "right",
      right: "left",
    };

    // Offset the body by parent amount
    const bodyElement = bodyRef.current;

    openToPosition(popoverPosition);

    // Try opening in another direction, if possible
    if (elementOverlapsViewport(bodyElement)) {
      openToPosition(positionReverseMapping[popoverPosition]);

      // Still overlapping??? Go back to the original
      if (elementOverlapsViewport(bodyElement)) {
        openToPosition(popoverPosition);
      }
    }
  };

  const handleTriggerMove = () => {
    if (!open) return;
    updatePopoverPosition();
  };

  const handleIntersect = (entries) => {
    const visible = entries[0].isIntersecting;
    if (!visible) {
      if (nested) {
        onMouseLeave();
      } else {
        onClickOutside();
      }
    }
  };

  useEffect(() => {
    if (!triggerRef.current || !bodyRef.current) return;

    updatePopoverPosition();
  }, [open]);

  // When user changed screen resolution, track the new position
  useEffect(handleTriggerMove, [windowDimensions]);

  // Whenever user scrolls relative to the window, update popover position
  useEffect(() => {
    return listenToScrollOnElement(window, handleTriggerMove);
  }, [x, y]);

  // When user scrolls relative to a provided container
  useEffect(() => {
    if (!open) return;
    if (!triggerRef.current) return;

    const nearestScrollBody = findScrollableElement(triggerRef.current);
    if (!nearestScrollBody) return;

    const isRoot = !nearestScrollBody.parentElement;

    // Causes storybook to break otherwise, likely because it throws inside an iFrame :(
    if (!isRoot) {
      createIntersectionObserverFor(
        nearestScrollBody,
        triggerRef.current,
        handleIntersect
      );
    }

    return listenToScrollOnElement(nearestScrollBody, handleTriggerMove);
  });

  const popoverClassNames = ["popover-container"];
  if(popoverClassName) {
    popoverClassNames.push(popoverClassName);
  }

  const dropdownClassNames = ["dropdown-container"];
  if(dropdownClassName) {
    dropdownClassNames.push(dropdownClassName)
  }
  return (
    <div
      className={popoverClassNames.join(" ")}
      style={parentStyles}
      onMouseLeave={onMouseLeave}
    >
      <div
        ref={triggerRef}
        className="dropdown-trigger"
        onClick={onTriggerClick}
      >
        {trigger}
      </div>

      <div className="popover-1" style={open ? {} : hiddenStyles}>
        <div className="popover-2" ref={bodyRef} style={bodyStyles}>
          <div ref={clickOutsideRef} className={dropdownClassNames.join(" ")}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

Popover.propTypes = {
  align: PropTypes.oneOf([
    "center",
    "start",
  ]),
  dropdownClassName: PropTypes.string,
  gap: PropTypes.string,

  maxWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null])
  ]),
  nested: PropTypes.bool,
  onClickOutside: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onTriggerClick: PropTypes.func,
  open: PropTypes.bool,
  popoverClassName: PropTypes.string,
  popoverPosition: PropTypes.oneOf(["top", "bottom", "left", "right"]).isRequired,
  trigger: PropTypes.element,
};
