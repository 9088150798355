import React from "react";
import createReactClass from "create-react-class";
import TimeSelectDropdown from "../TimeSelectDropdown";
import FacetSearchDateRangePicker from "./FacetSearchDateRangePicker";
import { parseDate } from "./timeSelectorHelpers";
import moment from "moment";
import Reflux from "reflux";

const HIDDEN_STYLES = {
  display: "none",
};

/**
 * This component is the entrypoint for all
 * time-related interactions in search bar.
 */
const FacetSearchTimeSelector = createReactClass({
  mixins: [
    Reflux.connectFilter(FacetSearchStore, function (data) {
      return {
        timeFrame: data.timeFrame,
      };
    }),
  ],
  getInitialState() {
    return {
      showRangePicker: false,
      datePickerRef: React.createRef(),
      timeSelectRef: React.createRef(),
    };
  },
  componentDidUpdate(prevProps, prevState) {
    const nowSelected = this.state.timeFrame.selected;
    const prevSelected = prevState.timeFrame?.selected;

    // Only care when selected timeframe is custom
    if (nowSelected === "time_custom" && nowSelected !== prevSelected) {
      this.setState({ showRangePicker: true });
    }
  },
  closeRangePicker() {
    this.setState({ showRangePicker: false });
    this.state.timeSelectRef.current.open();
  },
  openRangePicker(open) {
    this.setState({ showRangePicker: true });

    open && this.state.datePickerRef.current.open();
  },
  render() {
    const { showRangePicker } = this.state;
    let fromDate = parseDate(this.state.timeFrame?.from);
    let toDate = parseDate(this.state.timeFrame?.to);

    if (!fromDate.isValid()) {
      fromDate = moment().subtract(10, "years");
    }

    if (!toDate.isValid()) {
      toDate = moment();
    }

    const renderRangePicker =
      showRangePicker && this.state.timeFrame.selected === "time_custom";

    // Render both, but hide one using CSS to avoid jitter issues
    return (
      <>
        <div style={renderRangePicker ? {} : HIDDEN_STYLES}>
          <FacetSearchDateRangePicker
            onDismiss={this.closeRangePicker}
            fromDate={fromDate.toDate()}
            toDate={toDate.toDate()}
            dropdownRef={this.state.datePickerRef}
          />
        </div>

        <div style={renderRangePicker ? HIDDEN_STYLES : {}}>
          <TimeSelectDropdown
            onCustomTimeSelect={this.openRangePicker}
            dropdownRef={this.state.timeSelectRef}
          />
        </div>
      </>
    );
  },
});

export default FacetSearchTimeSelector;

window.FacetSearchTimeSelector = FacetSearchTimeSelector;
