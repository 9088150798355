/**
 * React component that displays a item in a list of facet search terms. Those terms are related to: "conditions", "organizations", and "therapies"
 *
 * @author
 * @namespace
 * @memberof
 *
 * @component
 * @example
 * return (
 *   <FacetListItem key={index} term={term} facetType={facetType} />
 * )
 *
 * @param {Number} key as this is one of a series of items, this is usually the index of the item in the array. But can be anything unique.
 * @param {Object} term a hash object of things related to the item, such as name etc.
 * @param {String} facetType the type of facet this link is being used with, currently limited to: "conditions", "organizations", and "therapies"
 *
 */

const FacetListItem = (props) => {

  const propagateSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    facet_search(props.facetType, props.term.key);
  };

  let term = props.term;
  let salesforceImage = null;

  if(props.facetType === 'organizations') {
    salesforceImage = <img src="/images/salesforce_lit.png" className="facet_filter_sf_icon" title={decodeHTMLContent(term.name) + " is in your Salesforce"} />;
    if(term.sf_enabled && !term.in_crm) {
      salesforceImage = <img src="/images/salesforce_unlit.png" className="facet_filter_sf_icon" title={decodeHTMLContent(term.name) + " is NOT in your Salesforce"} />
    }
  }

  return(
    <p onClick={(event) => propagateSearch(event)} className="facet_filter_count_hover clearfix" onMouseOver={() => highlightMapMarker(term.key)} onMouseOut={() => removeHighlightMarker(term.key)}>
      {salesforceImage}
      <span className="facet_search_term_link" title={"Click to filter your search to only events involving " + decodeHTMLContent(term.name) + '.'}>{decodeHTMLContent(term.name)}</span>
      <span className="organization_facet_count">{term.doc_count}</span>
    </p>
  )

};

export default FacetListItem;
