/** True iff `num` is less than 0 or greater than max */
function numOutsideRange(num, max) {
  return num < 0 || num > max;
}

/** True iff the bounding rect will extend beyond the viewport */
export function elementOverlapsViewport(element) {
  const { innerWidth, innerHeight } = window;
  const { x, y, width, height } = element.getBoundingClientRect();

  const topLeft = { x, y };
  const bottomRight = { x: x + width, y: y + height };

  return (
    numOutsideRange(topLeft.x, innerWidth) ||
    numOutsideRange(topLeft.y, innerHeight) ||
    numOutsideRange(bottomRight.x, innerWidth) ||
    numOutsideRange(bottomRight.y, innerHeight)
  );
}

// True iff given text element is wrapped text within a parent.
// Taken with help from: https://stackoverflow.com/a/53281556.
// NOTE: `element` must have an offsetHeight. This can be achieved
// by making sure it doesn't have `display: none`.
export function isTextElementWrapped(element) {
  if (!element) return false;

  const { lineHeight } = getComputedStyle(element);
  const lineHeightParsed = parseFloat(lineHeight);

  return element.offsetHeight > lineHeightParsed;
}

/** True iff `element` overflows into scroll. */
export function elementHasScroll(element) {
  if (!element) return false;

  const style = getComputedStyle(element);
  const allowedOverflowStyles = ["auto", "scroll"];

  return (
    element.clientHeight < element.scrollHeight &&
    allowedOverflowStyles.includes(style.overflowY)
  );
}

/**
 * Returns array of elements that are ancestors
 * to `element` and have a scroll.
 */
export function allScrollableAncestors(element) {
  const ancestors = [];
  while (element) {
    if (elementHasScroll(element)) {
      ancestors.push(element);
    }
    element = element.parentElement;
  }

  return ancestors;
}

/**
 * Traverses component tree to the root
 * until a parent with scrollbar is found.
 * Returns null if none found
 */
export function findScrollableElement(element) {
  while (element && !elementHasScroll(element)) {
    element = element.parentElement;
  }

  return element;
}
