import FacetListHeader from "./facetListHeader";
import FacetList from "./facetList";
import FacetSectionShowMore from "./facetSectionShowMore";

/**
 * React component that displays a list of facet search terms. Those terms are related to: "conditions", "organizations", and "therapies"
 *
 * @author
 * @namespace
 * @memberof
 *
 * @component
 * @example
 * return (
 *   <FacetListContainer facetList={facet_list} />
 * )
 *
 * @param {Object} facetList a hash object of things related to the categories of facet list items, such as an array of individual items (detailing it's name/other characteristics) and other parameters related to the category of facet search terms and their display
 *
 */
const FacetListContainer = createReactClass({

  getInitialState: function() {
    const initialDisplayList = _.first(
      this.props.facetList.buckets,
      this.props.facetList.facet_display_count
    );
    return {
      currentDisplayList: initialDisplayList,
      displayCount: this.props.facetList.facet_display_count,
      opened: false,
      isLoading: false,
    };
  },

  addMoreFacets: function(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isLoading: true });

    const facetGroup = this.props.facetList;

    const timeoutId = setTimeout(() => {
      if (facetGroup.facet_display_count < facetGroup.buckets.length) {
        let temp_version = _.clone(facetGroup);
        let diff = facetGroup.buckets.length - facetGroup.facet_display_count;

        if (diff > 10) {
          diff = 10;
        }
        facetGroup.facet_display_count += diff;
        temp_version.buckets = _.last(
          _.first(facetGroup.buckets, facetGroup.facet_display_count),
          diff
        );

        const buckets = _.first(
          temp_version.buckets,
          temp_version.facet_display_count
        );

        this.setState((state) => ({
          currentDisplayList: state.currentDisplayList.concat(buckets),
          displayCount: state.displayCount + diff,
          isLoading: false,
        }));
      } else {
        this.setState({ isLoading: false });
      }

      track("user_looked_at_more_facets", "View more facets", {
        facet_type: facetGroup.facet_type,
      });
      clearTimeout(timeoutId);
    }, 1000);
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    // List of buckets has changed, since we only update it ONCE
    // inside getInitialState(), we need to check each time it
    // is changed in the parent.
    //
    // Note: This fixes an issue where selecting MULTIPLE items from
    // the bucket would cause only the FIRST item to be marked as hidden.
    this.setState({
      currentDisplayList: nextProps.facetList.buckets,
      displayCount: nextProps.facetList.facet_display_count
    })
  },
  onPanelToggle() {
    this.setState({ opened: !this.state.opened })
  },
  render: function() {
    const facetList = this.props.facetList;
    const facetType = facetList.facet_type;

    let id = facetType + "_facets";

    const buckets = _.first(
      this.state.currentDisplayList,
      this.state.displayCount
    )

    return (
      <div className="facet_panel" ref="facetPanel">
        <FacetListHeader
          id={id}
          title={facetList.title_text}
          facetName={facetList.facet_name + " "}
          show={facetList.display}
          onToggle={this.onPanelToggle}
        />
        <div className="facet_body clearfix" id={facetType + "_content"}>
          <div id={facetType + "_facet_holder"}>
            <FacetList
              terms={buckets}
              facetType={facetType}
              opened={this.state.opened}
            />
          </div>
          <FacetSectionShowMore
            facetType={facetType}
            bucketLength={facetList.buckets.length}
            addMoreFacets={this.addMoreFacets}
            displayCount={this.state.displayCount}
            isLoading={this.state.isLoading}
          />
        </div>
      </div>
    );
  }
});

export default FacetListContainer;
