import MultiSelectCompanyHolder from "./multiSelectCompanyHolder";

const SelectedMutedCompanies = createReactClass({
  mixins: [Reflux.connect(MutedCompaniesStore, "store")],
  render: function () {
    const removeCompany = function(company, e){
      e.stopPropagation();
      console.log(company)
      MutedCompaniesActions.selectionRemoved(company)
    };
    let mutedCompanies = this.state.store.currentMuted.map(function(company, index){
      return <MultiSelectCompanyHolder key={index} company={company} classNames={["mute_selected"]} labelClasses={["multirow_selection_selected"]} onClick={removeCompany} />
    });
    if(mutedCompanies.length < 1){
      mutedCompanies = <p id="no_muted_companies_selected">None</p>
    }
    return (
      <div className="multirow_selection_box multirow_selections_selected">
        {mutedCompanies}
      </div>
    );
  }
});

window.SelectedMutedCompanies = SelectedMutedCompanies;
export default SelectedMutedCompanies;
