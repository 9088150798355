import LegacyModalService from "@javascript/globals/LegacyModalService";
import Button from "../lds/Button";
import ButtonGroup from "../lds/ButtonGroup";
import { ModalBody, ModalFooter } from "../lds/modal/Modal";
import NameThisListCard from "./nameThisListCard";
import Icon from "../lds/Icon";
import Heading from "../lds/typography/Heading";

const ShareListModalBody = (props) => {
  /**
   * https://www.pivotaltracker.com/story/show/186622915
   * The keyword "ref" has a special meaning in React,
   * it should not be used.
   */
  const list = { ...props.list };
  list.list_ref = list.ref;
  list.ref = null;

  const ctaVariant = props.checked === 0 ? "disable" : "primary";

  return (
    <form id="share_list_form" onSubmit={props.submit_form}>
      <ModalBody className="flex-col">
        <input
          type="hidden"
          name="authenticity_token"
          value={props.authenticity_token}
        />
        <NameThisListCard {...list} />
        <div className="share_list_instructions">
          Select the user(s) you wish to share this list with. An email
          containing a link to this list will then be sent to them.
        </div>
        <PaginatedSelectableListOfUsers
          userList={props.users}
          toggleUser={props.toggleUser}
          featureFlags={props.feature_flags}
          uniqueId="share_list"
          mixpanelActions={["search colleagues on shared list modal"]}
          columnsPerPage="2"
          usersPerColumn="5"
          legend="Share with"
          width="355px"
          size="small"
        />
      </ModalBody>

      <ModalFooter align="flex-end">
        <ButtonGroup size="medium">
          <Button
            onClick={LegacyModalService.closeModal}
            size="medium"
            type="submit"
            variant="secondary"
          >
            Cancel
          </Button>

          <Button
            id="share_list_submit_button"
            onClick={props.submit_form}
            size="medium"
            type="submit"
            variant={ctaVariant}
          >
            Share List
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
};

const ShareListModalSuccess = ({ listName, userNames }) => {
  let sharedWith = <></>;
  let namesForDisplay = userNames.map((name) => (<b>{name}</b>));

  if (userNames.length === 1) {
    sharedWith = namesForDisplay[0];
  } else {
    const finalName = namesForDisplay.pop();

    if (namesForDisplay.length > 1) {
      namesForDisplay = namesForDisplay.map((name) => (<>{name}{", "}</>));
    }

    sharedWith = <>{namesForDisplay} and {finalName}</>;
  }

  return (
    <>
      <ModalBody className="flex-col align-center justify-center">
        <Icon name="special-success" />

        <Heading size="h2">List shared successfully!</Heading>

        <p id="rspec_share_list_with">
          The list "{listName}" was shared with {sharedWith}.
        </p>
      </ModalBody>

      <ModalFooter align="flex-end">
        <Button
          size="medium"
          variant="primary"
          onClick={LegacyModalService.closeModal}
        >
          Close
        </Button>
      </ModalFooter>
    </>
  );
};

const ShareListModalError = () => {
  return (
    <>
      <ModalBody className="modal-success-content">
        <Icon name="special-destruct" />
        <Heading size="h2">We encountered an unexpected error.</Heading>
        <p>We're sorry, but something went wrong. Please reload the page.</p>
      </ModalBody>

      <ModalFooter align="flex-end">
        <Button
          variant="primary"
          size="medium"
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload Page
        </Button>
      </ModalFooter>
    </>
  );
};

var ShareListModal = createReactClass({
  getInitialState: function () {
    return {
      users: this.props.users,
      comment: "",
      checked: 0,
      modalStep: "initial",
      usersSharedWith: [],
    };
  },

  submit_form: function (e) {
    e.stopPropagation();
    e.preventDefault();
    var data = {
      comment: this.state.comment,
      users: _.map(
        _.filter(this.state.users, function (user) {
          if (user) {
            return user.checked;
          }
        }),
        function (u) {
          if (u) {
            return u.id;
          }
        }
      ),
    };
    if (data.users.length > 0) {
      $("body").addClass("wait");
      $.ajax({
        url: "/lists/" + this.props.list._id + "/share_list",
        type: "POST",
        headers: {
          "X-CSRF-Token": this.props.authenticity_token,
        },
        data: data,
        success: function (result) {
          $("body").removeClass("wait");
          this.setState({
            modalStep: "success",
            usersSharedWith: result.users,
          });
        }.bind(this),
        error: function () {
          $("body").removeClass("wait");
          this.setState({ modalStep: "error" });
        }.bind(this),
      });
    } else {
      alert(
        "Hey man... you have to choose at least someone to share this list with."
      );
    }
    return false;
  },

  toggleUser: function (user_id) {
    var user = _.find(this.state.users, function (user) {
      if (user && user.id) {
        return user.id == user_id;
      }
    });
    user.checked = !user.checked;
    var checked = this.state.checked;
    if (user.checked) {
      checked++;
    } else {
      checked--;
    }
    this.setState({ users: this.state.users, checked: checked });
  },

  updateComment: function (comment) {
    this.setState({ comment: comment });
  },

  render: function () {
    return (
      <>
        {this.state.modalStep === "initial" && (
          <ShareListModalBody
            {...this.state}
            {...this.props}
            toggleUser={this.toggleUser}
            updateComment={this.updateComment}
            submit_form={this.submit_form}
          />
        )}

        {this.state.modalStep === "success" && (
          <ShareListModalSuccess
            listName={this.props.list.name}
            userNames={this.state.usersSharedWith}
          />
        )}

        {this.state.modalStep === "error" && <ShareListModalError />}
      </>
    );
  },
});

window.ShareListModal = ShareListModal;
export default ShareListModal;
