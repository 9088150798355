import EventTitle from "./eventTitle";
import EventDate from "./eventDate";
import EventContent from "./eventContent";
import EventEntities from "./eventEntities";
import ModifyEvent from "./modifyEvent";

const EventDisplayCell = (props) => {
  return (
    <td className="rspec-event-display-cell">
      <EventTitle {...props} />
      <EventDate {...props} />
      <EventContent {...props} />
      <EventEntities {...props} />
      <ModifyEvent {...props} />
    </td>);
};

export default EventDisplayCell;
