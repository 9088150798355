import React from 'react';
import { createContext } from 'react';

import CategorySelectionSection from "./CategorySelectSection";
import SearchCriteriaPanel from "./SearchCriteriaPanel";
import { DEFAULT_LIST_TYPE } from "../../../../constants/lists/list_constants";
import PreviewSection from "./PreviewSection";

const DetailsSectionContext = createContext({
  query: "",
  entityCountsDetails: {},
  categorySelected: () => {},
  selectedCategory: DEFAULT_LIST_TYPE,
  name: "",
  onListNameChange: () => {},
  defaultOptionValue: DEFAULT_LIST_TYPE,
  entityCountDetail: {}
});

const DetailsSection = ({ query, baseQuery, entityCountsDetails, categorySelected, selectedCategory, name, onListNameChange }) => {
  const entityCountDetail = entityCountsDetails[selectedCategory];

  return(
    <DetailsSectionContext.Provider
      value={{
        defaultOptionValue: DEFAULT_LIST_TYPE,
        categorySelected,
        entityCountDetail,
        entityCountsDetails,
        name,
        onListNameChange,
        query,
        selectedCategory,
      }}
    >
      <SearchCriteriaPanel search={query} baseQuery={baseQuery} />
      <CategorySelectionSection />
      <PreviewSection />
    </DetailsSectionContext.Provider>
  )
}

export default DetailsSection;
export { DetailsSectionContext };
